#assignment_views {
  &.fullscreen_modal_container {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-y: auto;
    height: 95vh;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }
}
