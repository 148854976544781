.view_student_attempt__popup-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 20;
}

.view_student_attempt__popup {
  background: #ffffff;
  max-width: 900px;
  width: 95%;
  height: 99%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 10px 20px;
  max-width: 640px;
  max-height: 544px;
  overflow: scroll;
  border-radius: 4px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);

  &-close-popup {
    display: flex;
    align-items: end;
    justify-content: end;
    cursor: pointer;
    background-image: url(../../../assets/img/pec/all-images/pop-up-close@2x.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    width: 33px;
  }

  &-heading {
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: normal;
    color: #000000;
  }

  &-heading2 {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    // margin-top: -31px;
    margin-top: -23px;
  }

  &-img {
    // padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-top-section{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}
.main__section-student-attemp-details{
  margin-top: -28px;
}
.main__section-student-attemp-details-bottomheading-primary {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}

.main__section-student-attemp-details-bottomheading-primary-width{
  width: 75px;
}

.table-sorting-arrow-attempt-history{
  position: relative;
  top: -5px;
  left: 4px;
}

.main__section-student-attemp-details-data {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  padding-top: 10px;
  padding-bottom: 10px;
}
.main__section-student-attemp-details-down-row {
  border-top: 1px solid #EAEAEA;
  border-bottom: 1px solid #EAEAEA;
}
.main__section-student-attemp-details-bottom-row {
  // border-top: 1px solid #EAEAEA;
  border-bottom: 1px solid #EAEAEA;
}

@media only screen and (max-width: 1000px) {
  .view_student_attempt__popup-heading {
    // margin-left: 40px;
    margin-right: 40px;
    font-size: 24px;
    line-height: 28px;
  }

  .view_student_attempt__popup {
    gap: 10px;
  }
  .view_student_attempt__popup-heading2{
    margin-top: -7px;
  }
  .main__section-student-attemp-details {
    margin-top: -17px;
  }
}

@media only screen and (max-width: 641px) {
  .view_student_attempt__popup-heading {
    margin-left: 0px;
    margin-right: 0px;
  }

  .view_student_attempt__popup {
    gap: 5px;
  }
  .view_student_attempt__popup-heading2{
    margin-top: -7px;
  }
  .main__section-student-attemp-details {
    margin-top: -17px;
  }
}