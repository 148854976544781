label.additionalres {
  cursor: pointer;
  padding: 5px !important;
  width: 100%;
}
.form-check {
  display: flex;

  input {
    width: 18px;
    height: 18px;
    border-radius: 2px;
    border: 1px solid #c7c7c7;
    margin: auto 0;
  }
  label {
    color: #000000;
    padding-left: 5px;
    font-size: 15px;
    line-height: 18px;
    font-weight: 400;

    @media (max-width: 500px) {
      line-height: 19px;
    }
    margin-bottom: 0px;
  }
}
.form-group {
  & > label {
    font-size: 15px;
    line-height: 18px;
    color: #000000;
  }
}
.assign_form_details {
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  height: 100%;
  background-color: var(--white);
  padding: 30px;
  background-color: #ffffff;
  @media (max-width: 425px) {
    padding: 16px;
  }

  .line-separater {
    border-top: 1px solid #e8e8e8;
  }
}
