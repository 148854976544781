.pop_up_view .be-content #b2c.card,
.pop_up_view #class_body.b2c {
  box-shadow: none;
  // padding-bottom: 15px;
  margin: 0;
  display: flex;
  max-width: none;
  // @media (max-width: "599px") {
  //   margin-top: -45px;
  // }
  // background-color: #fef8ec;
  #b2c.sub-menu {
    background: rgba(207, 250, 252, 0.2);
    .menu-title {
      color: #013057;
      font-size: 30px;
      font-weight: 600;
      padding: 16px;
      text-align: center;
    }
    .menu-list {
      margin: auto;
      max-width: 1360px;
      // background: rgba(207, 250, 252, 0.2);

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        overflow: hidden;
        padding-top: 5px;
        li.tab {
          display: flex;
          margin: 0 2px;
          //   border-top-left-radius: 15px;
          //   border-top-right-radius: 15px;
          //   box-shadow: 2px 0 4px 0 #b2b0b0;
          //   background-color: white;
          .tab-item {
            min-height: 50px;
            display: flex;
            background-color: white;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
            box-shadow: 2px 0 4px 0 #b2b0b0;
            color: #505759;
            .tab-body {
              font-size: 20px;

              margin: auto 25px;

              display: flex;

              // img {
              //   padding-right: 10px;
              // }
            }
            @media (max-width: 768px) {
              padding: 10px;
              text-align: center;
              .tab-body {
                margin: auto 8px;
                font-size: 18px;
              }
            }
            @media (max-width: 599px) {
              .tab-body {
                font-size: 14px;
                margin: auto;
                span {
                  display: none;
                }
              }
            }
            @media (max-width: 500px) {
            }
            // &:hover {
            //   color: #000000;
            //   font-weight: 600;
            //   &.as {
            //     background-color: #ffbb1c;
            //   }
            //   &.result {
            //     background-color: #ff757a;
            //   }
            //   &.assessment {
            //     background-color: #84bd01;
            //   }
            // }

            span {
              &:hover {
                background-color: transparent;
              }
            }

            &.active {
              color: #000000;
              font-weight: 600;

              &.as {
                background-color: #ffbb1c;
                span {
                  color: black;
                }
              }
              &.result {
                background-color: #ff757a;
                span {
                  color: black;
                }
              }
              &.assessment {
                background-color: #84bd01;
                span {
                  color: black;
                }
              }
            }
          }
        }
      }
    }
    &.as {
      border-bottom: 6px solid #ffbb1c;
    }
    &.result {
      border-bottom: 6px solid #ff757a;
    }
    &.assessment {
      border-bottom: 6px solid #84bd01;
    }
  }
  .learnosity {
    &.api {
      .sub-menu {
        position: sticky;
        position: -webkit-sticky;
        top: 50px;
        margin-bottom: 0px;
      }
    }
    padding: 0 0 16px 0;
    // @media (max-width: 768px) {
    //   padding: 16px 8px;
    // }
    @media (max-width: 1360px) {
      box-shadow: none;
    }
    // @media (max-width: 500px) {
    //   padding: 1px 4px 0 4px;
    // }
    .sub-menu {
      // position: sticky;
      // top: 50px;
      // @media (max-width: 599.9px) {
      //   position: fixed;
      //   top: 58px;
      //   width: 100%;
      // }
      // z-index: 10000;
      // margin-top: -8px;
      // margin-left: -16px;
      // margin-right: -16px;
      // @media (max-width: 768px) {
      //   // margin-top: -8px;
      //   margin-left: -8px;
      //   margin-right: -8px;
      // }
      // @media (max-width: 500px) {
      //   margin-top: -8px;
      //   margin-left: -4px;
      //   margin-right: -4px;
      //   width: 103%;
      // }
      min-height: 45px;
      background-color: #fff;
      // border-bottom: 1px solid #007fa3;
      .menu-list {
        padding: 0 16px;
        @media (max-width: 500px) {
          padding: 0 8px;
        }
        ul {
          padding-left: 0;
          margin-bottom: 0;
          list-style: none;
          display: flex;
          min-height: 45px;
          justify-content: center;
          li {
            min-height: 45px;
            a {
              cursor: pointer;
              display: block;
              height: 100%;
              padding: 10px 18px;
              @media (max-width: 1000px) {
                font-size: 14px;
                padding: 10px 10px;
              }
              // @media (max-width: 500px) {
              //   font-size: 14px;
              //   // padding: 10px 10px;
              // }
              font-size: 16px;
              font-weight: 600;
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;
              color: #007fa3;
              &.active,
              .active:hover {
                background-color: #007fa3;
                color: #fff;
              }
              &:hover {
                background-color: #007fa3;
                color: #fff;
              }
            }
            div.navlink {
              cursor: pointer;
              display: block;
              height: 100%;
              padding: 10px 24px;
              font-size: 16px;
              font-weight: 600;
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;
              color: #007fa3;
              &.active {
                background-color: #007fa3;
                color: #fff;
              }
            }
          }
        }
      }
    }
    .body {
      position: relative;
      margin-bottom: -16px;
      padding-bottom: 150px;
      border-radius: 5px;
      &.as {
        background-color: #fef8ec;
      }
      @media (max-wdith: 500px) {
        padding: 0 10px;
      }
      & > div:not(.loading) {
        margin: auto;
        max-width: 1320px;
      }
      //padding-top: 16px;
      .learnosity_loading {
        padding-top: 48px;
      }
      .no-history {
        .model-answer-box-no-permission {
          text-align: center;
          padding: 18px;
          .model-answer-image {
            padding-bottom: 14px;
            img {
              max-width: 250px;
              width: 70%;
            }
          }
          .model-answer-text {
            h3 {
              padding-bottom: 14px;
              //font-family: OpenSans;
              font-size: 24px;
              font-weight: 600;
            }
            h5 {
              max-width: 518px;
              margin: 0 auto;
              //font-family: OpenSans;
              font-size: 16px;
            }
          }
        }
      }

      .filter-row {
        padding-top: 6px;
        padding-bottom: 10px;
        &.select-tab-excell {
          display: flex;
          justify-content: space-between;
          @media (max-width: 425px) {
            display: block;
          }
        }

        button {
          padding: 4px 10px;
          line-height: 23px;
          @media (max-width: 768px) {
            margin: 6px 10px 70px 3px;
          }
          @media (max-width: 425px) {
            margin: 6px 3px 17px 3px;
          }
        }
        .user-report {
          display: inline-block;
          h3 {
            //font-family: OpenSans;
            font-size: 24px;
            font-weight: 600;
            color: #000000;
          }
          h5 {
            //font-family: OpenSans;
            font-size: 14px;
            color: #505759;
          }
        }

        button {
          float: right;
        }
      }
      .questions-bar {
        overflow-x: auto;
        padding: 15px 0;
        table {
          width: 100%;
          thead {
            tr {
              background-color: #eee;
              th {
                padding: 10px;
                border: 1px solid #e3e3e3;
              }
            }
          }
          tbody {
            tr {
              td {
                padding: 10px;
                border: 1px solid #e3e3e3;
                .dot {
                  //text-align: center;
                  margin: auto 0;
                  border-radius: 50%;
                  width: 16px;
                  height: 16px;
                  &.green {
                    background-color: #61ae41;
                  }
                  &.red {
                    background-color: #da1819;
                  }
                  &.yellow {
                    background-color: #f39c12;
                  }
                  &.grey {
                    background-color: #ddd;
                  }
                }
              }
            }
          }
        }
      }
      .submited-report {
        padding: 0 20px;
      }
      .submited-box {
        text-align: center;
        padding: 0 10px;
        .submited-icon {
          max-width: 500px;
          width: 100%;
          margin-top: 30px;
          margin-right: auto;
          margin-left: auto;
          i {
            font-size: 150px;
            color: #84bd02;
          }
        }
        .submited-text {
          h5 {
            padding-top: 10px;
          }
        }
        .submited-btn {
          max-width: 500px;
          width: 100%;
          padding-top: 10px;
          margin-right: auto;
          margin-left: auto;
          .btn {
            margin-bottom: 10px;
            min-width: 160px;
            margin: 4px 8px;
            text-align: center;
          }
        }
      }
      .result-menu {
        background-color: #daf0ed;

        @media (max-width: 2560px) {
          margin: 0 -15px;
        }

        @media (max-width: 1024px) {
          margin: 0 -16px;
        }

        @media (max-width: 768px) {
          margin: 0 -8px;
        }
        display: flex;
        padding: 9px 30px;
        height: 50px;
        font-size: 16px;
        color: #007fa3;
        font-weight: bold;
        .gap {
          padding: 0 12px;
          color: #000000;
        }
        .active {
          font-weight: bold;
          color: #000000;
          cursor: default;
        }
      }
      .report-box {
        //padding: 0 16px;
        @media (max-width: 1360px) {
          //padding: 0 8px;
        }
        .filter-row {
          select {
            width: 100%;
            padding: 5px 50px 5px 15px;
            border-radius: 8px;
            background-color: #cffafc;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;

            background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgMjEzLjMzMyAyMTMuMzMzIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyMTMuMzMzIDIxMy4zMzM7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxnPg0KCQk8cG9seWdvbiBwb2ludHM9IjAsNTMuMzMzIDEwNi42NjcsMTYwIDIxMy4zMzMsNTMuMzMzIAkJIi8+DQoJPC9nPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=");
            background-repeat: no-repeat;
            background-size: 12px;
            background-position: 95% 50%;
            border: none;

            option {
              font-size: 16px;
            }

            margin-top: 4px;
            margin-right: 10px;
            width: 152px;
            @media (max-width: 375px) {
              width: 46%;
            }
            height: 36px;
            min-width: -webkit-min-content;
            &.user-last-attemptDate {
              float: right;
              margin: auto;
            }
          }
        }
        .chart-row {
          display: flex;
          padding: 10px 0;
          @media (max-width: 700px) {
            display: block;
            padding: 10px 0;
          }
          @media (max-width: 500px) {
            display: block;
            padding: 10px 10px;
          }
          .left-chart {
            width: 33.3333333333%;

            padding-right: 20px;
            @media (max-width: 700px) {
              width: 100%;
              padding-right: 0px;
            }
            .chart-box {
              position: relative;
              .left-chart-center-title {
                position: absolute;
                text-align: center;
                width: 100%;
                bottom: 20px;
                h3 {
                  color: #008637;
                }
              }
              .chart {
                width: 100%;
                height: 200px;
              }
            }
            .left-chart-footer {
              display: flex;
              justify-content: center;
              div {
                margin: auto;
                padding: 10px 0;
                h3 {
                  text-align: center;
                  font-size: 24px;
                  @media (max-width: 768px) {
                    font-size: 18px;
                  }
                }
                /*
                    padding: 10px 30px;
                    @media (max-width: 980px) {
                      padding: 10px 20px;
                    }
                    @media (max-width: 780px) {
                      padding: 10px 10px;
                    }
                    */
              }
            }
          }
          .right-chart {
            width: 66.6666666667%;
            padding-left: 20px;
            // border-left: 1px solid #00000069;
            @media (max-width: 700px) {
              width: 100%;
              border-left: none;
              padding-left: 0px;
            }
            @media (max-width: 500px) {
              border-left: none;
              padding-left: 0px;
            }
            .chart-box {
              padding-top: 10px;
              position: relative;

              .left-title {
                position: absolute;
                left: 0px;
                text-align: center;
                width: 280px;
                bottom: 0px;
                transform-origin: 0 0;
                transform: rotate(270deg);
                //font-family: OpenSans;
                font-size: 12px;
                color: #505759;
              }
              .bottom-title {
                position: absolute;
                bottom: 0px;
                text-align: center;
                width: 100%;
                //font-family: OpenSans;
                font-size: 12px;
                color: #505759;
                @media (max-width: 500px) {
                  bottom: 11px;
                }
              }
              .chart {
                width: 100%;
                height: 250px;
                padding-left: 25px;
                padding-bottom: 20px;
              }
            }
          }
          .report-chart-title {
            //font-family: OpenSans;
            font-size: 16px;
            color: #000000;
          }
          .report-chart-subtitle {
            //font-family: OpenSans;
            font-size: 12px;
            color: #505759;
          }
        }
      }
      .questions-row {
        display: flex;
        justify-content: space-between;
        min-height: 50px;
        background-color: #f9f9f9;
        border: 1px solid #e3e3e3;
        .questions-list-title {
          max-width: 160px;
          font-size: 14px;
          font-weight: 600;
          margin: auto 16px auto 16px;
        }
        .questions-list {
          display: flex;
          min-height: 50px;
          flex-wrap: wrap;
          width: max-content;
          flex-direction: row;
          justify-content: flex-start;
          border-left: 1px solid #e3e3e3;
          .question-item {
            height: 50px;
            margin: 0 0px;
            border-right: 1px solid #e3e3e3;
            border-bottom: 1px solid #e3e3e3;
            flex: 0;

            .content {
              padding: 15px 0px;
              cursor: pointer;
              min-width: 50px;
              text-align: center;
              color: #007fa3;
            }
          }
          .question-loading {
            height: 20px;
            margin: auto 20px;
          }
          .no-data {
            height: 20px;
            margin: auto 20px;
          }
        }
      }
      .type-row {
        padding: 10px 0;
        .type-bar {
          cursor: pointer;
          display: flex;
          height: 50px;
          padding: 0 22px;
          justify-content: space-between;
          background-image: linear-gradient(to bottom, #f0f0f0, #dddddd);
          &:hover {
            background-image: none;
            background-color: #eee;
          }
          > div {
            padding: 13px 0;
            &.title {
              display: flex;
              // i {
              //   font-size: 24px;
              //   padding-right: 12px;
              // }
              i {
                font-size: 24px;
                transition: transform 0.2s;
                &.open {
                  transform: rotate(90deg);
                }
              }
              h4 {
                margin: auto;
                //font-family: OpenSans;
                font-size: 18px;
                padding-left: 10px;
              }
            }
            &.progress-bar-box {
              display: flex;
              .text {
                margin: auto;
                padding-right: 5px;
              }
              .progress-bar {
                margin-left: 5px;
                width: 209px;
                height: 12px;
                margin: auto;
                @media (max-width: 500px) {
                  display: none;
                }
                .filler {
                  background-image: linear-gradient(
                    to bottom,
                    #29d9d2,
                    #12b2a6
                  );
                }
              }
            }
            &.progress-bar {
              width: 260px;
              .text {
                vertical-align: middle;
                display: inline-block;
                //font-family: OpenSans;
                font-size: 16px;
              }
              .rc-progress-line {
                padding-left: 10px;
                width: 200px;
              }
            }
          }
        }
        .type-item {
          display: flex;
          min-height: 40px;
          padding: 0 22px;
          justify-content: space-between;
          border: 1px solid #e2e2e2;
          border-top: none;
          .title {
            display: flex;
            // padding-left: 40px;
            padding: 8px 0 8px 0px;

            h4 {
              margin: auto;
              //font-family: OpenSans;
              font-size: 14px;
            }
          }
          .progress-bar-box {
            margin-left: 5px;
            display: flex;
            .text {
              margin: auto;
              padding-right: 5px;
            }
            .progress-bar {
              width: 209px;
              height: 12px;
              margin: auto;
              @media (max-width: 500px) {
                display: none;
              }
              .filler {
                background-image: linear-gradient(to bottom, #f6ae00, #ea7600);
              }
            }
          }
          .progress-bar {
            width: 260px;
            height: auto;
            margin: auto 0;
            .text {
              vertical-align: middle;
              display: inline-block;
              //font-family: OpenSans;
              font-size: 14px;
            }
            .rc-progress-line {
              padding-left: 10px;
              width: 200px;
            }
          }
        }
      }
      .all-result-row {
        padding: 10px 0;
        overflow: auto;
      }
      .suggestion-show-score-row {
        border: solid 1px #ea7600;
        background-color: #ea7600;
        padding: 6px 16px;
        div {
          font-size: 14px;
          font-weight: 600;
          color: #fff;
        }
      }
      .suggestion-box {
        background-color: #fffaf1;
        border: 1px solid #ee8c3a;
        border-radius: 2px;
        //display: flex;
        padding: 5px 5px;
        .suggestion-icon {
          width: 80px;
          text-align: center;
          img {
            width: 55px;
            padding-top: 10px;
          }
        }
        .suggestion-body {
          width: 100%;
          .sub-head {
            background-color: #daf0ed;
            font-size: 14px;
            font-weight: 600;
            color: #000000;
            padding: 5px 10px;
          }
          ul {
            list-style: none;
            padding: 5px 18px;
            margin: 0px;
            li {
              // margin-top: 5px;
              ul.list-item {
                padding: 0;
                li {
                  > div.mat-box {
                    // width: fit-content;
                    // cursor: pointer;
                    // border-radius: 4px;
                    // transition: 0.2s;
                    // display: flex;
                    // padding: 4px 2px;
                    width: fit-content;
                    cursor: pointer;
                    border-radius: 4px;
                    transition: 0.2s;
                    display: flex;
                    padding: 4px 2px;
                    align-items: flex-start;
                    &:hover {
                      background-color: #daf0ed;
                    }
                    img {
                    }
                    .title {
                      margin: auto 5px;
                      color: #007fa3;
                      text-decoration: underline;

                      vertical-align: middle;
                    }
                  }
                  //margin-top: 0px;
                }
              }
              h3 {
                //padding-top: 5px;
                font-size: 16px;
                color: #000000;
                font-size: 16px;
                font-weight: 600;
              }
              padding: 2px 0;
            }
          }
        }
      }
    }
  }
  section#menu {
    height: calc(100% - 150px);

    .menu_bar {
      display: flex;
      min-height: 50px;
      padding: 14px 16px;
      position: -webkit-sticky;
      position: sticky;
      top: 50px;
      z-index: 2;
      background-color: #daf0ed;
      @media (max-width: 500px) {
        padding: 14px 8px;
      }
      font-size: 16px;
      color: #007fa3;
      font-weight: bold;
      .gap {
        padding: 0 12px;
        color: #000000;
        @media (max-width: 500px) {
          display: none;
        }
      }
      .active {
        font-weight: bold;
        color: #000000;
        cursor: default;
      }
      .study-group {
        display: flex;
        i {
          font-size: 20px;
          line-height: initial;
        }
        div.name {
          padding-left: 5px;
        }
      }
      a {
        @media (max-width: 500px) {
          display: none;
        }
      }
    }
    section {
      .col-list-header {
        cursor: pointer;
        width: 100%;
        display: flex;
        background-image: linear-gradient(to bottom, #f0f0f0, #dddddd);
        height: 50px;
        position: -webkit-sticky;
        position: sticky;
        top: 100px;
        z-index: 1;
        -webkit-transition: 2s; /* Safari prior 6.1 */
        transition: 2s;
        font-size: 18px;
        padding: 12px 15px;
        //font-family: OpenSans;
        &:hover {
          background-image: linear-gradient(to bottom, #f0f0f0, #d3d3d3);
        }
        &:active {
          background-image: linear-gradient(to bottom, #f0f0f0, #ccc);
        }
        .handle {
          width: 35px;
          i {
            font-size: 25px;
            padding-right: 5px;
            &.mdi-chevron-right {
              padding-right: 0px;
              padding-left: 3px;
            }
          }
        }
        .list-title {
          padding-left: 5px;
          h3 {
            font-size: 18px;
            line-height: normal;
            margin: auto;
          }
        }
        .list-score {
          h4 {
            display: inline-block;
            font-size: 14px;
            font-weight: 600;
          }
        }
      }
    }
    table {
      width: 100%;
      padding: 0 20px;
      thead {
        tr {
          cursor: default;
          &.tab {
            cursor: pointer;
          }

          &.tab:hover {
            background-image: linear-gradient(to bottom, #f0f0f0, #d3d3d3);

            th {
              background-image: linear-gradient(to bottom, #f0f0f0, #d3d3d3);
            }
          }
          &.tab:active {
            background-image: linear-gradient(to bottom, #f0f0f0, #ccc);
            th {
              background-image: linear-gradient(to bottom, #f0f0f0, #ccc);
            }
          }
          th {
            background-image: linear-gradient(to bottom, #f0f0f0, #dddddd);
            height: 50px;
            position: -webkit-sticky;
            position: sticky;
            top: 100px;
            z-index: 1;
            -webkit-transition: 2s; /* Safari prior 6.1 */
            transition: 2s;
            font-size: 18px;
            padding: 0 15px;
            //font-family: OpenSans;
            .tab {
              display: flex;
              white-space: nowrap;
              .mdi {
                font-size: 25px;
                padding-right: 5px;
                &.mdi-chevron-right {
                  padding-right: 0px;
                  padding-left: 3px;
                }
              }
            }
            &.answers {
              @media (max-width: 500px) {
                display: none;
              }
            }
            &.none {
              width: 100%;
              @media (max-width: 500px) {
                display: none;
              }
            }
            &:nth-child(1) {
              width: 35px;
              min-width: 35px;
              padding-right: 0px;
              @media (max-width: 500px) {
                padding-left: 8px;
              }
            }
            &.level-name,
            &.book-name,
            &.exercise {
              white-space: nowrap;
              //width: 100%;z
              padding-left: 0px;
              max-width: 799px;
              font-weight: normal;
              padding-left: 0px;
              @media (max-width: 500px) {
                width: 100%;
              }
              /*&.exercise {
                    font-size: 14px;
                    font-weight: 600;
                  }*/
            }
            &.exercise {
              width: 100%;
              @media (max-width: 500px) {
                padding-left: 8px;
              }
            }
            /*
                &:nth-child(3) {
                  width: 100%;
                  @media (max-width: 500px) {
                    display: none;
                  }
                }
                */
            /*
                &:nth-child(4) {
                  width: 15%;
                  font-size: 14px;
                  font-weight: 600;
                  max-width: 180px;
                  @media (max-width: 500px) {
                    display: none;
                    width: auto;
                    min-width: auto;
                  }
                }
                */
            &.submitted {
              font-size: 14px;
              font-weight: 600;
              white-space: nowrap;
              @media (max-width: 500px) {
                display: none;
              }
            }
            &.p-score {
              width: 12%;
              font-size: 14px;
              font-weight: 600;
              min-width: 180px;
              @media (max-width: 500px) {
                min-width: 0px;
                display: none;
              }
              &.mobile {
                display: none;
                @media (max-width: 500px) {
                  display: table-cell;
                }
              }
            }
            &.answers {
              white-space: nowrap;
              padding-left: 0;
              font-size: 14px;
              font-weight: 600;
            }
            &:nth-child(6) {
              width: 10%;

              padding-left: 0;
              font-size: 14px;
              font-weight: 600;
            }
            &:last-child {
              width: 35px;
              min-width: 35px;
            }
            .title {
              div {
                max-width: 400px;
                width: 100%;
              }
            }
          }
        }
      }
      tbody {
        tr {
          cursor: pointer;
          -webkit-transition: 0.15s; /* Safari prior 6.1 */
          transition: 0.15s;

          &:hover {
            background-color: #ebf9ff;
          }
          &:active {
            background-color: #d4f2ff;
          }
          td {
            white-space: nowrap;
            height: 44px;
            border-bottom: 1px solid #e8e8e8;
            padding: 5px 15px;
            font-size: 16px;
            &.none {
              @media (max-width: 500px) {
                display: none;
              }
            }
            &.sub-title {
              padding-left: 0px;
              padding-right: 0px;
              width: 100%;
              position: relative;
              .unit-name {
                color: #007fa3;
                font-size: 15px;
                max-width: 500px;
                white-space: normal;
                //display: table;
                @media (max-width: 500px) {
                  width: 100%;
                }
              }
            }
            &.other {
              padding-left: 0px;
              width: 35%;
              .unit-name {
                color: #007fa3;
                font-size: 15px;
                //display: table;
              }
              @media (max-width: 500px) {
                display: none;
              }
            }
            &.empty:first-child {
              width: 35px;
              min-width: 35px;
              //min-width: 50px;
            }
            &.empty:last-child {
              width: 35px;
              text-align: center;
              font-size: 20px;
            }
            &.submitted {
              font-size: 14px;
              i {
                font-size: 24px;
                color: #84bd00;
              }
              @media (max-width: 500px) {
                display: none;
              }
            }
            &.title {
              //width: 5%;
              font-weight: 600;
              color: #007fa3;
              padding-left: 0px;
              @media (max-width: 500px) {
                padding-left: 8px;
              }
              div {
                white-space: normal;
                //max-width: 400px;
                @media (max-width: 500px) {
                  //max-width: 200px;
                }
                &.study-group,
                &.assessment {
                  display: flex;
                }
                min-height: 25px;
                &.study-group {
                  i {
                    font-size: 20px;
                    line-height: initial;
                  }
                  div.name {
                    padding-left: 5px;
                  }
                }

                .order-number {
                  color: #000000;
                  font-size: 16px;
                  min-height: 25px;
                }
                .assessment-name {
                  padding-left: 15px;
                  font-size: 15px;
                  min-height: 22px;
                  margin: auto 0;
                  @media (max-width: 500px) {
                    padding-left: 5px;
                  }
                }
                &.unit-box {
                  display: flex;

                  > .unit-name {
                    padding-left: 15px;
                  }
                  .label {
                    color: #000000;
                    font-weight: 600;
                    //display: table;
                    white-space: nowrap;
                    min-height: 25px;
                  }
                }
              }
            }
            &.center {
              width: 15%;
            }
            &.info {
              //width: 30%;

              .progress {
                display: flex;
                .text {
                  font-size: 14px;
                  color: #008638;
                }
                button {
                  margin-left: 25px;
                  @media (max-width: 500px) {
                    display: none;
                  }
                }
                .progress-bar {
                  margin: auto;
                  margin-left: 5px;
                  width: 100%;
                  @media (max-width: 500px) {
                    display: none;
                  }
                }
              }
            }
            &.answer {
              font-size: 14px;
              padding-left: 0;
              @media (max-width: 500px) {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  .bookseries_box {
    height: 150px;

    @media (max-width: 500px) {
      height: 70px;
    }
    .react-parallax {
      height: 100%;
    }
    .bookseries_logo {
      height: 150px;

      @media (max-width: 500px) {
        height: 60px;
      }
      img {
        max-width: 200px;
        margin: 27px;
        @media (max-width: 500px) {
          height: 60px;
          margin: 5px;
        }
      }
      text-align: center;
    }
  }
}
