@font-face {
  font-family: "icomoon";
  src: url("./fonts/icomoon.eot?djzpkd");
  src: url("./fonts/icomoon.eot?djzpkd#iefix") format("embedded-opentype"),
    url("./fonts/icomoon.ttf?djzpkd") format("truetype"),
    url("./fonts/icomoon.woff?djzpkd") format("woff"),
    url("./fonts/icomoon.svg?djzpkd#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  //   speak: never;
  margin: auto 10px;
  font-size: 30px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-tab-icon-assessment {
  color: #84bd01;
  &.active {
    color: black;
  }
}

.icon-tab-icon-result {
  color: #ff757a;
  &.active {
    color: black;
  }
}

.icon-tab-icon-analysis {
  color: #ffbb1c;
  &.active {
    color: black;
  }
}

.icon-tab-icon-analysis:before {
  content: "\e900";
}
.icon-tab-icon-assessment:before {
  content: "\e901";
}
.icon-tab-icon-result:before {
  content: "\e902";
}
