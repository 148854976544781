@keyframes animateThis {
    0% {
        /*transform: translateX(-100%);*/
        height: 100px;
        opacity: 0.2;
    }
    100% {
        /*transform: translateX(0%);*/
        height: 100%;
        opacity: 1;
    }
  }

@keyframes animateOp {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.ani-card 

{   animation: 0.2s ease 0s 1 animateThis; }
.ani-card-body 

{   animation: 0.5s ease 0s 1 animateOp; }