.be-wrapper.onboarding {
  .be-container {
    .main-content {
      padding-bottom: 45px;
      height: 100%;
      &.ft-screen {
        .onBoardingCard {
          .card-body {
            a.setup-later {
              font-size: 12px;
              float: right;
              cursor: pointer;
              margin-top: 10px;
            }
          }
        }
      }
      &.started {
        max-width: 1360px;

        .onBoardingCard {
          .stepborder {
            border-bottom: 1px solid #c7c7c7;
            margin-top: 30px;
          }
          &.step234 {
            .card-body {
              padding: 0;

              .onBoardingTitle {
                max-width: 800px;
                // min-height: 105px;
                padding: 15px 27px;

                @media (max-width: 599px) {
                  padding: 15px 15px;
                }
                .step-bar {
                  display: flex;
                  margin: 0px 25px;
                  @media (max-width: 385px) {
                    margin: 0px;
                  }
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  .item {
                    position: relative;
                    color: #a4a4a4;
                    &.active {
                      color: #007fa3;
                      .circle {
                        background-color: #007fa3;
                        transition: background-color 0.2s linear;
                      }
                    }
                    &.done {
                      .circle {
                        background-color: #84bd00;
                        transition: background-color 0.2s linear;
                      }
                    }

                    .circle {
                      width: 36px;
                      height: 36px;
                      border: solid 3px white;
                      background-color: #a4a4a4;
                      border-radius: 50%;
                      line-height: 28px;
                      color: white;
                      font-size: 16px;
                      font-weight: bold;
                      text-align: center;
                    }
                    .title {
                      position: absolute;
                      color: #a4a4a4;
                      width: 80px;
                      left: -22px;
                      top: 38px;
                      text-align: center;
                      z-index: 1;
                      font-size: 13px;
                      @media (max-width: 385px) {
                        font-size: 11px;
                      }
                    }
                  }

                  .line {
                    height: 5px;
                    background-color: #090;
                    flex: 1;
                    display: flex;
                  }
                  .line .progress {
                    width: 50%;
                  }
                  .line.inactive {
                    background-color: #dfdfdf;
                  }
                  .line.active {
                    background-color: #dfdfdf;
                  }
                  .line.active .progress {
                    background-color: #84bd00;
                  }
                  .line.done {
                    background-color: #84bd00;
                  }
                }
              }
              & > .content {
                padding: 15px 27px;
                label.custom-checkbox {
                  cursor: pointer;
                  font-size: 18px;
                  line-height: 18px;
                  padding-bottom: 8px;
                  padding-top: 8px;
                }
                .form-check {
                  display: flex;

                  input {
                    width: 18px;
                    height: 18px;
                    border-radius: 2px;
                    border: 1px solid #c7c7c7;
                    margin: auto 0;
                  }
                  label {
                    color: #000000;
                    padding-left: 5px;
                    font-size: 18px;
                    line-height: 18px;

                    @media (max-width: 500px) {
                      line-height: 19px;
                    }
                    margin-bottom: 0px;
                  }
                }
                .form-group {
                  & > label {
                    font-size: 18px;
                    line-height: 18px;
                    color: #000000;
                  }
                  select {
                    width: 152px;
                    height: 36px;
                    // margin: 18px 0;
                    padding: 0;
                  }
                }
                .bookseries-tab {
                  margin: 0 -27px;
                  padding: 0 27px;
                  @media (max-width: 599px) {
                    margin: 0 -15px;
                    padding: 0 15px;
                  }
                  border-bottom: 1px solid #007fa3;
                  background-color: #fff;
                  ul {
                    padding-left: 0;
                    margin-bottom: 0;
                    list-style: none;
                    display: flex;
                    min-height: 45px;
                    li {
                      // height: 45px;
                      > div {
                        cursor: pointer;
                        display: block;
                        height: 100%;
                        padding: 10px 24px;
                        font-size: 16px;
                        font-weight: 600;
                        border-top-left-radius: 5px;
                        border-top-right-radius: 5px;
                        color: #007fa3;
                        @media (max-width: 600px) {
                          padding: 10px 8px;
                          font-size: 14px;
                        }
                        &.active {
                          background-color: #007fa3;
                          color: #fff;
                        }
                      }
                    }
                  }
                }
                .classes-list {
                  margin: 0 -27px;

                  @media (max-width: 599px) {
                    margin: 0 -15px;
                  }
                  section.level-row {
                    // padding-bottom: 20px;
                    table {
                      width: 100%;
                      thead {
                        tr {
                          height: 50px;
                          background-image: linear-gradient(
                            to bottom,
                            #f0f0f0,
                            #dddddd
                          );
                          th {
                            background-image: linear-gradient(
                              to bottom,
                              #f0f0f0,
                              #dddddd
                            );
                            height: 50px;
                            position: -webkit-sticky;
                            position: sticky;
                            top: 50px;
                            z-index: 1;
                            -webkit-transition: 2s;
                            transition: 2s;
                            font-size: 18px;
                            padding: 0 15px;
                            &.handle {
                              width: 45px;
                              .tab {
                                i {
                                  font-size: 25px;
                                  transition: transform 0.2s;
                                  &.open {
                                    transform: rotate(90deg);
                                  }
                                }
                              }
                            }
                            &.level-title {
                              padding-left: 0;

                              font-weight: normal;
                              label.custom-checkbox {
                                cursor: pointer;
                                color: #000000;
                                margin-bottom: 0;
                                font-size: 18px;
                                line-height: 18px;
                                width: fit-content;
                              }
                            }
                          }
                        }
                      }
                      tbody {
                        tr {
                          td {
                            .class-row {
                              display: flex;
                              flex-wrap: wrap;
                              padding: 25px 29px;

                              div.form-check {
                                padding: 8px 29px;
                                box-sizing: border-box;
                                width: 15.6%;
                                margin-right: 1%;
                                @media (max-width: 1024px) {
                                  width: 19%;
                                }
                                @media (max-width: 768px) {
                                  width: 32.3%;
                                }
                                @media (max-width: 599px) {
                                  width: 49%;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                .finial-result-list {
                  .additonalResBtn {
                    margin-bottom: 20px;
                    font-size: 17px;
                  }
                  .disabledBtn {
                    background-color: #e9e9e9;
                    color: #c7c7c7;
                  }
                  .redcolor {
                    color: #db0020;
                  }
                  p {
                    font-size: 18px;
                    color: #000000;
                  }
                  ul {
                    list-style: none;
                    padding: 0 15px;
                    li {
                      font-size: 18px;
                      color: #000000;
                      i {
                        padding-right: 17px;
                        font-size: 24px;
                        color: #84bd00;
                      }
                    }
                  }
                }

                @media (max-width: 599px) {
                  padding: 15px 15px;
                }
              }
              & > .bottom {
                border-top: 1px solid #c7c7c7;
                padding: 15px 27px;
                a.setup-later {
                  font-size: 12px;
                  float: right;
                  cursor: pointer;
                  margin-top: 10px;
                }
                button {
                  margin-right: 5px;
                }
                @media (max-width: 599px) {
                  padding: 15px 15px;
                }
              }
            }
          }
        }
      }
    }
  }
}
