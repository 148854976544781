#class_body.b2c {
  .body {
    .b2c_assessments {
      padding: 0 30px;
      @media (max-width: 768px) {
        padding: 0 16px;
      }
      .options-row {
        display: flex;
        justify-content: flex-end;
        padding: 10px 40px;
        @media (max-width: 768px) {
          padding: 10px 0px;
        }
        .order-selection {
          position: relative;
          // max-width: 210px;
          display: flex;
          height: fit-content;
          min-height: 38px;
          .border {
            position: absolute;
            right: 15%;
            top: 5px;
            bottom: 5px;
            border-style: solid;
            border-color: transparent;
            border-left: solid 1px #505759;
          }
          select {
            min-width: 260px;
            font-size: 16px;
            // width: 100%;
            padding: 5px 50px 5px 15px;
            border-radius: 8px;
            // background-image: linear-gradient(to bottom, #cffafc, #cffafc);
            background-color: white;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;

            background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgMjEzLjMzMyAyMTMuMzMzIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyMTMuMzMzIDIxMy4zMzM7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxnPg0KCQk8cG9seWdvbiBwb2ludHM9IjAsNTMuMzMzIDEwNi42NjcsMTYwIDIxMy4zMzMsNTMuMzMzIAkJIi8+DQoJPC9nPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=");
            background-repeat: no-repeat;
            background-size: 12px;
            background-position: 93% 50%;
            border: 1px solid #505759;

            option {
              font-size: 16px;
              // background: var(--selectDropdownOptionColor);
              &:checked {
                background-color: var(--selectDropdownOptionColor);
              }
            }
          }
        }
      }
      .line {
        border-bottom: solid 1px #dfe1e1;
      }
      .group-row {
        display: flex;
        padding: 10px 25px;
        @media (max-width: 768px) {
          padding: 10px 0px;
        }
        @media (max-width: 500px) {
          flex-wrap: wrap;
        }
        .title-block {
          padding: 10px;
          max-width: 160px;

          /* max-height: 100px; */
          // min-height: 100px;

          width: 100%;
          display: flex;
          @media (min-width: 599px) {
            margin: 10px 10px 10px 10px;
            height: 100px;
          }
          @media (max-width: 500px) {
            max-width: 100%;
          }
          > div {
            text-align: center;
            margin: auto;
            font-size: 24px;
            font-weight: bold;
            color: var(--normalTextColor);
            @media (max-width: 500px) {
              text-align: left;
              margin: auto 0;
            }

            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
        .list-block {
          padding: 0 15px;
          width: 100%;
          @media (max-width: 500px) {
            padding: 0;
          }

          ul {
            padding: 0;
            margin: 0;
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            li {
              margin: 10px 10px 10px 10px;
              cursor: pointer;
              @media (max-width: 500px) {
                margin: 10px 3% 10px 3%;
                width: 44%;
              }
              .card {
                display: flex;
                width: 160px;
                height: 100px;
                border-radius: 5px;
                box-shadow: 0 1px 2px 0 rgba(0, 0, 1, 0.2);
                background-color: var(--assessmentCardColor);
                @media (max-width: 500px) {
                  width: 100%;
                }
                > div {
                  display: -webkit-box;
                  -webkit-line-clamp: 3;
                  -webkit-box-orient: vertical;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  margin: auto;
                  font-size: 16px;
                  position: relative;
                  // width: 140px;
                  text-align: center;
                  max-height: 68px;
                  color: var(--assessmentCardTextColor);
                }
              }
              .score-lable {
                display: flex;
                padding-top: 10px;
                .lable {
                  font-size: 20px;
                  font-weight: bold;
                  color: var(--scoreLabelTextColor);
                  display: flex;
                  margin: auto;
                  @media (max-width: 500px) {
                    font-size: 16px;
                  }
                  .score {
                    font-size: 22px;
                    color: var(--scoreTextColor);
                    margin: auto;
                    line-height: 22px;
                    padding-left: 5px;
                  }
                }
              }
              &:hover {
                .card {
                  background-color: var(--assessmentCardHoverColor);
                }
              }
            }
          }
        }
      }
    }
  }
}
