//details summary hide css
// default sdl section starts here
.default-sdl-section {
  background: #f5f6f7;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  height: 200px;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin-top: 66px;
  border-top: 17px solid #198cc5;
  border-radius: 0px 10px 0px 0px;

  &-bottom {
    background: #f5f6f7;
    border-radius: 10px;
    background: #f5f6f7;
    border-radius: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #cecece;
  }
}
// default sdl section ends here
.btns {
  background: #ffffff;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
}
.sdl-ebook-btn {
  border-radius: 10px;
  line-height: 19px;
  color: #198cc5;
  height: 43px;
  width: 111px;

  display: flex;
  align-items: center;
  justify-content: space-around;
}
.btns-group {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  margin-top: -20px;
  z-index: 1;
}
.btn-corner {
  width: 30px;
  height: 30px;
  background-color: #fff;
  overflow: hidden;
  position: relative;
}
.btn-corner-top::before,
.btn-corner-bottom::before {
  content: "";
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: #4fc2fb;
  position: absolute;
  right: 0;
}
.btn-corner-top::before {
  bottom: 0;
}
.btn-corner-bottom::before {
  top: 0;
}
.btn-corner-none {
  visibility: hidden;
}
.resource_iframe{
  height: 100%;
  width: 100%;
  //margin-bottom: 20px;
  border:0px;
}
.resource_teacher_btn{
  margin-top: 20px;
  margin-bottom: 50px;
}
.sdl-sdl-btn {
  border-radius: 30px 0px 0px 30px;
  line-height: 100%;
  text-align: center;
  color: #4fc2fb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 5px;
  text-align: center;
  width: 130px;
}
.main-sdl-sdl-btn {
  margin-top: 25px;
  border-radius: 30px 0px 0px 30px;
  line-height: 100%;
  text-align: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 5px;
  text-align: center;
  width: 130px;
  background: #4FC2FB;

  @media screen and (max-width: 641px) {
    margin-top: 0px;
    border-radius: 5px 5px 0px 0px !important;
    padding: 8px 15px !important;
    width: auto !important;
    gap: 4px !important;
    min-width: 100px !important;
    font-size: 12px !important;
    color: #ffffff !important;
  }
}




.sdl-sdl-btn-color {
  background: none;
  color: #ffffff;
  @media screen and (max-width: 641px) {
    color: #fff !important;
    justify-content: space-between !important;
  }
}
.sdl-sdl-resource-btn-color {
  background: #fff;
  color: #4fc2fb;
  //@media screen and (max-width: 641px) {
  //  color: #fff !important;
  //}
}
.sdl-book-resource-btn {
  box-sizing: border-box;
  border-radius: 10px 10px 0px 0px;
  height: 76px;
  min-width: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;

  background: #e0e0e0;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: #333333;
  padding: 0px 16px;
  &.active {
    background: #198cc5;
    border: 1px solid #198cc5;
    color: #ffffff;
  }
}

.sdl-view-progress-btn {
  background: #ffbb85; //HKPTDEV-11322
  border-radius: 10px;
  width: 198px;
  height: 43px;
  color: #333333;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 26px;
}
//HKPTDEV-11310
.sdl-view-progress-btn-refresh{
  background: #90cf29;
  border-radius: 10px;
  width: 43px;
  height: 43px;
  color: #333333;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 26px;
  &.d-none{
    display: none;
  }
  span{
    background-image: url(../../../assets/img/pec/all-images/icon-refresh.svg);
    background-repeat: no-repeat;
    background-size: contain;
    height: 24px;
    width: 24px;
    display: block;
  }
}

.sdl-download-btn {
  background: #198cc5;
  border-radius: 10px;
  width: 165px;
  height: 43px;
  color: #ffffff;
  line-height: 19px;
}
.mdi-open-in-new-sdl {
  background-image: url(../../../assets/img/pec/all-images/page-tab-SDL-active.png);
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 54px;
  min-width: 54px;
  display: block;
}
.mdi-open-in-new-sdl-resource {
  background-image: url(../../../assets/img/pec/all-images/page-tab-SDL.png);
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 54px;
  min-width: 54px;
  display: block;
}
.mdi-open-in-new-teacher-resource {
  background-image: url(../../../assets/img/pec/all-images/page-tab-teacher-resource.png);
  background-repeat: no-repeat;
  background-size: contain;
  height: 39px;
  width: 46px;
  display: block;
  background-position: center;
  margin-bottom: 8px;
}
.mdi-open-in-new-teacher-resource-active {
  background-image: url(../../../assets/img/pec/all-images/page-tab-teacher-resource-active.png);
  background-repeat: no-repeat;
  background-size: contain;
  height: 39px;
  width: 46px;
  display: block;
  background-position: center;
  margin-bottom: 8px;
}
.mdi-open-in-new-sdl-book-resource-active {
  background-image: url(../../../assets/img/pec/all-images/icon-book.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 22px;
  width: 26px;
  display: block;
}
.mdi-open-in-new-sdl-book-resource {
  background-image: url(../../../assets/img/pec/all-images/icon-book-black.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 22px;
  width: 26px;
  display: block;
}
.mdi-open-in-new-sdl-preview-active {
  background-image: url(../../../assets/img/pec/all-images/icon-pen.png);
  background-repeat: no-repeat;
  background-size: contain;
  height: 22px;
  width: 26px;
  display: block;
}
.mdi-open-in-new-sdl-modal-preview-active {
  background-image: url(../../../assets/img/pec/all-images/icon-model-answer.png);
  background-repeat: no-repeat;
  background-size: contain;
  height: 22px;
  width: 26px;
  display: block;
}
.mdi-open-in-new-sdl-student-active {
  background-image: url(../../../assets/img/pec/all-images/icon-people.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 19px;
  width: 30px;
  display: block;
}
.mdi-open-in-new-sdl-close {
  background-image: url(../../../assets/img/pec/all-images/icon-close@2x.png);
  background-repeat: no-repeat;
  background-size: contain;
  height: 19px;
  width: 30px;
  display: block;
}
.mdi-open-in-new-sdl-student {
  background-image: url(../../../assets/img/pec/all-images/icon-people-black.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 19px;
  width: 30px;
  display: block;
}
.mdi-open-in-new-sdl-view-progress-off {
  background-image: url(../../../assets/img/pec/all-images/toogle-off.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 26px;
  width: 43px;
  display: block;
}
.mdi-open-in-new-sdl-view-progress-on {
  background-image: url(../../../assets/img/pec/all-images/toogle-on-orange.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 26px;
  width: 43px;
  display: block;
}
.year-drop-down-sdl {
  background-image: url("../../../assets/img/pec/all-images/Filter-Books/pull-down-arrow-down.png") !important;
  background-repeat: no-repeat !important;
  background-position: right !important;
  display: inline-block;
  width: 157px;
  height: 43px;
  background: #e8eff3;
  border-radius: 10px;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  color: #198cc5;
  cursor: pointer;
  padding: 5px;
  background-position-x: 95% !important;
  margin-top: 9px;
  display: flex !important;
  align-items: center !important;
}


@media only screen and (max-width: 640px) {
  .main__section-right-side {
    margin: 0 0 0 15px;
    margin-right: 0px;
    flex: 1;

    .resource_iframe {
      height: 700px;
    }
  }
}

.year-drop-down-sdl {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  height: 43px;
  flex-grow: 1;
  line-height: 29px;
  padding-right: 25px;
  padding-left: 10px;
}
.sdl-greater-symbol-small {
  background-image: url("../../../assets/img/pec/all-images/breadcrumbarrow.png") !important;
  background-repeat: no-repeat !important;
  // cursor: pointer;
  width: 21px;
  background-position: center;
  flex-shrink: 0;
  display: inline-block;
  height: 21px;
  vertical-align: middle;
  @media screen and (max-width: 641px) {
    filter: brightness(0) invert(1);
  }
}
.main__section-breadcrumb-flex {
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline;
  font-weight: 800;
}
.sdl-greater-symbol-bigger {
  background-image: url("../../../assets/img/pec/all-images/breadcrumbarrow.png") !important;
  background-repeat: no-repeat !important;
  filter: brightness(0);
  // cursor: pointer;
  height: 21px;
  width: 21px;
  background-position: center;
  flex-shrink: 0;
  display: inline-block;
  vertical-align: middle;
  @media screen and (max-width: 641px) {
    filter: brightness(0) invert(1);
  }
}

.class-dropdown-loader-alignment {
  float: left;
}

.head-average {
  width: 10%;
  cursor: default;
}
.head-submitted {
  width: 10%;
  cursor: default;
}
.head-viewed {
  width: 10%;
  cursor: default;
}
.head-viewable {
  width: 10%;
  cursor: default;
}
.head-google-classroom {
  width: 10%;
  cursor: default;
}
.head-result {
  width: 10%;
  cursor: default;
}
.head-item-flex {
  display: flex;
  // gap: 16px;
  gap: 8px;
  align-items: center;
  cursor: pointer;
}

.main__section-unit-grid-details-progress {
  width: 39px;
  height: 39px;
  border: 7px solid #e0e0e0;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 39px;
  &-bar {
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #a7a7a7;
  }
}

.main__section-unit-grid-details-student-progress {
  width: 39px;
  height: 39px;
  border: 7px solid #e0e0e0;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 39px;
  &.active {
    border: 7px solid #fff;
  }
  &-bar {
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #a7a7a7;
  }
}
.main__section-unit-grid-details-student-progress .active {
  color: #ffffff;
}

// code to display percentage in different color in units sub section starts here
@keyframes growProgressBar {
  0%,
  33% {
    --pgPercentage: 0;
  }
  100% {
    --pgPercentage: var(--value);
  }
}

@property --pgPercentage {
  syntax: "<number>";
  inherits: false;
  initial-value: 0;
}

.progressbar {
  --size: 39px;
  --bg: #e0e0e0;
  --pgPercentage: var(--value);
  animation: growProgressBar 3s 1 forwards;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  display: grid;
  place-items: center;

  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
  scroll-behavior: smooth;

  // 00 - 20% (lowest)
  &-lowest {
    --fg: #ca2c2e;
    background: radial-gradient(
        closest-side,
        white 80%,
        transparent 85% 110%,
        white 0
      ),
      conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0);
    color: var(--fg);
  }

  // 21 - 40% (low)
  &-low {
    --fg: #fb842d;
    background: radial-gradient(
        closest-side,
        white 80%,
        transparent 85% 110%,
        white 0
      ),
      conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0);
    color: var(--fg);
  }

  // 41 - 60% (medium)
  &-medium {
    --fg: #fbc12d;
    background: radial-gradient(
        closest-side,
        white 80%,
        transparent 85% 110%,
        white 0
      ),
      conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0);
    color: var(--fg);
  }

  // 61 - 80% (high)
  &-high {
    --fg: #b2cf00;
    background: radial-gradient(
        closest-side,
        white 80%,
        transparent 85% 110%,
        white 0
      ),
      conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0);
    color: var(--fg);
  }

  // 81 - 100% (highest)
  &-highest {
    --fg: #008638;
    background: radial-gradient(
        closest-side,
        white 80%,
        transparent 85% 110%,
        white 0
      ),
      conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0);
    color: var(--fg);
  }
}

.progressbar::before {
  counter-reset: percentage var(--value);
  // content: counter(percentage) "%";
  content: counter(percentage);
}
// code to display percentage in different color in units sub section ends here

// code to display percentage in different color in units section starts here
.large-progressbar {
  --size: 65px;
  --bg: #e0e0e0;
  --pgPercentage: var(--value);
  animation: growProgressBar 3s 1 forwards;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  display: grid;
  place-items: center;

  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 19px;
  scroll-behavior: smooth;

  // 00 - 20% (lowest)
  &-lowest {
    --fg: #ca2c2e;
    background: radial-gradient(
        closest-side,
        white 80%,
        transparent 85% 110%, /*Venni : HKPTDEV-11001*/
        white 0
      ),
      conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0);
    color: var(--fg);
  }

  // 21 - 40% (low)
  &-low {
    --fg: #fb842d;
    background: radial-gradient(
        closest-side,
        white 80%,
        transparent 85% 110%, /*Venni : HKPTDEV-11001*/
        white 0
      ),
      conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0);
    color: var(--fg);
  }

  // 41 - 60% (medium)
  &-medium {
    --fg: #fbc12d;
    background: radial-gradient(
        closest-side,
        white 80%,
        transparent 85% 110%, /*Venni : HKPTDEV-11001*/
        white 0
      ),
      conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0);
    color: var(--fg);
  }

  // 61 - 80% (high)
  &-high {
    --fg: #b2cf00;
    background: radial-gradient(
        closest-side,
        white 80%,
        transparent 85% 110%, /*Venni : HKPTDEV-11001*/
        white 0
      ),
      conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0);
    color: var(--fg);
  }

  // 81 - 100% (highest)
  &-highest {
    --fg: #008638;
    background: radial-gradient(
        closest-side,
        white 80%,
        transparent 85% 110%, /*Venni : HKPTDEV-11001*/
        white 0
      ),
      conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0);
    color: var(--fg);
  }
}

.large-progressbar::before {
  counter-reset: percentage var(--value);
  content: counter(percentage);
}
// code to display percentage in different color in units section ends here

// code to display percentage in different color in students sub units section starts here
.student-progressbar {
  --size: 39px;
  --bg: #e0e0e0;
  --pgPercentage: var(--value);
  animation: growProgressBar 3s 1 forwards;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  display: grid;
  place-items: center;

  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
  scroll-behavior: smooth;

  // 00 - 20% (lowest)
  &-lowest {
    --fg: #ca2c2e;
    background: radial-gradient(
        closest-side,
        white 80%,
        transparent 85% 110%,/*Venni : HKPTDEV-11001*/
        white 0
      ),
      conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0);
    color: var(--fg);
  }

  // 21 - 40% (low)
  &-low {
    --fg: #fb842d;
    background: radial-gradient(
        closest-side,
        white 80%,
        transparent 85% 110%,/*Venni : HKPTDEV-11001*/
        white 0
      ),
      conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0);
    color: var(--fg);
  }

  // 41 - 60% (medium)
  &-medium {
    --fg: #fbc12d;
    background: radial-gradient(
        closest-side,
        white 80%,
        transparent 85% 110%,/*Venni : HKPTDEV-11001*/
        white 0
      ),
      conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0);
    color: var(--fg);
  }

  // 61 - 80% (high)
  &-high {
    --fg: #b2cf00;
    background: radial-gradient(
        closest-side,
        white 80%,
        transparent 85% 110%,/*Venni : HKPTDEV-11001*/
        white 0
      ),
      conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0);
    color: var(--fg);
  }

  // 81 - 100% (highest)
  &-highest {
    --fg: #008638;
    background: radial-gradient(
        closest-side,
        white 80%,
        transparent 85% 110%, /*Venni : HKPTDEV-11001*/
        white 0
      ),
      conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0);
    color: var(--fg);
  }
}

.student-progressbar::before {
  counter-reset: percentage var(--value);
  content: counter(percentage);
}
// code to display percentage in different color in students sub units section ends here

// code to display percentage in different color in students sub units section via line progress starts here
.main__progress-linebar {
  display: flex;
  align-items: center;
  gap: 11px;
  justify-content: center;
}
.progress-linebar {
  background-color: #d9d9d9;
  width: 114px;
  border-radius: 5px;
  height: 5px;
}
.progress-linebar-lowest {
  background-color: #ca2c2e;
  border-radius: 5px;
  height: 5px;
  &-text {
    color: #ca2c2e;
  }
}
.progress-linebar-low {
  background-color: #fb842d;
  border-radius: 5px;
  height: 5px;
  &-text {
    color: #fb842d;
  }
}
.progress-linebar-medium {
  background-color: #fbc12d;
  border-radius: 5px;
  height: 5px;
  &-text {
    color: #fbc12d;
  }
}
.progress-linebar-high {
  background-color: #b2cf00;
  border-radius: 5px;
  height: 5px;
  &-text {
    color: #b2cf00;
  }
}
.progress-linebar-highest {
  background-color: #008638;
  border-radius: 5px;
  height: 5px;
  &-text {
    color: #008638;
  }
}
.default-progress-linebar {
  background-color: #4FC2FB;
  border-radius: 5px;
  height: 5px;
  &-text {
    color: #fff;
    font-weight: 800;
  }
  &-text1 {
    color: #000000;
    font-weight: 800;
  }
}
.default-progress-linebar1 {
  background-color: #136790;
  border-radius: 5px;
  height: 5px;
  &-text1 {
    color: #000000;
    font-weight: 800;
  }
}
.progress-linebar-percentage {
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
}
// code to display percentage in different color in students sub units section via line progress ends here

.main__section-unit-grid-item-image {
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  height: 40px;
  width: 40px;
  align-self: start;
  margin-top: 4px;
  margin-bottom: 4px;
  // cursor: pointer;
}
.main__section-unit-grid-tick-image {
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  height: 13px;
  width: 18px;
  align-self: start;
  margin-top: 4px;
  margin-bottom: 4px;
  filter: brightness(0.5);
  // cursor: pointer;
}
.main__section-unit-grid-table-viewed-cnt{
  color: #198CC5;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
}
.main__section-unit-no-data {
  color: #999;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin-top: 92px;
  margin-bottom: 92px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.item-video-image {
  background-image: url(../../../assets/img/pec/all-images/icons/icon-video-blue.png);
}
.item-learnetic-image {
  background-image: url(../../../assets/img/pec/all-images/icons/icon-learnosity-blue.png);
}
.item-ebook-image {
  background-image: url(../../../assets/img/pec/all-images/icons/icon-ebook-blue.png);
}
.item-pdf-image {
  background-image: url(../../../assets/img/pec/all-images/icons/icon-pdf.png);
}
.item-doc-image {
  background-image: url(../../../assets/img/pec/all-images/icons/icon-doc.png);
}
.item-xls-image {
  background-image: url(../../../assets/img/pec/all-images/icons/icon-xls.png);
}
.item-ppt-image {
  background-image: url(../../../assets/img/pec/all-images/icons/icon-ppt.png);
}
.item-zip-image {
  background-image: url(../../../assets/img/pec/all-images/icons/icon-zip.png);
}

.item-audio-image {
  background-image: url(../../../assets/img/pec/all-images/icons/icon-audio-blue.png);
}
.item-jpeg-image {
  background-image: url(../../../assets/img/pec/all-images/icons/icon-image-blue.png);
}
.item-learnetics-image {
  background-image: url(../../../assets/img/pec/all-images/icons/icon-learnetic-blue.png);
}
.item-link-image {
  background-image: url(../../../assets/img/pec/all-images/icons/icon-link-blue.png);
}
.item-interactive-image {
  background-image: url(../../../assets/img/pec/all-images/icons/icon-interactive-blue.png);
}
.item-game-image {
  background-image: url(../../../assets/img/pec/all-images/icons/icon-game-blue.png);
}
.item-dictionary-image {
  background-image: url(../../../assets/img/pec/all-images/icons/icon-picture-dictionary-blue.png);
}
.item-eflashcard-image {
  background-image: url(../../../assets/img/pec/all-images/icons/icon-eflashcards-blue.png);
}

.item-audioscript-image {
  background-image: url(../../../assets/img/pec/all-images/icons/icon-audio-blue.png);
}
.item-onlineexcercise-image {
  background-image: url(../../../assets/img/pec/all-images/icons/icon-online-exercise-blue.png);
}
.item-assessment-image {
  background-image: url(../../../assets/img/pec/all-images/icons/icon-assessment-blue.png);
}

.viewable-icon {
  background-image: url(../../../assets/img/pec/all-images/eye-show.png);
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  height: 40px;
  width: 40px;
  cursor: pointer;
}
.not-viewable-icon {
  background-image: url(../../../assets/img/pec/all-images/eye-show-disable.png);
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  height: 40px;
  width: 40px;
  cursor: pointer;
}

.viewable-icon-disabled {
  background-image: url(../../../assets/img/pec/all-images/eye-hide.png);
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  height: 40px;
  width: 40px;
  // cursor: not-allowed;
  cursor: pointer;
}

.not-viewable-icon-disabled {
  background-image: url(../../../assets/img/pec/all-images/eye-hide-disable.png);
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  height: 40px;
  width: 40px;
  cursor: not-allowed;
}

.main__section {
  display: grid;
  grid-template-columns: 150px 1fr;
  grid-column-gap: 30px;
  padding-right: 30px;
  flex: 1;
  background: #fff;

  &-header-group {
    position: relative;
    background-color: #4fc2fb;
  }
  &-header {
    grid-row: 1/-1;
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 15px;
    padding-bottom: 150px;

    &-top {
      display: flex;
      flex-direction: column;
      gap: 13px;
      align-items: center;
      justify-items: center;
      align-self: center;
      z-index: 2;
    }
  }

  &-right {
    display: grid;
    grid-template-columns: 1fr;
    &-side {
      //margin-bottom: 23px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
    }

    &-side-top {
      display: flex;
      justify-content: space-between;
    }

    &-side-bottom {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }



  &-top {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    margin-top: 35px;
    margin-bottom: 60px;

    &-logo {
      line-height: 60px;
      height: 60px;
      width: 54px;
      background-image: url("../../../assets/img/pec/all-images/pearson-logo-only-white.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: left center;
      padding: 0 20px;
      margin: 0;
      display: block;
      cursor: pointer;
    }
  }
  &-book-img {
    width: 110px;
    border-radius: 5px;
    overflow: hidden;
  }
  &-teacher-resource {
    background: #4fc2fb;
    border-radius: 0px 40px 0px 0px;
  }
  &-breadcrumb {
    margin-top: 30px;
    &-text {
      min-width: 0px;
      gap: 8px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      gap: 10px;
      min-width: 0px;
    }
    &-left {
      color: #8c8c8c;
      cursor: pointer;
      display: inline;
    }
    &-right {
      font-weight: 800;
      color: #333333;
      flex-shrink: 0;
      display: inline;
    }
    @media only screen and (max-width: 640px){
      margin-top: 0px;
    }
  }
  &-navigation {
    margin-top: 14px;
  }
  &-heading {
    margin: 0;
    margin-top: 68px;
    max-width: 100%;
    min-width: 100%;
    &-bookseries {
      color: #363636;
      font-style: normal;
      font-weight: 800;
      font-size: 40px;
      line-height: 55px;
      cursor: pointer;
      display: inline-block;
    }
    &-carret {
      background-image: url("../../../assets/img/pec/all-images/title-pull-down-arrow.png") !important;
      background-repeat: no-repeat;
      background-size: cover;
      height: 14px;
      width: 22px;
      display: inline-block;
      margin-left: 15px;
    }
  }

  &-user-section {
    margin-top: 60px;
    display: grid;
    grid-template-columns: max-content max-content;
    justify-content: space-between;
    border-bottom: 17px solid #198cc5;
    overflow: hidden;

    &-left {
      transform: translateY(7px);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-right {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 14px;
    }
  }

  &-unit {
    display: grid;
    // grid-template-columns: repeat(5, minmax(auto, auto)); //changed as per demo team suggestion
    grid-template-columns: repeat(5, 1fr);
    column-gap: 10px;
    row-gap: 10px;
    padding: 20px;
    padding-bottom: 0;

    &-container {
      background: #f5f6f7;
      border: 1px solid #e9e9e9;
      border-radius: 0 0 10px 10px;
    }

    &-section {
      background: #ffffff;
      border: 1px solid #e9e9e9;
      border-radius: 4px;
      box-sizing: border-box;
      &.active {
        background: #ccdff2;
      }
      display: flex;
      flex-direction: column;
      cursor: pointer;
      padding: 10px;
    }
    &-heading {
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      line-height: 22px;
      color: #333333;
      max-height: 44px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      flex: 1;
    }
    &-details {
      display: flex;
      align-items: center;
      gap: 2px;
      justify-content: space-between;
      &-label {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: #333333;
      }
      &-progress {
        display: inline-block;
        width: 65px;
        height: 65px;
        border: 7px solid #e0e0e0;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 65px;
        &.active {
          border: 7px solid #ffffff;
        }
      }
      &-progress-bar {
        font-style: normal;
        font-weight: 800;
        font-size: 28px;
        line-height: 38px;
        display: flex;
        align-items: center;
        color: #333333;
        justify-content: center;
      }
    }
  }

  &-unit-grid {
    margin-top: 39px;
    padding: 20px;

    &-heading {
      font-style: normal;
      font-weight: 800;
      font-size: 30px;
      line-height: 41px;
      color: #363636;
      margin-bottom: 30px;
    }
    &-item-name {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #333333;
      cursor: pointer;
      text-align: left;
      padding-top: 12px;
      padding-bottom: 12px;
      line-height: 24px;
    }
    &-table-head1 {
      display: flex;
      align-items: flex-start;
    }
    &-table {
      width: 100%;

      &-details {
        margin-top: 8px;
      }

      &-head {
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        color: #333333;
      }
      &-summary {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: #333333;
        list-style: none;
      }
      &-data {
        text-align: center;
        text-align: -webkit-center;

        &-label {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 25px;
          color: #333333;
        }
      }
    }
    &-table tr {
      border-bottom: 1px solid #eaeaea;
    }
  }
  &-student-unit-grid {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 5px;
    padding: 13px;
    overflow-y: visible;
    overflow-x: scroll;

    &-details-topheading {
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      text-align: center;
      color: #ffffff;
    }
    &-details-bottomheading-primary {
      font-weight: 800;
      font-size: 14px;
      line-height: 19px;
      color: #333333;
    }
    &-details-bottomheading {
      font-weight: 800;
      font-size: 14px;
      line-height: 19px;
      color: #ffffff;
    }
    &-details {
      border-collapse: separate;
      border-spacing: 0 10px;
      padding: 10px;
      border-radius: 10px;

      &-top-row {
      }
      &-bottom-row {
        th {
          padding: 0 20px;
        }
      }
      &-up-row {
        background: #136790;
        border-radius: 10px;
        font-style: italic;
        font-weight: 800;
        font-size: 14px;
        line-height: 19px;
        color: #ffffff;
        padding: 15px;
        height: 55px;
        td {
          padding: 15px 20px;
        }
      }
      &-down-row {
        background: #ffffff;
        border-radius: 10px;
        font-style: normal;
        font-size: 14px;
        line-height: 19px;
        color: #333333;
        margin-top: 10px;
        margin-bottom: 10px;
        height: 55px;
        &-number {
          font-weight: 400;
        }
        &-name {
          font-weight: 800;
        }
        td {
          padding: 0 20px;
        }
      }
    }
    &-grade {
      background: #198cc5;
      border-radius: 10px;
      border-collapse: separate;
      border-spacing: 0 10px;
      padding: 10px;
      &-top-row {
      }
      &-bottom-row {
        text-align: center;
      }
      &-up-row {
        background: #136790;
        border-radius: 10px;
        font-style: italic;
        font-weight: 800;
        font-size: 14px;
        line-height: 19px;
        color: #ffffff;
        padding: 15px;
        height: 55px;
        text-align: center;
        td {
          padding: 0 20px;
        }
      }
      &-down-row {
        background: #ffffff;
        border-radius: 10px;
        font-style: normal;
        font-size: 14px;
        line-height: 19px;
        color: #333333;
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 18px;
        text-align: center;
        color: #333333;
        height: 55px;
        td {
          padding: 0 20px;
        }
      }
    }
    &-view {
      &-top-row {
      }
      &-bottom-row {
        text-align: center;
      }
      background: #4fc2fb;
      border-radius: 10px;
      border-collapse: separate;
      border-spacing: 0 10px;
      padding: 10px;
      &-up-row {
        background: #136790;
        border-radius: 10px;
        font-style: italic;
        font-weight: 800;
        font-size: 14px;
        line-height: 19px;
        color: #ffffff;
        padding: 15px;
        text-align: center;
        height: 55px;
        td {
          padding: 15px 20px;
        }
      }
      &-down-row {
        background: #ffffff;
        border-radius: 10px;
        font-style: normal;
        font-size: 14px;
        line-height: 19px;
        color: #333333;
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 18px;
        text-align: center;
        color: #333333;
        height: 55px;
        td {
          padding: 15px 20px;
        }
      }
    }
  }
}

// table row border radius code starts here
.main__section-student-unit-grid-details td:first-child,
th:first-child {
  border-radius: 10px 0 0 10px;
  min-width: 75px;
}
.main__section-student-unit-grid-details td:last-child,
th:last-child {
  border-radius: 0 10px 10px 0;
}

.main__section-student-unit-grid-grade td:first-child,
th:first-child {
  border-radius: 10px 0 0 10px;
}
.main__section-student-unit-grid-grade td:last-child,
th:last-child {
  border-radius: 0 10px 10px 0;
}

.main__section-student-unit-grid-view td:first-child,
th:first-child {
  border-radius: 10px 0 0 10px;
}
.main__section-student-unit-grid-view td:last-child,
th:last-child {
  border-radius: 0 10px 10px 0;
}
// table row border radius code ends here
th.main__section-student-unit-grid-details-bottomheading-primary {
  white-space: nowrap;
  cursor: pointer;
}

th.main__section-student-unit-grid-details-bottomheading {
  white-space: nowrap;
  cursor: pointer;
}

.google-btn {
  background: #198cc5;
  border-radius: 6px;
  width: 38px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.toggle-collapse-icons {
  border-left: 10px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 16px solid #808080;
  transform: rotate(90deg);
  position: relative;
  top: -5px;
  &-toggle {
    transform: rotate(180deg);
  }
}
.google-class-room {
  background-image: url(../../../assets/img/pec/all-images/icon-people.png);
  background-repeat: no-repeat;
  background-size: contain;
  height: 15px;
  width: 24px;
  display: block;
}
.results-btn {
  width: 75px;
  height: 35px;
  background: #198cc5;
  border-radius: 10px;
  line-height: 19px;
}
img.table-sorting-arrow {
  margin-left: 3.4px;
}

.column-data-without-progress {
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
  color: #a7a7a7;
}
.column-data-without-progress-color {
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
}
.column-data-with-progress {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #333333;
}
.column-data-with-progress-color {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
}

.book__series {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  max-width: 398px;
  height: 752px;
  overflow-y: scroll;
  padding: 25px;
  position: absolute;
  z-index: 9999;
  max-height: 240px;
  overflow: auto;

  &-block {
    display: inline-block;
    margin-top: 53px;
    margin-left: -45px;
    @media only screen and (max-width: 640px){
      // margin-top: 0;
      // margin-left: 0;
      position: relative;
      margin-top: -31px;
      margin-left: 0;
      left: -7px;
      float: right;
    }
  }
  &-section {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 21px;
    &-name {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      display: flex;
      align-items: center;
      color: #363636;
      cursor: pointer;
    }
    &-active {
      font-weight: 800;
    }
  }
}
.book__series-section-border {
  border: 1px solid #e9e9e9;
}
.main-component1 {
  transform: translate(1px, 3px);
}

// compatibility for tablet starts here
@media only screen and (max-width: 1024px) and (min-width: 641px) {
  .main__section-unit {
    grid-template-columns: repeat(4, 1fr);
  }
  .main__section {
    grid-column-gap: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 1000px) {
  .main__section-unit {
    grid-template-columns: repeat(3, 1fr);
  }
  .main__section-user-section {
    margin-top: 8px;
    display: flex;
    flex-direction: column-reverse;
  }
  .main__section-user-section-left {
    justify-content: start;
  }
  .main__section-user-section-right {
    justify-content: end;
  }
  .main__section-table-details {
    overflow-x: scroll;
    width: 100%;
  }
  .main__section-table-details::-webkit-scrollbar {
    height: 1px;
  }
  .main__section-unit-grid-table,
  .main__section-unit-grid-table-details {
    width: 900px;
  }

  // sprint6 students table compatibility starts here
  .main__section-student-unit-grid {
    overflow-x: scroll;
    width: 100%;
  }

  .main__section-student-unit-grid::-webkit-scrollbar {
    height: 1px;
  }
  // sprint6 students table compatibility ends here
}
@media only screen and (max-width: 1280px) {
  .main__section-student-unit-grid::-webkit-scrollbar {
    height: 7px;
  }
}

@media only screen and (max-width: 700px) {
  .main__section-unit {
    grid-template-columns: repeat(3, 1fr);
  }
}
// compatibility for tablet ends here

// compatibility for mobile starts here
@media only screen and (max-width: 640px) {
  .btn_refprogcontainer{
    display: flex;
  }
  .btn_downloadcontainer{
    display: flex;
    justify-content: end;
  }
  .sdl-download-btn{
    width: 200px !important;
  }
  .main__section-unit {
    display: flex;
    padding: 20px;
    padding-bottom: 10px;
    overflow-x: scroll;
    width: 100%;
  }
  .main__section-top {
    display: none;
  }

  .main__section-book-img {
    width: 110px;
  }
  .mdi-open-in-new-sdl {
    height: 40px;
    width: 40px;
    /* background-size: contain; */
  }
  .mdi-open-in-new-teacher-resource {
    height: 40px;
    width: 38px;
    margin-bottom: 0;
  }

  .main__section {
    display: flex;
    grid-column-gap: 0px;
    padding-right: 0px;
    flex: 1;
    flex-direction: column;

    &-right-side {
      margin: 0 0 0 15px;
      margin-right: 0px;
      flex: 1;
      &-top {
        position: absolute;
        left: 15px;
        top: 5px;
        width: calc(100% - 30px);
        flex-direction: column-reverse;
      }
      &-bottom {
        padding-right: 5%;
      }
    }

    &-header {
      align-items: start;
      gap: 40px;
      padding-bottom: 0px;
      margin-top: 170px;
      padding-left: 15px;
      padding-right: 15px;
      &-top {
        display: flex;
        flex-direction: row;
        gap: 13px;
        align-items: end;
        align-self: start;
        justify-items: center;
      }
    }
    &-breadcrumb {
      &-text {
        gap: 8px;
        font-size: 14px;
        line-height: 20px;
        max-height: 80px;
        overflow: hidden;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        color: #fff;
      }
      &-left {
        color: #fff;
      }
      &-flex {
        color: #fff;
      }
      &-right {
        color: #fff;
      }
    }
    &-heading {
      margin-top: 0;
      &-book {
        position: absolute;
        top: 170px;
        left: 138px;
        z-index: 20;
      }
      &-bookseries {
        font-size: 21px;
        line-height: 24px;
        color: #fff;
        position: relative;
        // padding-right: 40px;
        padding-right: 48px;
        max-height: 72px;
        overflow: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -webkit-box;
      }
      &-carret {
        position: absolute;
        right: 20px;
        top: 5px;
        height: 10px;
        width: 20px;
        background-size: contain;
        @media screen and (max-width: 641px) {
          filter: brightness(0) invert(1);
        }
      }
    }
    &-user-section {
      margin-top: 20px;
      display: flex;
      flex-direction: column-reverse;
      gap: 30px;
      &-right {
        display: grid;
        gap: 14px;
        //grid-template-columns: 1fr 1fr;
      }
    }
  }
  .sdl-download-btn {
    width: 100%;
  }
  .year-drop-down-sdl {
    width: 100%;
  }
  .sdl-book-resource-btn {
    box-sizing: border-box;
    border-radius: 10px 10px 0px 0px;
    height: 46px;
    min-width: 170px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    background: #e0e0e0;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 25px;
    color: #333333;
    padding: 0px 14px;
    width: 100%;
  }
  .mdi-open-in-new-sdl-book-resource-active {
    background-repeat: no-repeat;
    background-size: contain;
    height: 14px;
    width: 20px;
    background-position: center;
  }
  .mdi-open-in-new-sdl-student {
    background-repeat: no-repeat;
    background-size: contain;
    height: 14px;
    width: 20px;
    display: block;
  }
  .main__section-unit::-webkit-scrollbar {
    height: 1px;
  }
  .main__section-unit-details-label {
    white-space: nowrap;
    margin-right: 8px;
  }
  .btn-corner {
    display: none;
  }
  .sdl-sdl-btn {
    gap: 4px;
    font-size: 12px;
    color: #000;
  }

  .sdl-sdl-resource-btn-color {
    gap: 4px;
    font-size: 12px;
    color: #000;
  }

  .btns-group {
    flex-direction: row;
    align-items: stretch;
    margin-top: 0;
    z-index: 1;
  }
  .sdl-sdl-btn {
    border-radius: 5px 5px 0px 0px;
    padding: 8px 15px;
    width: auto;
    gap: 4px;
    min-width: 100px;
    height: 89px;
  }

  .sdl-sdl-resource-btn-color {
    border-radius: 5px 5px 0px 0px;
    padding: 8px 15px;
    width: auto;
    gap: 4px;
    min-width: 100px;
    justify-content: space-between !important;
  }

  .resource_iframe {
    height: 700px;
  }
}


details summary::-webkit-details-marker{
  display:none !important;
}
// compatibility for mobile ends here
.btn_refprogcontainer{
  display: flex;
  gap: 14px;
}
.main__section_centerAln .column-data-without-progress-color div,
.main__section_centerAln .column-data-without-progress div
{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main__section_centerAln .column-data-with-progress-color div,
.main__section_centerAln .column-data-with-progress div{
  //margin: 0 0 0 10px;
  //display: flex;
  align-items: center;
  justify-content: center;
}
.main__section_centerAln .student-progressbar{
  margin-left: 10px;
}
.main__section_centerAln .main__section-unit-grid-details-student-progress{
  margin-left: 10px;
}
//Code provided by David
.progress-linebar-percentage{
  width: 26px;
  text-align: left;
  overflow: visible;
}

.main__section-no-data{
  color: #999;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin-top: 30px;
    margin-left: 30px;
}
.main__section-unit-grid-table-summary-disabled{
  pointer-events: none;
	user-select: none;
}
.main__section-unit-grid-table-summary-enabled{
  pointer-events: all;
  user-select: all;
}
.disable-sorting-click{
	pointer-events: none;
    user-select: none;
}