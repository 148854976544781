#class_body.b2c {
  .body {
    position: relative;
    & > .message-box {
      padding-top: 50px;
      img {
        width: 250px;
        height: 250px;
      }
    }
    .b2c_rcenter {
      //   width: 100%;
      padding: 0 30px;
      @media (max-width: 768px) {
        padding: 0 16px;
      }
      @media (max-width: 599px) {
        padding: 0;
      }
      .content-row {
        display: flex;
        padding: 0 15px;
        @media (max-width: 599px) {
          padding: 52px 0px 0 0;
          flex-wrap: wrap;
        }
        @media (min-width: 1023px) {
          flex-wrap: nowrap;
        }
        .left-menu-box {
          margin: 0 25px;
          margin-top: 40px;
          border-top: solid 1px #dfe1e1;
          max-width: 280px;
          width: 100%;
          @media (max-width: 1023px) {
            margin: 0px;
            max-width: 240px;
          }

          & > .mobile-title {
            display: none;
          }
          @media (max-width: 599px) {
            margin: 0;
            position: absolute;
            z-index: 2;
            top: 0;
            max-width: 100%;
            min-height: 36px;
            border: none;
            & > .mobile-title {
              cursor: pointer;
              display: block;
              // height: 100%;
              // box-shadow: 0 2px 4px 0 rgb(0, 0, 0, 50%);
              background-color: #ffbb1c;
              padding: 5px;
              & > div {
                padding: 10px;
                border-radius: 4px;
                background-color: white;
                color: #000000;
                font-size: 16px;
                display: flex;
                justify-content: space-between;
                i {
                  font-size: 18px;
                }
              }
            }
          }
          > .pannel {
            display: block;
            @media (max-width: 599px) {
              display: none;
              // position: absolute;
              // right: 0;
              // left: 0;
              &.open {
                display: block;
                // z-index: 10;
                box-shadow: 2px 2px 5px 0px rgba(50, 50, 50, 0.35);
              }
            }
            ul {
              list-style: none;
              padding: 15px 0 0 0;
              display: block;
              @media (max-width: 599px) {
                padding: 0;
                margin: 0;
                background-color: white;
                border-bottom: 1px solid #eeeeee;
                // display: none;
              }
              li {
                cursor: pointer;
                padding: 15px 20px;
                font-size: 16px;
                color: #000000;
                &.active {
                  background-color: #d2db0e;
                }
              }
            }
          }
        }
        .content-box {
          width: 100%;
          margin: 0 25px;
          @media (max-width: 599px) {
            margin: 0 15px;
          }
          .top-row {
            padding: 10px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .title {
              font-size: 26px;
              font-weight: 500;
              color: #000000;
            }
            .right-buttons {
              ul.button-list {
                //  {
                display: flex;
                list-style: none;
                padding: 0;
                margin: auto;
                // }
                li {
                  cursor: pointer;
                  padding: 13px;
                  i {
                    font-size: 24px;
                    color: #600064;
                    &.active {
                      color: #9b007e;
                    }
                  }
                }
              }
            }
          }
          .resource-body {
            margin: 0 -4.4px;
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 25px;
            .menu-overlay {
              display: none;
            }
            @media (max-width: 599px) {
              .menu-overlay {
                &.open {
                  display: block;
                }
                position: absolute;
                left: 0;
                right: 0;
                // height: 100%;
                top: 52px;
                background: rgba(0, 0, 0, 0.3);
                -webkit-transition: opacity 0.3s;
                transition: opacity 0.3s;
                backdrop-filter: blur(5px);
                bottom: -110px;
                z-index: 1;
              }
            }
            .group-item {
              margin: 5px 4.4px;
              max-width: 176px;
              width: 100%;
              cursor: pointer;
              @media (max-width: "599px") {
                max-width: 47%;
              }
              .img {
                width: 100%;

                // cursor: pointer;
                background-position: center;
                border-radius: 5px;

                background-size: cover;
                transition: 0.2s;
                @media (min-width: "600px") {
                  max-height: 99px;
                  height: 101px;
                }
              }
              .title {
                margin: 10px 0;
                font-size: 16px;
                font-weight: 600;
                color: #000000;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2; /* number of lines to show */
                -webkit-box-orient: vertical;
              }
            }
            .list-item {
              width: 100%;
              margin: 0px 4.4px;
              padding: 10px 0;
              border-top: solid 1px #dfe1e1;
              display: flex;
              justify-content: space-between;
              cursor: pointer;
              .left {
                display: flex;
                align-items: center;
                .list-title {
                  padding-left: 10px;
                  font-size: 16px;
                  color: #000000;
                }
              }
              .right {
                display: flex;
                i {
                  margin: auto;
                  font-size: 24px;
                }
              }
              &:last-child {
                // .list-item {
                border-bottom: solid 1px #dfe1e1;
                // }
              }

              &:hover {
                .left {
                  .list-title {
                    color: var(--itemHoverColor);
                  }
                }
                .right {
                  i {
                    color: var(--arrowHoverColor);
                  }
                }
              }
            }
          }
          img.resourceCenterImg {
            width: 88px;
            height: 88px;
          }
          .footer-paginate {
            padding-bottom: 25px;
            text-align: center;
            select {
              padding: 8px;
              border: 1px solid #dfe1e1;
              background: white;
              border-radius: 5px;
              option {
                background: white;
              }
            }
          }
        }
      }
    }
  }
}
