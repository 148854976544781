#studynotes {
  .gallery {
    display: flex;
    flex-wrap: wrap;
    padding: 25px 0;
    .take-photo {
      padding: 0 25px;
      padding-bottom: 50px;
      height: auto;
      .body {
        width: 100%;
        height: 100%;
        min-width: 160px;
        min-height: 147px;
        border-radius: 12px;
        background-color: #eeeeee;
        display: flex;
        vertical-align: middle;
        .info {
          text-align: center;
          height: 60px;
          margin: auto;

          i {
            font-size: 34px;
            @media (max-width: 450px) {
              font-size: 24px;
            }
          }
          .title {
            width: 100%;

            font-size: 16px;
            @media (max-width: 450px) {
              font-size: 12px;
              width: 80px;
            }
          }
       
        }
        &:hover {
          background-color: #daf0ed;

          transform: scale(1.05) translate(0px, -3px);
        }
        &:active {
          background-color: #daf0ed;

          transform: scale(1) translate(0px, 0px);
        }
      }
    }
    .photo {
      padding: 0 25px;
      padding-bottom: 50px;
      .body {
        height: 100%;
        .img {
          width: 100%;
          border-radius: 12px;
          height: 147px;
          overflow:hidden;
          position:relative;
          .img-content{
            width:100%;
            height: auto;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%,-50%);
            -ms-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
            position:absolute;
          }
        }
        .info {
          position: relative;
          .date {
            position: absolute;
            width: 100%;
            font-size: 16px;
            &.button {
              padding-top: 5px;
              display: flex;
              justify-content: space-around;
            }
            @media (max-width: 600px) {
              font-size: 14px;
            }
          }
        }
        &.normal:hover {
          //background-color: #daf0ed;

          transform: scale(1.05) translate(0px, -3px);
        }
        &.normal:active {
          //background-color: #daf0ed;

          transform: scale(1) translate(0px, 0px);
        }
      }
    }
  }
}

.study-notes-container{
  position: absolute;
  width: 100%;
  height: 100%;
  .splash-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}