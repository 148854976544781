/*------------------------------------------------------------------
  [Badges]
*/

.badge {
	font-size: .8462rem;
	font-weight: 700;
	line-height: 1.55;
	border: 1px solid #d9d9d9;
}

.badge-primary {
	color: $white;
	background-color: #007fa3;
	border: transparent;
}

.badge-secondary {
	background-color: $white;
	color: #404040;
}

.badge-success {
	color: $white;
	background-color: #008638;
	border: transparent;
}

.badge-info {
	color: $white;
	background-color: #6ba4ff;
	border: transparent;
}

.badge-warning {
	color: $white;
	background-color: #ffb81c;
	border: transparent;
}

.badge-danger {
	color: $white;
	background-color: $red;
	border: transparent;
}

.popover {
	font-size: 1rem;
	-webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	.popover-header {
		margin: 0;
	}
	&.bottom > .arrow {
		border-bottom-color: #f7f7f7;
		top: -10px;
		&:after {
			background-color: transparent;
			top: -2px;
			margin-left: -10px;
			border-bottom-color: rgba(0, 0, 0, 0.2);
			border-top-width: 1px;
			z-index: -1;
		}
	}
}

.badge {
	display: inline-block;
	padding: 0.07692rem 0.6154rem;
	font-size: 75%;
	font-weight: 700;
	line-height: 1;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 2px;
	&:empty {
		display: none;
	}
}

.badge-pill {
	padding-right: 0.7em;
	padding-left: 0.7em;
	border-radius: 10rem;
}

.badge-primary {
	color: $white;
	background-color: #007fa3;
	&[href] {
		&:hover,
		&:focus {
			color: $white;
			text-decoration: none;
			background-color: #005770;
		}
	}
}

.badge-secondary {
	color: $white;
	background-color: #878787;
	&[href] {
		&:hover,
		&:focus {
			color: $white;
			text-decoration: none;
			background-color: #6e6e6e;
		}
	}
}

.badge-success {
	color: $white;
	background-color: #008638;
	&[href] {
		&:hover,
		&:focus {
			color: $white;
			text-decoration: none;
			background-color: #005323;
		}
	}
}

.badge-info {
	color: #212529;
	background-color: #6ba4ff;
	&[href] {
		&:hover,
		&:focus {
			color: #212529;
			text-decoration: none;
			background-color: #3885ff;
		}
	}
}

.badge-warning {
	color: #212529;
	background-color: #ffb81c;
	&[href] {
		&:hover,
		&:focus {
			color: #212529;
			text-decoration: none;
			background-color: #e89f00;
		}
	}
}

.badge-danger {
	color: $white;
	background-color: $red;
	&[href] {
		&:hover,
		&:focus {
			color: $white;
			text-decoration: none;
			background-color: #a80019;
		}
	}
}

.badge-light {
	color: #212529;
	background-color: $white;
	&[href] {
		&:hover,
		&:focus {
			color: #212529;
			text-decoration: none;
			background-color: #e6e6e6;
		}
	}
}

.badge-dark {
	color: $white;
	background-color: #3d3c3c;
	&[href] {
		&:hover,
		&:focus {
			color: $white;
			text-decoration: none;
			background-color: #232323;
		}
	}
}

.badge-grey {
	color: #212529;
	background-color: #cccccc;
	&[href] {
		&:hover,
		&:focus {
			color: #212529;
			text-decoration: none;
			background-color: #b3b3b3;
		}
	}
}
