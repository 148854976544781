.breadcrumb {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    padding: 7px 20px;
    margin-bottom: 1rem;
    list-style: none;
    background-color: whitesmoke;
    border-radius: 2px;
  }
  
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.6154rem;
  }
  
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.6154rem;
    color: #878787;
    content: ">";
  }
  
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline;
  }
  
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none;
  }
  
  .breadcrumb-item.active {
    color: #4d4d4d;
  }
  
  