.new-user-eyeIcons {
    position: relative;
    div {
      position: absolute;
      right: 14px;
      bottom: 12px;
      cursor: pointer;
    }
    .mdi {
      font-size: 22px;
    }
  }