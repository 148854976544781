@import "../../../Stylesheets/base/colors.scss";

.drop-menu-item {
  padding: 1rem 1.5rem;
  cursor: pointer;
  display: block;
  width: 100%;
  clear: both;
  font-weight: 400;
  color: $grey;
  font-size: 1.1rem;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.menu-icon {
  margin-right: 12px;
  width: 12px;
  text-align: center;
}

.drop-menu-item {
  &:hover,
  &:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f5f5f5;
  }
  &.active,
  &:active {
    color: $white;
    text-decoration: none;
    background-color: $primarycolor;
  }
  &.disabled,
  &:disabled {
    color: #878787;
    background-color: transparent;
  }
}

.nav-menu-icon {
  button {
    color: #737373;
    font-size: 1.846rem;
    vertical-align: middle;
    cursor: pointer;
  }
  .dashboard-menu {
    width: 100%;
    margin-top: 2px;
  }
  &.show {
    button {
      color: $primarycolor;
    }
    div {
      opacity: 1 !important;
      pointer-events: auto !important;
    }
  }
  .dashboard-menu {
    ul {
      padding-left: 0px;
    }
    li {
      list-style-type: none;
      > {
        a,
        button {
          width: 100%;
        }
        a > button,
        button {
          display: block;
          padding: 0 20px;
          color: dimgray;
          font-size: 1.077rem;
          line-height: 38px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        a > button .icon,
        button .icon {
          line-height: 18px;
          font-size: 19px;
          min-width: 19px;
          margin-right: 9px;
          text-align: center;
          vertical-align: middle;
        }
        a.active > button,
        button.active {
          color: $primarycolor;
        }
        a > button:hover,
        button:hover {
          background-color: #f0f0f0;
        }
      }
    }
  }
  button.focus {
    border: 1px solid;
  }
}

@media (min-width: 979.98px) {
  .nav-menu-icon {
    display: none;
  }
}

.back-btnText {
  font-size: 16px;
  // margin: auto 4px;
  margin: 0 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 330px;
  white-space: nowrap;
  @media (max-width: 500px) {
    max-width: 150px;
  }
  @media (max-width: 650px) {
    max-width: 130px;
  }
}

.tt-arrow {
  float: right;
  margin: 7px;
  transition: 0.3s ease;
  display: block;
}

.nav-title {
  display: block;
  background-color: #e0e0e0;
  padding: 6px;
  box-sizing: border-box;
  cursor: pointer;
}

/*-!!!!!!!!!!-- NOTICE-!!!!!!!--*/

.title-text {
  height: 40px;
  box-sizing: border-box;
  padding: 4px 3px;
  background-color: $white;
  border-radius: 5px;
  display: block;
  border: 1px solid #ccc;
  img {
    width: 32px;
    vertical-align: middle;
    float: left;
  }
}

.tt-text {
  margin-left: 37px;
  line-height: 32px;
  color: $grey;
}

.nav-title:hover .title-text {
  background-color: #fafafa;
}

.be-navbar-header {
  @media (max-width: 500px) {
    width: 100%;
  }
  .back {
    cursor: pointer;
    display: flex;
    padding: 8px 0;
    & > div {
      display: flex;
    }
    &.b2c {
      padding: 0;
      & > div.icon-btn {
        width: 35px;
        height: 35px;
        display: flex;
        background-color: #dfe1e1;
        border-radius: 20px;
        padding: 5px;
        > i {
          margin: auto;
          color: #000000;
        }
      }
      .back-btnText {
        color: #000000;
        margin: auto 5px;
      }
    }
    @media (max-width: 979.98px) {
      padding: 5px 0;
    }
    color: #007fa3;
    .mdi {
      font-size: 24px;
    }
  }
}

.be-fixed-sidebar .be-left-sidebar {
  width: 100%;
  height: 45px;
  margin-top: 50px;
  /* padding-bottom: 61px; */
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 96;
  @media (max-width: 500px) {
    height: 43px;
  }
}

.be-left-sidebar {
  background-color: #fff;
  width: 230px;
  left: 0;
  position: absolute;
  .left-sidebar-wrapper {
    display: block;
    table-layout: fixed;
    width: calc(100% - 30px);
    height: 100%;
    max-width: 1360px;
    margin: 0 auto;
  }
  .left-sidebar-spacer {
    height: 100%;
  }
  .left-sidebar-scroll {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
  .left-sidebar-content {
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /*padding-top: 8px;*/
  }
  .sidebar-elements {
    margin: 0;
    padding: 0;
    > li {
      list-style: none;
      display: inline-block;
      height: 100%;
      cursor: pointer;
      a {
        height: 100%;
        display: block;
        padding-right: 16px;
        padding-left: 16px;
        @media (max-width: 500px) {
          padding-right: 0;
          padding-left: 0;
        }
        color: dimgray;
        font-size: 16px;
        line-height: 38px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        &.active,
        &.active:hover {
          // border-radius: 5px 5px 0 0;
          background-color: #ffffff;
          color: #505759;
        }
        &:hover {
          background-color: #ffffff;
          // border-radius: 5px 5px 0 0;
          color: #505759;
        }
      }
    }
  }
  &:before {
    content: "";
    position: fixed;
    z-index: -1;
    width: inherit;
    top: 0;
    left: 0;
    height: 45px;
    bottom: 0;
    background-color: inherit;
    border-right: 1px solid #e3e3e3;
  }
}

@media (max-width: 979.98px) {
  .be-fixed-sidebar .be-left-sidebar .left-sidebar-content {
    position: relative;
  }
}

@media (max-width: 979.98px) {
  .be-fixed-sidebar .be-left-sidebar {
    margin-top: 0;
    /*position: static;*/
    /*height: 50px;*/
    padding-bottom: 0;
    /*display: none;*/
    top: 50px;
  }

  .be-left-sidebar {
    width: 100%;
    background-color: #fff;
    /*border-bottom: 1px solid #e3e3e3;*/
    border-right-width: 0;
    position: relative;
    .left-sidebar-wrapper {
      display: block;
      table-layout: fixed;
      width: calc(100% - 30px);
      height: 100%;
      max-width: 1360px;
      margin: 0 auto;

      @media (max-width: 500px) {
        width: calc(100% - 16px);
      }
    }
  }

  .be-left-sidebar .left-sidebar-spacer.open {
    display: block;
  }
  .be-left-sidebar .sidebar-elements > li > a {
    text-align: left;
    position: relative;
  }
  .be-left-sidebar .sidebar-elements > li > a:hover > span {
    color: inherit;
  }
  .be-left-sidebar .sidebar-elements > li > ul {
    position: relative;
    height: auto;
    width: 100%;
    left: 0;
  }
  .be-left-sidebar .sidebar-elements > li > ul li > a:hover {
    background-color: transparent;
  }
  .be-left-sidebar .sidebar-elements > li > ul li > a:active {
    background-color: #e7e7e7;
  }
  .be-left-sidebar .sidebar-elements > li > ul > li.title {
    display: none;
  }
  .be-left-sidebar .sidebar-elements > li > ul > li.nav-items {
    display: block;
    height: auto;
  }
  .be-left-sidebar .sidebar-elements > li > ul > li.nav-items > .be-scroller {
    height: auto;
    overflow: visible;
  }
  .be-left-sidebar
    .sidebar-elements
    > li
    > ul
    > li.nav-items
    > .be-scroller
    > .content {
    padding-top: 0;
    overflow: visible;
    position: relative;
  }
  .be-left-sidebar .sidebar-elements > li:hover {
    background-color: inherit;
  }
  .be-left-sidebar .sidebar-elements > li:hover > ul {
    display: none;
  }
  .be-left-sidebar .sidebar-elements li.open > ul {
    display: block;
  }
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10001;
}
// Notification pop-up starts here

#notification-tab-content {
  *display: inline-block;
  width: 100%;
  background-color: green;
  vertical-align: top;
}
.topnav {
  overflow: hidden;
  background-color: #ffffff;
  border-bottom-width: 1px;
  border-bottom-color: #e8e8e8;
  border-bottom-style: solid;
}

.notification-content {
  height: 400px;
  background-color: #ffffff;
  overflow-y: scroll;
  overflow-x: hidden;

  border-bottom-width: 1px;
  border-bottom-color: #c8c7c5;
  border-bottom-style: solid;
}
.notification-loading-content {
  height: 200px;
  width: 100%;
  background-color: #ffffff;
  padding: 50px;

  border-bottom-width: 1px;
  border-bottom-color: #c8c7c5;
  border-bottom-style: solid;
}

.notification-view-all {
  width: 100%;
  height: 62px;
  // border-bottom-left-radius: 7px;
  // border-bottom-right-radius: 7px;
  background-color: #daf0ed;
  padding: 10px;
  // margin-bottom: -2.9%;
}
.button-bg {
  display: block;
  width: 100%;
  height: 44px;
  border: none;
  background-color: #047a9c;
  color: #ffffff;
  padding: 11px 20px;
  font-size: 18px;
  cursor: pointer;
  text-align: center;
  font-family: sans-serif;
  font-weight: 400;
  transition: background-color 0.2s;
  &:hover {
    background-color: #005a70;
  }
}
.view-all-label {
  width: 280px;
  height: 22px;
  font-family: sans-serif;
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.Rectangle {
  width: 16px;
  height: 20px;
  background-color: #6a7070;
}
.notifications_24px {
  width: 16px;
  height: 20px;
}
.bg-v2 {
  width: 1024px;
  height: 768px;
  object-fit: contain;
}
.Rectangle-5 {
  position: absolute;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  // border: solid 2px #ffffff;
  background-color: #e40051;
  font-family: sans-serif;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding: 1px;
  margin-left: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.notification-count {
  width: 13px;
  height: 15px;
  font-family: OpenSans;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.v2tablettop-popuphandbook {
  width: 370px;
  height: 768px;
}
.notification-pop-up {
  width: 340px;
  height: 90%;
  background-color: #ffffff;
  padding: 0px 0px -1px 2px;
}
.topnav a {
  float: left;
  display: block;
  color: #505759;
  text-align: center;
  padding: 13px 32px;
  text-decoration: none;
  font-family: sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  border-width: 1px;
  border-bottom-width: 1px;
  border-bottom-color: #e7e6e5;
  border-bottom-style: solid;
}
.topnav a:hover {
  border-bottom: 3px solid #007fa3;
  color: #007fa3;
}

.topnav a.active {
  border-bottom: 3px solid #007fa3;
  color: #007fa3;
}

.latest {
  width: 41px;
  height: 20px;
  font-family: PingFangHK-Regular;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #005a70;
  float: left;
  display: block;
  margin-left: 26px;
  margin-top: 13px;
  border-bottom: 3px solid transparent;
}
.notification-rectangle-active {
  width: 56px;
  height: 4px;
  background-color: #007fa3;
}
.due-soon {
  width: 64px;
  height: 20px;
  font-family: PingFangHK-Regular;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #505759;
  float: left;
  display: block;
  margin-top: 13px;
  margin-left: 65px;
}
.system {
  width: 48px;
  height: 20px;
  font-family: PingFangHK-Regular;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #505759;
  float: left;
  display: block;
  margin-top: 13px;
  margin-left: 80px;
}
.latest-post-rectangle {
  width: 340px;

  height: 87px;

  background-color: #fafafa;
}

.no-notification-text {
  // width: 177px;
  // height: 19px;
  font-family: sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  // text-align: center;
  color: #505759;
  margin-top: "18px";
  // margin-left: 82px;
}
.no-notification-img-div {
  color: #cccccc;
  // text-align: center;
  font: normal normal normal 50px/1 "Material Icons" !important;
  // margin-top: 58px;
  // margin-bottom: 58px;
}
.subject-list-select {
  width: 80px;
  text-overflow: "...";
  -ms-text-overflow: ellipsis;
  position: absolute;
  z-index: +1;
}
.subject-list-select:focus {
  width: 100%;
}
// Notification pop-up ends here
