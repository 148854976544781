#fullscreen_modal {
  &.fullscreen_modal_container {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-y: auto;
    height: 100%;
    .be-navbar-header {
      display: flex;
      justify-content: space-around;
      max-width: 1360px;
      margin: 0 auto;
      width: 100%;
      .back {
        padding: 15px;
      }
      .fullscreen_modal_center_header {
        z-index: -1;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px 90px 0px 0px;
        @media (max-width: 320px) {
          margin: 0px 40px 0px 0px;
        }
        .center {
          font-size: 18px;
        }
      }
    }
    .body {
      background-image: url("../../../assets/img/bg-v-2.svg");
      // height: 100vh;
      overflow-y: visible;
      overflow-x: hidden;
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      flex: 1;
      background-position: center;
      > .content {
        background-color:  #007FA3;
        // display: flex;
        // justify-content: center;
        flex: 1;
        .col-12 {
          &[data-assignment-type="view_all_notification"] {
            max-width: 1360px;
          }
          height: -webkit-fit-content;
          padding: 10px 20px;
          width: 100%;
          max-width: 800px;
          margin: 0 auto 15px auto;
          @media (max-width: 500px) {
            padding: 10px 8px;
          }
          .handbook-card {
            border-radius: 5px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
            background-color: white;
            .title_row {
              width: 100%;
              text-align: center;
              color: #000000;
              font-size: 18px;
              font-weight: 600;
              // height: 24px;
              border-bottom: 1px solid #e8e8e8;
              padding: 12px 0;
            }
            .filter-row {
              padding: 8px;
              display: flex;
              flex-direction: row;
              select {
                max-width: 90%;
                height: 36px;
                overflow-x: auto;
                margin: 2px;
              }
            }
          }
          section.assignment-handbook {
            padding-bottom: 15px;
            border-top: 1px solid #e8e8e8;
            table {
              thead {
                tr {
                  th.dot {
                    padding-left: 20px;
                  }
                }
              }
              tbody {
                tr {
                  td.dot {
                    padding-left: 20px;
                  }
                  td {
                    .handle {
                      margin-right: 8px;
                    }
                  }
                  td:last-child {
                    padding-right: 20px;
                  }
                }
              }
            }
            .pagination-row {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}
