.progress-chart {
  > div.progress-chart-center {
    display: flex;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    flex-direction: column;
    img {
      width: 57px;
      height: 81px;
      object-fit: contain;
      margin: 0 auto;
    }
    .title {
      margin: 2px 8px;
      font-size: 16px;
      font-weight: 600;
      color: #003057;
    }
    .progress {
      font-size: 45px;
      font-weight: 600;
      color: #003057;
      margin: -15px auto;
      padding-bottom: 15px;
      > span {
        font-size: 18px;
      }
    }
  }

  > div.small-progress-chart-center {
    display: flex;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    flex-direction: column;
    img {
      width: 35px;
      height: 35px;
      object-fit: contain;
      margin: 0 auto;
    }
    .title {
      margin: 2px 8px;
      font-size: 16px;
      font-weight: 600;
      color: #003057;
    }
    .my-progress {
      font-size: 25px;
      font-weight: bold;
      &.active {
        color: #12b2a6;
      }
      &.inactive {
        color: #dfe1e1;
      }

      > span {
        font-size: 14px;
      }
    }
    .overall-progress {
      font-size: 25px;
      font-weight: bold;
      &.active {
        color: #ffbb1c;
      }
      &.inactive {
        color: #dfe1e1;
      }

      > span {
        font-size: 14px;
      }
    }
  }
}
