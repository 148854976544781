// progress bar for student individual learnosity starts here
.student-progressbar-highest1 {
    --size: 192px;
    --bg: #e0e0e0;
    --pgPercentage: var(--value);
    animation: growProgressBar 3s 1 forwards;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    display: grid;
    place-items: center;
  
    font-style: normal;
    // font-weight: 800;
    font-weight: 600;
    font-size: 69px;
    line-height: 19px;
    scroll-behavior: smooth;
  
    // 00 - 20% (lowest)
    &-lowest {
      --fg: #ca2c2e;
      background: radial-gradient(
          closest-side,
          white 80%,
          transparent 85% 110%,/*Venni : HKPTDEV-11001*/
          white 0
        ),
        conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0);
      color: var(--fg);
    }
  
    // 21 - 40% (low)
    &-low {
      --fg: #fb842d;
      background: radial-gradient(
          closest-side,
          white 80%,
          transparent 85% 110%,/*Venni : HKPTDEV-11001*/
          white 0
        ),
        conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0);
      color: var(--fg);
    }
  
    // 41 - 60% (medium)
    &-medium {
      --fg: #fbc12d;
      background: radial-gradient(
          closest-side,
          white 80%,
          transparent 85% 110%,/*Venni : HKPTDEV-11001*/
          white 0
        ),
        conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0);
      color: var(--fg);
    }
  
    // 61 - 80% (high)
    &-high {
      --fg: #b2cf00;
      background: radial-gradient(
          closest-side,
          white 80%,
          transparent 85% 110%,/*Venni : HKPTDEV-11001*/
          white 0
        ),
        conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0);
      color: var(--fg);
    }
  
    // 81 - 100% (highest)
    &-highest {
      --fg: #008638;
      background: radial-gradient(
          closest-side,
          white 80%,
          transparent 85% 110%, /*Venni : HKPTDEV-11001*/
          white 0
        ),
        conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0);
      color: var(--fg);
    }
  }
  
  .student-progressbar-highest1::before {
    counter-reset: percentage var(--value);
    content: counter(percentage);
  }

  .main__section-student-unit-grid-red{
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    background: #CA2C2E;
    border-radius: 50%;
  }
  .main__section-student-unit-grid-green{
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    background: #008638;
    border-radius: 50%;
  }
  .main__section-student-unit-grid-grey{
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    background: #A8A8A8;
    border-radius: 50%;
  }
// progress bar for student individual learnosity ends here

.result_text{
    font-weight:900px;
    line-height: normal;
    font-size:18px;
    color:#0088CF;
    cursor:pointer;
}
.qno_text{
    font-weight:900px;
    line-height: normal;
    font-size:18px;
    color:#0088CF;
    cursor:pointer;
}
.bredcrum_Qview {
    background-color: #daf0ed;
    width: 100%;
    height: 49px;
    border-radius: 5px;
    margin-top: -10px;
    margin-left: -10px;
}
.result-menu-QP {
    background-color: #daf0ed;

    @media (max-width: 2560px) {
      margin: 0 -10px;
    }

    @media (max-width: 1024px) {
      margin: 0 -11px;
    }

    @media (max-width: 768px) {
      margin: 0 -8px;
    }
    display: flex;
    //height:10px;
    padding: 9px 30px;
    height: 50px;
    font-size: 16px;
    color: #007fa3;
    font-weight: bold;
    .gap {
      padding: 0 12px;
      color: #000000;
    }
    .active {
      font-weight: bold;
      color: #000000;
      cursor: default;
    }
  }
.backText{
    margin-top: 16px;
    margin-bottom: 8px;
}
.back_btn_qp{
    color:#0088CF;
	font-size: 21px;
	font-style: normal;
	font-weight: 900;
	line-height: normal;
    padding-left: 10px;
    padding-right: 10px;
    cursor:pointer;
}
.student_name_heading{
	color: #333;
	font-size: 30px;
	font-style: normal;
	font-weight: 800;
	line-height: normal;
    padding-left: 10px;
    padding-right: 10px;
}
.student_class_name{
	color: #AAA;
	font-size: 18px;
	font-style: normal;
	font-weight: 800;
	line-height: normal;
	margin-top: -8px;
}

.student_top_section{
	min-height: 300px;
    // display: flex;
    gap: 16px;
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-template-rows: auto 1fr;
    padding-left: 10px;
    padding-right: 10px;
}



.student_top_section_left{
    width: 300px;
    border-radius: 10px;
    background: #FFF;
    padding: 18px 21px;
}
.student_top_section_left_score{
    color: #333;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.student_top_section_right{
    flex: 1;
    background: #fff;
    border-radius: 10px;
}

.student_top_section_right_question{
    // border: 1px solid #E0E0E0;
    display: flex;
    // gap: 10px;
    flex-wrap: wrap;
}

.student_top_section_progressbar{
    display: flex;
    align-items: center;
    justify-content: center;
}

.suggestions__mainsection{
	/*max-width: 1256px;
    width: 98%;*/
	background: #fff;
    border-radius: 10px;
    padding-top: 26px;
    padding-left: 30px;
    padding-right: 50px;
    padding-bottom: 28px;
    margin-bottom: 10px;
}
.score_label_up_content{
	// display: flex;
    // align-items: center;
    // justify-content: space-between;

    display: grid;
    grid-template-columns: 1fr 100px;
}
.score_label_main_content{
	// display: flex;
	// flex-direction: column;
    display: grid;
    grid-template-columns: 1fr 100px;
    /* grid-template-rows: 1fr 1fr; */
    padding: 16px 0;
    border-bottom: 1px solid #DCDCDC;
}
.score_label_sub_content-text-head.score_label_center{
	justify-self: center;
}
.score_label_sub_content-text-head{
	color: #333;
    font-size: 19px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    // cursor: pointer;
}
.score_label_sub_content-text{
	color: #333;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
	margin-top: 10px;
}
.score_label_sub_content{
	// display: flex;
    // align-items: center;
    // justify-content: space-between;

    // display: grid;
    // grid-template-columns: 1fr 33px;
    // justify-content: space-between;
    // gap: 5px;
    display: grid;
    grid-template-columns: 1fr 100px;
    margin-left: 5px;
}
.progressbar_center_position{
    align-self: center;
    justify-self: center;
}
.score_label_sub_content_border{
	border-bottom: 1px solid #DCDCDC;
    align-self: stretch;
    margin-bottom: 10px;
}
.student_top_section_question_no{
    color: #333;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.student_accordion_arrow{
    background-image: url("../../../../../../assets/img/pec/all-images/arrow-accordion.svg") !important;
    background-repeat: no-repeat;
    background-size: cover;
    height: 7px;
    width: 12px;
    display: inline-block;
    margin-left: 15px;
    filter: contrast(0.3);
    transform: rotate(180deg);
    position: relative;
    top: 8px;
    cursor: pointer;
}
.student_bottom_section{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    min-height: 126px;
    margin-top: 20px;
    grid-column: 3/1;

    &_details{
        border-radius: 10px;
        background: #FFF;
        display: flex;
        padding: 10px;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    &_name{
        color: #9A9A9A;
        font-size: 14px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
    }

    &_count{
        color: #333;
        text-align: center;
        font-size: 30px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
    }
}
.count-black{
    color: #333;
}
.count-green{
    color: #008638;
}
.count-red{
    color: #CA2C2E;
}
.student_top_section_box {
    height: 70px;
    width: 93px;
    color: #fff;
    padding: 10px;
    border: solid 1px #E0E0E0;
    display: flex;
    align-items: center;
    justify-content: center;
}


@media only screen and (max-width: 641px) {
    .student_top_section{
        // flex-direction: column;
        // align-items: center;
        grid-template-rows: 250px 1fr 1fr;
        grid-template-columns: 1fr;
    }
    .student_top_section_left{
        grid-row: 1/1;
        margin: 0 auto;
    }
    .student_bottom_section{
        // grid-template-columns: 1fr; 
        grid-row: 2/2;
        display: flex;
        flex-direction: column;
    }
    .student_top_section_right_question{
        display: grid;
        grid-template-columns: 160px 160px;
        // grid-template-columns: minmax(200px, 160px) minmax(200px, 160px);
        justify-content: center;
        // justify-items: center;
        gap: 20px;
    }
    .student_top_section_right{
        background: none;
        grid-row: 3/3;
    }
    .student_top_section_right_question_section{
        display: flex;
        background: #fff;
        border-radius: 10px;
    }
    .student_bottom_section_details{
        flex-direction: row;
        justify-content: space-between;
    }
    .student_bottom_section_name{
        margin-left: 10px;
    }
    .student_bottom_section_count{
        margin-right: 10px;
    }
    .score_label_center{
        justify-self: center;
    }
}

.question_tick_arrow{
    background-image: url("../../../../../../assets/img/pec/all-images/question-result-tick.png") !important;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.question_half_tick_arrow{
    background-image: url("../../../../../../assets/img/pec/all-images/question-result-semi-tick.png") !important;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.question_cross_tick_arrow{
    background-image: url("../../../../../../assets/img/pec/all-images/question-result-cross.png") !important;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.question_hyphen_arrow{
    background-image: url("../../../../../../assets/img/pec/all-images/question-result-none.png") !important;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media only screen and (max-width: 641px) {
.student_top_section_right_question_section .student_top_section_box:first-child {
    border-radius: 10px 0 0 10px;
}
.student_top_section_right_question_section .student_top_section_box:last-child {
    border-radius: 0 10px 10px 0;
}
}