@import "../../Stylesheets/base/colors.scss";

// .bg-color {
//background-color: $iceblue;
// }
.name-banner {
  // background: #007fa3;
  background: #211761;
  // height: 300px;
  height: 370px;
  // background-image: url(/assets/images/science.jpg);src/assets/img/pec/banner1.png
  // background-image: url(/assets/images/science.jpg);
  @media (max-width: 600px) {
    // margin-top: 10%;
  }
}

.name-banner-text {
  margin-top: 45px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  // font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  padding-top: 24px;
  @media (max-width: 600px) {
    margin-top: 0;
    font-size: 14px;
    line-height: 22px;
    padding-top: 18px;
    font-weight: 300;
  }
}
.pec-banner-text {
  padding-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  // font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}
@media (max-width: 480px) {
  .pec-banner-text {
    font-size: 16px;
    padding-bottom: 12px;
    line-height: 22px;
    font-weight: 300;
  }
}
@media (max-width: 320px) {
  .pec-banner-text {
    font-size: 16px;
  }
}

.fav-container {
  display: flex;
  flex-direction: row;
  // margin-left: auto;
  // margin-right: 12px;
  // border: 1px solid #007fa3;
  border-radius: 2px;
}

.new-tab {
  cursor: pointer;
  width: 110px;
  align-self: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #ffffff;
  color: #007fa3;
}
@media (max-width: 600px) {
  .new-tab {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
}

.active-tab {
  background: #007fa3;
  color: #ffffff;
}

@media (max-width: 500px) {
  .fav-new-tab {
    width: 90px;
    font-size: 11px;
    font-weight: 600;
    padding-top: 8px;
    padding-bottom: 8px;
    line-height: 19px;
  }
  .all-new-tab {
    width: 90px;
    font-size: 11px;
    font-weight: 600;
    padding-top: 8px;
    padding-bottom: 8px;
    line-height: 19px;
  }
}

.new-logo-image-toolitems {
  height: 30px;
  margin-right: 5px;
}
.like-icon-container-toolItems {
  margin-left: auto;
}

.site-wrapper {
  .action {
    display: flex;
    max-width: 1360px;
    margin: 0 auto;
    width: calc(100% - 30px);
    justify-content: space-between;
    // border-bottom: 1px solid grey;
    position: relative;
    // height: 90px;
    @media (max-width: 979.98px) and (max-width: 500px) {
      width: calc(100% - 16px);
    }
    .school-years {
      display: flex;
      // flex-direction: column;
      // margin-left: auto;
      // min-width: 259px;
      // height: 44px;
      // border-bottom-left-radius: 5px;
      // border-bottom-right-radius: 5px;
      // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      // background-color: #ffffff;
      // border: 1px solid #bbbbbb;
      border-radius: 2px;
      line-height: 15px;
      color: #252525;
      // font-family: 'Open Sans';
      font-weight: 400;
      font-size: 11px;
      line-height: 15px;
      span {
        // font-family: Avenir;
        font-weight: 400;
        font-size: 14px;
        margin: auto 5px;
        line-height: 19.12px;
      }
      select {
        border: 0;
        outline: 0;
        // min-height: 36px;
        min-width: 135px;
        margin: 1px 2px;
        // padding: 8px 14px;
        font-size: 14px;
        background: #ffffff;
        line-height: 18px;
        padding: 12px;
        // border: none;
        // @media (max-width: "599px") {
        //   padding: 4px 14px;
        // }
        @media (max-width: 500px) {
          min-width: 115px;
          font-size: 13px;
          background: #ffffff;
          line-height: 18px;
        }
      }
      select option {
        background: #ffffff;
      }
    }
  }
}

.aside-header .breadcrumb {
  background-color: transparent;
  padding: 3px 0;
  margin-bottom: 16px;
  line-height: 16px;
  li {
    font-size: 1.077rem;
    font-weight: 300;
    color: $grey999;
  }
}

.tp-table > {
  thead > tr > th {
    padding: 5px 0px;
  }
  tbody > tr {
    > td {
      padding: 5px 0px;
    }
    &:hover {
      background-color: $dcGrey;
    }
  }
}

.tab-container > .tp-subject {
  padding: 5px 5px 0px 5px;
  margin-top: 10px;
}

#m.main-content {
  .card {
    max-width: 1360px;
    margin: auto;
    margin-bottom: 15px;
  }
}

//useful app

.useful_app {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  border-radius: 2px;
  background-color: #fff;
  -webkit-transition: 0.15s ease-in;
  -moz-transition: 0.15s ease-in;
  -o-transition: 0.15s ease-in;
  transition: 0.15s ease-in;
  cursor: pointer;
  padding: 5px;
  height: 100%;
  h5 {
    margin-right: 10px;
    font-size: 16px;
    font-weight: bold;
    color: $primarycolor;
    display: flex;
    flex-wrap: wrap;
    // justify-content: center;
    line-height: 20px;
  }
  p {
    margin-top: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    font-size: 14px;
    line-height: 20px;
    color: $grey;
  }
  img.toolIcon {
    margin-left: auto;
    transition: 0.2s transform;
    width: 90px;
    height: 90px;
    margin-top: 0px;
    @media (max-width: 600px) {
      width: 50px;
      height: 50px;
    }
    @media (max-width: 768px) {
      width: 50px;
      height: 50px;
    }

    @media (max-width: 840px) {
      width: 50px;
      height: 50px;
    }

    // @media (max-width: 840px) {
    //   width: 80px;
    //   height: 80px;
    // }
  }
  .tool-device-available {
    display: flex;
    justify-content: space-around;
    width: 80px;
    margin: auto;
  }
  &:active {
    // background-color: #daf0ed;
    // border-radius: 20px;
    img {
      transform: scale(1) translate(0px, 0px);
    }
  }
  &:hover {
    h5 {
      color: $Inkblue;
    }
    // background-color: #daf0ed;
    // border-radius: 20px;
    // img {
    //   transform: scale(1.05) translate(0px, -3px);
    // }
  }
}
.dashItem:hover {
  cursor: pointer;
}

.dashItem {
  width: 100%;
  text-align: left;
  font-weight: 700;
  margin-left: 10px;
}
.mgl-new {
  font-weight: 700;
  margin-left: 10px;
  padding-bottom: 9px;
  padding-right: 8px;
  cursor: pointer;
}
.left-mgl-new {
  position: relative;
  top: -46px;
  left: -6px;
}
.right-mgl-new {
  position: relative;
  top: -46px;
  left: 20px;
}
.logo-image {
  // width: 135px;
  // height: 93px;
  height: 80px;
  cursor: pointer;
  margin-top: 8px;
  margin-bottom: 8px;
}
.grey-logo-image {
  filter: grayscale(100%);
}
.mgl-new-sup {
  font-weight: 700;
  margin-left: 10px;
  padding-bottom: 9px;
  padding-right: 8px;
}
.ml-pec {
  margin-left: 45px;
  @media (max-width: 500px) {
    margin-left: 30px;
  }
}

.be-left-sidebars {
  // border-bottom: 1px solid #007fa3;
  .left-sidebar-wrapper {
    /*
        padding-right: 15px;
        padding-left: 15px;
    
    */
    .left-sidebar-content {
      height: 100%;
      .sidebar-elements {
        @media (max-width: 500px) {
          display: flex;
        }
        height: 100%;
        & > li {
          @media (max-width: 500px) {
            width: 100%;
          }
          > a {
            color: #505759;
            :hover {
              color: #007fa3;
            }
            img {
              width: 32px;
              height: 32px;
              margin: auto;
              @media (max-width: 500px) {
                width: 24px;
                height: 24px;
                display: block;
                margin-top: 4px;
                margin-bottom: 3px;
              }
            }
            span {
              @media (max-width: 500px) {
                // display: block;
                height: 15px;
                line-height: normal;
                font-size: 14px;
                text-align: center;
                padding-left: 0px;
                font-weight: bold;
                color: #505759;
                :hover {
                  color: #007fa3;
                }
              }
              padding-left: 8px;
            }
            &.active {
              span {
                color: #505759;
                border-bottom: 4px solid #d2db0e;
                padding-bottom: 9px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 600px) {
    height: 50px;
  }
}

.left-menu-name.active {
  color: #505759;
  // border-bottom: 4px solid #198cc5;
  padding-bottom: 9px;
  position: relative;
  top: 43px;
  // background-image: url(../../src/assets/img/pec/logo1.png);
}
.left-menu-name.inactive {
  color: #505759;
  // border-bottom: 4px solid #d9d9d9;
  padding-bottom: 9px;
  position: relative;
  top: 43px;
}
.sidebar-elements {
  list-style: none;
  display: flex;
}

.be-content{
  // margin: 0 auto;
  margin: 0 auto !important;
  // width: 1342px;
  max-width: 1342px;
  width: 98%;
  transform: translate(0px, -98px);
  background: #ffffff;
  border: 1px solid #ededed;
  // box-shadow: 0px 0px 5px rgba(0, 0, 0, 10%);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  // border-radius: 18px;
  border-radius: 18px 18px 0 0;
}
.be-content-transform {
  transform: none;
  margin-top: -98px !important;
}

.select-books,
.favourite-books {
  // height: 465px;
  margin-top: 25px;
  // background: #f5f6f7;
}

.right-section-menu {
  display: flex;
  // gap: 20px;
  gap: 4px;
  // position: relative;
  // left: -13px;
}

.year-drop-down {
  // width: 79px;
  // left: 158px;
  // top: 11px;
  // font-family: "Avenir";
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #198cc5;
  background-color: #e8eff3 !important;
  border-radius: 10px;
  // height: 43px;
  // position: relative;
  // left: 10px;
  // top: 23px;
}
body {
  background-image: none !important;
}

.resource-btn {
  height: 43px;
  // position: relative;
  // left: 12px;
  // top: 23px;
  display: flex;
  gap: 7px;
  justify-content: space-between;
  border-radius: 8.30882px;
  background: #198cc5;
  font-weight: 800;
  font-size: 11.6324px;
  line-height: 16px;
}
.resource-btn:hover {
  background: #198cc5 !important;
  box-shadow: none;
  border-color: none !important;
  border-bottom-color: none !important;
  -webkit-box-shadow: none !important;
}

.left-menu-title-border.active {
  position: absolute;

  width: 204px;
  left: -40px;
  top: 44px;
}
.left-menu-title-border.inactive {
  position: absolute;
  width: 209px;
  // left: -5px;
  left: 10px;
  top: 44px;
}
.left-menu-title-border.active1 {
  border-bottom: 4px solid #198cc5;
  border-radius: 69px;
}
.left-menu-title-border.inactive1 {
  border-bottom: 4px solid #d9d9d9;
  border-radius: 69px;
}
// new logo menu css starts here
.left-side-menu-section {
  display: flex;
  margin-left: 42px;
  gap: 76px;
  align-items: center;
  justify-content: center;
}
.left-menu-title-border.inactive {
  position: absolute;
  width: 209px;
  left: 190px;
  top: 87px;
}
.left-menu-title-border.active {
  position: absolute;
  width: 204px;
  left: -14px;
  top: 87px;
}
// new logo menu css ends here
.logged-details {
  // font-family: "Avenir";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 50px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  // margin-top: 88px;
  margin-top: 74px;
  margin-left: 10px;
  // text-shadow: 0 4px 12px rgb(0 0 2 / 70%);
  text-shadow: 0 4px 12px rgba(0, 0, 20, 70%);
  // top: 109px;
  // left: 119px;
  // position: absolute;
  // top: 156px;
  // left: 90px;
  // align-items: center;
  // justify-content: center;
  @media screen and (min-width: 641px) and (max-width: 1024px) {
    font-size: 25px;
    line-height: 30px;
    margin-top: 16px;
    padding: 7px 15px;
  }
  @media screen and (max-width: 641px) {
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    margin-top: -15px;
    padding: 7px 15px;
  }
}
.loggedin-details {
  // position: absolute;
  // top: 156px;
  // left: 90px;
  max-width: 1360px !important;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
.select-books-heading {
  // font-family: "Avenir";
  // font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: #333333;
}
.select-books {
  // margin: 25px auto;
  margin: 72px auto;
  // width: 1300px;
  // width: calc(100% - 30px);
  background: #f5f6f7;
  padding: 10px 15px;
  width: 100%;
  margin-bottom: 0;
}
.book-series {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
  // height: 235px;
  // margin-top: 22px;
  margin-bottom: 30px;
  grid-auto-flow: row;
  margin-top: 23px;
}
// tablet, mobile compatibility for sprint1 starts here
@media only screen and (max-width: 1024px) and (min-width: 641px) {
  .book-series {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    // height: 235px;
    // margin-top: 22px;
    margin-bottom: 30px;
    grid-auto-flow: row;
    margin-top: 23px;
  }
  .logo-image {
    // width: 77px;
    height: 62px;
  }
  .left-mgl-new {
    position: relative;
    top: -35px;
    left: -50px;
  }
  .left-menu-title-border.active {
    width: 114px;
  }
  .left-menu-title-border.inactive {
    width: 100px;
    // left: -21px;
    left: 100px;
  }
  .right-mgl-new {
    top: -35px;
    left: -33px;
  }
  .right-section-menu {
    gap: 5px;
    // left: -30px;
  }
  .site-wrapper .action .school-years select {
    min-width: 117px;
    padding: 10px;
    font-size: 13px;
  }
  // .resource-btn {
  //   font-size: 13.5px;
  // }
  .book-series {
    grid-template-columns: repeat(3, 1fr);
    margin: 0 auto;
    // grid-gap: 5px;
    margin-bottom: 30px;
    margin-top: 23px;
    justify-content: space-around;
  }
  .select-books {
    margin: 0 auto;
    // width: 98%;
    margin-top: 20px;
  }
  .favourited-books {
    // margin: 77px auto;
    margin-bottom: 0px;
    // margin-bottom: 61px;
  }
  .favourited-books1 {
    margin-bottom: -64px;
  }
  .left-side-menu-section {
    margin-left: 2px;
    gap: 5px;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 840px) {
  .book-series {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    margin-bottom: 30px;
    grid-auto-flow: row;
    margin-top: 23px;
  }
}
@media only screen and (max-width: 1349px) and (min-width: 1025px) {
  .book-series {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 10px;
    margin-bottom: 30px;
    grid-auto-flow: row;
    margin-top: 23px;
  }
  // .select-books {
  // margin: 25px auto;
  // width: 1045px;
  // background: #f5f6f7;
  // }
}

// @media only screen and (max-width: 640px) and (min-width: 375px) {
@media only screen and (max-width: 640px) {
  .action {
    display: flex;
    flex-direction: column;
  }
  .school-class-names {
    display: none;
  }
  .logo-image {
    // width: 88px;
    height: 60px;
    // position: relative;
    // top: 14px;
  }
  .left-side-menu-section {
    align-items: baseline;
  }
  // .right-section-menu {
  //   top: 32px;
  //   position: relative;
  // }
  .select-books {
    // margin: 77px auto;
    margin: 0px;
    // width: 98%;
    // background: #f5f6f7;
  }
  .left-menu-title-border.inactive {
    width: 180px;
    left: 180px;
  }
  .left-menu-title-border.active {
    width: 180px;
    left: 0px;
  }
  .book-series {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0px;
    margin-top: 23px;
    background: #ffffff;
  }
  .single-book-item {
    display: grid;
    // grid-template-columns: 76px auto 84px;
    grid-template-columns: 64px auto 84px;
    grid-auto-flow: column;
    background: #ffffff;
    gap: 13px;
    align-items: center;
  }
  a.darken span {
    font-size: 12px !important;
  }
  /*new item, for hidden the icon*/
  a.darken:hover span i.icon.mdi.mdi-open-in-new.new-open-icon {
    display: none;
  }
  // .single-book-item:hover::after {
  //   content: "";
  //   border-bottom: 1px solid #c1c1c1;
  // }
  .single-book-border {
    border-bottom: 1px solid #c1c1c1;
    height: 105px;
  }
  .book-image {
    height: 90px !important;
    width: 64px !important;
    border-radius: 6.10541px !important;
    cursor: pointer;
  }
  .book-item-title {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 22px !important;
    color: #333333 !important;
    display: flex;
    align-items: center;
  }
  .like-icon-container {
    display: none;
  }
  .mobile-heart-btn {
    background-image: url("../../assets/img/pec/all-images/icon-heart-outline-grey.png") !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    height: 30px;
    width: 30px;
    background-color: transparent !important;
    // position: relative;
    // right: -50px;
  }
  .mobile-heart-btn:hover {
    font-size: 0px !important;
  }
  .mobile-unfavourite-btn {
    background-image: url("../../assets/img/pec/all-images/icon-heart-red-filled.png") !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    height: 30px;
    width: 30px;
    background-color: transparent !important;
    font-size: 0px !important;
    // position: relative;
    // right: -50px;
  }
  .mobile-unfavourite-btn:hover {
    font-size: 0px !important;
  }
  .footer-part1,
  .footer-part2 {
    flex-direction: column;
    gap: 0px !important;
  }
  .footers-copyright {
    font-size: 12px !important;
  }
  .logged-details {
    font-style: normal;
    font-weight: 500;
    font-size: 20.9722px;
    line-height: 26px;
  }
  .favourited-books {
    // margin: 75px auto;
    // margin: 88px auto;
  }
  .favourited-books1 {
    margin: 31px auto;
  }

  .selected-favourite {
    margin-top: 14px;
  }
  .new-border-line {
    border-bottom: 1px solid grey;
    position: relative;
    top: 72px;
    width: 361px;
  }
  .new-border-line {
    display: block !important;
  }

  .site-wrapper .action {
    border-bottom: none;
  }
}

// tablet, mobile compatibility for sprint1 ends here
.new-border-line {
  display: none;
}

@media only screen and (max-width: 640px) and (min-width: 601px) {
  .right-section-menu {
    top: 2px;
    position: relative;
  }
}

// @media only screen and (max-width: 1328px) and (min-width: 1040px) {
//   .book-series {
//     display: grid;
//     grid-template-columns: repeat(5, 1fr);
//     grid-gap: 10px;
//     margin-bottom: 30px;
//     grid-auto-flow: row;
//     margin-top: 23px;
//   }
// }
// @media only screen and (max-width: 1140px) and (min-width: 1025px) {
//   .book-series {
//     display: grid;
//     grid-template-columns: repeat(5, 1fr);
//     grid-gap: 10px;
//     margin-bottom: 30px;
//     grid-auto-flow: row;
//     margin-top: 23px;
//   }
// }
.buttons {
  cursor: pointer;
  // font-family: "Avenir";
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  border: none;
}
.heart-btn {
  background: #e40051;
  border-radius: 8.30882px;
  // width: 180px;
  width: 100%;
  padding: 0.52rem 0.75rem;
}

.heart-btn:hover {
  background: #bd0043;
  border-radius: 8.30882px;
  line-height: 23px;
  .like-icon-container {
    display: none;
  }
}
// .heart-btn:hover::before {}
.heart-btn:hover::after {
  content: "Favourite";
}
.heart-btn-chinese:hover::after {
  content: "我的最愛";
}
// .heart-btn:hover:after {
//   background: #bd0043;
//   border-radius: 8.30882px;
//   content: "Favourite";
// }
.unfavourite-btn {
  background: #c1c1c1;
  border-radius: 8.30882px;
  // width: 180px;
  width: 100%;
  padding: 0.654rem 0.75rem;
}
.book-item-title {
  // font-family: "Avenir";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #333333;
  // width: 179.56px;
  width: 100%;
  // word-break: break-all;
  margin-top: 10px;
  margin-bottom: 10px;
}
.book-image {
  // height: 252.87px;
  // width: 179.56px;
  width: 100%;
  border-radius: 5.3125px;
  cursor: pointer;
}

a.darken {
  // display: inline-block;
  display: block;
  // background: #00000080;
  // background: #7e7e7e;
  padding: 0;
  position: relative;
}
a.darken:hover {
  background: #7e7e7e;
  border-radius: 5.3125px;
}
a.darken img {
  display: block;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

a.darken:hover img {
  opacity: 0.3;
}
a.darken span {
  visibility: hidden;
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  line-height: 100%;
}
a.darken:hover span {
  color: #fff;
  visibility: visible;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.year-drop-down {
  appearance: none;
  background-image: url("../../assets/img/pec/all-images/Filter-Books/pull-down-arrow-down.png") !important;
  background-repeat: no-repeat !important;
  background-position: right !important;
  background-position-x: 95% !important;
  background-position-y: 16px !important;
  width: 11.21px;
}
.load-spinners {
  margin: 40px;
}

.logo-image-loading {
  margin-left: 92px;
  margin-top: 12px;
}
.dashboard-footer {
  // margin-top: -38px !important;
  margin-top: -97px !important;
}

// new logo tab css starts here
.whole-tab {
  display: flex;
  width: 100%;
  border-bottom: 2px solid rgba(137, 137, 137, 0.3);
  align-items: center;
}
.tab-group {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  overflow-x: auto;
}
.tab-group::-webkit-scrollbar {
  height: 1px;
}
.tab-group .tab {
  padding: 8px 17px;
  position: relative;
}
.tab-group .tab img {
  // height: 70px;
  filter: grayscale(1);
  opacity: 0.5;
  transition: filter opacity;
  transition-duration: 0.2s;
  height: 80px;
}
.tab-group .tab:hover img {
  filter: grayscale(0.8);
  opacity: 0.7;
}
.tab-group .tab.active img {
  filter: grayscale(0);
  opacity: 1;
}
.tab-group .tab .line {
  height: 5px;
  background-color: #d9d9d9;
  border-radius: 2.5px;
  position: absolute;
  left: 0px;
  bottom: -2px;
  width: 100%;
}
.tab-group .tab.active .line {
  background-color: #198cc5;
}
.right-control {
  margin-top: 10px;
  margin-bottom: 10px;
  // min-width: 200px;
}
@media only screen and (max-width: 1024px) {
  .tab-group .tab img {
    height: 50px;
  }
}
@media only screen and (max-width: 640px) {
  .whole-tab {
    flex-direction: column;
    align-content: start;
    border-bottom: 0;
  }
  .tab-group {
    border-bottom: 2px solid rgba(137, 137, 137, 0.3);
    width: 100%;
  }
  .tab-group .tab img {
    height: 60px;
  }
  .right-control {
    width: 100%;
  }
}
// new logo tab css ends here

.not-clickable {
  pointer-events: none;
  cursor: not-allowed;
}
.default-messages {
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 150%;
  text-align: center;
  color: #666666;
  margin-top: 213px;
  margin-bottom: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-select-selector {
  height: 80px !important;
}

.select-box-logo-image {
  height:80px;
}

.tab-group .logo-select-bookname{
  display:none;
}

.ant-select-dropdown {
  width:350px !important;
  z-index: 9999 !important;
}

.ant-select-item-option-content {
  white-space: break-spaces !important;
  word-break: break-word;
}

.ant-row {
  white-space: break-spaces !important;
}

.rc-virtual-list {
  pointer-events: auto !important;
}