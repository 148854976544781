.handbook-details {
  font-style: normal;
  font-weight: 900;
  font-size: 70px;
  line-height: 83px;
  color: #ffffff;
  margin-top: 78px;
  margin-left: 10px;
}

.main__handbook-section {
  padding: 40px 20px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-wrap: wrap;

  &-filter {
    &-by {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #333333;
    }
    &-selection {
      display: grid;
      align-items: center;
      // grid-template-columns: repeat(3, minmax(200px, 1fr));
      grid-template-columns: 350px repeat(2, minmax(200px, 1fr));
      gap: 30px;
    }
  }
}

.handbook-series-filter,
.handbook-book-filter,
.handbook-class-filter,
.handbook-series-sorting {
  appearance: none;
  border: none;
  background-image: url("../../../../assets/img/pec/all-images/Filter-Books/pull-down-arrow-down.png") !important;
  background-repeat: no-repeat !important;
  background-position: right !important;
  margin: 0;
  cursor: pointer;
  padding: 5px;
  background-position-x: 95% !important;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #377d9f;
  width: 200px;
  height: 31px;
  background: #f8f8f8;
  border-radius: 5px;
  padding: 11px;
}
.series-handbook-series-filter {
  width: 350px;
}
.main-component-expanded {
  width: 315px;
}

.sortings-section {
  //   padding: 5px;
  padding: 0px;
  padding-left: 11px;
}

.handbook-series-filter,
.handbook-book-filter,
.handbook-class-filter {
  padding-top: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.handbook-series-selection {
  //   display: flex;
  //   flex-direction: column;
  //   gap: 5px;
  position: absolute;
  z-index: 9999;
  min-width: 157px;
  min-height: 238px;
  width: 200px;
  height: 238px;
  cursor: default;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  overflow: auto;
  padding: 10px;
  &-name {
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #377d9f;
    // width: 150px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    cursor: pointer;
  }
}

.main__handbooks {
  padding: 20px;
  // margin-top: 30px;
  padding-top: 10px;
  min-height: 70vh;

  &-label-unread{
    width: 20px;
  }
  &-unread{
    display: inline-block;
    height: 8px;
    width: 8px;
    border-radius: 10px;
    background-color: #FF0000;
    margin-right: 10px;
  }

  &-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 20px;
    &-head {
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      line-height: 25px;
      color: #333333;
      padding: 30px 20px;
      padding-top: 0;
    }
    &-data {
      padding: 30px 20px;
      &:first-child {
        border-radius: 10px 0 0 10px;
      }
      &:last-child {
        border-radius: 0 10px 10px 0;
      }
    }
    &-row {
      background: #fbfbfb;
      border: 1px solid #e9e9e9;
      cursor: pointer;
    }
  }
}

.fullscreen_modal_footer {
  margin-top: -97px;
}
.main__handbooks {
  &-book {
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    color: #333333;
  }
  &-topic {
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    color: #198cc5;
  }
  &-text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #333333;
  }
}

//tablet phone compatibility
@media only screen and (max-width: 1024px) and (min-width: 641px) {
  .handbook-details {
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 83px;
    color: #ffffff;
    margin-top: 30px;
  }
  .main__handbook-section-filter-selection {
    // grid-template-columns: repeat(3, minmax(120px, 200px));
    gap: 5px;
  }
  .name-banner {
    height: 257px !important;
  }
  .loggedin-details {
    top: -30px;
    position: relative;
  }
}

//mobile phone compatibility
@media only screen and (max-width: 640px) {
  .handbook-details {
    font-style: normal;
    font-weight: 800;
    line-height: 29px;
    font-size: 21px;
    color: #ffffff;
    margin-top: 30px;
  }
  .main__handbook-section {
    flex-direction: column;
  }
  .main__handbook-section-filter-selection {
    grid-template-columns: minmax(120px, 612px);
  }
  .main__handbook-section-sorting {
    margin-top: 30px;
    display: grid;
    grid-template-columns: minmax(120px, 612px);
  }
  .handbook-series-filter,
  .handbook-book-filter,
  .handbook-class-filter,
  .handbook-series-sorting {
    width: inherit;
  }
  .name-banner {
    height: 257px !important;
  }
}

@media only screen and (max-width: 1000px) {
  .main__handbooks {
    overflow-x: scroll;
    width: 100%;
  }
  .main__handbooks::-webkit-scrollbar {
    height: 1px;
  }
}

@media only screen and (max-width: 800px) {
  .main__handbook-section-filter-selection {
    // grid-template-columns: minmax(120px, 612px);
    grid-template-columns: minmax(272px, 647px);
    gap: 10px;
  }
  .handbook-series-filter,
  .handbook-book-filter,
  .handbook-class-filter {
    width: inherit;
    cursor: pointer;
  }
}

tr.main__handbooks-table-row td:last-child {
  border-right: 1px solid #e9e9e9;
}
tr.main__handbooks-table-row td:first-child {
  border-left: 1px solid #e9e9e9;
}
tr.main__handbooks-table-row td {
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
}
.main__handbooks-book-series-name{
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 400px;
  width: 95%;
}
