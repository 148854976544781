#assignment_views {
  &.fullscreen_modal_container {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-y: auto;
    height: 95vh;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }
}
.first_onboarding{
  padding: 20px;
}
.first_onboarding .modal-header{
  padding: 0px !important;
}
.first_onboarding .modal-body{
  padding: 0px !important;
}
.first_onboarding .modal-footer{
  padding: 0px !important;
}
.first_onboarding .apps{
  display: flex;
    margin: 4px 0 4px 1px;
    padding: 16px 100px 16px 100px;
    background-color: #daf0ed;
    justify-content: center;
    flex-wrap: wrap;
}
.first_onboarding .apps li:last-child{
  margin-left: 0px;
  margin-bottom: 0px;
}
.modal-body ul li {
  font-size: 16px !important;
  color: rgb(0, 0, 0) !important;
}
.first_onboarding .apps li{
  list-style: none;
  margin-left: 15px;
  margin-bottom: 15px;
  max-width: 100px;
  padding: 0px;
  background: none;
}
.first_onboarding img{
  width: 100%;
}
.bookseries{
  padding: 0px;
}
.bookseries li{
  list-style: none;
}
p{
  font-size: 16px;
  color: #000;
}
label{
  font-size: 16px;
  color: #000;
}

#assignment_views.fullscreen_modal_container.first_onboarding {
  height: auto;
  max-height: 95vh;
}
// .ReactModal__Content.ReactModal__Content--after-open{
//   background: none !important;
//   padding: 0px 4px !important;
// }

@media (max-width: 599.98px) {
  .first_onboarding .apps{
    display: flex;
      margin: 4px 0 4px 1px;
      padding: 16px;
      background-color: #daf0ed;
      justify-content: center;
      flex-wrap: wrap;
  }

}
@media (max-width: 330px) {
  .first_onboarding .modal-body ul li {
  font-size: 12px !important;
}

.first_onboarding .onBoardingTitle h4 p {
  font-size: 21px;
}

.first_onboarding .modal-body p {
  font-size: 13px;
  line-height: 16px;
}
}