/*------------------------------------------------------------------
	[Top header]
*/

.be-top-header {
  background-color: #ffffff;
  border: 0;
  margin-bottom: 0;
  border-bottom: 1px solid #e6e6e6;
  padding: 0;
  .container-fluid {
    padding: 0;
  }
  .be-navbar-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 230px;
  }
}

@media (max-width: 979.98px) {
  .be-top-header {
    border-bottom-width: 0;
    /* position: relative;*/
    min-height: 0;
  }
}

@media (max-width: 979.98px) {
  .be-nosidebar-left .be-top-header .container-fluid {
    display: block;
    .navbar-collapse {
      -webkit-box-align: baseline;
      -ms-flex-align: baseline;
      align-items: baseline;
      &.collapse {
        display: none !important;
        &.show {
          display: block !important;
        }
      }
    }
  }
}

.be-collapsible-sidebar .be-top-header .be-navbar-header {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.be-collapsible-sidebar-hide-logo.be-collapsible-sidebar-collapsed
  .be-top-header
  .be-navbar-header {
  width: 60px;
}

@media (max-width: 1199.98px) {
  .be-nosidebar-left .be-top-header .be-navbar-header {
    width: auto;
  }
}

.be-top-header .be-navbar-header .navbar-brand {
  /*line-height: 60px;
  height: 60px;
  width: 162px;
  background-image: url("../../src/assets/img/logo.svg");
  background-repeat: no-repeat;
  background-position: 20px center;
  padding: 0 20px;
  margin: 0;
  display: block;*/
  line-height: 60px;
  height: 32px;
  width: 150px;
  background-image: url("../../src/assets/img/pec/pearson-logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left center;
  padding: 0 20px;
  margin: 0;
  display: block;
}

#dashboard.class-nav-bar .be-navbar-header .navbar-brand,
#main.class-nav-bar .be-navbar-header .navbar-brand {
  /*line-height: 60px;
  height: 34px;
  width: 137px;
  background-image: url("../../src/assets/img/logo.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left center;
  padding: 0 20px;
  margin: 0;
  display: block;*/
  line-height: 60px;
  height: 32px;
  width: 150px;
  background-image: url("../../src/assets/img/pec/pearson-logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left center;
  padding: 0 20px;
  margin: 0;
  display: block;
}
#dashboard.class-nav-bar .be-navbar-header .navbar-brand-pec,
#main.class-nav-bar .be-navbar-header .navbar-brand-pec {
  line-height: 60px;
  height: 34px;
  width: 137px;
  // background-image: url("../../src/assets/img/pearson-logo.svg");
  background-image: url("../../src/assets/img/pec/pearson-logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left center;
  padding: 0 20px;
  margin: 0;
  display: block;
  cursor: pointer;
}
// .navbar-pec-logo{
//   background-image: url("../../src/assets/img/pearson-logo");
// }

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .be-top-header .be-navbar-header .navbar-brand {
    background-image: url("../../src/assets/img/logo-xx.svg");
    background-size: 102px;
  }
}

@media (max-width: 979.98px) {
  .be-top-header .be-navbar-header .navbar-brand,
  #dashboard.class-nav-bar .be-navbar-header .navbar-brand {
    /*display: none;*/
  }
}

@media (max-width: 1199.98px) {
  .be-top-header .be-navbar-header .navbar-brand {
    margin-right: 10px;
  }
}

.be-nosidebar-left .be-top-header .be-navbar-header .navbar-brand {
  width: 162px;
}

.be-collapsible-sidebar-hide-logo.be-collapsible-sidebar-collapsed
  .be-top-header
  .be-navbar-header
  .navbar-brand {
  display: none;
}

.be-top-header .be-navbar-header .be-toggle-left-sidebar {
  display: inline-block;
  text-align: center;
  line-height: 60px;
  float: right;
  padding: 0 15px;
  .icon {
    font-size: 1.615rem;
    color: #5a5a5a;
    line-height: 25px;
    vertical-align: middle;
  }
}

.be-collapsible-sidebar-hide-logo.be-collapsible-sidebar-collapsed
  .be-top-header
  .be-navbar-header
  .be-toggle-left-sidebar {
  padding: 0 21px;
}

@media (max-width: 979.98px) {
  .be-top-header .be-navbar-header {
    display: none;
  }
}

@media print {
  .be-top-header .be-navbar-header {
    display: none;
  }
}

.be-top-header .page-title {
  font-size: 1.462rem;
  line-height: 60px;
  float: left;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0 20px;
  color: #404040;
}

.be-nosidebar-left .be-top-header .page-title {
  padding-left: 10px;
}

@media (max-width: 979.98px) {
  .be-top-header .page-title {
    display: none;
  }
}

.be-top-header {
  .navbar-nav > li {
    > a button.nav-link {
      line-height: 60px;
      color: #5a5a5a;
      font-size: 14px;
      padding: 0 18px;
      outline: none;
      &:hover,
      &:focus {
        color: #5a5a5a;
      }
    }
    &.show > a button {
      color: #007fa3;
      &:focus,
      &:active,
      &:hover {
        color: #007fa3;
      }
    }
    &.dropdown {
      > a .mdi-caret-down {
        font-size: 1.769rem;
        vertical-align: middle;
        margin-left: 3px;
      }
      .dropdown-menu {
        border-radius: 3px;
        margin-top: 12px;
        line-height: 20px;
      }
      #handbook.dropdown-menu,
      #nav.dropdown-menu {
        border-radius: 3px;
        margin-top: -10px;
        line-height: 20px;
      }
      .dropdown-menu {
        > a {
          color: #504e4e;
          padding: 10px 20px;
          min-width: 175px;
          line-height: 18px;
          &:hover {
            background-color: #007fa3;
            color: #ffffff;
          }
          &:focus,
          &:active {
            background-color: transparent;
            color: #504e4e;
          }
        }
        &:after {
          border-bottom: 8px solid white;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          top: -8px;
          content: "";
          display: inline-block;
          left: 42px;
          margin-left: -6px;
          position: absolute;
        }
        &:before {
          border-bottom: 8px solid white;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          top: -8px;
          content: "";
          display: inline-block;
          left: 42px;
          margin-left: -6px;
          position: absolute;
          border-bottom-width: 9px;
          border-left-width: 9px;
          border-right-width: 9px;
          border-bottom-color: #e3e3e3;
          margin-left: -7px;
          top: -9px;
        }
      }
    }
  }
  .navbar-collapse {
    border-width: 0;
  }
  .search-container {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: inline-block;
    height: 60px;
    padding: 10px 20px;
    .input-group {
      max-width: 800px;
    }
  }
  .search-input {
    border-width: 1px;
    border-right: 0;
    color: #404040;
    &:focus {
      background-color: #ffffff;
      color: gray;
      &::-webkit-input-placeholder,
      &::-ms-input-placeholder,
      &::placeholder {
        color: #737373;
      }
    }
  }
  .be-right-navbar {
    width: calc(100% - 230px);
  }
  .be-right-navbar-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media (max-width: 1199.98px) {
  .be-top-header .navbar-nav > li > a button.nav-link {
    padding: 0 24px;
  }
}

@media (max-width: 979.98px) {
  .be-top-header .navbar-collapse {
    border-top: 1px solid #f5f5f5;
    max-height: none;
  }
}

@media (max-width: 979.98px) {
  .be-top-header .navbar-collapse .navbar-nav {
    background-color: #fafafa;
    margin: 0;
    padding: 15px 0;
  }
  .be-nosidebar-left .be-top-header .navbar-collapse .navbar-nav {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .be-top-header .navbar-collapse .navbar-nav > li {
    > a {
      display: block;
      padding: 0 20px;
      color: dimgray;
      font-size: 1.077rem;
      line-height: 38px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      .icon {
        line-height: 18px;
        font-size: 19px;
        min-width: 19px;
        margin-right: 9px;
        text-align: center;
        vertical-align: middle;
      }
    }
    &.dropdown {
      .mdi-caret-down {
        float: right;
        line-height: 38px;
        color: #c5c5c5;
      }
      .dropdown-menu {
        background-color: #eeeeee;
        padding: 10px 0;
        border-top: 1px solid #e6e6e6;
        border-right: 0;
        border-bottom: 1px solid #e6e6e6;
        border-left: 0;
        border-radius: 0;
      }
    }
  }
  .be-nosidebar-left
    .be-top-header
    .navbar-collapse
    .navbar-nav
    > li.dropdown
    .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .be-top-header .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu {
    &:before,
    &:after {
      display: none;
    }
    > a {
      color: dimgray;
      padding: 8px 15px 8px 49px;
      padding-left: 23px;
    }
  }
}

@media (max-width: 979.98px) {
  .be-top-header .search-container {
    display: none;
  }
}

@media (max-width: 979.98px) {
  .be-top-header .search-input {
    background-color: #ffffff;
    color: #404040;
  }
}

.be-wrapper.be-nosidebar-left .be-top-header .be-right-navbar-flex {
  width: calc(100% - 230px);
}

.be-top-header .be-right-navbar .navbar-nav {
  background-color: transparent;
}

@media (max-width: 979.98px) {
  .be-top-header .be-right-navbar .navbar-nav > li {
    > a.nav-link {
      color: #ffffff;
      &:focus,
      &:active {
        color: #ffffff;
      }
    }
    &.show > a.nav-link {
      color: #ffffff;
      &:focus,
      &:active,
      &:hover {
        color: #ffffff;
      }
    }
  }
}

@media (max-width: 979.98px) {
  .be-top-header .be-right-navbar {
    padding-right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid #007fa3;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #007fa3;
    z-index: 1030;
    height: 60px;
  }
}

.be-collapsible-sidebar-hide-logo.be-collapsible-sidebar-collapsed
  .be-top-header
  .be-right-navbar {
  width: calc(100% - 60px);
}

@media (max-width: 979.98px) {
  .be-collapsible-sidebar-hide-logo.be-collapsible-sidebar-collapsed
    .be-top-header
    .be-right-navbar {
    width: 100%;
  }
}

@media (max-width: 979.98px) {
  .be-offcanvas-menu .be-top-header .be-right-navbar {
    padding-right: 5px;
    position: relative;
  }
}

.be-nosidebar-left .be-top-header .be-right-navbar {
  width: auto;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

@media (max-width: 979.98px) {
  .be-nosidebar-left .be-top-header .be-right-navbar {
    width: 100%;
  }
}

@media (max-width: 979.98px) {
  .be-top-header .be-toggle-right-sidebar {
    margin-right: 0;
    padding-left: 5px;
    padding-right: 9px;
  }
}

.be-top-header .be-toggle-top-header-menu {
  display: none;
  background-color: #ffffff;
  padding: 16px 10px;
  color: #404040;
  font-weight: 300;
  font-size: 1.077rem;
  border-bottom: 1px solid #f5f5f5;
  &:before {
    content: "\f2f8";
    float: right;
    font-family: "Material Icons";
    font-size: 2.077rem;
    line-height: 22px;
    color: #c5c5c5;
  }
  &.collapsed {
    border-bottom: 1px solid #e3e3e3;
    -webkit-transition: border-bottom-color 0.4s ease;
    transition: border-bottom-color 0.4s ease;
    &:before {
      content: "\f2f2";
    }
  }
}

@media (max-width: 979.98px) {
  .be-top-header .be-toggle-top-header-menu {
    display: block;
  }
}

@media (max-width: 979.98px) {
  .be-offcanvas-menu .be-top-header {
    position: fixed;
  }
}

@media (max-width: 979.98px) {
  .be-offcanvas-menu .be-top-header .container-fluid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.be-offcanvas-menu .be-top-header {
  .be-navbar-header {
    width: 230px;
    .be-toggle-left-sidebar {
      padding-left: 25px;
      .icon {
        color: #737373;
        font-size: 1.846rem;
      }
    }
  }
  .navbar-brand {
    width: 142px;
  }
  .be-toggle-left-sidebar {
    display: inline-block;
    text-align: center;
    line-height: 60px;
    float: left;
    .icon {
      font-size: 1.615rem;
      color: #5a5a5a;
      line-height: 25px;
      vertical-align: middle;
    }
  }
}

@media (max-width: 979.98px) {
  .be-offcanvas-menu .be-top-header .be-navbar-header {
    display: block;
    width: auto;
    background-color: #007fa3;
  }
}

@media (max-width: 979.98px) {
  .be-offcanvas-menu .be-top-header .be-navbar-header .be-toggle-left-sidebar {
    padding-left: 15px;
    padding-right: 13px;
  }
}

@media (max-width: 979.98px) {
  .be-offcanvas-menu
    .be-top-header
    .be-navbar-header
    .be-toggle-left-sidebar
    .icon {
    color: #ffffff;
    overflow: hidden;
  }
}

@media (max-width: 979.98px) {
  .be-offcanvas-menu .be-top-header .be-toggle-left-sidebar {
    line-height: 62px;
    margin-top: -1px;
  }
}

.be-color-header .be-top-header {
  background-color: #007fa3;
  border-bottom-color: #007fa3;
  .be-navbar-header .navbar-brand {
    background-image: url("../../src/assets/img/logo-white.png");
  }
  .page-title {
    color: #ffffff;
  }
  .be-right-navbar {
    .navbar-nav > li > button {
      color: #ffffff;
    }
    .be-icons-nav > li.dropdown > button {
      .icon {
        color: #ffffff;
      }
      .indicator {
        background-color: #ffffff;
      }
    }
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .be-color-header .be-top-header .be-navbar-header .navbar-brand {
    background-image: url("../../src/assets/img/logo-white-xx.png");
  }
}

.be-color-header-success .be-top-header {
  background-color: #008638;
  border-bottom-color: #008638;
}

@media (max-width: 979.98px) {
  .be-color-header-success .be-top-header .be-right-navbar {
    background-color: #008638;
    border-bottom-color: #008638;
  }
}

.be-color-header-warning .be-top-header {
  background-color: #ffb81c;
  border-bottom-color: #ffb81c;
}

@media (max-width: 979.98px) {
  .be-color-header-warning .be-top-header .be-right-navbar {
    background-color: #ffb81c;
    border-bottom-color: #ffb81c;
  }
}

.be-color-header-danger .be-top-header {
  background-color: #db0020;
  border-bottom-color: #db0020;
}

@media (max-width: 979.98px) {
  .be-color-header-danger .be-top-header .be-right-navbar {
    background-color: #db0020;
    border-bottom-color: #db0020;
  }
}

@media (min-width: 1195px) {
  .be-boxed-layout .be-top-header {
    max-width: 1200px;
    margin: 0 auto;
    z-index: 1032;
  }
}

@media print {
  .be-top-header {
    position: absolute;
  }
}

.be-top-header .be-icons-nav {
  margin-right: 20px;
}

@media (min-width: 1195px) {
  .be-right-navbar-flex .be-icons-nav {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
}

@media (max-width: 1199.98px) {
  .be-top-header .be-icons-nav {
    margin-right: 10px;
  }
}

.be-icons-nav > li.dropdown {
  position: static;
  @media (min-width: 980px) {
    // position: relative;
  }

  > button {
    position: relative;
  }
}

.navbar-expand .be-icons-nav > li.dropdown > button {
  padding: 0 0 0 16px;
}

.home-icon {
  background-image: url("../../src/assets/img/pec/all-images/icon-home.png");
  background-repeat: no-repeat;
  height: 26px;
  width: 22.7px;
  background-size: contain;
  margin: 2px 0 !important;
  @media screen and (max-width: 1024px) {
    height: 32px;
    width: 27px;
  }
}
.home-icon1 {
  // background-image: url("../../src/assets/img/pec/all-images/icon-home-blue.png");
  background-image: url("../../src/assets/img/pec/all-images/icon-home-hover.png");
  background-repeat: no-repeat;
  height: 26px;
  width: 22.7px;
  background-size: contain;
  margin: 2px 0 !important;
  @media screen and (max-width: 641px) {
    background-image: url("../../src/assets/img/pec/all-images/icon-home.png");
  }
  @media screen and (max-width: 1024px) {
    height: 32px;
    width: 27px;
  }
}

.be-icons-nav > li.dropdown {
  > button {
    .icon {
      font-size: 1.846rem;
      vertical-align: middle;
      // color: #6A7070;
      // color: #737373;
      color: #fff;
      transition: 0.2s color;
      &:hover {
        // color:#63666A;
        // color: #6a7070;
        // color: #333;
        // transform: scale(1.1, 1.1);
      }
      &.mdi-comment-more {
        font-size: 1.538rem;
        position: relative;
        top: 1px;
      }
    }
    .icon1 {
      color: #377d9f;
      @media screen and (max-width: 641px) {
        color: #fff;
      }
    }
    .indicator {
      background-color: #db001f;
      border: 2px solid #fff;
      border-radius: 50%;
      display: block;
      height: 16px;
      width: 16px;
      position: absolute;
      bottom: -7px;
      right: -4px;
      font-size: 0.7rem;
      box-sizing: content-box;
      color: #fff;
      line-height: 17px;
      text-align: center;
    }
    &:after {
      display: none;
      font-size: 1.231rem;
      margin-left: 6px;
    }
  }
  .dropdown-toggle:after {
    content: "";
    margin: 0;
    border: 0;
  }
  &.show > a {
    &:after {
      border-bottom: 8px solid #ffffff;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      bottom: -13px;
      content: "";
      display: inline-block;
      left: 50%;
      margin-left: -8px;
      position: absolute;
      z-index: 1001;
    }
    &:before {
      border-bottom: 8px solid #ffffff;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      bottom: -13px;
      content: "";
      display: inline-block;
      left: 50%;
      margin-left: -8px;
      position: absolute;
      z-index: 1001;
      border-bottom-width: 9px;
      border-left-width: 9px;
      border-right-width: 9px;
      border-bottom-color: #e3e3e3;
      margin-left: -9px;
      bottom: -12px;
    }
  }
  .dropdown-menu {
    //margin-top: 10px;
    padding: 0;
    -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
    border: 1px solid #e0e0e0;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    .title {
      font-size: 1rem;
      color: #8a8a8a;
      text-align: center;
      padding: 10px 0 8px;
      border-bottom: 1px solid #ebebeb;
      .badge {
        font-size: 0.6962rem;
        line-height: 10px;
        vertical-align: middle;
        margin-left: 4px;
        background-color: #e4e4e4;
        color: #8a8a8a;
        min-width: 17px;
        height: 17px;
        text-align: center;
        padding: 3px 6px;
      }
      .badge-new {
        background-color: #007fa3;
        border-color: #007fa3;
        color: #ffffff;
      }
    }
    .footer button {
      background-color: #fafafa;
      font-size: 1rem;
      color: #8a8a8a;
      text-align: center;
      padding: 10px 0;
      border-top: 1px solid #ebebeb;
      display: block;
      outline: none;
      &:hover {
        background-color: whitesmoke;
      }
    }
  }
}

.dropdown-toggle-cursor {
  cursor: pointer;
}

.dropdown-toggle-cursor-default {
  cursor: default !important;
}

@media (max-width: 979.98px) {
  .be-icons-nav > li.dropdown > button:after {
    display: inline-block;
  }
}

.be-top-header .be-icons-nav > li.dropdown .dropdown-menu {
  &:after,
  &:before {
    display: none;
  }
}

.be-icons-nav > li.dropdown.show > button {
  background-color: transparent;
  .icon {
    // color: #007fa3;
  }
}

@media (min-width: 1349px) {
  .be-mega-menu .be-icons-nav > li.dropdown {
    position: relative;
  }
}

@media (max-width: 979.98px) {
  .be-icons-nav {
    float: right;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .be-top-header .be-icons-nav {
    margin: 0 5px 0 0;
  }
  .be-icons-nav > li.dropdown {
    display: inline-block;
    margin-left: 3px;
  }
  .navbar-expand .be-icons-nav > li.dropdown > button {
    padding: 0 0 0 14px;
  }
  .be-icons-nav > li.dropdown > button {
    .icon {
      /*color: #003057;*/
    }
    .indicator {
      right: -5px;
      background-color: #db001f;
    }
  }
}

@media (min-width: 980px) {
  .be-icons-nav > li.dropdown #handbook.dropdown-menu {
    width: 300px;
  }
  .be-icons-nav > li.dropdown .dropdown-menu {
    &.profile {
    }
  }
}

@media (max-width: 979.98px) and (min-width: 600px) {
  .be-icons-nav > li.dropdown .dropdown-menu {
    left: -100px !important;
    width: 150% !important;
  }
}

@media (max-width: 979.98px) {
  .be-icons-nav > li.dropdown .dropdown-menu {
    width: 100%;
    border-width: 0;
    margin-top: 5px;
    padding: 10px 0 15px 0;
    -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
    border: 1px solid #e3e3e3;
    &.profile {
      //margin-top: 8px;
    }
  }
  .be-top-header .be-icons-nav > li.dropdown .dropdown-menu {
    border-radius: 0;
    margin-top: 1px;
  }
  .be-icons-nav > li.dropdown {
    .dropdown-menu .footer a {
      border-bottom: 1px solid #ebebeb;
    }
    &.show > {
      a {
        &:before {
          display: none;
        }
        &:after {
          bottom: -1px;
        }
      }
      button .icon {
        /*color: #FFFFFF;*/
      }
    }
  }
}
.main__section-sdl-top{
  display: none;
  @media only screen and (max-width: 640px){
      // line-height: 60px;
      // height: 60px;
      // width: 94px;
      // background-image: url("../../src/assets/img/pec/all-images/pearson-logo-white.png");
      width: 243px;
      background-image: url("../../src/assets/img/pec/all-images/pearson-logo-white@2x.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: left center;
      // padding: 0 20px;
      // margin: 0;
      display: block;
      cursor: pointer;
  }
}

@media print {
  .be-icons-nav {
    display: none;
  }
}

.be-user-nav {
  margin-right: 0;
  > li.dropdown > a {
    font-size: 0;
  }
}

@media (min-width: 1195px) {
  .be-right-navbar-flex .be-user-nav {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}

.navbar-expand .be-user-nav > li.dropdown > a {
  padding-left: 5px;
  padding-right: 5px;
}

@media (max-width: 1199.98px) {
  .navbar-expand .be-user-nav > li.dropdown > a {
    padding-left: 10px;
  }
}

.be-user-nav > li.dropdown {
  > a {
    img {
      max-width: 32px;
      max-height: 32px;
      border-radius: 50%;
    }
    .user-name {
      margin-left: 8px;
      display: none;
      font-size: 1.077rem;
      line-height: 20px;
      vertical-align: middle;
    }
    &:after {
      display: none;
      font-size: 1.231rem;
      margin-left: 6px;
    }
  }
  .dropdown-menu {
    width: 220px;
    padding: 0 0 7px;
    left: -178px;
  }
}

@media (max-width: 979.98px) {
  .be-user-nav > li.dropdown > a:after {
    display: inline-block;
  }
}

@media (max-width: 1199.98px) {
  .be-user-nav > li.dropdown .dropdown-menu {
    left: -173px;
  }
}

.be-top-header .be-user-nav > li.dropdown .dropdown-menu a {
  &:focus,
  &:active {
    background-color: transparent;
    color: #504e4e;
  }
  &:hover {
    background-color: whitesmoke;
    color: #504e4e;
  }
}

.be-user-nav > li.dropdown .dropdown-menu {
  a .icon {
    vertical-align: middle;
    margin-right: 10px;
  }
  .user-info {
    background-color: #007fa3;
    color: #ffffff;
    overflow: hidden;
    padding: 20px 15px 14px;
    border-radius: 2px 2px 0 0;
    margin-bottom: 7px;
    .user-name {
      display: block;
      font-size: 1.462rem;
      line-height: 22px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .user-position {
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding-left: 13px;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        height: 7px;
        width: 7px;
        left: 0;
        top: 5px;
        border: 1px solid #f0f0f0;
        border-radius: 50%;
        background-color: transparent;
        display: block;
      }
      &.online:after {
        background-color: #00e760;
        border-color: #00e760;
      }
      &.away:after {
        background-color: #ffd069;
        border-color: #ffd069;
      }
      &.busy:after {
        background-color: #ff3855;
        border-color: #ff3855;
      }
    }
    .switch-status {
      float: right;
      margin-top: 5px;
    }
  }
}

.be-top-header .be-user-nav > li.dropdown .dropdown-menu {
  &:after {
    left: auto;
    right: 13px;
    border-bottom-color: #007fa3;
  }
  &:before {
    display: none;
  }
}

@media (max-width: 979.98px) {
  .be-user-nav {
    margin: 0;
    display: inline-block;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    overflow: hidden;
    > li.dropdown {
      > a {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: 60px;
        white-space: nowrap;
        &:after {
          color: #ffffff;
        }
        img {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
          flex: 0 0 auto;
        }
        .user-name {
          display: block;
          -webkit-box-flex: 1;
          -ms-flex: 1 1 100%;
          flex: 1 1 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .dropdown-menu {
        -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
        background-color: #ffffff;
        position: fixed;
        top: 60px;
        left: 0;
        margin-left: 10px;
        margin-right: 10px;
        max-width: 220px;
      }
    }
  }
  .be-top-header .be-user-nav > li.dropdown .dropdown-menu:after {
    right: auto;
    left: 20px;
  }
}

@media (max-width: 979.98px) {
  .be-offcanvas-menu .be-user-nav > li.dropdown > a {
    padding-left: 0;
    img {
      display: none;
    }
    .user-name {
      margin-left: 0;
    }
  }
}

@media print {
  .be-user-nav {
    display: none;
  }
}

.be-notifications {
  padding-top: 0;
}

.float-right .be-notifications {
  left: auto;
  //right: 25px;
}

@media (min-width: 1349px) {
  .be-mega-menu .float-right .be-notifications {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

.be-notifications {
  .be-scroller-notifications {
    position: relative;
    height: 222px;
    > .content > ul {
      list-style: none;
      padding-left: 0;
    }
  }
  .notification {
    overflow: hidden;
    border-bottom: 1px solid #ebebeb;
    > button {
      padding: 15px;
      display: block;
      overflow: hidden;
      .image {
        height: 38px;
        width: 38px;
        border-radius: 50%;
        background-color: #e4e4e4;
        float: left;
        text-align: center;
        overflow: hidden;
        img {
          height: 38px;
          width: 38px;
          font-size: 1.923rem;
          color: #979595;
          line-height: 36px;
        }
      }
      .notification-info {
        padding-left: 50px;
        line-height: 14px;
        .text {
          font-weight: 500;
          color: #8a8a8a;
          font-size: 0.9231rem;
          line-height: 16px;
        }
        .user-name {
          color: #007fa3;
          font-size: 1rem;
        }
        .date {
          color: #8a8a8a;
          display: block;
          font-size: 0.6923rem;
          margin-top: 4px;
          text-transform: uppercase;
        }
      }
    }
    &.notification-unread {
      background-color: #57daff;
      border-bottom: 1px solid #47d6ff;
      > button {
        position: relative;
        &:after {
          content: "";
          display: block;
          position: absolute;
          right: 15px;
          top: 20px;
          width: 6px;
          height: 6px;
          background-color: #007fa3;
          border-radius: 50%;
        }
      }
    }
    &:hover {
      background-color: #007fa3;
      > button {
        color: #ffffff;
        .logo {
          background-color: #00637f;
          .icon {
            color: #ffffff;
          }
        }
        .notification-info {
          .circle {
            background: #ffffff;
          }
          .text,
          .date,
          .user-name {
            color: #ffffff;
          }
        }
        &:after {
          background-color: #ffffff;
        }
      }
    }
    &:last-child {
      border-bottom: 0;
    }
  }
}

@media (max-width: 979.98px) {
  .be-notifications .notification.notification-unread > button {
    width: 100%;
  }
}

@media (max-width: 599.98px) {
  .navbar-nav .show .be-notifications {
    position: absolute;
    background-color: #ffffff;
  }
}

@media (max-width: 599.98px) {
  .navbar-nav .show .be-notifications {
    position: fixed;
  }
}

.be-connections {
  min-width: 304px;
}

.float-right .be-connections {
  left: auto;
  right: 25px;
}

@media (min-width: 1349px) {
  .be-mega-menu .float-right .be-connections {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

.be-connections {
  .content {
    padding: 20px 15px;
    .row {
      margin: 0;
      > div {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }
  .connection-item {
    display: block;
    border-radius: 3px;
    line-height: 34px;
    text-align: center;
    padding: 15px 0 9px;
    display: block;
    border: 1px solid transparent;
    color: #737373;
    span {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    img {
      max-height: 35px;
      max-width: 35px;
    }
    &:hover {
      background-color: transparent;
      border-color: #ebebeb;
    }
  }
}

@media (max-width: 979.98px) {
  .float-right .be-connections {
    right: 5px;
  }
  .navbar-nav .show .be-connections {
    position: absolute;
    background-color: #ffffff;
  }
}

@media (max-width: 599.98px) {
  .be-connections {
    min-width: 260px;
  }
  .navbar-nav .show .be-connections {
    position: fixed;
  }
  .be-connections .content {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.be-icons-nav > li.dropdown .dropdown-menu {
  right: -5px;
}
@media (max-width: 1650px) {
  .be-icons-nav > li.dropdown .dropdown-menu {
    // right: 15px;
  }
}
