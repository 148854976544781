.Modal {
    /* fixed position will always show modal in the top left corner */
    position: fixed;
    top: 0;
    left: 0;
    /* z-index to show modal even if it is used before some other elements */
    z-index: 1;
    /* make modal fill whole window */
    width: 100vw;
    height: 100vh;
    /* add some background to blur content behind modal */
    background: rgba(255, 255, 255, 0.8);
    /* center content inside modal */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .Component-handbook{
    z-index:9999;
    min-width: 300px;
    min-height: 100px;
    background-color: #FFFFFF;
    max-width: 300px;
    height: 340px;
    overflow-y: scroll;
    margin-top: 45px;
    margin-left: 23px;
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    position: absolute;
    cursor: default;
  }
//     .top-icon{
//     display: flex;
//     margin-left: auto;
//     margin-right: auto;
//     background: #000000;
//     transform: rotate(-45deg);
//   }
  .main-container{
    display:flex;
    flex-direction: row;
    justify-content: center;
    margin-top:8px;
  }
  .main-class-container{
   display:flex;
    flex-direction: row;
    justify-content: space-between; 
    border-bottom:1px solid #CCCCCC;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .class-row{
    font-size: 16px;
  line-height: 19px;
  color: #000000;
  padding-left: 10px;
  cursor: pointer;
  
  }
  .like-row{
    padding-right: 10px;
    cursor: pointer;
  }
  
  .please-select-row{ 
    border-bottom:1px solid #CCCCCC;
    border-top:1px solid #CCCCCC;
    margin-top:5px;
    padding: 10px 0px 10px 10px;
    
    
    
  }
  .static-tab-dropdown{
    cursor: pointer;
    width: 120px;
    align-self: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    padding-top: 7px;
    padding-bottom: 7px;
    background: #FFFFFF;
    color: #007FA3;
    border: 1px solid #007FA3;
    // border-radius: 0px 2px 2px 0px;
  
  }
  .active-tab-dropdown{
    background: #007FA3;
    color: #FFFFFF;
  }

  .fav-button{
   cursor: pointer;
    width: 110px;
    align-self: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    background: #007FA3;
    color: #FFFFFF;
    border: 1px solid #007FA3;
    border-radius: 0px 2px 2px 0px;
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
  
  .App {
    width: 100vw;
    height: 100vh;
    text-align: center;
    background: lightblue;
  }
  
  body {
    margin: 0;
  }