.pangination__box {
  display: flex;
  .pangination__span {
    display: flex;
    vertical-align: middle;
    line-height: 3.3;
    padding: 0 6px;
    font-size: 14px;
    text-transform: capitalize !important;
    .page__dropdown {
      padding-left: 5px;
      padding-right: 5px;
      select {
        border: 1px solid #c7c7c7;
        height: 30px;
        line-height: 30px;
        background: #fff;
        border-radius: 2px;
        font-size: 14px;
      }
    }
  }
  @media (max-width: 980px) {
    .pangination__span {
      font-size: 18px;
      line-height: 2.5;
      .page__dropdown {
        select {
          font-size: 18px;
        }
      }
    }
  }
  .pangination__box__touch {
    width: 44px;
    height: 44px;
    text-align: center;
    vertical-align: middle;
    border: 0;
    background: #fff0;
    cursor: pointer;
    padding: 0px;
    .pangination__arrow__btn {
      border-radius: 50%;
      height: 28px;
      margin: auto;
      padding: 0;
      width: 28px;
      text-align: center;
      line-height: 30px;
      background: #fff;
      border: 1px solid #c7c7c7;
      i {
        display: inline-block;
        //color: #ccc;
        color: #6a7070;
        font-size: 1.5em;
      }
    }
  }
  button.pangination__box__touch:hover .pangination__arrow__btn {
    background: #e9e9e9;
    border: 1px solid #c7c7c7;
    i {
      color: #6a7070;
    }
  }
  button.pangination__box__touch:disabled .pangination__arrow__btn,
  button.pangination__box__touch[disabled] .pangination__arrow__btn {
    cursor: not-allowed;
    background: #ffffff;
    border: 1px solid #e9e9e9;
    i {
      color: #d9d9d9;
    }
  }
}
