.be-prizmcontent{
  margin: auto;
  width: 100% !important;
 // max-width: inherit !important;
   box-shadow: 0 0 0 !important;
   border: 0px none !important;
   padding-top: 0px !important; 
   transform: translate(0px, -0px) !important;
   & .body{
          display: flex;
          flex-direction: column;
   }
}
// .pop_up_view .be-content #a .card{
//   //max-width: inherit !important;
// }
.prizmIframe{
  margin: 0 auto 0 auto;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-bottom: 0px;
  width: 100%;
  flex: 1;
  position: relative;
  max-width: 1360px;
  & .loading{
    position: absolute;
  }
  & #iframe_id{ 
    height: 100%;
    width:100%;
   }
}
.iframe-top-sections{
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  //padding-top: 50px;
  height: calc(100vh - 50px);
}
.main__iframe-navigation {
  // position: absolute;
  // top: 0;
  // right: 0;
  // left: 0;
  // z-index: 10001;
  max-width: 1360px;
  margin: 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  margin-top: 11px;
}
.main__iframe-heading {
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: #0088cf;
  margin-left: 10px;
}

.main__iframe-result-btn {
  box-sizing: border-box;
  border-radius: 10px;
  height: 43px;
  min-width: 189px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  background: #198cc5 !important;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  padding: 0px 16px;
}
.main__iframe-result-btn-gap{
  gap: 8px;
  min-width: 0;
}
.main__iframe-preview-btn {
  box-sizing: border-box;
  border-radius: 10px;
  height: 43px;
  min-width: 189px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  background: #198cc5 !important;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  padding: 0px 16px;
}
.main__iframe-close-btn {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 0px;
  display: flex;
  align-items: center;
  gap: 5px;
  text-align: center;
  color: #198cc5 !important;
  background: #ffffff;
  height: 43px;
  width: 74px;
  padding: 0px 8px;
  border-radius: 10px;
  padding-right: 0px !important;
}

.main__iframe-righ-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 19px;
  margin-right: 10px;
}

//mobile phone compatibility

@media only screen and (max-width: 640px) {
  .main__iframe-navigation {
    flex-direction: column;
    //margin-bottom: 142px;
   // margin-top: -55px;
  }
  .main__iframe-heading {
    order: 2;
    align-self: self-start;
    // margin-top: 45px;
    // margin-top: 70px;
    margin-top: 48px;
  }
  .main__iframe-righ-section {
    order: 1;
    align-self: end;
    display: flex;
    flex-direction: column-reverse;
    max-width: 641px;
    width: 98%;
    gap: 20px;
  }
  .main__iframe-result-btn {
    align-self: start;
  }

  .main__iframe-close-btn {
    align-self: end;
  }
}
@media only screen and (max-width: 519px) and  (min-width: 500px){
  .main__iframe-heading {
    margin-top: 110px;
  }
}
@media only screen and (max-width: 640px){
  .main__iframe-navigation { /*it also fix the problem on when there is long title*/
      flex-direction: column;
      /* margin-bottom: 120px; */
      padding-top: 0px;
      height: auto;
     }
 }
 .pop_up_view .be-content #a .card#studygroup #assessment.card.learnosity{
  box-shadow: none;
  padding-bottom:150px !important;
} 
// .pop_up_view iframe{
//   position:absolute;
// }