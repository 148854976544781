#learnosity_assess.lrn {
  .app-layout {
    .dialogs {
      .lrn-start-page {
        .lrn-dialog-content {
          .lrn-dialog-header {
            padding-top: 35px;
          }
        }
      }
    }
    .lrn-group-middle {
      .lrn-region.items {
        .slides-container {
          .slides-control {
            .learnosity-item {
              .lrn-assess-content {
                .row {
                  button.hits-button {
                    height: 38px;
                    font-size: 14px;
                  }
                  .distractor-rationale.alert.hits-box {
                    width: 100%;
                    div.hits-item {
                      min-height: 42px;
                      border-radius: 2px;
                      border: solid 1px #ea7600;
                      background-color: #fffaf1;
                      padding: 11px 16px;
                      margin-bottom: 4px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
#lastscore-list-item.lrn.learnosity-report.lastscore-by-item-by-user {
  table {
    tbody {
      tr {
        td {
          &.lrn-report-user,
          &.lrn-report-score {
            white-space: nowrap;
            vertical-align: middle;
          }
          &.lrn-report-user {
            position: relative;
            padding-right: 25px;
            button {
              position: absolute;
              right: 10px;
              height: 100%;
              top: 0;
            }
          }
        }
      }
    }
  }
}
