// dashboard favourite Section css starts here
.select-favourite-books-heading {
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: #333333;
}
.first-section-heading {
  font-style: normal;
  font-weight: 800;
  font-size: 38px;
  line-height: 52px;
  color: #000000;
}
.first-section-details1,
.first-section-details2 {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
}
.first-section-details2 {
  font-weight: bold;
}
.favourited-books {
  margin: 25px auto;
  width: calc(100% - 30px);
}
.favourite-section {
  max-width: 1154px;
  display: grid;
  grid-template-columns: 1fr 630px;
  grid-gap: 10px;
  height: 325px;
  margin: 0 auto;
  margin-top: 61px;
}
.first-section {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  gap: 20px;
}
.second-section {
  border: 1px dashed #b6b6b6;
  border-radius: 5px;
}
.favourite-book-area {
  background: #cecece;
  border-radius: 5px;
  width: 201px;
  height: 282px;
}
.favourite-book-area-background-image {
  background-image: url("../../../assets/img/pec/all-images/icon-book-1.png");
  background-size: 22px 26px;
  background-repeat: no-repeat;
  background-position: right 10px bottom 10px;
}
.favourite-book-name {
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 30px;
  color: #cecece;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
}
.second-section {
  padding: 10px;
  display: flex;
  /* grid-template-columns: 201px 350px 1fr; */
  /* grid-gap: 15px; */
  height: 325px;
  padding-top: 21px;
  justify-items: center;
  align-items: center;
}
.favourite-icon-heart {
  background-image: url("../../../assets/img/pec/all-images/icon-heart.png") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  height: 30px;
  // width: 60px;
  width: 30px;
  font-size: 0px !important;
  border: none;
  cursor: pointer;
  background-color: transparent !important;
}
.favourite-icon-heart-transparent {
  background-color: transparent !important;
  cursor: default;
}

.favourite-book-heading {
  display: grid;
  grid-template-columns: 1fr 30px;
  grid-gap: 5px;
}

.selected-favourite {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 25px;
  height: auto;
  max-width: 1300px;
  margin: 0 auto;
}
.selected-book-favourite {
  background: #198cc5;
  border-radius: 5px;
}
.selected-book-area {
  border: 1px dashed #b6b6b6;
  border-radius: 5px;
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  color: #cecece;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 418px;

  max-width: 1024px;
  width: 98%;
  margin: 0 auto;
}
.selected-book-favourite {
  // display: grid;
  // grid-template-columns: 220px 1fr;
  // column-gap: 5px;
  // padding: 15px;

  display: grid;
  grid-template-columns: 200px 1fr;
  column-gap: 10px;
  padding: 10px;
  box-sizing: border-box;
}
.selected-book-favourite-first-section {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

// .selected-book-favourite-second-section {padding-right: 10px;}

.selected-fav-image {
  border-radius: 5px;
  width: 200px;
}
.selected-fav-btn {
  width: 200px;
  height: 36px;
  background: #ffffff;
  border-radius: 5px;
  color: #198cc5 !important;
  font-style: normal !important;
  font-weight: 800 !important;
  font-size: 14px !important;
  line-height: 150% !important;
}
.selected-fav-btn-disable {
  background: #cccccc;
  border-radius: 5px;
  color: #a6a6a6 !important;
}
.favourite-mdi-icons-disable {
  color: #a6a6a6 !important;
}
.selected-fav-sdl-btn,
.selected-fav-resource-btn {
  background: #ffffff;
  border-radius: 5px;
  width: 95.37px;
  height: 36px;
  color: #198cc5 !important;
  font-style: normal !important;
  font-weight: 800 !important;
  font-size: 14px !important;
  line-height: 150% !important;
}
.fav-button-section {
  display: flex;
  gap: 10px;
  align-items: end;
}
.favourite-mdi-icons {
  height: 16px;
  width: 16px;
  position: relative;
  left: 30px;
  color: #198cc5 !important;
  font-size: 14px !important;
}
.fav-book-name {
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 30px;
  color: #ffffff;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 57px;
  flex-grow: 1;
}
.favourite-book-notification {
  margin-top: 10px;
  background: #136790;
  border-radius: 5px 5px 0px 0px;
  // padding: 5px;
  width: auto;
}
.notification-area {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 120px;
  height: 44px;
  gap: 4px;
  padding: 0px 8px;
}
.notification-area-teacher{
  position: relative;
}
.notification-year-drop-down-teacher{
  position: relative;
    display: block;
}
.Component1-teacher{
  right: 0;
  transform: translate(0, 5px) !important;
}
.notification-label-name {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  // line-height: 19px;
  // display: flex;
  align-items: center;
  color: #ffffff;
  // margin: 7px 10px;
  margin: 0px;
}

.notification-year-drop-down {
  appearance: none;
  background-image: url("../../../assets/img/pec/all-images/Filter-Books/pull-down-arrow-down.png") !important;
  background-repeat: no-repeat !important;
  background-position: right !important;
  width: 100%;
  height: 31px;
  background: #ffffff;
  border-radius: 5px;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  margin: 0;
  color: #136790;
  cursor: pointer;
  padding: 5px;
  background-position-x: 95% !important;
}
.favourite-book-notification-details {
  // height: 266px;
  height: 264px;
  background: #ffffff;
  border-radius: 0px 0px 5px 5px;
}

// dashboard favourite Section css starts here

// select class dropdown css starts here
/*select pop up css*/
.Modal {
  /* fixed position will always show modal in the top left corner */
  position: fixed;
  top: 0;
  left: 0;
  /* z-index to show modal even if it is used before some other elements */
  z-index: 1;
  /* make modal fill whole window */
  width: 100vw;
  height: 100vh;
  /* add some background to blur content behind modal */
  background: rgba(255, 255, 255, 0.8);
  /* center content inside modal */
  display: flex;
  justify-content: center;
  align-items: center;
}

.Component1 {
  z-index: 9999;
  min-width: 157px;
  min-height: 238px;
  width: 157px;
  height: 238px;
  position: absolute;
  transform: translate(226px, 136px);
  cursor: default;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  overflow: auto;
}

.main-container1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 8px;
}
.main-class-container1 {
  display: flex;
}
.class-rows {
  padding-left: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
  color: #198cc5;
}

.class-rows1 {
  width: 90%;
  cursor: pointer;
}
.like-rows {
  padding-right: 40px;
  cursor: pointer;
  line-height: 32px;
}

.like-rows1 {
  width: 10%;
}

.please-select-row {
  border-bottom: 1px solid #cccccc;
  border-top: 1px solid #cccccc;
  padding: 10px 0px 10px 10px;
  margin-top: 5px;
  color: #000000;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
}
.static-tab-dropdown-one1 {
  cursor: pointer;
  width: 69px;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 32px;
  background: #d9d9d9;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 32px;
  color: #198cc5;
}
.active-tab-dropdown1 {
  color: #ffffff;
  background: #198cc5;
  border-radius: 5px !important;
  z-index: 1;
}
.static-tab-dropdown-favourite {
  width: 77px;
  position: relative;
  left: -6px;
  border-radius: 0px 5px 5px 0px;
}

.fav-button {
  cursor: pointer;
  width: 110px;
  align-self: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #007fa3;
  color: #ffffff;
  border: 1px solid #007fa3;
  border-radius: 0px 2px 2px 0px;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

// select class dropdown css ends here
// down arrow css starts here
.down-arrow {
  position: relative;
  left: 98px;
  top: 23px;
}
.bounce {
  animation-name: bounce;
  animation-timing-function: ease;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
@keyframes bounce {
  0% {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(10px);
  }
}
// down arrow css ends here
.empty-favourite-mesaage {
  font-size: 14px;
  margin-top: 10px;
  margin-left: 5px;
}
.favourite-notification-title {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #333333;
  margin-bottom: 1px;
  cursor: pointer;
}

.favourite-notification-details {
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 150%;
  color: #333333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
  cursor: pointer;
}
@media only screen and (min-width: 1300px) {
  .favourite-notification-details {
    max-width: 300px;
  }
}

.favourite-notification-main {
  display: flex;
  flex-direction: column;
  padding: 5px;
  cursor: pointer !important;
}
.no-notification-message {
  color: #cecece;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  justify-content: center;
  margin-top: 31px;
}
.favourite-notification-lines {
  border: 1px solid #8cc5e2 !important;
}
.favourite-book-notification-details-scroll-bar {
  overflow-y: scroll;
  // margin-bottom: 10px;
}

// tablet compatibility for sprint2 starts here
@media only screen and (max-width: 1024px) and (min-width: 641px) {
  .first-section-heading {
    font-size: 25px;
    line-height: 28px;
  }
  .first-section-details1,
  .first-section-details2 {
    font-size: 14px;
    line-height: 19px;
  }
  .first-section-details2 {
    font-weight: bold;
  }
  .favourite-section {
    width: 100%;
    grid-template-columns: 0.47fr 0.53fr;
  }
  .second-section {
    padding: 10px;
    display: flex;
    /* grid-template-columns: 201px 350px 1fr; */
    /* grid-gap: 15px; */
    height: 325px;
    padding-top: 21px;
    justify-items: center;
    align-items: center;
  }
  .favourite-section {
    margin-top: 28px !important;
  }
  .first-section {
    width: 100%;
    gap: 9px;
    height: 259px;
  }
  .favourite-book-area {
    width: 121.21px;
    height: 168px;
  }
  .favourited-books {
    width: calc(100% - 25px);
  }
  .down-arrow-images {
    width: 67px;
    height: 48px;
  }
  .favourite-book-name {
    font-size: 14px;
    line-height: 19px;
  }
  .selected-book-favourite {
    width: auto;
    grid-template-columns: 162px 1fr;
  }
  .selected-fav-image {
    width: 162px;
    height: 228px;
  }
  .selected-fav-btn {
    width: 162px;
    height: 38px;
  }
  .selected-fav-sdl-btn {
    width: 65px;
    height: 38px;
  }
  .selected-fav-resource-btn {
    width: 90px;
    height: 38px;
  }
  .favourite-book-notification-details {
    height: 195px;
  }
  .favourite-mdi-icons {
    left: 10px;
  }
  .selected-fav-btn,
  .selected-fav-sdl-btn,
  .selected-fav-resource-btn {
    color: #136790 !important;
  }
  .favourite-book-notification {
    height: 53px;
  }
  .notification-area {
    height: 53px;
    margin-left: 5px;
    margin-right: 5px;
  }
  .favourite-notification-title {
    font-size: 14px;
  }
  .favourite-notification-details {
    font-size: 12px;
    max-width: 350px;
  }
  .selected-book-favourite-first-section {
    gap: 9px;
  }
  .fav-button-section {
    gap: 7px;
  }
  .selected-book-area {
    display: none;
  }
  .selected-favourite {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    margin-top: 20px;
  }
}

// mobile compatibility for sprint2 starts here

@media only screen and (max-width: 640px) {
  // and (min-width: 375px) {
  .first-section-heading {
    font-size: 34px;
    line-height: 41px;
    color: #333333;
  }
  .first-section-details1 {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }
  .favourite-section {
    width: auto;
    grid-template-columns: 1fr;
    height: auto;
  }
  .favourite-section {
    width: auto;
    grid-template-columns: calc(100% - 16px);
    height: auto;
    margin: 0 auto;
  }
  .second-section {
    padding: 10px;
    height: auto;
    max-width: 357px;
    grid-template-columns: 97px auto 30px;
    margin: 0 auto;
  }
  .favourite-book-name {
    font-size: 21px;
    line-height: 29px;
  }

  .favourite-book-area {
    width: 97px;
    height: 140px;
  }
  .selected-favourite {
    grid-template-columns: 1fr;
    margin: 0 auto;
    width: 100%;
  }
  .selected-book-area {
    display: none;
  }
  .down-arrow {
    display: none;
  }
  .selected-book-favourite {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 12px;
    height: auto;
    position: relative;
  }
  .favourite-book-notification,
  .favourite-book-notification-details {
    margin: 0 auto;
  }
  .selected-fav-image {
    width: 100%;
  }
  .selected-book-favourite-first-section {
    display: grid;
    grid-template-columns: 120px auto;
    gap: 10px;
    width: 100%;
    grid-template-rows: 1fr 45px;
  }
  .selected-fav-image {
    grid-row: 1/3;
  }
  .selected-fav-btn {
    width: 100%;
    height: 45px;
    align-self: end;
  }
  .selected-fav-sdl-btn,
  .selected-fav-resource-btn {
    height: 45px;
    width: 100%;
  }
  .fav-book-name {
    font-size: 16px;
    line-height: 22px;
    height: 44px;
  }
  .notification-area {
    height: 60px;
    grid-template-columns: 1fr 100px;
  }
  .favourite-book-notification-details {
    height: 300px;
  }
  .notification-label-name {
    font-size: 16px;
    line-height: 22px;
  }

  .favourited-books {
    margin: 0 auto;
    width: calc(100% - 16px);
  }
  .favourite-book-heading {
    top: 11px;
    width: calc(100% - 149px);
    right: 10px;
    position: absolute;
  }

  .favourite-mdi-icons {
    left: 5px;
  }
  .favourite-notification-title {
    font-size: 14px;
  }
  .favourite-notification-details {
    font-size: 12px;
    max-width: 230px;
  }
  .right-section-menu {
    gap: 4px;
  }
  // .favourite-icon-heart {position: relative;right: 18px;}
}

// my favourtie section mobile compatibility for selected favourite book series starts here
// @media only screen and (max-width: 1340px) and (min-width: 1025px) {
//   .selected-favourite {
//     grid-template-columns: repeat(auto-fit, 639px);
//     width: 639px;
//   }
// }
// @media only screen and (max-width: 1340px) and (min-width: 1025px) {
//   .selected-favourite {
//     grid-template-columns: 1fr;
//     grid-auto-flow: row;
//     max-width: 1024px;
//     width: 98%;
//     justify-content: space-around;
//     margin-top: 20px;
//   }
//   .selected-book-favourite {
//     width: auto;
//     margin: 0 17px;
//     grid-template-columns: 0.26fr auto 0.05fr;
//   }
//   .favourite-book-notification {
//     max-width: 1024px;
//     width: 98%;
//   }
//   .favourite-book-notification-details {
//     max-width: 1024px;
//     width: 98%;
//   }
// }
// my favourtie section mobile compatibility for selected favourite book series ends here
