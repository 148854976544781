@import "../../Stylesheets/base/colors.scss";

.login-page {
  flex:1;
  background-color:#003058;
  //height:100vh; // Responsive fix Venni
}

.login-container {
  max-width: 401px;
  // min-height: 710px;
  margin: 0 auto;
  width: 100%;
  margin-top: 10px;
  background-color:#003058;
  // flex-grow: 1;
  // height: calc(100% - (35px));
  // @media (max-width: 729px) {
  //   height: calc(100% - (55px));
  // }
  .logincard {
    margin-bottom: 30px;
    .card-header {
      text-align: center;
      margin-bottom: 20px;
      padding-top: 40px;
      padding-bottom: 0;
    }
    .card-body {
      padding: 10px 30px 5px;
    }
  }
  .pull-right {
    float: right;
  }
  .pull-left {
    float: left;
  }
  .link-button {
    color: #007fa3;
  }
}

.logincard .splash-description h3 {
  margin-top: 0px;
  font-size: 21px;
}

.re-label {
  cursor: pointer;
}

.re-checkbox {
  margin-right: 5px;
}

.top-bar {
  width: 98%;
  max-width: 401px;
  //min-width: 301px;
  margin: 0 auto;
  padding: 20px 0px 30px 0px;
  padding-top:env(safe-area-inset-top);
  padding-top:constant(safe-area-inset-top);
}

.logincard {
  background-color: $white;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

  border-width: 0;
  border-radius: 3px;
  display: block;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  //min-width: 401px;
  word-wrap: break-word;
  background-color: $white;
  background-clip: border-box;
  //border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 3px;
  margin-bottom: 30px;
}

.createUser-bar {
  background-color: $iceblue;
}

.backToHome {
  text-decoration: underline;
  cursor: pointer;
  color:white;
}

.new-platform {
  margin-bottom: 1rem;
  text-align: center;
  margin-top: 1rem;
  span {
    font-size: 12px;
    padding: 6px 14px;
    background: $white;
    position: relative;
    top: -13px;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.85;
    letter-spacing: normal;
    text-align: center;
    color: #505759;
  }
  .border-line {
    border-bottom: 1px solid #d5d8de;
    margin-top: 2.5rem;
  }
}

.forgetpassword {
  padding: 10px 0px 20px 0px;
  a {
    text-decoration: underline;
  }
}
.recaptchapolicy {
  font-size: 12px;
  border-top: 1px solid #d5d8de;
  padding: 8px 0px 10px 0px;
}

.error-text {
  margin-top: 10px;
}
.grecaptcha-badge {
  visibility: hidden;
}

.login-nav-link {
  color:white !important;
}