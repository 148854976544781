section.assignment-handbook {
  .assignment_loading {
    @media (max-width: 500px) {
      padding-top: 50px;
    }
  }
  .table {
  }
  .pagination-row {
    border-radius: 5px;
    border: solid 1px #dbdbdb;
    margin: 0 20px;
    .pangination__box {
      justify-content: center;
    }
  }
}
