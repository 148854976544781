.pear-eng-con-text {
  // width: 185px;
  // height: 22px;
  // left: 146px;
  // top: 12px;
  // align-self: center;
  // border-left: 1px solid #979797;
  // padding-left: 20px;

  // font-family: "Avenir";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #ffffff;
}
.navbar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 7px 15px;
  > {
    .container,
    .container-fluid {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
  }
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.392857rem;
  padding-bottom: 0.392857rem;
  margin-right: 0px;
  font-size: 1.15rem;
  line-height: inherit;
  white-space: nowrap;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  .nav-link {
    padding-right: 0;
    padding-left: 0;
  }
  .dropdown-menu {
    position: static;
    float: none;
  }
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.15rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 2px;
  &:hover,
  &:focus {
    text-decoration: none;
  }
  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 599.98px) {
  .navbar-expand-sm > {
    .container,
    .container-fluid {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

@media (min-width: 600px) {
  .navbar-expand-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      .dropdown-menu {
        position: absolute;
      }
      .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
      }
    }
    > {
      .container,
      .container-fluid {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
      }
    }
    .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }
    .navbar-toggler {
      display: none;
    }
  }
}

@media (max-width: 979.98px) {
  .navbar-expand-md > {
    .container,
    .container-fluid {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

@media (min-width: 980px) {
  .navbar-expand-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      .dropdown-menu {
        position: absolute;
      }
      .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
      }
    }
    > {
      .container,
      .container-fluid {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
      }
    }
    .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }
    .navbar-toggler {
      display: none;
    }
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-lg > {
    .container,
    .container-fluid {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

@media (min-width: 1200px) {
  .navbar-expand-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      .dropdown-menu {
        position: absolute;
      }
      .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
      }
    }
    > {
      .container,
      .container-fluid {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
      }
    }
    .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }
    .navbar-toggler {
      display: none;
    }
  }
}

@media (max-width: 1299.98px) {
  .navbar-expand-xl > {
    .container,
    .container-fluid {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

@media (min-width: 1300px) {
  .navbar-expand-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      .dropdown-menu {
        position: absolute;
      }
      .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
      }
    }
    > {
      .container,
      .container-fluid {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
      }
    }
    .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }
    .navbar-toggler {
      display: none;
    }
  }
}

@media (max-width: 1348.98px) {
  .navbar-expand-xxl > {
    .container,
    .container-fluid {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

@media (min-width: 1349px) {
  .navbar-expand-xxl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      .dropdown-menu {
        position: absolute;
      }
      .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
      }
    }
    > {
      .container,
      .container-fluid {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
      }
    }
    .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
    }
    .navbar-toggler {
      display: none;
    }
  }
}

.navbar-expand {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  > {
    .container,
    .container-fluid {
      padding-right: 0;
      padding-left: 0;
    }
  }
  .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    .dropdown-menu {
      position: absolute;
    }
    .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
  }
  > {
    .container,
    .container-fluid {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
    }
  }
  .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-toggler {
    display: none;
  }
}

.navbar-light {
  .navbar-brand {
    color: rgba(0, 0, 0, 0.9);
    &:hover,
    &:focus {
      color: rgba(0, 0, 0, 0.9);
    }
  }
  .navbar-nav {
    .nav-link {
      color: rgba(0, 0, 0, 0.5);
      &:hover,
      &:focus {
        color: rgba(0, 0, 0, 0.7);
      }
      &.disabled {
        color: rgba(0, 0, 0, 0.3);
      }
    }
    .show > .nav-link,
    .active > .nav-link {
      color: rgba(0, 0, 0, 0.9);
    }
    .nav-link {
      &.show,
      &.active {
        color: rgba(0, 0, 0, 0.9);
      }
    }
  }
  .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }
  .navbar-text {
    color: rgba(0, 0, 0, 0.5);
    a {
      color: rgba(0, 0, 0, 0.9);
      &:hover,
      &:focus {
        color: rgba(0, 0, 0, 0.9);
      }
    }
  }
}

.navbar-dark {
  .navbar-brand {
    color: #ffffff;
    &:hover,
    &:focus {
      color: #ffffff;
    }
  }
  .navbar-nav {
    .nav-link {
      color: rgba(255, 255, 255, 0.5);
      &:hover,
      &:focus {
        color: rgba(255, 255, 255, 0.75);
      }
      &.disabled {
        color: rgba(255, 255, 255, 0.25);
      }
    }
    .show > .nav-link,
    .active > .nav-link {
      color: #ffffff;
    }
    .nav-link {
      &.show,
      &.active {
        color: #ffffff;
      }
    }
  }
  .navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.1);
  }
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }
  .navbar-text {
    color: rgba(255, 255, 255, 0.5);
    a {
      color: #ffffff;
      &:hover,
      &:focus {
        color: #ffffff;
      }
    }
  }
}

.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  gap: 10px;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #878787;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #dddddd #dddddd #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #878787;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #636c72;
  background-color: #eeeeee;
  border-color: #dee2e6 #dee2e6 #eeeeee;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 2px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #007fa3;
}

.nav-fill .nav-item {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}

.class-nav-bar {
  // background-color: #007fa3;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: row;
  -ms-flex: row;
  flex: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 50px;
  min-width: 320px;
}

#dashboard.class-nav-bar {
  // background: #fff;
  // background: #211761;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: row;
  -ms-flex: row;
  flex: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 50px;
}

#main.class-nav-bar {
  background: #fff;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: row;
  -ms-flex: row;
  flex: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: calc(50+ env(safe-area-inset-top)) !important;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 500px) {
  #dashboard.class-nav-bar {
    /*background-color: #007FA3;*/
    padding: 7px 8px;
  }
}

.class-nb-exit {
  text-align: left;
  min-width: 40px;
}

.class-nb-title {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: normal;
  text-shadow: none;
  font-size: 1.2rem;
  color: #fff;
  cursor: default;
  //padding-left: 75.63px;
  z-index: -1;
  position: absolute;
  line-height: 24px;
  overflow: hidden;
  width: 100%;
  top: 0;
  left: 0;
  text-align: center;
  bottom: 0;
  .study-group-view-title {
    @media (max-width: 599px) {
      display: none;
    }
  }
  > div {
    max-height: 48px;
    // margin-left: 120px;
    white-space: nowrap;
    // margin-right: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.none {
    @media (max-width: 500px) {
      display: none;
    }
  }
}
.class-nb-title-prizm {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: normal;
  text-shadow: none;
  font-size: 1.2rem;
  color: #fff;
  cursor: default;
  //padding-left: 75.63px;
  position: relative;
  line-height: 24px;
  overflow: hidden;
  width: 100%;
  top: 0;
  left: 0;
  text-align: center;
  bottom: 0;
  .study-group-view-title {
    @media (max-width: 599px) {
      display: none;
    }
  }
  > div {
    max-height: 48px;
    // margin-left: 120px;
    white-space: nowrap;
    // margin-right: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.none {
    @media (max-width: 500px) {
      display: none;
    }
  }
}

.class-nb-title,
.class-nb-title div {
  line-height: 24px;
  max-height: 48px;
  overflow: hidden;
}

.class-nb-title div {
  max-height: 48px;
}

.class-nb-buttons {
  margin-left: auto;
  text-align: right;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-width: 135px;
  &.b2c {
    min-width: fit-content;
  }
  > .back-home {
    display: flex;
    > img {
      margin: auto;
      cursor: pointer;
    }
  }
  &:focus {
    outline: none;
    outline-width: 0px;
  }
  &.lang {
    #be-navbar-collapse {
      ul li {
        .drop-menu-item {
          padding: 0 10px;
        }
      }
    }
  }
}

.class-nb-buttons > ul > li > a {
  font-size: 1.846rem;
  vertical-align: middle;
  color: white;
}

.class-nb-exit a {
  color: #fff;
  text-decoration: none;
  display: block;
  height: 50px;
  overflow: hidden;
  padding: 0p 10px;
  top: 0px;
  position: absolute;
  left: 0px;
  min-width: 60px;
  line-height: 50px;
  text-align: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: none;
  border: none;
}

.class-nb-exit a div {
  display: inline-block;
  text-wrap: nowrap;
}

.dashboradClass {
  background-image: linear-gradient(116deg, #007fa3, rgba(0, 128, 163, 0));
}

.dashboradClass:hover {
  background-image: linear-gradient(116deg, #003057, rgba(0, 48, 87, 0));
  -webkit-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}

#main.class-nav-bar,
#dashboard.class-nav-bar {
  border-bottom: 1px solid transparent;
  &.assessment {
    border-bottom: 1px solid #dddddd;
    @media (max-width: 1360px) {
      border-bottom: 0px;
    }
  }
  &.studygroup {
    border-bottom: 1px solid #dddddd;
    @media (max-width: 1360px) {
      border-bottom: 0px;
    }
  }
  div.main_nav_container {
    max-width: 1360px;
    margin: 0 auto;
    width: 100%;
    height: 36px;
    display: flex;
    // justify-content: space-between;
    .class-nb-exit {
      height: 34px;
      margin: auto 0;
      max-width: 130px;
    }
    .class-nb-buttons {
      //padding: 0.3rem 0rem;
      justify-content: flex-end;
      ul:focus {
        outline: none;
        li:focus {
          outline: none;
        }
      }
      button {
        &.link-button.nav-link {
          display: flex;
          i {
            font-size: 24px;
            margin: 0px 2px;
          }
        }
        span {
          // opacity: 0.7;
          margin: 2px 0;
          font-size: 2rem;
          @media (max-width: 979.98px) {
            margin: 0px;
          }
        }
        img {
          &.profileIcon {
            width: 29px;
            transition: 0.2s;
            &:hover {
              // transform: scale(1.1, 1.1);
            }
            @media (max-width: 979.98px) {
              width: 32px;
            }
          }
        }
        margin: auto;
        // height: 100%;
        font-size: 16px;
        text-decoration: none;
        &:hover {
          color: #007fa3;

          span {
            opacity: 1;
          }
          img {
          }
        }
      }
      .be-notifications.notification {
        // right: -110px;

        border-radius: 0 0 8px 8px;
        max-height: 720px;
        overflow: hidden;
        // box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
        //   0 1px 6px rgba(0, 0, 0, 0.23);
        @media (min-width: 980px) {
          min-width: 340px;
          max-width: 340px;
        }
        @media (max-width: 979.9px) {
          padding: 10px 0 0px 0;
        }
        &:not(.noti-loading) {
          height: 80vh;
        }
        &.show {
          display: flex;
          flex-direction: column;
        }
        .topnav {
          border-top-left-radius: 7px;
          border-top-right-radius: 7px;
          min-height: 49px;
          a {
            min-height: 48px;
          }
        }
        .tab-body {
          flex: 1;
          overflow-y: auto;
          .notification_loading,
          .notification_empty {
            margin-top: 58px;
            margin-bottom: 58px;
            text-align: center;
          }
          .no-notification {
            margin: 58px 0;
            text-align: center;
          }
          .notification-item {
            width: 100%;
            min-height: 87px;
            background-color: #fafafa;
            padding: 11px 16px;
            border-width: 1px;
            border-bottom-width: 1px;
            border-bottom-color: #e7e6e5;
            border-bottom-style: solid;
            position: relative;
            transition: background-color 0.2s;
            &:hover {
              background-color: #ebf9ff;
            }
            .new-assignment {
              width: 16px;
              height: 100%;
              position: absolute;
              display: flex;
              right: 15px;
              top: 0;
              img {
                width: 16px;
                height: 16px;
                object-fit: contain;
                margin: auto;
              }
            }
            .notification-item-header {
              width: 90%;
              min-height: 22px;
              font-size: 16px;
              color: #005a70;
              // padding: 11px 16px;
              // font-family: sans-serif;
              font-weight: 600;
              display: block;
              // padding: 11px 16px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .notification-item-body {
              width: 90%;
              min-height: 17px;
              // font-family: sans-serif;
              font-size: 12px;
              display: block;
              // padding: 12px 16px;
              // font-weight: normal;
              // font-stretch: normal;
              // font-style: normal;
              // line-height: normal;
              // letter-spacing: normal;
              color: #505759;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .notification-item-views {
              width: 100%;
              min-height: 17px;
              font-size: 12px;
            }
            .notification-item-footer {
              width: 100%;
              min-height: 17px;
              // font-family: sans-serif;
              font-size: 12px;
              display: block;
              // padding-top: 9px;
              // padding-bottom: 6px;
              // padding-left: 16px;
              // padding-right: 16px;
              // font-weight: normal;
              // font-stretch: normal;
              // font-style: normal;
              // line-height: normal;
              // letter-spacing: normal;
              color: #505759;
            }
          }
        }
      }
    }
  }
}

#main.class-nav-bar {
  ul.navbar-nav {
    .nav-item {
      display: flex;
      a {
        display: flex;
      }
    }
  }
}
