.access__right-message {
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  color: #000000;
}

.access__right-btn {
  width: 115px !important;
  height: 35px !important;
}

.modal-btn-right2{
  margin-top: -25px;
}

.access__right-body {
  border-bottom: 1px solid #ccc;
  margin-bottom: 30px;
  padding-bottom: 30px !important;
}
// pop up css for unit, subunit
.google_class_main{
	border-radius: 8px;
    border: 1px solid #E9E9E9;
    background: #FBFBFB;
    padding: 20px 22px;
}
.google_class_main_head{
	color: #333;
    font-size: 18px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}
.google_class_main_sub {
    display: grid;
    // grid-template-columns: 150px 1fr;
    grid-template-columns: 120px 1fr;
    gap: 10px;
}
.google_class_main_text {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
}
.google_class_resource{
	font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 160%;
    color: #000000;
    display: flex;
    align-items: center;
    gap: 19px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #fff;
}
.google_class_resource-icon-content {
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.google_class_resource-img-icon-content{
	margin-top: 4px;
    margin-bottom: 4px;
	left: 8px;
    position: relative;
}
.google_class-resource-border{
	border-right: 1px solid #ccc;
    align-self: stretch;
}
.google_class-resources-title{
	padding: 16px;
}
.google_class-resources-title-text{
	font-size: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;
    text-decoration: underline;
    color: #198CC5;
    line-height: 25px;
    cursor: pointer;
}