div.my-score.lalo {
  width: 100%;
  flex-wrap: wrap;
  margin-top: 16px;
  & > div {
    width: 100%;
    & > .my-score-card {
      max-width: 1310px;
      background-color: white;
      min-height: 180px;
      flex-direction: column;
      padding: 16px 28px;
      @media (max-width: 599px) {
        padding: 16px;
      }
      & > .title {
        padding-top: 10px;
        font-size: 18px;
        font-weight: 600;
        color: #003057;
      }
      .note {
        display: flex;
        flex-direction: row-reverse;
        font-size: 14px;
        @media (max-width: 599px) {
          flex-direction: column-reverse;
        }
        & > div {
          display: flex;
          padding-left: 20px;
          @media (max-width: 599px) {
            padding-left: 0px;
          }
          .dot {
            width: 15px;
            min-width: 15px;
            height: 15px;
            border-radius: 20px;
            margin: auto 10px;
            &.green {
              background-color: #84bd01;
            }
            &.blue {
              background-color: #67c5c9;
            }
          }
        }
      }
      .lo {
        padding: 15px 0;
        & > div {
          display: flex;

          &.lo-body {
            padding: 10px 0;
          }
          &.title {
            display: flex;
            line-height: 35px;
            color: #000000;
            font-size: 18px;
            font-weight: 600;
            .dot {
              width: 35px;
              height: 35px;
              background-color: #003057;
              border-radius: 20px;
              margin-right: 12px;
              & > div {
                text-align: center;
                color: white;
              }
            }
          }
          & > .info {
            // max-width: 50%;
            width: 100%;
            margin: auto 0;
            // @media (max-width: 1000px) {
            //   max-width: 100%;
            // }
            .progress-box {
              display: flex;
              padding-left: 35px;
              @media (max-width: 1024px) {
                padding-left: 0px;
              }
              .progress-bar {
                border-radius: 5px;
                width: 90%;
                margin: auto auto auto 10px;
                background-image: linear-gradient(to bottom, #dfe1e1, #dfe1e1);
                .filler {
                  background-image: linear-gradient(
                    to bottom,
                    #67c5c9,
                    #67c5c9
                  );
                }
              }
              .text {
                color: #000000;
                font-size: 18px;
                font-weight: 600;
                margin: auto;
                text-align: center;
                min-width: 50px;
              }
              &.avg {
                .progress-bar {
                  .filler {
                    background-image: linear-gradient(
                      to bottom,
                      #84bd01,
                      #84bd01
                    );
                  }
                }
                .text {
                  font-weight: normal;
                }
              }
            }
          }
          & > .suggestions {
            width: 100%;
            @media (min-width: 1001px) {
              max-width: 50%;
            }
            ul {
              display: flex;
              flex-wrap: wrap;
              padding: 0;
              margin: 0;
              list-style: none;
              li {
                min-height: 42px;
                margin: 5px 10px;
                padding: 5px 45px;
                border-radius: 60px;
                background-color: #ffbb1c;
                display: flex;
                cursor: pointer;
                &:hover {
                  background-color: #e0791b;
                }
                > div.suggestion-title {
                  margin: auto;
                  color: #000000;
                  font-size: 18px;
                  line-height: 18px;
                  font-weight: 600;
                  display: flex;
                  align-items: center;
                  overflow: hidden;
                  img {
                    margin-right: 10px;
                  }
                }
              }
            }
          }
          @media (min-width: "1001px") {
            .info {
              max-width: 50%;
            }
          }
          @media (max-width: "1000px") {
            flex-wrap: wrap;

            .suggestions {
              padding-top: 10px;
              ul {
                li {
                  padding: 5px 25px;
                }
              }
            }
          }
        }
      }
      & > .lo:not(:last-child) {
        border-bottom: 1px solid #003057;
      }
    }
  }
  & :not(:first-child) {
    & > .my-score-card {
      margin: 10px 2px;
    }
  }
}
