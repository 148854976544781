.activity_notification__popup-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 2;
  }
  .idle_notification__popup {
    background: #ffffff;
    max-width: 450px;
    width: 95%;
    height: 220px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 50px 20px;
    padding-bottom: 45px;
    border-radius: 12px;
    &-top-heading{
      color: #000;
      font-size: 18px;
      font-weight: 800;
      margin-top: 20px;
    }
    &-heading {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 36px;
      color: #000000;
      min-height: 60px;
      max-height: 200px;  
      padding-bottom: 16px;
      &-class {
        font-weight: 800;
        text-decoration: underline;
        white-space: nowrap;
      }
      &-resource {
        display: inline-block;
      }
    }
    &-save-heading{
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      margin-top: 60px;
    }
    &-show {
      margin-left: 134px;
      margin-right: 134px;
      display: flex;
      gap: 7px;
      &-checkbox {
        width: 18px;
        height: 18px;
      }
      &-label {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #000000;
      }
    }
    &-border {
      border-bottom: 1px solid #ddd;
    }
    &-bottom {
      display: flex;
      align-items: end;
      justify-content: end;
      gap: 16px;
    }
    &-ok-btn {
      background: #0088CF!important;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 10px;
      font-style: normal;
      font-weight: 800;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      color: #fff;
      padding: 7px 20px;
    }
    &-back-btn {
      background: #198cc5 !important;
      border-radius: 10px;
      font-style: normal;
      font-weight: 800;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      color: #ffffff;
      padding: 7px 20px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }
  
  @media only screen and (max-width: 1000px) {
    // .activity_notification__popup-heading {
    //   margin-left: 40px;
    //   margin-right: 40px;
    //   font-size: 24px;
    //   line-height: 28px;
    // }
    .activity_notification__popup-show {
      margin-left: 40px;
      margin-right: 40px;
    }
    .activity_notification__popup {
      gap: 10px;
    }
  }
  @media only screen and (max-width: 641px) {
    // .activity_notification__popup-heading {
    //   margin-left: 0px;
    //   margin-right: 0px;
    // }
    .activity_notification__popup-show {
      margin-left: 0px;
      margin-right: 0px;
    }
    .activity_notification__popup {
      gap: 10px;
    }
  }
  