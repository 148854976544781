div.my-score.ranking {
  max-width: 35%;
  width: 100%;

  @media (max-width: 1024px) {
    max-width: 100%;
  }
  .c-img {
    .title {
      color: #008638;
    }
  }
  .my-score-card {
    background-color: #d2db0e;
    // .progress-chart > div.progress-chart-center .title{
    min-width: 100%;
    // }
    .my-score-chart {
      margin: auto;
    }
  }
}
