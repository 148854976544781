.message-box.inline {
  max-width: 450px;
  // width: 100%;
  width: 80%;
  margin: 144px auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  @media (max-width: 599px) {
    margin: 40px auto;
  }
  .settings {
    position: absolute;
    top: 66px;
    right: 23px;
    @media (max-width: 425px) {
      top: 93px;
      right: 4px;
    }
    @media (max-width: 325px) {
      top: 90px;
      right: 1px;
    }
  }
  i {
    &.ResourceCenter {
      font-size: 30px;
      @media (min-width: 600px) {
        display: none;
      }
    }
  }
  .defaultImg {
    width: 88px;
    height: 88px;
    padding-bottom: 21px;
    margin: auto;
  }

  .resourceCenterImg {
    // width: 200px;
    width: 115px;
    // height: 200px;
    padding-bottom: 21px;
    margin: auto;
    @media (max-width: 599px) {
      width: 100px;
      height: 100px;
    }
  }
  // img {
  //   width: 88px;
  //   height: 88px;
  //   padding-bottom: 21px;
  //   margin: auto;
  // }

  .message {
    text-align: center;
    font-size: 16px;
  }
  &#StudyGroupAssignment,
  &#Noti_Assignment {
    margin: 70px auto;
    img {
      // width: 200px;
      // height: 200px;
      width: 125px;
      height: 125px;
    }
  }
  &#Noti_tools {
    // height: 500px;
    margin: 0px auto;
    img {
      width: 200px;
    }
  }
}

.message-box-inline {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 6rem;
}
