.main__handbooks-pagination {
  margin-bottom: 120px;
  margin-top: -45px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  &-top {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }

  &-bottom {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }

  &-lt {
    background-image: url("../../../assets/img/pec/all-images/breadcrumbarrow.png") !important;
    background-repeat: no-repeat !important;
    cursor: pointer;
    background-position: center;
    flex-shrink: 0;
    display: inline-block;
    height: 10px;
    width: 16px;
    vertical-align: middle;
    transform: rotate(180deg);
  }
  &-gt {
    background-image: url("../../../assets/img/pec/all-images/Filter-Books/pull-down-arrow-down.png") !important;
    background-repeat: no-repeat !important;
    cursor: pointer;
    width: 16px;
    background-position: center !important;
    flex-shrink: 0;
    display: inline-block;
    height: 10px;
    vertical-align: middle;
    transform: rotate(-90deg);
  }
}

.pagination-label-top {
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: #dddddd;
  position: relative;
  top: 3px;
  cursor: pointer;
  &:hover {
    color: #333333;
  }
}
.pagination-label-bottom {
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  color: #1691d2;
  position: relative;
  top: 3px;
  cursor: pointer;
  &:hover {
    color: #333333;
  }
}
.main__handbooks-pagination-btn {
  height: 31px;
  width: 31px;
  border-radius: 50%;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  background: none;
  color: #333333;
  border: none;
  cursor: pointer;
}
.main__handbooks-pagination-btn.active {
  background: #1691d2;
  color: #ffffff;
  cursor: not-allowed;
}
.border-radius-bottom {
  border-radius: 18px;
}
