.notifications__popup-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  // z-index: 2;
  // z-index: 99993 !important;
  z-index: 99991 !important;
}
.notifications__popup-overlay-handbook {
  z-index: 99996 !important;
}
.notification__popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 0 2rem 4rem rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  // z-index: 99999;
  z-index: 99992;
  filter: blur(0) !important;
  padding: 34px 50px;
  // min-width: 1118px;
  max-width: 1118px;
  width: 98%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-bottom: 80px;
  &-heading {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 55px;
    color: #333333;
    &-label{
      font-style: normal;
      font-weight: 900;
      font-size: 18px;
      line-height: 55px;
      color: #333333;
      display: inline;
    }
  }
  &-delete {
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    color: #e40051;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  &-edit {
    background: #198cc5;
    border-radius: 8.30882px;
    width: 77px;
    height: 42px;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  &-leftcontent {
    background: #fbfbfb;
    border: 1px solid #e9e9e9;
    border-radius: 8px;
    flex: 2 1 0;
    padding: 15px;
  }
  &-rightcontent {
    background: #fbfbfb;
    border: 1px solid #e9e9e9;
    border-radius: 8px;
    flex: 1 1 0;
    padding: 15px;
  }

  &-close {
    display: flex;
    align-items: end;
    justify-content: end;
    cursor: pointer;
    background-image: url(../../../assets/img/pec/all-images/pop-up-close@2x.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
  }
  &-top {
    order: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-content {
    order: 3;
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  &-button-section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }
  &-message-heading {
    color: #333;
    // font-weight: 800;
    font-weight: 900;
    font-size: 18px;
    line-height: 25px;
  }
  &-message-content {
    font-style: normal;
    font-weight: 500;
    // font-size: 14px;
    font-size: 18px;
    line-height: 160%;
    color: #000000;
    display: flex;
    align-items: center;
    gap: 19px;
    // cursor: pointer;
  }
  &-message-combine-content{
    display: flex;
    flex-direction: column;
    gap: 0px;
    align-items: start;
  }
  &-resource-area{
    display: flex;
    align-items: center;
    gap: 19px;
  }
  &-rescontent {
    margin-top: 40px;
  }
  &-icon {
    height: 24px !important;
    width: 24px !important;
    align-self: center !important; 
    left: 8px;
    position: relative;
  }
  &-main-icon {
    height: 40px !important;
    width: 40px !important;
  }
  &-main-icon-content{
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-book-section{
    gap: 5px;
    display: grid;
    // align-items: center;
    grid-template-columns: 140px 1fr;
  }
  &-resource-top{
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #fff;
  }
  &-resource-border{
    border-right: 1px solid #ccc;
    align-self: stretch;
  }
  &-resources-title{
    padding: 16px;
  }
  &-resource-title{
    font-size: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;
    text-decoration: underline;
    color: #198CC5;
    line-height: 25px;
    cursor: pointer;
  }
}
.topic__intro-icon {
  background-image: url("../../../assets/img/resources_icon/video.svg");
}
.content-cursor {
  cursor: pointer;
}
@media only screen and (max-width: 641px) {
  .notification__popup {
    padding: 16px;
    gap: 16px;
    &-top {
      align-items: flex-end;
      flex-direction: column;
    }
    &-heading {
      font-size: 18px;
      line-height: 10px;
      align-self: self-start;
      &-label{
        font-size: 18px;
      }
    }
    &-content {
      gap: 8px;
      flex-direction: column;
    }
    &-rescontent {
      margin-top: 16px;
    }
    &-overlay{
      overflow: scroll;
    }
    .notification__popup{
      top: 0; 
     left: 0; 
     transform: translate(-0%, -0%);
    }
  }
}
/*.view_progress{
  min-width: 160px !important;
  padding-left: 30px !important;  
}
.main__progress-linebar{padding-left: 30px !important;}*/
@media only screen and (max-width: 640px) {
  .notification__popup{
    top: 5% !important; 
   left: 0; 
   transform: translate(-0%, -0%);
  }
  .notifications__popup-overlay{
    overflow: scroll;
  }
}
@media only screen and (max-width: 1024px) {
  .notification__popup{
    top: 5%;
    left: 5%;
    transform: translate(0%, 0%);   
    width: 90%;
  }
  .notifications__popup-overlay{
    overflow: scroll;
  }
}