.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  thead {
    background: #F2F2F2;
    th {
      padding: 10px 5px;
      vertical-align: middle;
      font-size: 14px;
      font-weight: 600;
      color: #000000;
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid #e8e8e8;
      transition: 0.15s;
      &.pointer:not(.loading_tr) {
        cursor: pointer;
      }
      &:hover:not(.loading_tr) {
        background-color: #ebf9ff;
      }
      td {
        padding: 10px 5px;
        vertical-align: top;
        font-size: 15px;
        font-weight: 600;
        // line-height: 1.47;
        color: #000000;
      }
    }
  }
  &.handbook {
    thead {
      tr {
        th {
          &.dot {
            width: 50px;
          }
          &.bookseries {
            width: 150px;
            max-width: 100px;
          }
          &.title {
            max-width: 200px;
          }
          &.date {
            width: 100px;
            max-width: 100px;
            min-width: 60px;
          }
          &.from {
            max-width: 80px;
            width: 100px;
          }
          &.to {
            max-width: 70px;
            width: 150px;
          }
          &.due_date {
            max-width: 100px;
            width: 100px;
            min-width: 60px;
          }
          &.view {
            width: 50px;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          &.dot {
            // width: 50px;
            > div {
              //   margin-left: auto;
              //   width: auto;
              //   height: 16px;
              //   border-radius: 50%;
              //   background-color: red;
              text-align: right;
              img {
                width: 16px;
                height: 16px;
              }
            }
          }

          &.bookseries {
            width: 150px;
            max-width: 100px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          &.date {
            width: 100px;
            max-width: 100px;
            min-width: 60px;
          }
          &.from {
            max-width: 80px;
            width: 100px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          &.to {
            max-width: 70px;
            width: 150px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          &.due_date {
            max-width: 100px;
            width: 100px;
            min-width: 60px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          &.title {
            color: #007fa3;
            width: 50px;
            max-width: 200px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          &.view {
            width: 50px;
          }
          .views {
            display: flex;
            justify-content: space-between;
            .number {
              margin: auto 0;
              padding-right: 10px;
            }
            .handle {
              margin-right: 50px;

              i {
                color: rgba(0, 0, 0, 0.54);
                font-size: 24px;
                line-height: initial;
              }
            }
          }
        }
      }
    }
  }
  &.resource-list-table {
    thead {
      tr {
        th {
          &.book-name-label {
            width: 550px;
            padding-left: 50px;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          &.book-name {
            font-weight: bold;
            font-size: 16px;
            padding-left: 50px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
  &.student-list-table {
    thead {
      tr {
        th {
          &.student-name-label {
            width: 550px;
            padding-left: 50px;
          }
          &.viewed-label {
            width: 250px;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          &.student-name {
            width: 550px;
            padding-left: 50px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          &.viewed-date {
            width: 250px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}

// .table th,
// .table td {
//   padding: 10px 5px;
//   vertical-align: top;
//   border-top: 1px solid #dee2e6;
// }
// .table
// .table thead th {
//   vertical-align: bottom;
// //   border-bottom: 2px solid #dee2e6;
// }

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table .table {
  background-color: #eeeeee;
}

.table-sm th,
.table-sm td {
  padding: 7px;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-hover tbody .item:hover,
.item-hover:hover {
  background-color: #f0f0f0;
}

.table-hover tbody td.handleTd:hover .item {
  background-color: #005a70;
}

.table-hover tbody .item:hover td.handleTd {
  background-color: #007fa3;
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8dbe5;
}

.table-hover .table-primary:hover {
  background-color: #a5d2de;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #a5d2de;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #dddddd;
}

.table-hover .table-secondary:hover {
  background-color: #d0d0d0;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #d0d0d0;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #b8ddc7;
}

.table-hover .table-success:hover {
  background-color: #a7d5b9;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #a7d5b9;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #d6e6ff;
}

.table-hover .table-info:hover {
  background-color: #bdd6ff;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #bdd6ff;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffebbf;
}

.table-hover .table-warning:hover {
  background-color: #ffe3a6;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe3a6;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5b8c1;
}

.table-hover .table-danger:hover {
  background-color: #f2a2ad;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f2a2ad;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: white;
}

.table-hover .table-light:hover {
  background-color: #f2f2f2;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #f2f2f2;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c9c8c8;
}

.table-hover .table-dark:hover {
  background-color: #bcbbbb;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #bcbbbb;
}

.table-grey,
.table-grey > th,
.table-grey > td {
  background-color: #f1f1f1;
}

.table-hover .table-grey:hover {
  background-color: #e4e4e4;
}

.table-hover .table-grey:hover > td,
.table-hover .table-grey:hover > th {
  background-color: #e4e4e4;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: #f0f0f0;
}

.table-hover .table-active:hover {
  background-color: #e3e3e3;
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: #e3e3e3;
}

.table .thead-dark th {
  color: #eeeeee;
  background-color: #212529;
  border-color: #32383e;
}

.table .thead-light th {
  color: #636c72;
  background-color: #dddddd;
  border-color: #dee2e6;
}

.table-dark {
  color: #eeeeee;
  background-color: #212529;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #32383e;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 599.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 979.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1299.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1348.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xxl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive > .table-bordered {
  border: 0;
}

/*------------------------------------------------------------------
  [Tables]
*/
.table > thead > tr > th {
  border-bottom-width: 2px;
  font-weight: 700;
}

.table > thead.primary > tr > th {
  color: #007fa3;
}

.table > thead.success > tr > th {
  color: #008638;
}

.table > tbody > tr > td {
  font-weight: 400;
  vertical-align: middle;
}

.table > tbody > tr > td#clickable:hover {
  background-color: #e6e6e6;
}

.be-table-responsive {
  scroller-x: hidden;
}

@media (max-width: 600px) {
  .noDisplay {
    display: none;
  }
}

@media (max-width: 979.98px) {
  .be-table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
  }
}

.table-condensed > thead > tr > th {
  padding-top: 10px;
  padding-bottom: 8px;
}

.table-bordered > thead > tr > th {
  padding: 14px 10px;
}

.table-bordered tbody > tr td:first-child {
  padding-left: 10px;
}

.table-bordered tbody > tr td:last-child {
  padding-right: 10px;
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #007fa3;
  color: #ffffff;
}

.table-hover .table-primary:hover {
  background-color: #006b8a;
  color: #ffffff;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #006b8a;
  color: #ffffff;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #008638;
  color: #ffffff;
}

.table-hover .table-success:hover {
  background-color: #006d2d;
  color: #ffffff;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #006d2d;
  color: #ffffff;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #6ba4ff;
  color: #ffffff;
}

.table-hover .table-info:hover {
  background-color: #5294ff;
  color: #ffffff;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #5294ff;
  color: #ffffff;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffb81c;
  color: #ffffff;
}

.table-hover .table-warning:hover {
  background-color: #ffb003;
  color: #ffffff;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffb003;
  color: #ffffff;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #db0020;
  color: #ffffff;
}

.table-hover .table-danger:hover {
  background-color: #c2001c;
  color: #ffffff;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #c2001c;
  color: #ffffff;
}

.table-responsive {
  border: 0;
  margin-bottom: 0;
}

.table-responsive tbody > tr > td {
  padding-top: 11px;
  padding-bottom: 11px;
}

@media (max-width: 979.98px) {
  .table-responsive tbody > tr > td {
    white-space: nowrap;
  }
}

.table tr th .custom-control,
.table tr td .custom-control {
  padding: 0;
  margin: 0;
  vertical-align: middle;
}

.table tr th .custom-control .custom-control-indicator,
.table tr td .custom-control .custom-control-indicator {
  top: 0;
}

.table tr th.actions,
.table tr td.actions {
  text-align: center;
}

.table tr th.actions .icon,
.table tr td.actions .icon {
  color: #a1a1a1;
  font-size: 1.3846rem;
  line-height: 18px;
}

.table tr th.actions .icon:hover,
.table tr td.actions .icon:hover {
  color: gray;
}

.table tbody tr td.user-avatar img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

@media (max-width: 979.98px) {
  .table tbody tr td.user-avatar img {
    display: none;
  }
}

.table tbody tr td.cell-detail span {
  display: block;
}

.table tbody tr td.cell-detail .cell-detail-description {
  display: block;
  font-size: 0.8462rem;
  color: #999999;
}

.table tbody tr td.user-info img {
  float: left;
}

.table tbody tr td.user-info span {
  padding-left: 40px;
}

@media (max-width: 979.98px) {
  .table tbody tr td.user-info span {
    padding-left: 0;
  }
}

.table tbody tr td.milestone {
  min-width: 100px;
}

.table tbody tr td.milestone span {
  display: block;
  font-size: 0.8462rem;
  color: #999999;
  margin-bottom: 6px;
}

.table tbody tr td.milestone span.completed {
  float: right;
}

.table tbody tr td.milestone .progress {
  height: 6px;
  background-color: #e6e6e6;
  margin-bottom: 0;
}

.table tbody tr td.milestone .progress .progress-bar {
  border-radius: 2px;
}

.table tbody tr.online td:first-child {
  border-left: 3px solid #008638;
  padding-left: 17px;
}

.table tbody tr.primary td:first-child {
  border-left: 3px solid #007fa3;
  padding-left: 17px;
}

.table tbody tr.info td:first-child {
  border-left: 3px solid #6ba4ff;
  padding-left: 17px;
}

.table tbody tr.success td:first-child {
  border-left: 3px solid #008638;
  padding-left: 17px;
}

.table tbody tr.warning td:first-child {
  border-left: 3px solid #ffb81c;
  padding-left: 17px;
}

.table tbody tr.danger td:first-child {
  border-left: 3px solid #db0020;
  padding-left: 17px;
}

.table .number {
  text-align: right;
}

.table.table-borderless thead tr th {
  border-bottom: 2px solid #dee2e6;
}

.table.table-borderless tbody tr td {
  border-top-width: 0;
}

.card-table table {
  margin-bottom: 0;
}

.card-table tr th:first-child,
.card-table tr td:first-child {
  padding-left: 20px;
}

.card-table tr th:last-child,
.card-table tr td:last-child {
  padding-right: 20px;
}

.card-table thead tr th {
  padding-top: 15px;
  padding-bottom: 10px;
}

.table.tp-table tr td.actions {
  text-align: right;
}
