section.student-list {
  width: 100%;
  .table {
  }
  .pagination-row {
    border-radius: 5px;
    border: solid 1px #dbdbdb;
    margin: 0 20px;
    .pangination__box {
      justify-content: center;
    }
  }
}
