.form-control {
  display: block;
  width: 100%;
  height: 3.692rem;
  padding: 0.7692rem 0.75rem;
  font-size: 1rem;
  line-height: 1.428571;
  color: #404040;
  background-color: $white;
  background-clip: padding-box;
  border: 1px solid #d5d8de;
  border-radius: 2px;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }
  &:focus {
    color: #404040;
    background-color: $white;
    border-color: $primarycolor;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.15rem transparent;
    box-shadow: 0 0 0 0.15rem transparent;
  }
  &::-webkit-input-placeholder,
  &::-ms-input-placeholder,
  &::placeholder {
    color: $grey87;
    opacity: 1;
  }
  &:disabled,
  &[readonly] {
    background-color: #eeeeee;
    opacity: 1;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    -webkit-transition: none;
    transition: none;
  }
}

select.form-control:focus::-ms-value {
  color: #404040;
  background-color: $white;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.7692rem + 1px);
  padding-bottom: calc(0.7692rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.428571;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.15rem;
  line-height: 1.7;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.85rem;
  line-height: 1.6;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.7692rem;
  padding-bottom: 0.7692rem;
  margin-bottom: 0;
  line-height: 1.428571;
  color: #404040;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
  &.form-control-sm,
  &.form-control-lg {
    padding-right: 0;
    padding-left: 0;
  }
}

.form-control-sm {
  height: 2.3125rem;
  padding: 0.25rem 8px;
  font-size: 0.85rem;
  line-height: 1.6;
  border-radius: 2px;
}

.form-control-lg {
  height: 2.846rem;
  padding: 0.5rem 12px;
  font-size: 1.15rem;
  line-height: 1.7;
  border-radius: 4px;
}

select.form-control {
  &[size],
  &[multiple] {
    height: auto;
  }
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1.538rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
  > {
    .col,
    [class*="col-"] {
      padding-right: 5px;
      padding-left: 5px;
    }
  }
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
  &:disabled ~ .form-check-label {
    color: $grey87;
  }
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 1.385rem;
  .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0;
  }
}

.form-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  .form-check {
    width: 100%;
  }
}

.custom-control-sm {
  min-width: 1.38462rem;
  min-height: 1.38462rem;
}

.custom-control-sm .custom-control-input ~ .custom-control-label::before {
  width: 1.38462rem;
  height: 1.38462rem;
  margin-left: 2px;
}

.custom-control-sm
  .custom-control-input:checked
  ~ .custom-control-label::after {
  width: 1.38462rem;
  height: 1.38462rem;
  font-size: 16px;
  right: 0;
  line-height: 1.2;
  vertical-align: top;
  margin: 0px 2px;
}

@media (min-width: 600px) {
  .form-inline {
    label {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      margin-bottom: 0;
    }
    .form-group {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-bottom: 0;
    }
    .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle;
    }
    .form-control-plaintext {
      display: inline-block;
    }
    .input-group,
    .custom-select {
      width: auto;
    }
    .form-check {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: auto;
      padding-left: 0;
    }
    .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0;
    }
    .custom-control {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
    .custom-control-label {
      margin-bottom: 0;
    }
  }
}

.custom-control-label {
  line-height: 1.7;
}

.custom-control-label:before,
.custom-control-label:after {
  top: 0;
  left: 0;
}

.custom-control-input:checked ~ .custom-control-label::after {
  color: #007fa3;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #bbbbbb;
}

.custom-control-input:hover ~ .custom-control-label::before {
  border-color: #999999;
}

.custom-control-input:checked ~ .custom-control-color::after {
  background-color: #007fa3;
  color: #ffffff;
  border-radius: 3px;
}

.custom-radio .custom-control-input:checked ~ .custom-control-color::after {
  border-radius: 50%;
}

.custom-checkbox .custom-control-label:before {
  content: "";
  width: 1.699rem;
  height: 1.699rem;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin: 0 0;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  content: "\f26b";
  font-size: 1.5452rem;
  font-family: "Material Icons";
  margin: -2px 2px;
  font-weight: normal;
  text-align: center;
  line-height: 1.1;
}

/*------------------------------------------------------------------
  [Forms]
*/
.form-control {
  border-width: 1px;
  border-top-color: #bdc0c7;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 10px 12px;
  font-size: 1.1538rem;
  -webkit-transition: none;
  transition: none;
  height: 3.692rem;
}

.form-control[multiple] {
  padding: 0;
}

@supports (-webkit-overflow-scrolling: touch) {
  .form-control[multiple] {
    padding: 10px 12px;
  }
}

.form-control[multiple] option {
  padding: 8px 12px;
}

.col-form-label {
  padding-bottom: 0;
  line-height: 1;
}

@media (max-width: 599.98px) {
  .col-form-label {
    padding-bottom: 8px;
  }
}

input.form-control,
textarea.form-control {
  -webkit-appearance: none;
  font-family: sans-serif;
  &::placeholder {
    font-family: "Noto Sans TC", "\860B\679C\5137\4E2D\9ED1",
      "Apple LiGothic Medium", "Open Sans", sans-serif,
      "\5FAE\8EDF\6B63\9ED1\9AD4", "Microsoft JhengHei", "\9ED1\4F53", "SimHei",
      "Roboto", Arial;
  }
}

textarea.form-control,
select[multiple],
select[size] {
  height: auto;
}

label {
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: 8px;
  color: #666666;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  padding: 0 10px;
  font-size: 1rem;
  line-height: 28px;
  border: 1px solid transparent;
  border-color: #d5d8de;
  border-radius: 2px;
  display: inline-block;
  cursor: pointer;
}

.inputfile + label i {
  margin-right: 10px;
}

.form-control-xs {
  height: 30px;
  font-size: 0.9231rem;
  padding: 6px 9px;
}

select.form-control-xs:not([size]):not([multiple]) {
  height: 3.1384rem;
}

.form-control-sm {
  height: 37px;
  font-size: 1rem;
  padding: 4px 12px;
}

.form-control-lg {
  height: 57px;
  padding: 12px 14px;
  font-size: 1.385rem;
  font-weight: 300;
  border-radius: 2px;
}

.form-group.row {
  margin-bottom: 0;
  padding: 12px 0;
}

.form-horizontal .form-group.row .col-form-label {
  text-align: right;
}

.form-group.row label {
  white-space: nowrap;
}

.input-group-prepend .custom-control-label,
.input-group-append .custom-control-label {
  position: static;
}

.input-group-prepend .custom-control-label:before,
.input-group-prepend .custom-control-label:after,
.input-group-append .custom-control-label:before,
.input-group-append .custom-control-label:after {
  left: 0;
}

.input-group-prepend .custom-control,
.input-group-append .custom-control {
  width: 1.77592rem;
  height: 1.77592rem;
}

.input-group-prepend .input-group-text,
.input-group-append .input-group-text {
  padding: 10px 12px;
}

.input-group-prepend .custom-control,
.input-group-append .custom-control {
  margin: 0;
  padding: 0;
}

.custom-select {
  line-height: 1.2;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.form-control-success,
.form-control-error {
  padding: 10px 45px 10px 12px;
}

.form-control-success + span,
.form-control-error + span {
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right 0.923rem;
  background-size: 1.846rem 1.846rem;
  font-size: 19px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 48px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  pointer-events: none;
}

.form-control-success.is-valid + span,
.form-control-error.is-valid + span {
  color: #008638;
}

.form-control-success.is-invalid + span,
.form-control-error.is-invalid + span {
  color: #db0020;
}

.form-title {
  font-size: 1.385rem;
  font-weight: 400;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0.2384rem;
  padding-bottom: 1.031rem;
  margin: 0 0 13px;
  border-bottom-width: 0;
  border-radius: 3px 3px 0 0;
  background-color: transparent;
  border-bottom: 1px solid #d9d9d9;
}

.form-title .form-subtitle {
  font-size: 1rem;
  color: #707070;
}
/*------------------------------------------------------------------
  [Input Groups]
*/
.input-search input {
  border: 1px solid #d5d8de;
  border-radius: 0;
  display: block;
  font-size: 1rem;
}

.input-search input:focus {
  background-color: transparent;
}

.input-search .input-group-btn {
  position: absolute;
  right: 0;
  display: block;
  width: auto;
  top: 1px;
  right: 1px;
  z-index: 3;
}

.input-search .input-group-btn button {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  height: 3.5382rem;
}

.input-search .input-group-btn button:active {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.input-search .input-group-btn button .icon {
  font-size: 1.923rem;
  color: #737373;
}

.input-search .input-group-btn button:hover .icon {
  color: #5a5a5a;
}

.input-search.input-group-lg .input-group-btn button {
  height: 2.6922rem;
}

.input-search.input-group-sm .input-group-btn button {
  height: 0.6205rem;
}

.input-search.input-group-sm .input-group-btn button .icon {
  font-size: 1.692rem;
}

.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  height: 57px;
  padding: 12px 14px;
  font-size: 1.385rem;
  font-weight: 300;
  border-radius: 2px;
}

.input-group-lg > .input-group-append .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-lg .input-group-prepend .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  height: 37px;
  font-size: 1rem;
  padding: 4px 12px;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.428571rem;
  padding-left: 2.1377rem;
  cursor: pointer;
}

.custom-control-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1.385rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #007fa3;
  background-color: #ffffff;
}

.custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.15rem #eeeeee;
  box-shadow: 0 0 0 0.15rem #eeeeee;
}

.custom-control-input:active ~ .custom-control-label::before {
  color: #ffffff;
  background-color: #ffffff;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #878787;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #dddddd;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
}

.custom-control-label::before {
  position: absolute;
  top: 0.004rem;
  left: -2.1377rem;
  display: block;
  width: 1.7rem;
  height: 1.7rem;
  pointer-events: none;
  content: "";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #ffffff;
}

.custom-control-label::after {
  position: absolute;
  top: 0.004rem;
  left: -2.1377rem;
  display: block;
  width: 1.7rem;
  height: 1.7rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 3px;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ecf9f7;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none;
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  // background-color: #007fa3;
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::after {
  top: -1px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23007FA3' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(0, 127, 163, 0.5);
}

.custom-checkbox
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label::before {
  background-color: rgba(0, 127, 163, 0.5);
}

.custom-checkbox {
  display: flex;
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ffffff;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none;
}

.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(0, 127, 163, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: 3.692rem;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.428571;
  color: #404040;
  vertical-align: middle;
  background: #ffffff
    url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%233d3c3c' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
    no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #d5d8de;
  border-radius: 2px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-select:focus {
  border-color: #007fa3;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.15rem rgba(0, 127, 163, 0.5);
  box-shadow: 0 0 0 0.15rem rgba(0, 127, 163, 0.5);
}

.custom-select:focus::-ms-value {
  color: #404040;
  background-color: #ffffff;
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #878787;
  background-color: #dddddd;
}

.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  height: 2.3125rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}

.custom-select-lg {
  height: 2.846rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 3.692rem;
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 3.692rem;
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #007fa3;
  -webkit-box-shadow: 0 0 0 0.15rem transparent;
  box-shadow: 0 0 0 0.15rem transparent;
}

.custom-file-input:focus ~ .custom-file-label::after {
  border-color: #007fa3;
}

.custom-file-input:disabled ~ .custom-file-label {
  background-color: #eeeeee;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 3.692rem;
  padding: 0.7692rem 0.75rem;
  line-height: 1.428571;
  color: #404040;
  background-color: #ffffff;
  border: 1px solid #d5d8de;
  border-radius: 2px;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.966971rem;
  padding: 0.7692rem 0.75rem;
  line-height: 1.428571;
  color: #404040;
  content: "Browse";
  background-color: #f5f5f5;
  border-left: 1px solid #d5d8de;
  border-radius: 0 2px 2px 0;
}

.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  -webkit-box-shadow: 0 0 0 1px #eeeeee, 0 0 0 0.15rem transparent;
  box-shadow: 0 0 0 1px #eeeeee, 0 0 0 0.15rem transparent;
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #eeeeee, 0 0 0 0.15rem transparent;
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #eeeeee, 0 0 0 0.15rem transparent;
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007fa3;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #57daff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007fa3;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #57daff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.15rem;
  margin-left: 0.15rem;
  background-color: #007fa3;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #57daff;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    -webkit-transition: none;
    transition: none;
  }
}
