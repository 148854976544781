/*------------------------------------------------------------------
  [dataTables]
*/
table.dataTable {
	margin: 0 !important;
	border-collapse: collapse !important;
	width: 100% !important;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after {
	font-size: 1.538rem;
	bottom: .5rem;
}

table.dataTable tbody tr.child ul.dtr-details {
	padding: 0;
	line-height: 28px;
	width: 100%;
}

table.dataTable tbody td span.expand-content-icon {
	display: none;
	cursor: pointer;
	color: #007fa3;
	font-size: 1.692rem;
	padding-top: 5px;
}

table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child {
	padding-left: 33px;
}

table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child:before {
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	left: 9px;
	color: #007fa3;
	font-weight: bold;
	font-size: 1.538rem;
	line-height: 15px;
	border-color: #007fa3;
	background-color: transparent;
	-webkit-box-shadow: none;
	box-shadow: none;
}

div.dataTables_wrapper {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-flow: column;
	flex-flow: column;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	padding-left: 0;
	padding-right: 0;
}

div.dataTables_wrapper div.dataTables_info {
	color: #616161;
	padding-top: 5px;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
	margin: 0;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

@media (max-width: 599.98px) {
	div.dataTables_wrapper div.dataTables_paginate ul.pagination {
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
}

div.dataTables_wrapper div.dataTables_length label,
div.dataTables_wrapper div.dataTables_filter label {
	margin-bottom: 0;
	font-size: 1rem;
	color: #616161;
}

div.dataTables_wrapper div.dataTables_length label {
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

div.dataTables_wrapper div.dataTables_length label select.form-control-sm {
	height: 37px;
	padding: 2px;
	margin: 0 5px;
}

div.dataTables_wrapper div.dataTables_length select {
	width: 75px;
}

div.dataTables_wrapper div.dataTables_filter + * {
	padding-top: 10px;
}

div.dataTables_wrapper div.dataTables_filter label {
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

div.dataTables_wrapper div.dataTables_filter label select {
	padding: 2px;
}

.be-datatable-header {
	border-bottom: 1px solid #dee2e6;
	margin: 2px 0 0;
	padding: 15px 10px 22px;
}

.be-datatable-header .dt-buttons .btn embed {
	outline: none;
}

.be-datatable-footer {
	margin: 0;
	padding: 15px 3px 13px;
	border-top: 1px solid #dee2e6;
}
