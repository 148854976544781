.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
  }

  .link-button:active{
    outline: none;
  }
  
  .link-button:hover,
  .link-button:focus {
    text-decoration: none;
    outline: none;
  }

  .default-link-button {
    background-color: transparent;   
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
  }
  
  .default-link-button:hover,
  .default-link-button:focus {
    text-decoration: none;
    outline: none;
  }

  .default-link-button:active{
    outline: none;
  }
