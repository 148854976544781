@import "../../Stylesheets/base/colors.scss";

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #3d3c3ca1;
  z-index: 99999;
  // min-width: 401px;
}

.sprint {
  position: absolute;
  top: 50%;
  left: 50%;
  // @media (max-width: 499px) {
  //   left: 44.5%;
  // }
  margin-top: -60px;
  margin-left: -60px;
  width: 120px;
  height: 120px;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: $white;
  .splash-container {
    margin: 35px auto;
  }
}

.splash-container {
  &.in-page {
    padding: 50px 0;
  }
}
