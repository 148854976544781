.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  // word-wrap: break-word;
  background-color: $white;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  //border-radius: 3px;

  > {
    hr {
      margin-right: 0;
      margin-left: 0;
    }
    .list-group {
      &:first-child .list-group-item:first-child {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
      }
      &:last-child .list-group-item:last-child {
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
      }
    }
  }
}

.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.538rem;
}

.card-title {
  margin-bottom: 0.7692rem;
}

.card-subtitle {
  margin-top: -0.3846rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link {
  &:hover {
    text-decoration: none;
  }
  + .card-link {
    margin-left: 1.538rem;
  }
}

.card-header {
  padding: 0.7692rem 1.538rem;
  margin-bottom: 0;
  background-color: $white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  &:first-child {
    border-radius: calc(3px - 1px) calc(3px - 1px) 0 0;
  }
  + .list-group .list-group-item:first-child {
    border-top: 0;
  }
}

.card-footer {
  padding: 0.7692rem 1.538rem;
  background-color: $white;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  &:last-child {
    border-radius: 0 0 calc(3px - 1px) calc(3px - 1px);
  }
}

.card-header-tabs {
  margin-right: -0.769rem;
  margin-bottom: -0.7692rem;
  margin-left: -0.769rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.769rem;
  margin-left: -0.769rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(3px - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(3px - 1px);
  border-top-right-radius: calc(3px - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(3px - 1px);
  border-bottom-left-radius: calc(3px - 1px);
}

.card-deck {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  .card {
    margin-bottom: 15px;
  }
}

@media (min-width: 600px) {
  .card-deck {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
    .card {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 1;
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px;
    }
  }
}

.card-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  > .card {
    margin-bottom: 15px;
  }
}

@media (min-width: 600px) {
  .card-group {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    > .card {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
      margin-bottom: 0;
      + .card {
        margin-left: 0;
        border-left: 0;
      }
      &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        .card-img-top,
        .card-header {
          border-top-right-radius: 0;
        }
        .card-img-bottom,
        .card-footer {
          border-bottom-right-radius: 0;
        }
      }
      &:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        .card-img-top,
        .card-header {
          border-top-left-radius: 0;
        }
        .card-img-bottom,
        .card-footer {
          border-bottom-left-radius: 0;
        }
      }
      &:only-child {
        border-radius: 3px;
        .card-img-top,
        .card-header {
          border-top-left-radius: 3px;
          border-top-right-radius: 3px;
        }
        .card-img-bottom,
        .card-footer {
          border-bottom-right-radius: 3px;
          border-bottom-left-radius: 3px;
        }
      }
      &:not(:first-child):not(:last-child):not(:only-child) {
        border-radius: 0;
        .card-img-top,
        .card-img-bottom,
        .card-header,
        .card-footer {
          border-radius: 0;
        }
      }
    }
  }
}

.card-columns .card {
  margin-bottom: 0.7692rem;
}

@media (min-width: 600px) {
  .card-columns {
    -webkit-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
    .card {
      display: inline-block;
      width: 100%;
    }
  }
}

.accordion .card {
  &:not(:first-of-type) {
    &:not(:last-of-type) {
      border-bottom: 0;
      border-radius: 0;
    }
    .card-header:first-child {
      border-radius: 0;
    }
  }
  &:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  &:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.aside-header {
  margin-bottom: 5px;
}

.aside-header .title {
  display: block;
  margin: 16px 0 0;
  font-size: 27px;
  line-height: 27px;
  font-weight: 300;
}

.aside-header .navbar-toggle {
  background: rgba(0, 0, 0, 0);
  display: none;
  outline: none;
  border: 0;
  padding: 0 11px 0 0;
  text-align: right;
  margin: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

@media (max-width: 979.98px) {
  .aside-header .navbar-toggle {
    display: block;
  }
}

.aside-header .navbar-toggle .icon {
  font-size: 1.538rem;
  color: silver;
}

.aside-header .description {
  color: #5a5a5a;
  margin: 0;
}

.row#Ebooks .card.ani-card {
  height: 100%;
}
/*------------------------------------------------------------------
  [Cards]
*/
.card {
  display: block;

  background-color: #ffffff;
  // -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

  // commented because result page does not have box shadow
  // -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border-width: 0;
  border-radius: 5px;
}

.card-group .card {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.card th {
  border-top: 0;
  vertical-align: middle;
}

.card.border-primary,
.card.border-secondary,
.card.border-success,
.card.border-info,
.card.border-warning,
.card.border-danger,
.card.border-light,
.card.border-dark {
  border-width: 1px;
}

.card .list-group-item {
  padding-left: 20px;
  padding-right: 20px;
}

.classCard {
  border-width: 1px;
  cursor: pointer;
  margin-bottom: 25px;
}

.card-body {
  padding: 8px 20px 20px;
  border-radius: 0 0 3px 3px;
}

.card-body .card-title {
  margin-bottom: 20px;
  margin-top: 7px;
}

.card-body .card-text-no-title {
  margin-top: 15px;
}

.card-body .blockquote p {
  margin-bottom: 25px;
  margin-top: 30px;
}

.card-nav-tabs {
  border-radius: 2px 2px 0 0;
}

.card-header {
  font-size: 18px;
  font-weight: 300;
  padding-left: 0;
  padding-right: 0;
  padding-top: 1.5384rem;
  margin: 0 1.538rem;
  border-bottom-width: 0;
  border-radius: 3px 3px 0 0;
  background-color: transparent;
}

.card-header .tools {
  float: right;
  font-size: 0;
}

.card-header .tools > a {
  display: inline-block;
}

.card-header .tools .icon {
  display: inline-block;
  font-size: 1.538rem;
  line-height: 25px;
  vertical-align: middle;
  cursor: pointer;
  color: #bababa;
  min-width: 1.538rem;
  text-align: center;
}

.card-header .tools .icon + .icon {
  margin-left: 2px;
}

.card-header .tools .icon:hover {
  color: #a6a6a6;
}

.card-title {
  font-size: 18px;
  font-weight: 300;
  display: block;
  margin: 0;
}

.card-subtitle {
  display: block;
  font-size: 1rem;
  color: #707070;
  margin-bottom: 0.4615rem;
}

.card-header-divider {
  border-bottom: 1px solid #d9d9d9;
  margin: 0 1.538rem 8px;
  padding-left: 0;
  padding-right: 0;
  /*padding-bottom: 1.2307rem;*/
}

.card-header-divider .card-subtitle {
  margin-top: 0;
  margin-bottom: 0;
}

.card-flat {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.card-border {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #d4d4d4;
}

.card-contrast .card-header {
  margin: 0;
  padding: 20px 20px 11px;
}

.card-contrast .card-header.card-header-featured {
  padding-bottom: 17px;
}

.card-contrast .card-footer {
  margin: 0;
  padding: 20px 20px 17px;
}

.card-contrast .card-body {
  padding-top: 16px;
}

.card-header-contrast,
.card-footer-contrast {
  background-color: whitesmoke;
}

.card-body-contrast {
  background-color: whitesmoke;
}

.card-border-color {
  border-top: 3px solid #c9c9c9;
}

.card-border-color-primary {
  border-top-color: #007fa3;
}

.card-border-color-info {
  border-top-color: #6ba4ff;
}

.card-border-color-danger {
  border-top-color: #db0020;
}

.card-border-color-warning {
  border-top-color: #ffb81c;
}

.card-border-color-success {
  border-top-color: #008638;
}

.card-border-color-light {
  border-top-color: #ffffff;
}

.card-border-color-dark {
  border-top-color: #3d3c3c;
}

.card-full .card-header {
  margin: 0;
  padding-left: 1.538rem;
  padding-right: 1.538rem;
}

.card-full .card-header .card-title {
  border-width: 0;
}

.card-full-color .card-header {
  color: #ffffff;
  padding: 27px 20px 15px;
  margin: 0;
}

.card-full-color .card-header .card-subtitle,
.card-full-color .card-header .icon {
  color: #ffffff;
}

.card-full-color .card-body {
  padding: 20px;
}

.card-full-color.card-full-light .card-header {
  color: #404040;
}

.card-full-color.card-full-light .card-header .card-subtitle,
.card-full-color.card-full-light .card-header .icon {
  color: #404040;
}

.card-full-primary {
  background-color: #5f99f5;
  color: #ffffff;
}

.card-full-primary .card-header-contrast {
  background-color: #6da2f6;
  padding: 20px 20px 15px;
  margin: 0;
}

.card-full-primary .tools .icon {
  color: #ffffff;
}

.card-full-primary .tools .icon:hover {
  color: #4287f3;
}

.card-full-success {
  background-color: #37b358;
  color: #ffffff;
}

.card-full-success .card-header-contrast {
  background-color: #3bbf5e;
  padding: 20px 20px 15px;
  margin: 0;
}

.card-full-success .tools .icon {
  color: #ffffff;
}

.card-full-success .tools .icon:hover {
  color: #309c4c;
}

.card-full-info {
  background-color: #6ba4ff;
  color: #ffffff;
}

.card-full-info .card-header-contrast {
  background-color: #7aadff;
  padding: 20px 20px 15px;
  margin: 0;
}

.card-full-info .tools .icon {
  color: #ffffff;
}

.card-full-info .tools .icon:hover {
  color: #4c91ff;
}

.card-full-warning {
  background-color: #f6c163;
  color: #ffffff;
}

.card-full-warning .card-header-contrast {
  background-color: #f7c771;
  padding: 20px 20px 15px;
  margin: 0;
}

.card-full-warning .tools .icon {
  color: #ffffff;
}

.card-full-warning .tools .icon:hover {
  color: #f4b646;
}

.card-full-danger {
  background-color: #eb6357;
  color: #ffffff;
}

.card-full-danger .card-header-contrast {
  background-color: #ed7065;
  padding: 20px 20px 15px;
  margin: 0;
}

.card-full-danger .tools .icon {
  color: #ffffff;
}

.card-full-danger .tools .icon:hover {
  color: #e84a3c;
}

.card-full-light {
  background-color: #ffffff;
  color: #404040;
}

.card-full-light .card-header-contrast {
  background-color: white;
  padding: 20px 20px 15px;
  margin: 0;
}

.card-full-light .tools .icon {
  color: #404040;
}

.card-full-light .tools .icon:hover {
  color: #f0f0f0;
}

.card-full-dark {
  background-color: #eb6357;
  color: #ffffff;
}

.card-full-dark .card-header-contrast {
  background-color: #ed7065;
  padding: 20px 20px 15px;
  margin: 0;
}

.card-full-dark .tools .icon {
  color: #ffffff;
}

.card-full-dark .tools .icon:hover {
  color: #e84a3c;
}

.card-full {
  background-color: #bdbdbd;
  color: #ffffff;
}

.card-full .card-header-contrast {
  background-color: #c4c4c4;
  padding: 20px 20px 15px;
  margin: 0;
}

.card-full .tools .icon {
  color: #ffffff;
}

.card-full .tools .icon:hover {
  color: #adadad;
}

.card-full-dark {
  background-color: #333333;
  color: #ffffff;
}

.card-full-dark .card-header-contrast {
  background-color: #3b3b3b;
  padding: 20px 20px 15px;
  margin: 0;
}

.card-full-dark .tools .icon {
  color: #ffffff;
}

.card-full-dark .tools .icon:hover {
  color: #242424;
}

.card-table .card-body {
  padding: 0;
}

.card-fullwidth .card-header {
  margin: 0;
  padding-left: 1.538rem;
  padding-right: 1.538rem;
}

.card-fullwidth .card-body {
  padding-top: 16px;
}

.card-footer {
  border: unset;
}

.card-footer small {
  font-size: 0.8462rem;
}

@media (min-width: 600px) {
  .card-group .card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.card-columns {
  margin-bottom: 25px;
}

.card-img-overlay {
  overflow: hidden;
}

.card-img-overlay.card-img-overlay-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.card-img-overlay .card-title {
  font-size: 30px;
  margin-bottom: 40px;
}

.card-img-overlay p {
  font-size: 18px;
}

.card-img-overlay .btn {
  margin-top: 55px;
  color: #ffffff;
  border: 1px solid #d8d8d8;
}

@media (max-width: 600px) {
  .card-img-overlay .card-title {
    font-size: 19px;
    font-weight: 500;
    margin-bottom: 2px;
  }
  .card-img-overlay p {
    font-size: 13px;
    line-height: 1.2;
  }
  .card-img-overlay .btn {
    margin-top: 5px;
    background-color: rgba(0, 0, 0, 0.07);
    color: #ffffff;
    border: 1px solid #d8d8d8;
  }
}

.card-header-divider-noborder {
  padding-left: 0;
  padding-right: 0;
}
