  
  .component-new-select {
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    z-index:9999;
    min-width: 270px;
    min-height: 100px;
    background-color: #FFFFFF;
    width: 300px;
    height: 340px;
    overflow-y: scroll;
    position:absolute;
    margin-top: 45px;
    left: 450px;
    cursor: default;
    
  }