.view_notification__confirmpopup-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 2;
}
.view_notification__confirmpopup-overlay-confirmation {
  z-index: 99999;
}
.view_notification__confirmpopup {
  background: #ffffff;
  //   max-width: 900px;
  //   max-height: 328px;
  //   min-width: 900px;
  max-width: 900px;
  width: 95%;
  height: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  //   gap: 58px;
  gap: 30px;
  padding: 10px 20px;
  padding-bottom: 45px;
  &-close-popup {
    display: flex;
    align-items: end;
    justify-content: end;
    cursor: pointer;
    background-image: url(../../../assets/img/pec/all-images/pop-up-close@2x.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
  }
  &-heading {
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    color: #000000;
    // width: 543px;
    // height: 92px;
    // margin-left: 134px;
    // margin-right: 143px;
    margin-left: 134px;
    margin-right: 134px;
    min-height: 92px; /*add*/
    max-height: 200px; /*update*/
    overflow-x: hidden; /*add*/
    overflow-y: scroll;
    // margin-bottom: -58px;
    padding-bottom: 16px;
    &-class {
      font-weight: 800;
      text-decoration: underline;
      white-space: nowrap;
    }
    &-resource {
      // white-space: nowrap;
      display: inline-block;
    }
  }
  &-show {
    // width: 543px;
    // margin-left: 134px;
    margin-left: 134px;
    margin-right: 134px;
    display: flex;
    gap: 7px;
    &-checkbox {
      width: 18px;
      height: 18px;
    }
    &-label {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: #000000;
    }
  }
  &-border {
    // border: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
  }
  &-bottom {
    display: flex;
    align-items: end;
    justify-content: end;
    gap: 16px;
  }
  &-cancel-btn {
    background: #e4e4e4 !important;
    border-radius: 10px;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #333333 !important;
    width: 115px;
    height: 35px;
  }
  &-save-btn {
    background: #198cc5 !important;
    border-radius: 10px;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #ffffff;
    width: 115px;
    height: 35px;
  }
}

@media only screen and (max-width: 1000px) {
  .view_notification__confirmpopup-heading {
    margin-left: 40px;
    margin-right: 40px;
    font-size: 24px;
    line-height: 28px;
  }
  .view_notification__confirmpopup-show {
    margin-left: 40px;
    margin-right: 40px;
  }
  .view_notification__confirmpopup {
    gap: 10px;
  }
}
@media only screen and (max-width: 641px) {
  .view_notification__confirmpopup-heading {
    margin-left: 0px;
    margin-right: 0px;
  }
  .view_notification__confirmpopup-show {
    margin-left: 0px;
    margin-right: 0px;
  }
  .view_notification__confirmpopup {
    gap: 5px;
  }
}
