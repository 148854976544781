div.my-score {
  display: flex;
  position: relative;
  padding: 0 10px;
  .message {
    position: absolute;
    bottom: -15px;
    font-size: 13px;
  }
  @media (max-width: 599px) {
    padding: 0;
  }
  // width: 60%;
  &.this {
    // min-width: 700px;
    width: 100%;
    @media (max-width: 1023px) {
      margin-bottom: 20px;
    }
  }

  @media (max-width: 1023px) {
    &.this {
      min-width: 100%;
    }
    width: 100%;
  }
  .c-img {
    position: absolute;
    z-index: 1;
    top: 0;
    display: flex;
    .po-size {
      margin-bottom: 15px;
      display: flex;
      .title {
        line-height: 20px;
        margin: auto 12px;
        font-size: 20px;
        font-weight: 600;
        color: #003057;
        display: flex;
        @media (max-width: "599px") {
          font-size: 18px;
        }
      }
    }
  }
  .my-score-card {
    padding-top: 60px;
    margin: 10px 2px;
    border-radius: 20px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #94e7ea;
    // max-width: 670px;
    min-width: 670px;
    width: 100%;
    display: flex;
    margin-top: 60px;
    padding: 10px 15px;
    min-height: 250px;
    @media (max-width: 1024px) {
      min-width: 100%;
    }
    // height: 100%;
    @media (max-width: "735px") {
      flex-wrap: wrap;
    }
    .my-score-chart {
      min-width: 240px;

      .box-loading {
        height: 100%;
        display: flex;
        & > div {
          margin: auto;
        }
      }
      @media (max-width: "735px") {
        width: 100%;
        .progress-chart {
          margin: auto;
        }
        margin: auto;
      }
    }
    .line-border {
      width: 1px;
      border-left: solid 1px white;
      // padding-right: 15px;
      margin: 15px;
      // height: 80%;
      @media (max-width: "735px") {
        visibility: hidden;
        margin: 0px;
      }
    }
    .pass-chart {
      margin: auto;
      max-width: 375px;
      width: 100%;
      display: flex;
      flex-direction: column;
      > .line-title {
        font-size: 13px;
        font-weight: 600;
        color: #003057;
        margin: 15px auto 5px auto;
      }
      > .chart {
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 0.6);
        width: 100%;
        height: 100%;
        padding: 15px 10px 10px 20px;
      }
    }
  }
}
