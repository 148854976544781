#result-tag {
  // margin: 0 25px;
  @media (max-width: 599px) {
    // margin: 0 16px;
    padding-top: 15px;
  }
  @media (max-width: 1000px) {
    padding: 16px 0;
  }
  //   @media (max-width: 599px) {
  //     padding-top: 15px;
  //   }
  //   @media (max-width: 500px) {
  //     padding: 1px 4px 0 4px;
  //   }
  .report-box {
    .info-row {
      overflow-x: auto;
      padding: 15px 0;
      clear: both;
      .username_text {
        float: left;
      }
      .attempt-selection {
        float: right;
      }
    }
    .l-content {
      padding: 0 25px;
      @media (max-width: 599px) {
        // padding-top: 16px;
        padding: 16px;
      }
    }
  }
}
