.viewredo-tab {
  padding: 17px 0px 0px 20px;
  @media (max-width: 599px) {
    padding: 0 8px;
  }
  border-bottom: 1px solid #007fa3;
  background-color: #fff;
  ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    display: flex;
    min-height: 45px;
    li {
      // height: 45px;
      > div {
        cursor: pointer;
        display: block;
        height: 100%;
        padding: 10px 24px;
        font-size: 16px;
        font-weight: 600;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        color: #007fa3;
        &.active {
          background-color: #007fa3;
          color: #fff;
        }
      }
    }
  }
}

.class-list {
  table {
    width: 100%;
    thead {
      tr {
        height: 50px;
        th {
          background-image: linear-gradient(to bottom, #f0f0f0, #dddddd);
          height: 50px;
          // position: absolute;
          top: 50px;
          z-index: 1;
          -webkit-transition: 2s;
          transition: 2s;
          padding: 0 19px;
          color: #666666;
          &.assessment-title {
            width: 45px;
          }
          &.studygroup-list {
            .select-all-tab {
              display: flex;
              justify-content: center;
              margin-top: 9px;
            }
            div {
              display: flex;
              justify-content: space-around;
              padding: 1px 50px 0px 32px;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
    tbody {
      tr {
        td {
          &.title {
            color: #000000;
            font-size: 15px;
          }
          white-space: nowrap;
          height: 20px;
          border-bottom: 1px solid #e8e8e8;
          padding: 7px 19px;
          font-size: 16px;
          &.studygroup-list {
            div {
              display: flex;
              justify-content: space-around;
              padding: 1px 50px 0px 32px;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}
