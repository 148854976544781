#as-tag {
  padding: 0 16px 16px 16px;
  @media (max-width: 1000px) {
    padding: 0 10px 16px 10px;
  }
  border-radius: 5px;
  margin: auto;
  max-width: 1320px;
  .attempt-selection {
    position: relative;
    max-width: 175px;

    .border {
      position: absolute;
      right: 15%;
      top: 5px;
      bottom: 5px;
      border-style: solid;
      border-color: transparent;
      border-left: solid 1px #94e7ea;
    }
    select {
      width: 100%;
      padding: 5px 50px 5px 15px;
      border-radius: 8px;
      // background-image: linear-gradient(to bottom, #cffafc, #cffafc);
      background-color: #cffafc;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgMjEzLjMzMyAyMTMuMzMzIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyMTMuMzMzIDIxMy4zMzM7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxnPg0KCQk8cG9seWdvbiBwb2ludHM9IjAsNTMuMzMzIDEwNi42NjcsMTYwIDIxMy4zMzMsNTMuMzMzIAkJIi8+DQoJPC9nPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=");
      background-repeat: no-repeat;
      background-size: 12px;
      background-position: 95% 50%;
      border: none;

      option {
        font-size: 16px;
      }
    }
  }
  .content-row {
    padding-top: 10px;
    display: flex;
    @media (max-width: 1023px) {
      flex-wrap: wrap;
    }

    &:last-child {
      padding-top: 20px;
    }
    > .no-history {
      text-align: center;
      margin: auto;
    }
  }
  .action-row {
    padding-top: 10px;
    display: flex;
    flex-direction: row-reverse;
    img {
      margin-right: 10px;
    }
  }
}
