$text-color-blue: #136790;
$pc-color-white: #ffffff;
$background-color-blue: #198CC5;
$background-color-light-blue: #E8EFF3;
$question-background-color: #FBFBFB;
$question-border-color: #E9E9E9;

// body {
//     font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
//     background-color: #E9E9E9;
// }

.parent__container {
    padding-left:10px;
    padding-right: 10px;
}

.question {
    &__container {
        display: flex;
        justify-content: flex-start;
        padding: 10px 30px;
        border-radius: 8px;
        background: $question-background-color;
        border: 1px solid $question-border-color;
        align-items: center;
        margin-top: 28px;
    }
    &__numbers {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 40px;
        flex-wrap: wrap;
    }
    &__number {
        padding: 8px 20px;
        background: $background-color-blue;
        color: $pc-color-white;
        border-radius: 8px;
        margin-left: 20px;
        font-weight: 800;
        margin-bottom: 6px;
        margin-top: 6px;
        min-width: 77px;
        cursor: pointer;
    }
    &__head {
        margin: 0;
        font-weight: 800;
    }
}

.suggestion {
    &__container {
        margin-top: 32px;
    }
    &__label::-webkit-.accordion-marker {
        display: none;
    }
    &__label {
        width: 100%;
        padding: 0.5rem 0;
        position: relative;
        cursor: pointer;
        font-size: 1.875rem;
        list-style: none;
        outline: 0;
        margin-bottom: 14px;
        font-weight: 800;
        color: #333;
        @media only screen and (max-width: 640px) {
          font-size: 14px;
        }
    }
    &__label::after {
        content: "";
        position: absolute;
        margin-top: 12px;
        right: 0;
        width: 26px;
        height: 14px;
        transform-origin: center;
        transition: transform .2s ease-in-out;
        background-image: url("../../../../../../assets/img/pec/all-images/arrow-accordion.svg") !important;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: right center;
        transform: rotate(180deg);
    }
    // &[open] &__label::after {
    //     transform: rotate(180deg);
    // }
    &[open] &__label::after {
      transform: rotate(360deg);
    }
    &__content-body {
        background-color: $pc-color-white;
        border-radius: 10px;
        margin-bottom: 30px;
    }
    &__summary {
        background: $text-color-blue;
        padding: 16px 20px;
        color:$pc-color-white;
        border-radius: 10px;
        font-weight: 800;
    }
    &__summary-heading {
        display: inline-block;
        margin: 0;
        margin-left: 10px;
        font-weight: 800;
        font-size: 18px;
        @media only screen and (max-width: 640px) {
          font-size: 12px;
        }
    }
    &__details-head {
        background: $background-color-light-blue;
        padding: 15px;
        margin-left: 14px;
        margin-right: 14px;
        border-radius: 10px;
        color: $text-color-blue;
        margin-top: 10px;
        font-weight: 800;
        font-size: 18px;
        @media only screen and (max-width: 640px) {
          font-size: 12px;
        }
    }
    &__content {
        padding: 0px 2px 16px;
    }
    &__materials {
        margin-left: 58px;
        color: $text-color-blue;
        font-weight: 800;
        @media only screen and (max-width: 640px) {
          font-size: 12px;
        }
    }
    &__video {
       margin-left: 56px;
       display: flex;
       align-items: center;
       justify-content: flex-start;
    }
    &__web-link {
        margin-right: 10px;
    }
    &__external-link {
        margin-left: 10px;
    }
}
.suggestion__asset_img{
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  height: 35px;
  width: 35px;
  margin-right: 10px;
}

.year-drop-down-sdl-result,
.main-level-drop-down-result {
  background-image: url("../../../../../../assets/img/pec/all-images/Filter-Books/pull-down-arrow-down.png") !important;
  background-repeat: no-repeat !important;
  background-position: right !important;
  display: inline-block;
  width: 157px;
  height: 31px;
  background: #ffffff;
  border-radius: 5px;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  color: #377d9f;
  cursor: pointer;
  padding: 5px;
  background-position-x: 95% !important;
  margin-top: 9px;
  display: flex !important;
  align-items: center !important;
}
.year-drop-down-sdl-result {
  overflow: hidden;
  text-overflow: ellipsis;
  //   display: -webkit-box !important;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  height: 43px;
  flex-grow: 1;
  line-height: 19px;
  padding-right: 25px;
  padding-left: 10px;
  border-radius: 5px;
}
.main-level-drop-down-result {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  flex-grow: 1;
  width: 340px;
  height: 43px;
  background: #e8eff3;
  border-radius: 10px;
  color: #198cc5;
  line-height: 19px;
  padding-right: 25px;
  padding-left: 10px;
}
.main-level-drop-down {
  display: grid;
  justify-content: end;
  margin-bottom: 20px;
}
.report-box-main-section {
  background: #f5f6f7;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  padding: 10px;
  border-top: 14px solid #0088cf;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.main-component2 {
  transform: translate(1px, 50px);
}
.main-component3 {
  background: #e8eff3;
  border-radius: 10px;
  width: 250px;
  height: 220px;
  overflow-y: auto;
  transform: translate(59px, 125px);
}
.overall-component-section3 {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .year-drop-down-sdl-result {
    width: 100%;
  }
}

.chart-row {
  background: #fff;
}

.distribution-section {
  @media only screen and (max-width: 640px){
    margin-top: 30px;
  }
  &-score {
    color: #000000;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
  }
  &-bottom {
    display: flex;
    gap: 10px;
    align-items: center;
    &-submitted {
      color: #828282;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
    }
    &-total {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      color: #828282;
    }
  }
}

.pop_up_view {
    @media (max-width: 1360px) {
      background-color: #fff;
    }
    .p-score {
      i {
        margin-left: 4px;
        font-size: 24px;
        vertical-align: sub;
        display: inline;
        color: rgba(0, 0, 0, 0.54);
      }
    }
    .be-content {
      padding: 0px;
      #a {
        & > .row {
          margin: 0px;
        }
        .card {
          width: 100%;
          max-width: 1360px;
          // margin: 0 auto 15px auto; //old code to show footer overlap
          // margin: 0 auto 60px auto;
         margin: 0 auto 0 auto;
  
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          padding-bottom: 0px;
          @media (max-width: 1360px) {
            border: 0px;
            box-shadow: none;
            // height: 100%;
          }
          &.b2c {
            max-width: none;
          }
          &#studygroup {
            section#menu {
              table {
                width: 100%;
                padding: 0 20px;
                thead {
                  tr {
                    cursor: default;
                    &.tab {
                      cursor: pointer;
                    }
  
                    &.tab:hover {
                      background-image: linear-gradient(
                        to bottom,
                        #f0f0f0,
                        #d3d3d3
                      );
  
                      th {
                        background-image: linear-gradient(
                          to bottom,
                          #f0f0f0,
                          #d3d3d3
                        );
                      }
                    }
                    &.tab:active {
                      background-image: linear-gradient(to bottom, #f0f0f0, #ccc);
                      th {
                        background-image: linear-gradient(
                          to bottom,
                          #f0f0f0,
                          #ccc
                        );
                      }
                    }
                    th {
                      background-image: linear-gradient(
                        to bottom,
                        #f0f0f0,
                        #dddddd
                      );
                      height: 50px;
                      position: -webkit-sticky;
                      position: sticky;
                      // top: 95px;
                      z-index: 1;
                      -webkit-transition: 2s; /* Safari prior 6.1 */
                      transition: 2s;
                      font-size: 18px;
                      padding: 0 15px;
                      //font-family: OpenSans;
                      .tab {
                        display: flex;
                        white-space: nowrap;
                        .mdi {
                          font-size: 25px;
                          // padding-right: 5px;
                          &.mdi-chevron-right {
                            padding-right: 0px;
                            padding-left: 3px;
                          }
                        }
                      }
                      &.answers {
                        @media (max-width: 500px) {
                          display: none;
                        }
                      }
                      &.none {
                        width: 100%;
                        // @media (max-width: 500px) {
                        //   display: none;
                        // }
                      }
                      &.empty-first {
                        width: 35px;
                        min-width: 35px;
                        padding-right: 0px;
                        @media (max-width: 500px) {
                          width: 5px;
                          min-width: 5px;
                          padding-right: 0px;
                        }
                      }
                      // &:nth-child(1) {
                      //   width: 35px;
                      //   min-width: 35px;
                      //   padding-right: 0px;
                      //   @media (max-width: 500px) {
                      //     padding-left: 8px;
                      //   }
                      // }
                      &.level-name,
                        &.book-name,
                        // &.exercise {
                        //   white-space: nowrap;
                        //   //width: 100%;
                        //   padding-left: 0px;
                        //   max-width: 799px;
                        //   font-weight: normal;
                        //   padding-left: 0px;
                        //   @media (max-width: 500px) {
                        //     width: 100%;
                        //   }
                        //   /*&.exercise {
                        //       font-size: 14px;
                        //       font-weight: 600;
                        //     }*/
                        // }
                        // &.exercise {
                        //   width: 100%;
                        //   @media (max-width: 500px) {
                        //     padding-left: 8px;
                        //   }
                        // }
                        &.exercise {
                        // white-space: nowrap;
                        //width: 100%;
                        color: #000000;
                        font-size: 14px;
                        font-weight: bold;
                        padding-left: 0px;
                        // max-width: 799px;
                        // font-weight: normal;
                        // padding-left: 0px;
                        @media (max-width: 500px) {
                          width: 100%;
                        }
                        /*&.exercise {
                            font-size: 14px;
                            font-weight: 600;
                          }*/
                      }
                      &.exercise {
                        width: 40%;
                        @media (max-width: 500px) {
                          padding-left: 8px;
                        }
                      }
                      /*
                          &:nth-child(3) {
                            width: 100%;
                            @media (max-width: 500px) {
                              display: none;
                            }
                          }
                          */
                      /*
                          &:nth-child(4) {
                            width: 15%;
                            font-size: 14px;
                            font-weight: 600;
                            max-width: 180px;
                            @media (max-width: 500px) {
                              display: none;
                              width: auto;
                              min-width: auto;
                            }
                          }
                          */
                      &.submitted {
                        color: #000000;
                        font-size: 14px;
                        font-weight: 600;
                        white-space: nowrap;
                        @media (max-width: 500px) {
                          display: none;
                        }
                      }
                      &.viewable {
                        color: #000000;
                        font-size: 14px;
                        font-weight: 600;
  
                        white-space: nowrap;
                        @media (max-width: 500px) {
                          display: none;
                        }
                      }
                      &.redoable {
                        color: #000000;
                        font-size: 14px;
                        font-weight: 600;
  
                        white-space: nowrap;
                        @media (max-width: 500px) {
                          display: none;
                        }
                      }
                      &.p-score {
                        color: #000000;
                        cursor: pointer;
                        width: 12%;
                        font-size: 14px;
                        font-weight: 600;
                        min-width: 180px;
                        @media (max-width: 500px) {
                          min-width: 0px;
                          display: none;
                        }
                        &.mobile {
                          display: none;
                          @media (max-width: 500px) {
                            display: table-cell;
                            padding-right: 1px;
                          }
                        }
                      }
                      &.answers {
                        white-space: nowrap;
                        padding-left: 0;
                        font-size: 14px;
                        font-weight: 600;
                      }
                      &:nth-child(6) {
                        width: 10%;
  
                        // padding-left: 0;
                        font-size: 14px;
                        font-weight: 600;
                      }
                      // &:last-child {
                      //   width: 35px;
                      //   min-width: 35px;
                      // }
                      .title {
                        div {
                          max-width: 400px;
                          width: 100%;
                        }
                      }
                    }
                  }
                }
                tbody {
                  tr {
                    cursor: pointer;
                    -webkit-transition: 0.15s; /* Safari prior 6.1 */
                    transition: 0.15s;
  
                    &:hover {
                      background-color: #ebf9ff;
                    }
                    &.assessment_row_dim {
                      opacity: 0.3;
                      cursor: default;
                      &:hover {
                        background-color: #ffffff;
                      }
                    }
  
                    &:active {
                      background-color: #d4f2ff;
                    }
                    td {
                      .assessment-document-icon {
                        position: absolute;
                      }
                      white-space: nowrap;
                      height: 44px;
                      border-bottom: 1px solid #e8e8e8;
                      padding: 5px 19px;
                      @media (max-width: 500px) {
                        padding: 5px 13px;
                      }
                      font-size: 16px;
                      &.none {
                        // @media (max-width: 500px) {
                        //   display: none;
                        // }
                        @media (max-width: 500px) {
                          padding: 5px 5px;
                        }
                      }
                      &.sub-title {
                        padding-left: 0px;
                        padding-right: 0px;
                        width: 100%;
                        position: relative;
                        .unit-name {
                          color: #007fa3;
                          font-size: 15px;
                          max-width: 500px;
                          white-space: normal;
                          //display: table;
                          @media (max-width: 500px) {
                            width: 100%;
                          }
                        }
                      }
                      &.other {
                        padding-left: 0px;
                        width: 35%;
                        .unit-name {
                          color: #007fa3;
                          font-size: 15px;
                          //display: table;
                        }
                        @media (max-width: 500px) {
                          display: none;
                        }
                      }
                      &.empty:first-child {
                        width: 35px;
                        min-width: 35px;
                        //min-width: 50px;
                      }
                      &.empty:last-child {
                        width: 35px;
                        text-align: center;
                        font-size: 20px;
                      }
                      &.submitted {
                        font-size: 14px;
                        i {
                          font-size: 24px;
                          color: #84bd00;
                        }
                        @media (max-width: 500px) {
                          display: none;
                        }
                      }
                      &.title {
                        //width: 5%;
                        font-weight: 600;
                        color: #007fa3;
                        padding-left: 0px;
                        @media (max-width: 500px) {
                          padding-left: 2px;
                        }
                        div {
                          white-space: normal;
                          //max-width: 400px;
                          @media (max-width: 500px) {
                            //max-width: 200px;
                          }
                          // &.study-group,
                          // &.assessment {
                          //   display: flex;
                          // }
                          &.study-group {
                            display: flex;
                          }
                          &.assessment {
                            display: flex;
                            flex-direction: column;
                          }
                          min-height: 25px;
                          &.study-group {
                            i {
                              font-size: 20px;
                              line-height: initial;
                            }
                            div.name {
                              padding-left: 5px;
                            }
                          }
  
                          .order-number {
                            // color: #000000;
                            // font-size: 16px;
                            // min-height: 25px;
                            color: #000000;
                            font-size: 16px;
                            min-height: 25px;
                            min-width: 30px;
                            white-space: nowrap;
                            margin-right: 5px;
                          }
                          .assessment-name {
                            padding-left: 30px;
                            // padding-left: 15px;
                            // font-size: 15px;
                            // min-height: 22px;
                            // margin: auto 0;
                            // padding-left: 25px;
                            font-size: 15px;
                            min-height: 22px;
                            margin: auto 0;
                            @media (max-width: 500px) {
                              padding-left: 20px;
                            }
                          }
                          &.unit-box {
                            display: flex;
  
                            > .unit-name {
                              padding-left: 15px;
                            }
                            .label {
                              color: #000000;
                              font-weight: 600;
                              //display: table;
                              white-space: nowrap;
                              min-height: 25px;
                            }
                          }
                        }
                      }
                      &.center {
                        width: 15%;
                      }
                      &.info {
                        //width: 30%;
  
                        .progress {
                          display: flex;
  
                          .text {
                            font-size: 14px;
                            color: #008638;
                            margin: auto 0;
                            min-width: 37px;
                          }
                          .follow-up-text {
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 18px;
                            text-align: center;
                            color: #007fa3;
                            align-self: center;
                            place-self: center;
                            margin-left: 25px;
                          }
                          button {
                            margin-left: 25px;
                            @media (max-width: 500px) {
                              display: none;
                            }
                          }
                          .progress-bar {
                            margin: auto;
                            margin-left: 5px;
                            width: 100%;
                            @media (max-width: 500px) {
                              display: none;
                            }
                          }
                        }
                      }
                      &.answer {
                        font-size: 14px;
                        padding-left: 0;
                        @media (max-width: 500px) {
                          display: none;
                        }
                      }
                    }
                  }
                }
              }
            }
            #assessment.card.learnosity {
              box-shadow: none;
              padding-bottom: 0px;
              // .body {
              // @media (max-width: 599.9px) {
              //   padding-top: 35px;
              // }
              // @media (max-width: 768px) {
              //   padding: 0 8px;
              // }
              // @media (max-width: 400px) {
              //   padding: 0 4px;
              // }
              // }
            }
          }
        }
      }
    }
  }

  #prizm.card {
    // height:600px;
    // height: 100vh;
    // height: 666px;
    // height: 670px;
    height: calc(100vh - 50px);
    border-radius: 0px;
    overflow: hidden;
    // -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 10%);
    // box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 10%);
  }
  #sub-frame-error:hover {
    background-color: #ddd !important;
  }
  .icplayer-scrollable-content {
    padding-bottom: 5px;
  }
  #assessment.card {
    &.learnosity {
      &.api {
        .sub-menu {
          position: sticky;
          position: -webkit-sticky;
          top: 45px;
          margin-bottom: 0px;
        }
      }
      padding: 16px;
      @media (max-width: 768px) {
        padding: 16px 8px;
      }
      @media (max-width: 599px) {
        padding-top: 0px;
      }
      @media (max-width: 500px) {
        padding: 1px 4px 0 4px;
      }
      .sub-menu {
        position: sticky;
        top: 50px;
        @media (max-width: 599.9px) {
          //position: fixed;
          top: 58px;
          width: 100%;
        }
        z-index: 10000;
        margin-top: -8px;
        margin-left: -16px;
        margin-right: -16px;
        @media (max-width: 768px) {
          // margin-top: -8px;
          margin-left: -8px;
          margin-right: -8px;
        }
        @media (max-width: 500px) {
          margin-top: -8px;
          margin-left: -4px;
          margin-right: -4px;
          width: 103%;
        }
        min-height: 45px;
        background-color: #fff;
        border-bottom: 1px solid #007fa3;
        .menu-list {
          padding: 0 16px;
          @media (max-width: 500px) {
            padding: 5px 8px 0px 8px;
          }
          ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style: none;
            display: flex;
            min-height: 45px;
            li {
              min-height: 45px;
              a {
                cursor: pointer;
                display: block;
                height: 100%;
                padding: 10px 24px;
                @media (max-width: 500px) {
                  font-size: 14px;
                  padding: 10px 10px;
                }
                font-size: 16px;
                font-weight: 600;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                color: #007fa3;
                &.active,
                .active:hover {
                  background-color: #007fa3;
                  color: #fff;
                }
                &:hover {
                  background-color: #007fa3;
                  color: #fff;
                }
              }
              div.navlink {
                cursor: pointer;
                display: block;
                height: 100%;
                padding: 10px 24px;
                font-size: 16px;
                font-weight: 600;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                color: #007fa3;
                &.active {
                  background-color: #007fa3;
                  color: #fff;
                }
              }
            }
          }
        }
      }
      .body {
        @media (max-wdith: 500px) {
          padding: 0 10px;
        }
        //padding-top: 16px;
        .learnosity_loading {
          padding-top: 48px;
        }
        .model-answer-box-no-permission {
          text-align: center;
          padding: 18px;
          .model-answer-image {
            padding-bottom: 14px;
            img {
              max-width: 250px;
              width: 70%;
            }
          }
          .model-answer-text {
            h3 {
              padding-bottom: 14px;
              //font-family: OpenSans;
              font-size: 24px;
              font-weight: 600;
            }
            h5 {
              max-width: 518px;
              margin: 0 auto;
              //font-family: OpenSans;
              font-size: 16px;
            }
          }
        }
        .school_year_row {
          margin: 0 -16px;
        }
        .filter-row {
          padding-top: 6px;
          padding-bottom: 10px;
          &.select-tab-excell {
            display: flex;
            justify-content: space-between;
            @media (max-width: 425px) {
              display: block;
            }
          }
  
          button {
            padding: 4px 10px;
            line-height: 23px;
            @media (max-width: 768px) {
              margin: 6px 10px 70px 3px;
            }
            @media (max-width: 425px) {
              margin: 6px 3px 17px 3px;
            }
          }
          .user-report {
            display: inline-block;
            h3 {
              //font-family: OpenSans;
              font-size: 24px;
              font-weight: 600;
              color: #000000;
            }
            h5 {
              //font-family: OpenSans;
              font-size: 14px;
              color: #505759;
            }
          }
  
          button {
            float: right;
          }
        }
        .questions-bar {
          overflow-x: auto;
          padding: 15px 0;
          table {
            width: 100%;
            thead {
              tr {
                background-color: #eee;
                th {
                  padding: 10px;
                  border: 1px solid #e3e3e3;
                }
              }
            }
            tbody {
              tr {
                td {
                  padding: 10px;
                  border: 1px solid #e3e3e3;
                  .dot {
                    //text-align: center;
                    margin: auto 0;
                    border-radius: 50%;
                    width: 16px;
                    height: 16px;
                    &.green {
                      background-color: #61ae41;
                    }
                    &.red {
                      background-color: #da1819;
                    }
                    &.yellow {
                      background-color: #f39c12;
                    }
                    &.grey {
                      background-color: #ddd;
                    }
                  }
                }
              }
            }
          }
        }
        .submited-report {
          padding: 0 20px;
        }
        .submited-box {
          text-align: center;
          padding: 0 10px;
          .submited-icon {
            max-width: 500px;
            width: 100%;
            margin-top: 30px;
            margin-right: auto;
            margin-left: auto;
            i {
              font-size: 150px;
              color: #84bd02;
            }
          }
          .submited-text {
            h5 {
              padding-top: 10px;
            }
          }
          .submited-btn {
            max-width: 500px;
            width: 100%;
            padding-top: 10px;
            margin-right: auto;
            margin-left: auto;
            .btn {
              margin-bottom: 10px;
              min-width: 160px;
              margin: 4px 8px;
              text-align: center;
            }
          }
        }
        .result-menu {
          background-color: #daf0ed;
  
          @media (max-width: 2560px) {
            margin: 0 -15px;
          }
  
          @media (max-width: 1024px) {
            margin: 0 -16px;
          }
  
          @media (max-width: 768px) {
            margin: 0 -8px;
          }
          display: flex;
          padding: 9px 30px;
          height: 50px;
          font-size: 16px;
          color: #007fa3;
          font-weight: bold;
          .gap {
            padding: 0 12px;
            color: #000000;
          }
          .active {
            font-weight: bold;
            color: #000000;
            cursor: default;
          }
        }
        .report-box {
          //padding: 0 16px;
          @media (max-width: 1360px) {
            //padding: 0 8px;
          }
          .filter-row {
            select {
              margin-top: 4px;
              margin-right: 10px;
              width: 152px;
              @media (max-width: 375px) {
                width: 46%;
              }
              height: 36px;
              min-width: -webkit-min-content;
              &.user-last-attemptDate {
                float: right;
                margin: auto;
              }
            }
          }
          .chart-row {
            display: flex;
            padding: 10px 0;
            @media (max-width: 700px) {
              display: block;
              padding: 10px 0;
            }
            @media (max-width: 500px) {
              display: block;
              padding: 10px 10px;
            }
            .left-chart {
              width: 33.3333333333%;
  
              padding-right: 20px;
              @media (max-width: 700px) {
                width: 100%;
                padding-right: 0px;
              }
              .chart-box {
                position: relative;
                .left-chart-center-title {
                  position: absolute;
                  text-align: center;
                  width: 100%;
                  bottom: 20px;
                  h3 {
                    color: #008637;
                  }
                }
                .chart {
                  width: 100%;
                  height: 200px;
                }
              }
              .left-chart-footer {
                display: flex;
                justify-content: center;
                div {
                  margin: auto;
                  padding: 10px 0;
                  h3 {
                    text-align: center;
                    font-size: 24px;
                    @media (max-width: 768px) {
                      font-size: 18px;
                    }
                  }
                  /*
                      padding: 10px 30px;
                      @media (max-width: 980px) {
                        padding: 10px 20px;
                      }
                      @media (max-width: 780px) {
                        padding: 10px 10px;
                      }
                      */
                }
              }
            }
            .right-chart {
              width: 100%;
              padding-left: 20px;
              // border-left: 1px solid #00000069;
              @media (max-width: 700px) {
                width: 100%;
                border-left: none;
                padding-left: 0px;
              }
              @media (max-width: 500px) {
                border-left: none;
                padding-left: 0px;
              }
              .chart-box {
                padding-top: 10px;
                position: relative;
  
                .left-title {
                  position: absolute;
                  left: 0px;
                  text-align: center;
                  width: 280px;
                  bottom: 0px;
                  transform-origin: 0 0;
                  transform: rotate(270deg);
                  //font-family: OpenSans;
                  font-size: 12px;
                  color: #505759;
                }
                .left-title-result {
                  color: #333333;
                  font-style: normal;
                  font-weight: 800;
                  font-size: 14px;
                  text-transform: capitalize;
                }
                .bottom-title {
                  position: absolute;
                  bottom: 0px;
                  text-align: center;
                  width: 100%;
                  //font-family: OpenSans;
                  font-size: 12px;
                  color: #505759;
                  @media (max-width: 500px) {
                    bottom: 11px;
                  }
                }
                .bottom-title-result {
                  color: #333333;
                  font-style: normal;
                  font-weight: 800;
                  font-size: 14px;
                }
                .chart {
                  width: 100%;
                  height: 250px;
                  padding-left: 25px;
                  padding-bottom: 20px;
                }
              }
            }
            .report-chart-title {
              //font-family: OpenSans;
              font-size: 16px;
              color: #000000;
            }
            .report-chart-subtitle {
              //font-family: OpenSans;
              font-size: 12px;
              color: #505759;
            }
          }
        }
        .questions-row {
          display: flex;
          justify-content: space-between;
          min-height: 50px;
          background-color: #f9f9f9;
          border: 1px solid #e3e3e3;
          .questions-list-title {
            max-width: 160px;
            font-size: 14px;
            font-weight: 600;
            margin: auto 16px auto 16px;
          }
          .questions-list {
            display: flex;
            min-height: 50px;
            flex-wrap: wrap;
            width: max-content;
            flex-direction: row;
            justify-content: flex-start;
            border-left: 1px solid #e3e3e3;
            .question-item {
              height: 50px;
              margin: 0 0px;
              border-right: 1px solid #e3e3e3;
              border-bottom: 1px solid #e3e3e3;
              flex: 0;
  
              .content {
                padding: 15px 0px;
                cursor: pointer;
                min-width: 50px;
                text-align: center;
                color: #007fa3;
              }
            }
            .question-loading {
              height: 20px;
              margin: auto 20px;
            }
            .no-data {
              height: 20px;
              margin: auto 20px;
            }
          }
        }
        .type-row {
          padding: 10px 0;
          .type-bar {
            cursor: pointer;
            display: flex;
            height: 50px;
            padding: 0 22px;
            justify-content: space-between;
            background-image: linear-gradient(to bottom, #f0f0f0, #dddddd);
            &:hover {
              background-image: none;
              background-color: #eee;
            }
            > div {
              padding: 13px 0;
              &.title {
                display: flex;
                // i {
                //   font-size: 24px;
                //   padding-right: 12px;
                // }
                i {
                  font-size: 24px;
                  transition: transform 0.2s;
                  &.open {
                    transform: rotate(90deg);
                  }
                }
                h4 {
                  margin: auto;
                  //font-family: OpenSans;
                  font-size: 18px;
                  padding-left: 10px;
                }
              }
              &.progress-bar-box {
                display: flex;
                .text {
                  margin: auto;
                  padding-right: 5px;
                }
                .progress-bar {
                  margin-left: 5px;
                  width: 209px;
                  height: 12px;
                  margin: auto;
                  @media (max-width: 500px) {
                    display: none;
                  }
                  .filler {
                    background-image: linear-gradient(
                      to bottom,
                      #29d9d2,
                      #12b2a6
                    );
                  }
                }
              }
              &.progress-bar {
                width: 260px;
                .text {
                  vertical-align: middle;
                  display: inline-block;
                  //font-family: OpenSans;
                  font-size: 16px;
                }
                .rc-progress-line {
                  padding-left: 10px;
                  width: 200px;
                }
              }
            }
          }
          .type-item {
            display: flex;
            min-height: 40px;
            padding: 0 22px;
            justify-content: space-between;
            border: 1px solid #e2e2e2;
            border-top: none;
            .title {
              display: flex;
              // padding-left: 40px;
              padding: 8px 0 8px 0px;
  
              h4 {
                margin: auto;
                //font-family: OpenSans;
                font-size: 14px;
              }
            }
            .progress-bar-box {
              margin-left: 5px;
              display: flex;
              .text {
                margin: auto;
                padding-right: 5px;
              }
              .progress-bar {
                width: 209px;
                height: 12px;
                margin: auto;
                @media (max-width: 500px) {
                  display: none;
                }
                .filler {
                  background-image: linear-gradient(to bottom, #f6ae00, #ea7600);
                }
              }
            }
            .progress-bar {
              width: 260px;
              height: auto;
              margin: auto 0;
              .text {
                vertical-align: middle;
                display: inline-block;
                //font-family: OpenSans;
                font-size: 14px;
              }
              .rc-progress-line {
                padding-left: 10px;
                width: 200px;
              }
            }
          }
        }
        .all-result-row {
          padding: 10px 0;
          // overflow: auto;
        }
        .suggestion-show-score-row {
          border: solid 1px #ea7600;
          background-color: #ea7600;
          padding: 6px 16px;
          div {
            font-size: 14px;
            font-weight: 600;
            color: #fff;
          }
        }
        .suggestion-box {
          background-color: #fffaf1;
          border: 1px solid #ee8c3a;
          border-radius: 2px;
          //display: flex;
          padding: 5px 5px;
          .suggestion-icon {
            width: 80px;
            text-align: center;
            img {
              width: 55px;
              padding-top: 10px;
            }
          }
          .suggestion-body {
            width: 100%;
            .sub-head {
              background-color: #daf0ed;
              font-size: 14px;
              font-weight: 600;
              color: #000000;
              padding: 5px 10px;
            }
            ul {
              list-style: none;
              padding: 5px 18px;
              margin: 0px;
              li {
                // margin-top: 5px;
                ul.list-item {
                  padding: 0;
                  li {
                    > div.mat-box {
                      // width: fit-content;
                      // cursor: pointer;
                      // border-radius: 4px;
                      // transition: 0.2s;
                      // display: flex;
                      // padding: 4px 2px;
                      width: fit-content;
                      cursor: pointer;
                      border-radius: 4px;
                      transition: 0.2s;
                      display: flex;
                      padding: 4px 2px;
                      align-items: flex-start;
                      &:hover {
                        background-color: #daf0ed;
                      }
                      img {
                      }
                      .title {
                        margin: auto 5px;
                        color: #007fa3;
                        text-decoration: underline;
  
                        vertical-align: middle;
                      }
                    }
                    //margin-top: 0px;
                  }
                }
                h3 {
                  //padding-top: 5px;
                  font-size: 16px;
                  color: #000000;
                  font-size: 16px;
                  font-weight: 600;
                }
                padding: 2px 0;
              }
            }
          }
        }
      }
    }
    section#menu {
      height: calc(100% - 150px);
  
      .menu_bar {
        display: flex;
        min-height: 50px;
        padding: 14px 16px;
        position: -webkit-sticky;
        position: sticky;
        top: 50px;
        z-index: 2;
        background-color: #daf0ed;
        @media (max-width: 500px) {
          padding: 14px 8px;
        }
        font-size: 16px;
        color: #007fa3;
        font-weight: bold;
        .gap {
          padding: 0 12px;
          color: #000000;
          @media (max-width: 500px) {
            display: none;
          }
        }
        .active {
          font-weight: bold;
          color: #000000;
          cursor: default;
        }
        .study-group {
          display: flex;
          i {
            font-size: 20px;
            line-height: initial;
          }
          div.name {
            padding-left: 5px;
          }
        }
        a {
          @media (max-width: 500px) {
            display: none;
          }
        }
      }
      section {
        .col-list-header {
          cursor: pointer;
          width: 100%;
          display: flex;
          background-image: linear-gradient(to bottom, #f0f0f0, #dddddd);
          height: 50px;
          position: -webkit-sticky;
          position: sticky;
          top: 100px;
          z-index: 1;
          -webkit-transition: 2s; /* Safari prior 6.1 */
          transition: 2s;
          font-size: 18px;
          padding: 12px 15px;
          //font-family: OpenSans;
          &:hover {
            background-image: linear-gradient(to bottom, #f0f0f0, #d3d3d3);
          }
          &:active {
            background-image: linear-gradient(to bottom, #f0f0f0, #ccc);
          }
          .handle {
            width: 35px;
            i {
              font-size: 25px;
              padding-right: 5px;
              &.mdi-chevron-right {
                padding-right: 0px;
                padding-left: 3px;
              }
            }
          }
          .list-title {
            padding-left: 5px;
            h3 {
              font-size: 18px;
              line-height: normal;
              margin: auto;
            }
          }
          .list-score {
            h4 {
              display: inline-block;
              font-size: 14px;
              font-weight: 600;
            }
          }
        }
      }
      table {
        width: 100%;
        padding: 0 20px;
        thead {
          tr {
            cursor: default;
            &.tab {
              cursor: pointer;
            }
  
            &.tab:hover {
              background-image: linear-gradient(to bottom, #f0f0f0, #d3d3d3);
  
              th {
                background-image: linear-gradient(to bottom, #f0f0f0, #d3d3d3);
              }
            }
            &.tab:active {
              background-image: linear-gradient(to bottom, #f0f0f0, #ccc);
              th {
                background-image: linear-gradient(to bottom, #f0f0f0, #ccc);
              }
            }
            th {
              background-image: linear-gradient(to bottom, #f0f0f0, #dddddd);
              height: 50px;
              position: -webkit-sticky;
              position: sticky;
              top: 100px;
              z-index: 1;
              -webkit-transition: 2s; /* Safari prior 6.1 */
              transition: 2s;
              font-size: 18px;
              padding: 0 15px;
              //font-family: OpenSans;
              .tab {
                display: flex;
                white-space: nowrap;
                .mdi {
                  font-size: 25px;
                  padding-right: 5px;
                  &.mdi-chevron-right {
                    padding-right: 0px;
                    padding-left: 3px;
                  }
                }
              }
              &.answers {
                @media (max-width: 500px) {
                  display: none;
                }
              }
              &.none {
                width: 100%;
                @media (max-width: 500px) {
                  display: none;
                }
              }
              &:nth-child(1) {
                width: 35px;
                min-width: 35px;
                padding-right: 0px;
                @media (max-width: 500px) {
                  padding-left: 8px;
                }
              }
              &.level-name,
              &.book-name,
              &.exercise {
                white-space: nowrap;
                //width: 100%;z
                padding-left: 0px;
                max-width: 799px;
                font-weight: normal;
                padding-left: 0px;
                @media (max-width: 500px) {
                  width: 100%;
                }
                /*&.exercise {
                      font-size: 14px;
                      font-weight: 600;
                    }*/
              }
              &.exercise {
                width: 100%;
                @media (max-width: 500px) {
                  padding-left: 8px;
                }
              }
              /*
                  &:nth-child(3) {
                    width: 100%;
                    @media (max-width: 500px) {
                      display: none;
                    }
                  }
                  */
              /*
                  &:nth-child(4) {
                    width: 15%;
                    font-size: 14px;
                    font-weight: 600;
                    max-width: 180px;
                    @media (max-width: 500px) {
                      display: none;
                      width: auto;
                      min-width: auto;
                    }
                  }
                  */
              &.submitted {
                font-size: 14px;
                font-weight: 600;
                white-space: nowrap;
                @media (max-width: 500px) {
                  display: none;
                }
              }
              &.p-score {
                width: 12%;
                font-size: 14px;
                font-weight: 600;
                min-width: 180px;
                @media (max-width: 500px) {
                  min-width: 0px;
                  display: none;
                }
                &.mobile {
                  display: none;
                  @media (max-width: 500px) {
                    display: table-cell;
                  }
                }
              }
              &.answers {
                white-space: nowrap;
                padding-left: 0;
                font-size: 14px;
                font-weight: 600;
              }
              &:nth-child(6) {
                width: 10%;
  
                padding-left: 0;
                font-size: 14px;
                font-weight: 600;
              }
              &:last-child {
                width: 35px;
                min-width: 35px;
              }
              .title {
                div {
                  max-width: 400px;
                  width: 100%;
                }
              }
            }
          }
        }
        tbody {
          tr {
            cursor: pointer;
            -webkit-transition: 0.15s; /* Safari prior 6.1 */
            transition: 0.15s;
  
            &:hover {
              background-color: #ebf9ff;
            }
            &:active {
              background-color: #d4f2ff;
            }
            td {
              white-space: nowrap;
              height: 44px;
              border-bottom: 1px solid #e8e8e8;
              padding: 5px 15px;
              font-size: 16px;
              &.none {
                @media (max-width: 500px) {
                  display: none;
                }
              }
              &.sub-title {
                padding-left: 0px;
                padding-right: 0px;
                width: 100%;
                position: relative;
                .unit-name {
                  color: #007fa3;
                  font-size: 15px;
                  max-width: 500px;
                  white-space: normal;
                  //display: table;
                  @media (max-width: 500px) {
                    width: 100%;
                  }
                }
              }
              &.other {
                padding-left: 0px;
                width: 35%;
                .unit-name {
                  color: #007fa3;
                  font-size: 15px;
                  //display: table;
                }
                @media (max-width: 500px) {
                  display: none;
                }
              }
              &.empty:first-child {
                width: 35px;
                min-width: 35px;
                //min-width: 50px;
              }
              &.empty:last-child {
                width: 35px;
                text-align: center;
                font-size: 20px;
              }
              &.submitted {
                font-size: 14px;
                i {
                  font-size: 24px;
                  color: #84bd00;
                }
                @media (max-width: 500px) {
                  display: none;
                }
              }
              &.title {
                //width: 5%;
                font-weight: 600;
                color: #007fa3;
                padding-left: 0px;
                @media (max-width: 500px) {
                  padding-left: 8px;
                }
                div {
                  white-space: normal;
                  //max-width: 400px;
                  @media (max-width: 500px) {
                    //max-width: 200px;
                  }
                  &.study-group,
                  &.assessment {
                    display: flex;
                  }
                  min-height: 25px;
                  &.study-group {
                    i {
                      font-size: 20px;
                      line-height: initial;
                    }
                    div.name {
                      padding-left: 5px;
                    }
                  }
  
                  .order-number {
                    color: #000000;
                    font-size: 16px;
                    min-height: 25px;
                  }
                  .assessment-name {
                    padding-left: 15px;
                    font-size: 15px;
                    min-height: 22px;
                    margin: auto 0;
                    @media (max-width: 500px) {
                      padding-left: 5px;
                    }
                  }
                  &.unit-box {
                    display: flex;
  
                    > .unit-name {
                      padding-left: 15px;
                    }
                    .label {
                      color: #000000;
                      font-weight: 600;
                      //display: table;
                      white-space: nowrap;
                      min-height: 25px;
                    }
                  }
                }
              }
              &.center {
                width: 15%;
              }
              &.info {
                //width: 30%;
  
                .progress {
                  display: flex;
                  .text {
                    font-size: 14px;
                    color: #008638;
                  }
                  button {
                    margin-left: 25px;
                    @media (max-width: 500px) {
                      display: none;
                    }
                  }
                  .progress-bar {
                    margin: auto;
                    margin-left: 5px;
                    width: 100%;
                    @media (max-width: 500px) {
                      display: none;
                    }
                  }
                }
              }
              &.answer {
                font-size: 14px;
                padding-left: 0;
                @media (max-width: 500px) {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
    .bookseries_box {
      height: 150px;
  
      @media (max-width: 500px) {
        height: 70px;
      }
      .react-parallax {
        height: 100%;
      }
      .bookseries_logo {
        height: 150px;
  
        @media (max-width: 500px) {
          height: 60px;
        }
        img {
          max-width: 200px;
          margin: 27px;
          @media (max-width: 500px) {
            height: 60px;
            margin: 5px;
          }
        }
        text-align: center;
      }
    }
  }
  
  .card-top-borders {
    border-top: 14px solid #0088cf !important;
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
  }
  
  .view-result-student-name {
    color: #333333;
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 41px;
    margin-top: 20px;
    margin-left: 12px;
    @media only screen and (max-width: 1024px) and (min-width: 641px) {
      font-size: 20px;
      line-height: 27px;
    }
    @media only screen and (max-width: 640px) {
      font-weight: 800;
      font-size: 21px;
      line-height: 29px;
    }
  }
  
  
  .main__section-student-unit-grid {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 5px;
    padding: 13px;
    overflow-y: visible;
    overflow-x: scroll;
}
.main__section-student-unit-grid.result-page {
    grid-template-columns: 1fr 120px 1fr;
    overflow-x: scroll;
    width: 100%;
}

.main__section-student-unit-grid-details {
    border-collapse: separate;
    border-spacing: 0 10px;
    padding: 10px;
    border-radius: 10px;
}

.main__section-student-unit-grid-details-bottomheading-primary {
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    color: #333333;
}
.card th {
    border-top: 0;
    vertical-align: middle;
}
.main__section-student-unit-grid-details-bottom-row th {
    padding: 0 20px;
}
.main__section-student-unit-grid-details td:first-child, th:first-child {
    border-radius: 10px 0 0 10px;
    min-width: 75px;
}
.main__section-student-unit-grid-grade td:first-child, th:first-child {
    border-radius: 10px 0 0 10px;
}
.main__section-student-unit-grid-view td:first-child, th:first-child {
    border-radius: 10px 0 0 10px;
}
.main__section-student-unit-grid-grade td:last-child, th:last-child {
    border-radius: 0 10px 10px 0;
}
img.table-sorting-arrow {
    margin-left: 3.4px;
}
th.main__section-student-unit-grid-details-bottomheading-primary {
    white-space: nowrap;
    cursor: pointer;
}
.result-page-table-head-height {
    height: 38px;
}

.main__section-student-unit-grid-details-up-row {
    background: #136790;
    border-radius: 10px;
    font-style: italic;
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    color: #ffffff;
    padding: 15px;
    height: 55px;
}
.main__section-student-unit-grid-details td:first-child, th:first-child {
    border-radius: 10px 0 0 10px;
    min-width: 75px;
}
.main__section-student-unit-grid-details-up-row td {
    padding: 15px 20px;
}
.main__section-student-unit-grid-details-down-row {
    background: #ffffff;
    border-radius: 10px;
    font-style: normal;
    font-size: 14px;
    line-height: 19px;
    color: #333333;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 55px;
}
.main__section-student-unit-grid-details-down-row-number {
    font-weight: 400;
}
.main__section-student-unit-grid-details-down-row td {
    padding: 0 20px;
}
.main__section-student-unit-grid-details-down-row-name {
    font-weight: 800;
}
.main__section-student-unit-grid-details td:last-child, th:last-child {
    border-radius: 0 10px 10px 0;
}
.main__section-student-unit-grid-grade {
    background: #198cc5;
    border-radius: 10px;
    border-collapse: separate;
    border-spacing: 0 10px;
    padding: 10px;
}
.main__section-student-unit-grid-grade-bottom-row {
    text-align: center;
}
.main__section-student-unit-grid-grade.overall-table td:first-child, .main__section-student-unit-grid-grade.overall-table th:first-child {
    border-radius: 10px 10px 10px 10px;
}
th.main__section-student-unit-grid-details-bottomheading {
    white-space: nowrap;
    cursor: pointer;
}
.main__section-unit-grid-details-progress {
    width: 39px;
    height: 39px;
    border: 7px solid #e0e0e0;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 39px;
}
.main__section-unit-grid-details-progress-bar {
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #a7a7a7;
}
.main__section-student-unit-grid-grade-down-row {
    background: #ffffff;
    border-radius: 10px;
    font-style: normal;
    font-size: 14px;
    line-height: 19px;
    color: #333333;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    color: #333333;
    height: 55px;
}
.main__section-student-unit-grid-grade-up-row {
    background: #136790;
    border-radius: 10px;
    font-style: italic;
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    color: #ffffff;
    padding: 15px;
    height: 55px;
    text-align: center;
}
.school_activities_table {
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    min-width: 100%;
}

.main__section-student-unit-grid-view-up-row {
    background: #136790;
    border-radius: 10px;
    font-style: italic;
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    color: #ffffff;
    padding: 15px;
    text-align: center;
    height: 55px;
}
*, *::before, *::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.progress-bar-center-position {
    text-align: -webkit-center;
}

@media only screen and (max-width: 641px) {
  .question__container {
    flex-direction: column;
  }
  .question__head{
    color: #333;
    font-size: 14px;
  }
  .question__numbers{
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
}

.suggestions__mainsection{
	/*max-width: 1256px;
    width: 98%;*/
	background: #fff;
    border-radius: 10px;
    padding-top: 26px;
    padding-left: 30px;
    padding-right: 50px;
    padding-bottom: 28px;
}
.score_label_up_content{
	// display: flex;
    // align-items: center;
    // justify-content: space-between;

    display: grid;
    grid-template-columns: 1fr 100px;
}
.score_label_main_content{
	display: flex;
	flex-direction: column;
}
.score_label_sub_content-text-head{
	color: #333;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}
.score_label_sub_content-text{
	color: #333;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
	margin-top: 10px;
}
.score_label_sub_content{
	// display: flex;
    // align-items: center;
    // justify-content: space-between;

    // display: grid;
    // grid-template-columns: 1fr 33px;
    // justify-content: space-between;
    // gap: 5px;
    display: grid;
    grid-template-columns: 1fr 100px;
}
.score_label_sub_content_border{
	border-bottom: 1px solid #DCDCDC;
    align-self: stretch;
    margin-bottom: 10px;
}
.student_top_section_question_no{
    color: #333;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.student_accordion_arrow{
    background-image: url("../../../../../../assets/img/pec/all-images/arrow-accordion.svg") !important;
    background-repeat: no-repeat;
    background-size: cover;
    height: 7px;
    width: 12px;
    display: inline-block;
    margin-left: 15px;
    filter: contrast(0.3);
    transform: rotate(180deg);
    position: relative;
    top: 8px;
}
.student_bottom_section{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    min-height: 126px;
    margin-top: 20px;
    grid-column: 3/1;

    &_details{
        border-radius: 10px;
        background: #FFF;
        display: flex;
        padding: 10px;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    &_name{
        color: #9A9A9A;
        font-size: 14px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
    }

    &_count{
        color: #333;
        text-align: center;
        font-size: 30px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
    }
}
.student_bottom_section_updated {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  min-height: 126px;
  margin-top: 20px;
  grid-column: 3/1;
  padding-left: 10px;
  padding-right: 10px;
}



.student_bottom_section_updated_details {
  border-radius: 10px;
  background: #FFF;
  display: flex;
  padding: 10px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}



.student_bottom_section_updated_name {
  color: #9A9A9A;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}



.student_bottom_section_updated_count {
  color: #333;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.count-black{
    color: #333;
}
.count-green{
    color: #008638;
}
.count-red{
    color: #CA2C2E;
}
.student_top_section_box {
    height: 70px;
    width: 93px;
    color: #fff;
    padding: 10px;
    border: solid 1px #E0E0E0;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 641px) {
  .student_bottom_section_updated_details {
    flex-direction: row;
    justify-content: space-between;
}



.sstudent_bottom_section_updated_name {
    margin-left: 10px;
}



.student_bottom_section_updated_count {
    margin-right: 10px;
}



.student_bottom_section_updated {
    grid-row: 2/2;
    display: flex;
    flex-direction: column;
}
    .student_top_section{
        // flex-direction: column;
        // align-items: center;
        grid-template-rows: 250px 1fr 1fr;
        grid-template-columns: 1fr;
    }
    .student_top_section_left{
        grid-row: 1/1;
        margin: 0 auto;
    }
    .student_bottom_section{
        // grid-template-columns: 1fr; 
        grid-row: 2/2;
        display: flex;
        flex-direction: column;
    }
    .student_top_section_right_question{
        display: grid;
        grid-template-columns: 160px 160px;
        justify-content: center;
        // justify-items: center;
        gap: 20px;
    }
    .student_top_section_right{
        background: none;
        grid-row: 3/3;
    }
    .student_top_section_right_question_section{
        display: flex;
        background: #fff;
        border-radius: 10px;
    }
    .student_bottom_section_details{
        flex-direction: row;
        justify-content: space-between;
    }
    .student_bottom_section_name{
        margin-left: 10px;
    }
    .student_bottom_section_count{
        margin-right: 10px;
    }
    .score_label_center{
        justify-self: center;
    }
}
.main__section-student-unit-grid-red{
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  background: #CA2C2E;
  border-radius: 50%;
}
.main__section-student-unit-grid-green{
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  background: #008638;
  border-radius: 50%;
}
.main__section-student-unit-grid-grey{
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  background: #A8A8A8;
  border-radius: 50%;
}
.learnosity-scroll-height::-webkit-scrollbar {
  height: 6px !important;
}