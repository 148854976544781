.be-container-prizm{
    //height: calc(100% - 50px);
    height: 100%;
    display: flex;
    width: 100%;
    flex-direction: column;
    flex:1;
    // margin-top: 20px;
    margin-top: 11px;
    #prizm.card{
        height: 100%;
    }
}

@media only screen and (max-width: 640px) {
    .be-container-prizm{
        margin-top: 5px;
    }
}