#assigned-students-info {
  .modal-body {
    padding: 0;
    .assigned-viewable-student {
      padding: 13px 40px 0px 40px;
      @media (max-width: 599px) {
        // padding: 0 8px;
        padding: 0px 16px 0px 16px;
      }
      border-bottom: 1px solid #007fa3;
      background-color: #fff;
      ul {
        padding-top: 4px;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        display: flex;
        min-height: 45px;
        li {
          // height: 45px;
          > div {
            cursor: pointer;
            display: block;
            height: 100%;
            padding: 10px 8px;
            font-size: 14px;
            font-weight: 600;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            color: #007fa3;
            &.active {
              background-color: #007fa3;
              color: #fff;
            }
          }
        }
      }
    }
    .assigned-scroll {
      overflow-y: scroll;
      position: relative;
      height: 300px;
      .assigned-students-list {
        table {
          thead {
            tr {
              height: 40px;
              th {
                cursor: pointer;
                padding: 0 5px;
                &.students-list {
                  width: 85%;
                  @media (max-width: 375px) {
                    width: 71%;
                  }
                  padding: 0px 40px;
                  @media (max-width: 599px) {
                    padding: 0px 16px;
                  }
                }
              }
            }
          }
          tbody {
            tr {
              td {
                &.assigned_students_table_list {
                  display: flex;
                  .assigned_students_table_classnumber {
                    padding-right: 0px;
                    min-width: 28px;
                  }
                  // padding: 6px 19px;
                  padding: 6px 40px;
                  @media (max-width: 599px) {
                    padding: 6px 16px;
                  }
                  font-size: 16px;
                }
                padding-left: 2px;
              }
            }
          }
        }
      }

      .related-assignment-list {
        table {
          width: 100%;
          thead {
            tr {
              height: 40px;
              th {
                &.related-assignment {
                  padding-left: 20px;
                  width: 25%;
                }
                &.related-assignment-date {
                  width: 15%;
                }
                &.related-assignment-to {
                  width: 35%;
                }
                &.related-assignment-due-date {
                  width: 15%;
                }
                &.related-assignment-read {
                  width: 15%;
                }
              }
            }
            th {
              cursor: pointer;
            }
          }
        }
        tbody {
          tr {
            td {
              cursor: pointer;
              &.related_assignment_name {
                color: #007fa3;
                padding-left: 20px;
              }
              .assign_views_number {
                cursor: pointer;
                font-size: 15px;
                display: flex;
                padding: 0px 10px;
                width: 91px;
                height: 28px;
                border-radius: 14px;
                background-color: #007fa3;
                .people_icn {
                  padding-top: 2px;
                  margin: auto;
                }
                .views_number_sub {
                  margin: auto;
                  font-size: 15px;
                  font-weight: 600;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  color: #ffffff;
                }
              }
            }
          }
        }
        .related_assignment_title_moble {
          width: 100%;
          height: 40px;
          font-size: 14px;
          color: #000000;
          background-color: transparent;
          border-color: grey;
          background-image: linear-gradient(to bottom, #f0f0f0, #dddddd);
          padding: 10px 17px;
          vertical-align: middle;
          font-weight: 600;
        }

        .notification-item {
          width: 100%;
          min-height: 87px;
          background-color: #fafafa;
          padding: 11px 16px;
          border-width: 1px;
          border-bottom-width: 1px;
          border-bottom-color: #e7e6e5;
          border-bottom-style: solid;
          position: relative;
          .new-assignment {
            width: 16px;
            height: 100%;
            position: absolute;
            display: flex;
            right: 15px;
            top: 0;
            img {
              width: 16px;
              height: 16px;
              object-fit: contain;
              margin: auto;
            }
          }
          .notification-item-header {
            width: 90%;
            min-height: 22px;
            font-size: 16px;
            color: #005a70;

            font-weight: 600;
            display: block;
          }
          .notification-item-body {
            width: 90%;
            min-height: 17px;

            font-size: 12px;
            display: block;

            color: #505759;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .notification-item-views {
            width: 100%;
            min-height: 17px;
            font-size: 12px;
          }
          .notification-item-footer {
            width: 100%;
            min-height: 17px;

            font-size: 12px;
            display: block;

            color: #505759;
          }
        }
      }
    }
  }
}
