.progress-bar {
  position: relative;
  width: 81px;
  height: 7px;
  border-radius: 2px;
  background-image: linear-gradient(to top, #eeeeee, #c8c8c8);

  .filler {
    background-image: linear-gradient(to bottom, #00bb69, #008638);
    height: 100%;
    border-radius: inherit;
    transition: width 0.2s ease-in;
  }
}
