#report_body.b2c {
  .body {
    .b2c_assessments {
      padding: 0 30px;
      @media (max-width: 768px) {
        padding: 0 16px;
      }
      select {
        min-width: 260px;
        font-size: 16px;
        // width: 100%;
        padding: 5px 50px 5px 15px;
        border-radius: 8px;
        // background-image: linear-gradient(to bottom, #cffafc, #cffafc);
        background-color: white;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgMjEzLjMzMyAyMTMuMzMzIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyMTMuMzMzIDIxMy4zMzM7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxnPg0KCQk8cG9seWdvbiBwb2ludHM9IjAsNTMuMzMzIDEwNi42NjcsMTYwIDIxMy4zMzMsNTMuMzMzIAkJIi8+DQoJPC9nPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=");
        background-repeat: no-repeat;
        background-size: 12px;
        background-position: 93% 50%;
        border: 1px solid #505759;

        option {
          font-size: 16px;
          // background: var(--selectDropdownOptionColor);
          &:checked {
            background-color: var(--selectDropdownOptionColor);
          }
        }
      }
      .score-identifier {
        display: flex;
        margin-top: 15px;
        justify-content: flex-end;

        .score-label {
          font-size: 14px;
          color: #000000;
          line-height: 15px;
          margin: 0px 30px 0 10px;
        }
        .score-label2 {
          font-size: 14px;
          color: #000000;
          line-height: 15px;
          margin: 0px 0px 0 10px;
        }

        .my-circle {
          height: 15px;
          width: 15px;
          background-color: #12b2a6;
          border-radius: 50%;
          display: inline-block;
        }
        .other-circle {
          height: 15px;
          width: 15px;
          background-color: #ffbb1c;
          border-radius: 50%;
          display: inline-block;
        }
      }
      .notification {
        font-size: 16px;
        color: #505759;
        margin-bottom: 20px;
      }
      .chart-analyis {
        margin: 15px 0px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        border-radius: 20px;
        &.nochart {
          height: 550px;
        }
        .nochart-data {
          text-align: center;
          p {
            font-size: 16px;
          }
        }
        .panel-heading {
          width: 100%;
          border-top-right-radius: 20px;
          border-top-left-radius: 20px;
          height: 68px;
          background-color: #94e7ea;
          .title {
            font-size: 24px;
            font-weight: 600;
            text-align: center;
            color: #000000;
            padding-top: 20px;
          }
        }
        .chartSection {
          display: flex;
          margin: 0 20px;

          @media (max-width: 1000px) {
            flex-wrap: wrap;
            flex-direction: column;
          }
          .center-line-div {
            width: 4%;

            @media (max-width: 1000px) {
              display: none;
            }
            .center-line {
              width: 1px;
              height: 540px;
              margin: auto;
              border: solid 1px #dfe1e1;
              margin-top: 20px;
            }
          }
          .heading {
            margin-top: 30px;
            @media (max-width: 599px) {
              margin-top: 14px;
            }
          }
          .radardiv {
            width: 48%;
            @media (max-width: 1000px) {
              width: 100%;
            }
            //margin: auto;

            .line {
              margin: auto;
              height: 1px;

              margin-top: 8px;

              // min-width: 410px;
              border: solid 1px #94e7ea;
            }
            .chart-title {
              margin: auto;
              font-size: 19px;
              text-align: center;
              color: #000000;
              // min-width: 410px;
              padding: 10px 0px 10px 0px;
            }
            .chart-view {
              margin-top: 30px;
              @media (max-width: 599px) {
                padding: 10px;
                max-height: 268px;
              }
            }
          }

          .bardiv {
            width: 48%;
            //height: 500px;
            @media (max-width: 1000px) {
              width: 100%;
            }
            //margin: auto;
            .line {
              margin: auto;
              height: 1px;

              margin-top: 8px;

              //min-width: 410px;
              border: solid 1px #94e7ea;
            }
            .chart-title {
              margin: auto;
              font-size: 19px;
              text-align: center;
              color: #000000;
              //min-width: 410px;
              padding: 10px 0px 10px 0px;
            }
            .chart-view {
              margin-top: 30px;
              .score-order {
                font-size: 16px;
                color: #000000;
                text-align: end;
                > select {
                  margin-left: 10px;
                  min-width: 150px;
                }
              }
            }
            .subchart {
              display: flex;
              .chartdiv {
                width: 80%;
                @media (max-width: 500px) {
                  width: 98%;
                }
              }
              .arrow {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                width: 20%;
                align-items: flex-end;
                @media (max-width: 500px) {
                  width: 2%;
                  align-items: center;
                }
                .top-arrow {
                  float: right;
                  cursor: pointer;
                  &.active {
                    color: #12b2a6;
                  }
                  &.inactive {
                    color: #dfe1e1;
                    pointer-events: none;
                  }
                  .mdi {
                    font-size: 60px;
                  }
                }
                .down-arrow {
                  margin-top: 10px;
                  cursor: pointer;
                  float: right;
                  &.active {
                    color: #12b2a6;
                  }
                  &.inactive {
                    color: #dfe1e1;
                    pointer-events: none;
                  }
                  .mdi {
                    font-size: 60px;
                  }
                }
              }
            }
          }
        }
      }
      .numbergroup {
        display: flex;
        margin: 0px auto;
        //max-width: 1280px;
      }

      .numberbox {
        display: flex;
        height: 80px;
        border-radius: 6px;
        margin-top: 30px;
        width: 100%;
      }
      .numberbox-self {
        background-color: #12b2a6;
        margin-right: 20px;
      }

      .inactiveself {
        background-color: #dfe1e1;
        margin-right: 20px;
      }
      .inactiveall {
        background-color: #dfe1e1;
        margin-right: 20px;
      }
      .numberbox-all {
        background-color: #ffbb1c;
      }
      .numberbox-text {
        display: flex;
        font-size: 20px;
        font-weight: 600;
        font-family: sans-serif;
        align-items: center;
        flex-grow: 1;
        justify-content: center;
      }
      .character-area {
        width: 85px;
        flex-shrink: 0;
        position: relative;
      }
      .character-area img {
        position: absolute;
        top: -30px;
        left: 15px;
      }
      .titletext {
        font-size: 20px;
        font-weight: 600;
        color: #000000;
      }
      .scoretext {
        font-size: 52px;
        margin: 0px 16px 0px 8px;
        width: 80px;
        text-align: left;
        flex: 0;
        color: #000000;
        font-weight: 600;
      }
      @media only screen and (max-width: 600px) {
        .numbergroup {
          flex-direction: column;
        }
        .character-area {
          width: 80px;
        }
        .character-area img {
          left: 5px;
        }
        .numberbox-text {
          font-size: 18px;
          justify-content: space-around;
        }
        .titletext {
          flex: 1;
        }
        .scoretext {
          text-align: left;
        }
      }

      .score-div {
        display: flex;

        margin-bottom: 15px;

        @media (max-width: 900px) {
          flex-wrap: wrap;
        }

        .report-my-score {
          position: relative;
          margin-right: 20px;
          display: flex;
          width: 50%;
          height: 80px;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
          @media (max-width: 900px) {
            width: 100%;
            margin: 0px;
          }
          &.active {
            background-color: #12b2a6;
          }
          &.inactive {
            background-color: #dfe1e1;
          }

          .titlediv {
            width: 50%;
            text-align: center;
            @media (max-width: 900px) {
              text-align: left;
            }
            @media (max-width: 500px) {
              text-align: left;
              padding: 10px 0px 0px 30px;
            }
          }
          font-size: 20px;
          font-weight: 600;
          color: #000000;
          .c-img {
            position: absolute;
            z-index: 1;
            top: -30px;
            left: 20px;
            display: flex;
          }
          .score-title {
            font-size: 20px;
            font-weight: 600;
          }
          .score-val {
            vertical-align: text-top;
            font-size: 52px;
            font-weight: 600;
            padding-left: 10px;
          }
        }
        .my-score1 {
          width: 50%;
          display: flex;

          font-size: 20px;
          font-weight: 600;
          color: #000000;
          position: relative;
          height: 80px;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
          @media (max-width: 900px) {
            width: 100%;
            margin-top: 40px;
          }

          .othertitlediv {
            width: 50%;
            text-align: center;
            @media (max-width: 900px) {
              text-align: left;
            }
            @media (max-width: 500px) {
              text-align: left;
              padding: 10px 0px 0px 30px;
            }
          }

          .c-img {
            position: absolute;
            z-index: 1;
            top: -30px;
            left: 20px;
            display: flex;
          }
          &.active {
            background-color: #ffbb1c;
          }
          &.inactive {
            background-color: #dfe1e1;
          }
          .score-title {
            font-size: 20px;
            font-weight: 600;
          }
          .score-val {
            font-size: 52px;
            font-weight: 600;
            vertical-align: text-top;
            padding-left: 10px;
          }
        }
      }

      .options-row {
        display: flex;
        justify-content: flex-end;
        margin: 6px 0px;
        @media (min-width: 599px) {
          margin: 20px 0px;
        }
        @media (max-width: 768px) {
          padding: 10px 0px;
        }
        .order-selection {
          position: relative;
          // max-width: 210px;
          display: flex;
          height: fit-content;
          min-height: 38px;
          .border {
            position: absolute;
            right: 15%;
            top: 5px;
            bottom: 5px;
            border-style: solid;
            border-color: transparent;
            border-left: solid 1px #505759;
          }
          select {
            min-width: 260px;
            font-size: 16px;
            // width: 100%;
            padding: 5px 50px 5px 15px;
            border-radius: 8px;
            // background-image: linear-gradient(to bottom, #cffafc, #cffafc);
            background-color: white;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;

            background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgMjEzLjMzMyAyMTMuMzMzIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyMTMuMzMzIDIxMy4zMzM7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxnPg0KCQk8cG9seWdvbiBwb2ludHM9IjAsNTMuMzMzIDEwNi42NjcsMTYwIDIxMy4zMzMsNTMuMzMzIAkJIi8+DQoJPC9nPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=");
            background-repeat: no-repeat;
            background-size: 12px;
            background-position: 93% 50%;
            border: 1px solid #505759;

            option {
              font-size: 16px;
              // background: var(--selectDropdownOptionColor);
              &:checked {
                background-color: var(--selectDropdownOptionColor);
              }
            }
          }
        }
      }
      .line {
        border-bottom: solid 1px #dfe1e1;
      }
      .group-row {
        display: flex;
        padding: 10px 25px;
        @media (max-width: 768px) {
          padding: 10px 0px;
        }
        @media (max-width: 500px) {
          flex-wrap: wrap;
        }
        .title-block {
          padding: 10px;
          max-width: 160px;
          /* max-height: 100px; */
          min-height: 100px;
          height: 100%;
          width: 100%;
          display: flex;
          @media (max-width: 500px) {
            max-width: 100%;
          }
          > div {
            text-align: center;
            margin: auto;
            font-size: 24px;
            font-weight: bold;
            color: var(--normalTextColor);
            @media (max-width: 500px) {
              text-align: left;
              margin: auto 0;
            }

            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
        .list-block {
          padding: 0 15px;
          width: 100%;
          @media (max-width: 500px) {
            padding: 0;
          }

          ul {
            padding: 0;
            margin: 0;
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            li {
              margin: 10px 10px 10px 10px;
              @media (max-width: 500px) {
                margin: 10px 3% 10px 3%;
                width: 44%;
              }
              .card {
                display: flex;
                width: 160px;
                height: 100px;
                border-radius: 5px;
                box-shadow: 0 1px 2px 0 rgba(0, 0, 1, 0.2);
                background-color: var(--assessmentCardColor);
                @media (max-width: 500px) {
                  width: 100%;
                }
                > div {
                  display: -webkit-box;
                  -webkit-line-clamp: 3;
                  -webkit-box-orient: vertical;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  margin: auto;
                  font-size: 16px;
                  position: relative;
                  width: 140px;
                  text-align: center;
                  max-height: 68px;
                  color: var(--assessmentCardTextColor);
                }
              }
              .score-lable {
                display: flex;
                padding-top: 10px;
                .lable {
                  font-size: 20px;
                  font-weight: bold;
                  color: var(--scoreLabelTextColor);
                  display: flex;
                  margin: auto;
                  @media (max-width: 500px) {
                    font-size: 16px;
                  }
                  .score {
                    font-size: 22px;
                    color: var(--scoreTextColor);
                    margin: auto;
                    line-height: 22px;
                    padding-left: 5px;
                  }
                }
              }
              &:hover {
                .card {
                  background-color: var(--assessmentCardHoverColor);
                }
              }
            }
          }
        }
      }
    }
  }
}
