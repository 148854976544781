#class_body.b2c .body .b2c_assessment_list {
  margin-top: -55px;
  .box-container {
    display: flex;
    flex-wrap: wrap;
    padding: 0 2px;
    @media (min-width: "599px") {
      margin: 15px;
    }
    .cell {
      display: flex;
      flex-direction: column;
      margin: 15px 12px;
      min-width: 304px;
      width: 100%;
      box-shadow: 0 0 4px 0 #b2b0b0;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      border-top-right-radius: 15px;
      border-top-left-radius: 15px;
      overflow: hidden;
      @media (min-width: "1024px") {
        &[data-count="3"] {
          width: calc((100% - 72px) / 3);
        }
        &[data-count="2"] {
          width: calc((100% - 48px) / 2);
        }
      }
      @media (min-width: "768px") {
        &[data-count="2"] {
          width: calc((100% - 48px) / 2);
        }
      }
      @media (max-width: "599px") {
        margin: 15px 6px;
      }
      &[data-count="1"] {
      }
      > .title {
        padding: 11px 0px;

        background-color: #ffbb1c;
        text-align: center;
        color: #000000;
        font-size: 16px;
        font-weight: 600;
      }
      > .content {
        padding: 20px 17px 20px 17px;
        .img_w_text {
          cursor: pointer;
          padding: 19px 0;
          text-align: center;
          font-size: 22px;
          font-weight: 600;
          color: #000000;
          border-bottom: 1px solid #dfe1e1;
          img {
            margin: 6px auto;
            width: 120px;
          }
          &:hover {
            color: var(--itemHoverColor);
          }
        }
        .item {
          cursor: pointer;
          padding: 20px 0;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #dfe1e1;
          > .title {
            font-size: 16px;
            margin: auto 0;
            flex: 1;
            // max-width: 250px;
          }
          > i {
            margin: auto 0;
            font-size: 22px;
            padding-left: 20px;
          }
          &:hover {
            .title {
              color: var(--itemHoverColor);
            }
          }
        }
      }
    }
  }
}
