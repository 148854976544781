#report_body.b2c {
  //themes
  --normalTextColor: #000000;
  --bannerBGcolor: #e1f6f7;
  --bannerTitleTextColor: #000000;
  --scoreLabelTextColor: #000000;
  --scoreTextColor: #9b007e;
  --bodyBGColor: #fff;
  --assessmentCardColor: #ffffff;
  --assessmentCardHoverColor: #ffbb1c;
  --assessmentCardTextColor: #000000;
  --selectDropdownOptionColor: #d2db0e;
  --itemHoverColor: #9b007e;
  // --selectDropdownOptionHoverColor: #d2db0e;
  //tab one
  --TabColor: #008174;
  --TabActiveColor: #94e7ea;
  --TabLabelColor: #a5dad6;
  --TabLabelActiveColor: #000000;
  --TabHoverColor: #12b2a6;

  // //tab two
  // --TabTwoColor: #f57f17;
  // --TabTwoLabelColor: #ffeaa6;
  // --TabTwoHoverColor: #f9a825;

  &[data-theme="theme_1"] {
  }
  &[data-theme="theme_2"] {
  }

  //common
  // max-width: 1360px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  .top-section {
    min-height: 150px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto;
    background-color: var(--bannerBGcolor);
    .content-center {
      //   background-color: yellow;
      min-height: 95px;
      display: flex;

      max-width: 1360px;
      margin: auto;
      > div {
        margin: auto;
        font-size: 30px;
        font-weight: 600;
        color: var(--bannerTitleTextColor);
        max-width: 400px;
        text-align: center;
        overflow-wrap: break-word;
        @media (max-width: 500px) {
          font-size: 21px;
        }
      }
    }
    .menu {
      min-height: 50px;
      //   background-color: aquamarine;
      // &.assessment {
      border-bottom: 6px solid var(--TabActiveColor);
      // }
      // &.exp_videos {
      // border-bottom: 6px solid var(--TabColor);
      // }
      &.hide {
        background-color: white;
        height: 100%;
        border: none;
      }
      ul {
        padding: 0;
        list-style: none;
        display: flex;
        margin: 0;
        min-height: 50px;
        overflow: hidden;
        padding: 5px 0px 0px 0px;

        max-width: 1360px;
        margin: auto;
        li {
          max-width: 50%;
          width: 100%;
          //   margin: auto;
          text-align: center;
          cursor: pointer;
          border-top-right-radius: 15px;
          border-top-left-radius: 15px;
          box-shadow: 2px 0 4px 0 #b2b0b0;
          background-color: white;
          // &.assessment {
          //   background-color: var(--TabColor);
          //   color: var(--TabLabelColor);
          // }
          // &.exp_videos {
          background-color: var(--TabColor);
          color: var(--TabLabelColor);
          &:hover {
            background-color: var(--TabHoverColor);
            color: var(--TabLabelColor);
          }
          // }
          > div {
            margin: auto;
            width: fit-content;
            height: 100%;
            display: flex;
            padding: 0px;
            img {
              width: 40px;
              height: 40px;
              margin: auto;
            }
            .title {
              margin: auto;
              // padding-left: 10px;
              // color: #505759;
              font-size: 20px;
              @media (max-width: 768px) {
                font-size: 16px;
              }
            }
          }
          &.active {
            // &.assessment {
            background-color: var(--TabActiveColor);
            //   &:hover {
            //     > div {
            .title {
              color: var(--TabLabelActiveColor);
              font-weight: 600;
            }
            //     }
            //   }
            // }
            // &.exp_videos {
            //   background-color: var(--TabTwoColor);
            //   &:hover {
            //     > div {
            //       .title {
            //         // color: #fff;
            //       }
            //     }
            //   }
            // }

            // > div {
            // .title {
            // color: var(--normalTextColor);
            // }
            // }
          }
        }
      }
    }
  }
  .body {
    flex: 1;
    background-color: var(--bodyBGColor);
    & > div {
      max-width: 1360px;
      margin: auto;
    }
  }
}
