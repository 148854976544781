/*------------------------------------------------------------------
  [Select2]
*/
.input-group .select2-container--default {
	position: relative;
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	width: 1% !important;
}

.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--multiple {
	border: 0.07692rem solid #d5d8de;
	border-radius: 2px;
}

.select2-container--default .select2-selection--single:focus,
.select2-container--default .select2-selection--multiple:focus {
	border-color: #4285f4;
	outline: 0;
}

.select2-container--default .select2-selection--single {
	height: 3.692rem;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
	padding: 0 15px;
	height: 3.53816rem;
	line-height: 3.53816rem;
	font-size: 1.077rem;
	color: #404040;
}

.select2-container--default .select2-selection--single .select2-selection__rendered .select2-selection__clear {
	right: 25px;
	font-size: 1.538rem;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 3.5382rem;
	width: 30px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
	border: 0;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	margin: 0;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b:after {
	content: "\f2f9";
	font-family: "Material Icons";
	font-size: 1.923rem;
	font-weight: normal;
	line-height: 3.5382rem;
	color: #404040;
}

.select2-container--default .select2-selection--multiple {
	min-height: 3.53816rem;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
	margin-top: 8px;
	margin-right: 0;
	font-size: 1.538rem;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
	padding: 4px 12px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
	border-radius: 0;
	background-color: #f2f2f2;
	color: #5a5a5a;
	border-width: 0;
	padding: 4px 6px;
	line-height: 18px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
	color: #666666;
	margin-right: 3px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
	color: #4d4d4d;
}

.select2-container--default .select2-selection--multiple .select2-search--inline .select2-search__field {
	line-height: 2rem;
	font-family: "Roboto", Arial, sans-serif;
	font-size: 1.077rem;
	color: #999;
}

.select2-container--default.select2-container--default.select2-container--focus .select2-selection--multiple {
	border: 0.07692rem solid #d5d8de;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b:after {
	content: "\f2f9";
}

.select2-container--default .select2-results__group {
	font-size: 0.9231rem;
	color: #5a5a5a;
}

.select2-container--default .select2-results__option {
	padding: 10px 6px;
}

.select2-container--default .select2-results__option[aria-selected="true"] {
	background-color: #f7f7f7;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #007fa3;
}

.select2-container--default .select2-dropdown {
	border-width: 0.07692rem;
	border-color: #d5d8de;
}

.select2-container--default .select2-dropdown--above {
	border-radius: 2px 2px 0 0;
	-webkit-box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.12);
	box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.12);
}

.select2-container--default .select2-dropdown--below {
	border-radius: 0 0 2px 2px;
	-webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
}

.select2-container--default .select2-search--dropdown {
	background-color: #f2f3f4;
	border-bottom: 0.07692rem solid #d5d8de;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
	background-color: transparent;
	border-width: 0;
	outline: none;
}

.select2.select2-lg + .select2-container--default,
.select2.input-lg + .select2-container--default {
	padding: 0;
}

.select2.select2-lg + .select2-container--default .select2-selection--single,
.select2.input-lg + .select2-container--default .select2-selection--single {
	height: 4.385rem;
	padding: 5px 0;
	font-weight: 300;
	border-radius: 2px;
}

.select2.select2-lg + .select2-container--default .select2-selection--single .select2-selection__rendered,
.select2.input-lg + .select2-container--default .select2-selection--single .select2-selection__rendered {
	height: 4.385rem;
	font-size: 1.385rem;
}

.select2.select2-lg + .select2-container--default .select2-selection--single .select2-selection__arrow,
.select2.input-lg + .select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 4.385rem;
}

.select2.select2-lg + .select2-container--default .select2-selection--single .select2-selection__arrow b:after,
.select2.input-lg + .select2-container--default .select2-selection--single .select2-selection__arrow b:after {
	line-height: 4.385rem;
}

.select2.select2-sm + .select2-container--default,
.select2.input-sm + .select2-container--default {
	padding: 0;
}

.select2.select2-sm + .select2-container--default .select2-selection--single,
.select2.input-sm + .select2-container--default .select2-selection--single {
	height: 2.846rem;
	padding: 0;
}

.select2.select2-sm + .select2-container--default .select2-selection--single .select2-selection__rendered,
.select2.input-sm + .select2-container--default .select2-selection--single .select2-selection__rendered {
	height: 2.846rem;
	line-height: 2.692rem;
	font-size: 1rem;
}

.select2.select2-sm + .select2-container--default .select2-selection--single .select2-selection__arrow,
.select2.input-sm + .select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 2.846rem;
}

.select2.select2-sm + .select2-container--default .select2-selection--single .select2-selection__arrow b:after,
.select2.input-sm + .select2-container--default .select2-selection--single .select2-selection__arrow b:after {
	line-height: 2.846rem;
}

.select2.select2-xs + .select2-container--default,
.select2.input-xs + .select2-container--default {
	padding: 0;
}

.select2.select2-xs + .select2-container--default .select2-selection--single,
.select2.input-xs + .select2-container--default .select2-selection--single {
	height: 2.308rem;
	padding: 0;
}

.select2.select2-xs + .select2-container--default .select2-selection--single .select2-selection__rendered,
.select2.input-xs + .select2-container--default .select2-selection--single .select2-selection__rendered {
	height: 2.308rem;
	line-height: 2.231rem;
	font-size: .9231rem;
}

.select2.select2-xs + .select2-container--default .select2-selection--single .select2-selection__arrow,
.select2.input-xs + .select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 2.308rem;
}

.select2.select2-xs + .select2-container--default .select2-selection--single .select2-selection__arrow b:after,
.select2.input-xs + .select2-container--default .select2-selection--single .select2-selection__arrow b:after {
	line-height: 2.1rem;
}

.input-group.input-group-lg .select2-container--default {
	padding: 0;
}

.input-group.input-group-lg .select2-container--default .select2-selection--single {
	height: 4.385rem;
	padding: 5px 0;
	font-weight: 300;
	border-radius: 2px;
}

.input-group.input-group-lg .select2-container--default .select2-selection--single .select2-selection__rendered {
	height: 4.385rem;
	font-size: 1.385rem;
}

.input-group.input-group-lg .select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 4.385rem;
}

.input-group.input-group-lg .select2-container--default .select2-selection--single .select2-selection__arrow b:after {
	line-height: 4.385rem;
}

.input-group.input-group-sm .select2-container--default {
	padding: 0;
}

.input-group.input-group-sm .select2-container--default .select2-selection--single {
	height: 2.846rem;
	padding: 0;
}

.input-group.input-group-sm .select2-container--default .select2-selection--single .select2-selection__rendered {
	height: 2.846rem;
	line-height: 2.692rem;
	font-size: 1rem;
}

.input-group.input-group-sm .select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 2.846rem;
}

.input-group.input-group-sm .select2-container--default .select2-selection--single .select2-selection__arrow b:after {
	line-height: 2.846rem;
}

.input-group.input-group-xs .select2-container--default {
	padding: 0;
}

.input-group.input-group-xs .select2-container--default .select2-selection--single {
	height: 2.308rem;
	padding: 0;
}

.input-group.input-group-xs .select2-container--default .select2-selection--single .select2-selection__rendered {
	height: 2.308rem;
	line-height: 2.231rem;
	font-size: .9231rem;
}

.input-group.input-group-xs .select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 2.308rem;
}

.input-group.input-group-xs .select2-container--default .select2-selection--single .select2-selection__arrow b:after {
	line-height: 2.1rem;
}
