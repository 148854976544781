.switch {
  position: relative;
   display: inline-block;
   width: 62px;
   height: 24px;
   line-height: 24px;
  .on,
  .off {
      z-index: 1;
    position: absolute;
    font-size: 14px;
    font-weight: bold;
    top:-1px;
    color: #fff;
    letter-spacing: normal;
    cursor: pointer;
    line-height: 24px;
  }
  .on {
    left: 8px;
  }

  .off {
    right: 8px;
  }

  
  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      border: solid 1px #6e9d00;
      background-color: #84bd00;
    }

    &:focus + .slider {
      box-shadow: 0 0 1px #6e9d00;
    }

    &:checked + .slider:before {
      -webkit-transform: translateX(40px);
      -ms-transform: translateX(40px);
      transform: translateX(40px);
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: solid 1px #ff3855;
    background-color: #ff5c74;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    &:before {
      position: absolute;
      z-index: 2;
      content: "";
      height: 30px;
      width: 30px;
      left: -6px;
      top: -5px;
      -webkit-transition: 0.4s;
      transition: 0.4s;

      background-image: linear-gradient(to bottom, #e6e6e6, #c9c9c9);
      border: 3px solid #fff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    }
    &.round {
      border-radius: 12px;
    }
    &.round:before {
      border-radius: 50%;
    }
  }
}
