.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.header-left {
  flex-basis: 50%;
  display: flex;
  flex-direction: row;
}
.sec-1 {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #505759;
}
.sec-2 {
  border: 1px solid #979797;
  transform: rotate(180deg);
  margin-left: 9px;
  margin-right: 13px;
}
.header-right {
  flex-basis: 50%;
  display: flex;
  flex-direction: row;
  justify-content: end;
}
.sec-1-l {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}
.sec-2-l {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.sec-3-l {
  margin-left: 29px;
  margin-right: 4px;
}
.banner-container {
  /*position: relative;
  text-align: center;
  color: white;
  max-width: 1360px;
  margin-left: auto;
  margin-right: auto;*/
  height:450px;
  width:100%;
  max-width:1360px;
  margin:auto;
  background-position:center;
  background-size:cover;
  display:flex;
  flex-direction:row;
  justify-content:flex-end;
  transition: 0.3s height;
  overflow: hidden;
  //background-image:pec.stg.pearson.com.hk/assets/images/webbanner_V8.jpg);
  background-image: url("../../../assets/img/webbanner_V8.jpg");
}
.banner-image {
  width: 100%;
}
.welcome-text {
  //position: absolute;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  color: #ffffff;
  top: 20%;
  left: 55%;
}
.p-eng-text {
 // position: absolute;
  left: 55%;
  top: 26%;
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  color: #ffffff;
}
.sign-in-text {
 // position: absolute;
  left: 55%;
  //bottom: 40%;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #252525;
  background: #ffbb1c;
  border-radius: 2px;
  padding: 0px 8px 0px 8px;

  display: inline-block;
  flex:0;
  -webkit-box-shadow: 0 1px 5px rgba(0,0,0,0.5);
  box-shadow: 0 1px 5px rgba(0,0,0,0.5);
 // padding: 12px 34px;
  font-size: 0.8em;
  //line-height: 1em;
  text-decoration:none;
  border-radius: 2px;
  color: #212529;
  background-color: #ffb81c;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width:auto;
  transition:0.2s color, 0.2s background-color, 0.2s transform;
}
.banner-below-container {
  background-color: #ffffff;
  padding-top: 15px;
  padding-bottom: 50px;
  max-width: 1360px;
  margin-left: auto;
  margin-right: auto;
}
.upper-text {
  font-weight: 100;
  font-size: 32px;
  line-height: 44px;
  color: #000000;
  margin: 32px 0px 32px 0px;
  display: flex;
  justify-content: start;
  padding-left: 60px;
  max-width: 1360px;
}
.below-text {
  font-weight: 100;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
  display: flex;
  // max-width: 1050px;
  padding-left: 60px;
  max-width: 1360px;
}
.promise-container {
  background-color: #f5f5f5;
  max-width: 1360px;
  margin-left: auto;
  margin-right: auto;
}
.promise-upper-text {
  font-weight: 300;
  font-size: 32px;
  line-height: 44px;
  text-align: center;
  color: #000000;
  padding-top: 28px;
  padding-bottom: 19px;
  place-content: center;
  max-width: 1360px;
  margin-left: auto;
  margin-right: auto;
}
.promise-below-text {
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #000000;
  place-content: center;
  padding-bottom: 50px;
  max-width: 1360px;
  margin-left: auto;
  margin-right: auto;
}
.promise-card-container {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin: 4px;
  background-color: #ffffff;
}
.banner-image-card {
  width: 100%;
}
.promise-card-1 {
  max-width: 280px;
  font-weight: 400;
  font-size: 21px;
  line-height: 29px;
  color: #003057;
  padding: 25px 15px 10px 15px;
}
.promise-card-2 {
  max-width: 300px;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  padding-top: 29px;
  padding-bottom: 45px;
  padding-left: 15px;
  padding-right: 15px;
}
.promise-card-border {
  border-bottom: 1px solid #dbdbdb;
  margin-left: 15px;
  max-width: 90%;
}
.promise-main-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  // margin-bottom: 50px;
  max-width: 1360px;
  margin-left: auto;
  margin-right: auto;
  padding: 12px;
  margin-bottom: -45px;
}
.pec-footer {
  background-color: #ffffff;
  height: 64px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.end-agreement {
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration-line: underline;
  color: #047a9c;
}
.universal-service {
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration-line: underline;
  color: #047a9c;
}
.acceptable-policy {
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration-line: underline;
  color: #047a9c;
}
.privacy-policy {
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration-line: underline;
  color: #047a9c;
}
.personal-information {
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration-line: underline;
  color: #047a9c;
}
.copyright-pec-Pearson {
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
}

@media only screen and (max-width: 640px) {
  .promise-main-container {
    flex-direction: column;
  }
  .promise-card-container {
    flex-direction: column;
    max-width: 370px;
    margin: 7px auto 7px auto;
  }
  .welcome-text {
   // position: absolute;
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
    top: 20%;
  }
  .p-eng-text {
    //position: absolute;
    top: 38%;
    font-weight: 200;
    font-size: 12px;
    line-height: 18px;
  }
  .sign-in-text {
   // position: absolute;
    bottom: 12%;
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
    padding: 0px 0px 0px 0px;  
  }
  .banner-below-container {
    background-color: #ffffff;
    padding: 15px 0px 30px 0px;
  }
  .upper-text {
    font-weight: 100;
    font-size: 16px;
    line-height: 18px;
    margin: 15px 0px 22px 0px;
    justify-content: start;
    padding: 5px 0px 0px 10px;
  }
  .below-text {
    font-weight: 100;
    font-size: 12px;
    line-height: 18px;
    padding: 0px 10px 0px 10px;
  }
  .promise-container {
    background-color: #f5f5f5;
  }
  .promise-upper-text {
    font-weight: 100;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    padding: 28px 0px 19px 0px;
    place-content: center;
  }
  .promise-below-text {
    font-weight: 100;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    place-content: center;
    padding-bottom: 10px;
    padding: 0px 30px 0px 30px;
  }
  .promise-card-1 {
    max-width: 280px;
    font-weight: 200;
    font-size: 16px;
    line-height: 18px;
    padding: 25px 15px 10px 15px;
  }
  .promise-card-2 {
    max-width: 300px;
    font-weight: 200;
    font-size: 12px;
    line-height: 18px;
    padding: 10px 15px 20px 15px;
  }
}
// @media only screen and (min-width: 641px) and (max-width: 800px){
//   .welcome-text {
//     right: 32%;
//     top: 20%;
//   }
//   .p-eng-text {
//     right: 14.5%;
//     top: 26%;
//   }
//   .sign-in-text {
//     right: 34%;
//     bottom: 40%;
//   }

// }
// @media only screen and (min-width: 801px) and (max-width: 1024px){
//   .welcome-text {
//     right: 32%;
//     top: 20%;
//   }
//   .p-eng-text {
//     right: 14.5%;
//     top: 26%;
//   }
//   .sign-in-text {
//     right: 34%;
//     bottom: 40%;
//   }

// }
// @media only screen and (min-width : 1824px){
//   .welcome-text {
//     position: absolute;
//     font-weight: 600;
//     font-size: 22px;
//     line-height: 30px;
//     color: #ffffff;
//     right: 32%;
//     top: 20%;
//   }
//   .p-eng-text {
//     right: 23.7%;
//     top: 24%;
//   }
//   .sign-in-text {
//     right: 33%;
//     bottom: 60%;
//   }

// }

//Help css provided by David  starts here
// Banner Badge
.banner-context{
  color:#FFF;
  width:42%;
  display:flex;
  flex-direction:column;
  justify-content:center;
  height:100%;
  font-size:21px;
  transition: font-size 0.3s, width 0.3s;
  }
  .banner-context .welcome-text, .banner-context .p-eng-text{
  text-shadow: 0px 0px 7px rgba(0, 40, 106, 0.7);
  cursor: default;
  }
  .banner-context .welcome-text{
  font-size:1em;
  }
  .banner-context .p-eng-text{
  font-size:1.5em;
  font-weight:bold;
  }
  .banner-btn-group{
  margin-top:1.5em;
  }
  .banner-btn {
  display: inline-block;
  flex:0;
  -webkit-box-shadow: 0 1px 5px rgba(0,0,0,0.5);
  box-shadow: 0 1px 5px rgba(0,0,0,0.5);
  padding: 12px 34px;
  font-size: 0.8em;
  line-height: 1em;
  text-decoration:none;
  border-radius: 2px;
  color: #212529;
  background-color: #ffb81c;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width:auto;
  transition:0.2s color, 0.2s background-color, 0.2s transform;

  /*animation: opening-animation 0.5s;*/
  animation-iteration-count: 1;
  }
  @keyframes opening-animation {
  0% { transform: translate(150px, 0px); opacity: 0.2; }
  100% { transform: translate(0px, 0px); opacity: 1; }
  }

  .banner-btn:hover {
  color:#FFF;
  background-color:#fcad00;
  -webkit-box-shadow: 0 2px 8px rgba(0,0,0,0.5);
  box-shadow: 0 2px 8px rgba(0,0,0,0.5);
  transform: translateY(1px);
  }
  .banner-btn:active{
  background-color:#dd9900;
  transform: translateY(3px);
  }
  .banner-badge{
  display:flex;
  flex-direction:row;
  margin-top:1.5em;
  gap:4px;
  }
  .banner-badge a{
      display:block;
      transition: 0.8s opacity, 0.8s filter;
      opacity: 0.6;
      filter: grayscale(100%) contrast(0.7);
  }
  .cls_applestore{
    background-image: url("../../../assets/img/welcome-badge-app-store.svg");
    background-position:center;
    background-size:cover;
    width: 100%;
    max-width: 200px;
    min-width: 120px;
    height: 40px;
    border: 0px none;
    background-color: inherit;
    cursor: pointer;
  }
  .cls_playstore{
    background-image: url("../../../assets/img/welcome-badge-google-play.svg");
    background-position:center;
    background-size:cover;
    width: 100%;
    max-width: 200px;
    min-width: 135px;
    height: 40px;
    border: 0px none;
    background-color: inherit; 
   cursor: pointer;
  }
  .cls_grcodestore{
    background-image: url("../../../assets/img/welcome-badge-qr.svg");
    background-position:center;
    background-size:cover;
    width: 100%;
    height: 40px;
    max-width: 100px;
    min-width: 40px;
    border: 0px none;
    background-color: inherit;
    cursor: pointer;
  }
  .banner-badge a:hover{
      opacity:1;
      filter: grayscale(0%) contrast(1);
  }
  .banner-badge img{
  height:40px;
  display: block;
  visibility: hidden;
  }
//Overlay QR pop-up
  .overlay-container{
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:10003;
    display:none;
    }
    .overlay{
    background-color:rgba(0,0,0,0.8);
    width:100%;
    height:100%;
    }
    .overlay-box{
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    background-color:#FFF;
    padding:1.5em;
    padding-top: 2em;
    text-align:center;
    border-radius: 8px;
    width: 96%;
    max-width: 600px;
    box-sizing: border-box;
    overflow: hidden;
    }
    .overlay-box img.qr-img{
    display:block;
    margin:auto;
    width: 200px;
    }
    .overlay-box .qr-code-group{
        position: relative;
    }
    .overlay-box span.app-icon{
      background-image: url("../../../assets/img/pec app icon rounded-512w.png");
      background-position:center;
      background-size:cover;
        position: absolute;
        /*center the image*/
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 40px;
        height: 40px;

    }
    .overlay-box img.qr-badge{
    display:block;
    margin:auto;
    height: 40px;
    }
    .overlay-box .cls_applestore,.overlay-box .cls_playstore{
      width: auto;
    }
    .overlay-box .close-btn{
    position:absolute;
    top:0;
    right:0;
    padding:0.8em;
    cursor:pointer;
    }
    .close-btn span{
      background-image: url("../../../assets/img/icon-close-black.svg");
      background-position:center;
      background-size:cover;
      width: 16px;
      height: 16px;
      float: left;
    }
    .overlay-box .close-btn img{
    width:13px;
    }
    .overlay-box .overlay-title{
    font-size:1.5em;
    font-weight: bold;
    border-bottom: 1px solid #CCC;
    color: #047a9c;
    padding-bottom:0.5em;

    }
    .overlay-qr-link{
        transition: 0.2s opacity;
    }
    .overlay-qr-link:hover{
        opacity: 0.8;
        
    }
    .overlay-box .overlay-qr{
    margin-top:0em;
    display: flex;
    flex-direction: row;
    gap: 24px;
    justify-content: center;
    }
    .overlay-box .overlay-qr .overlay-qr-title{
    font-size:0.8em;
    color: #666;
    font-weight: bold;
    }

@media only screen and (max-width: 1000px) {
  .banner-container{height:350px;}
  }
  @media only screen and (max-width: 800px) {
  .banner-container{height:300px;}
  .banner-context{font-size: 18px; width: 60%;}
  }
  @media only screen and (max-width: 600px), (max-height: 400px) {
  .banner-container{height:210px;}
  .banner-context{width:auto; font-size: 16px; margin-right: 8px}
  .banner-badge img,.banner-badge a button{height:30px;}
  .banner-btn{padding: 8px 18px; font-size: 0.9em;}
  .banner-btn-group{margin-top:1em;}
  .banner-badge {margin-top:1em;}
  .overlay-box .overlay-qr{gap: 8px;}
  .overlay-box img.qr-img{width: 120px;}
  .overlay-box span.app-icon{width: 30px !important;height: 30px !important;}
  .overlay-box .overlay-title{font-size:1.2em;}
  .overlay-box img.qr-badge{height: 30px;}
  .overlay-box .cls_applestore,.overlay-box .cls_playstore{height: 30px;}
  }
  @media only screen and (max-width: 400px) {
  .banner-container{height:150px;}
  .banner-context{width: auto; font-size: 15px; margin-right: 8px;}
  .banner-context .p-eng-text{font-size:1.2em;}
  .banner-btn-group{margin-top:0.5em;}
  .banner-badge {margin-top:0.5em;}
  .overlay-box .overlay-title{font-size:1em;}
  }

  @media only screen and (min-device-width: 300px) and (max-device-width: 1024px) {
    .banner-context .welcome-text {
      font-weight: 300;
      font-size: 22px;
      line-height: 22px;
    }
       .banner-context .p-eng-text{
      font-size: 20px;
    }
  }
  @media only screen and (max-width: 600px), (max-height: 400px) {
    .cls_applestore{
      min-width: 90px;
    }
    .cls_playstore{
      min-width: 100px;
    }
    .cls_grcodestore{
      min-width: 30px;
    }
  }
  
//Help css provided by David  ends here