section.dashboard {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  height: 100%;
  .bg {
    flex-grow: 1;
    min-height: 520px;
    background-color: #daf0ed;
    .container {
      height: 100%;
      max-width: 1100px;

      .text_box {
        .text_body {
          padding-top: 154px;
          .title {
            font-size: 32px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #005a70;
          }
          .subtitle {
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #000000;
            height: 60px;
            max-width: 382px;
            width: 100%;
          }
          .error_code {
            width: 29px;
            height: 17px;
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #505759;
          }
          .go_to_home {
            padding-top: 28px;
            button {
              width: 243px;
              height: 44px;
              padding-right: 20px;
              padding-left: 20px;
              font-size: 18px;
              font-weight: 600;
              font-style: normal;
              font-stretch: normal;
              line-height: 1.22;
              letter-spacing: normal;
              text-align: center;
              color: #252525;
              border-radius: 2px;
              &:hover {
                background-color: #ff9a19;
                border-color: #d39e00;
                text-decoration: none;
              }
            }
          }
        }
      }
      @media (max-width: 599px) {
        .text_box {
          .text_body {
            padding-top: 0;
            .title {
              margin: auto;
              width: 300px;
              height: 27px;
              font-size: 20px;
              font-weight: 600;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: center;
              color: #005a70;
            }
            .subtitle {
              margin: auto;
              width: 300px;
              height: 39px;
              font-size: 14px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: center;
              color: #000000;
            }
            .error_code {
              margin: auto;
              width: 36px;
              height: 15px;
              font-size: 11px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #505759;
            }
            .go_to_home {
              text-align: center;
            }
          }
        }
        .image_box {
          text-align: center;
          img {
            width: 100%;
            max-width: 300px;
            height: 100%;
            max-height: 100%;
          }
        }
      }
    }
  }
}
