@import "../base/colors.scss";
.dim-button {
  pointer-events: none;
  opacity: 0.35;
}
.btn {
  display: inline-block;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.7692rem 0.75rem;
  font-size: 1rem;
  line-height: 1.428571;
  border-radius: 4px;
  -webkit-transition: none;
  transition: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    -webkit-transition: none;
    transition: none;
  }
}

.btn:hover,
.btn:focus {
  text-decoration: none;
}

.btn:focus,
.btn.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.15rem rgba(135, 135, 135, 0.5);
  box-shadow: 0 0 0 0.15rem rgba(135, 135, 135, 0.5);
}

.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: $white;
  background-color: $primarycolor;
  border-color: $primarycolor;
}

.btn-primary:hover {
  color: $white;
  background-color: #00617d;
  border-color: $Inkblue;
}

.btn-primary:focus,
.btn-primary.focus {
  -webkit-box-shadow: 0 0 0 0.15rem rgba(0, 127, 163, 0.5);
  box-shadow: 0 0 0 0.15rem rgba(0, 127, 163, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: $white;
  background-color: $primarycolor;
  border-color: $primarycolor;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: $white;
  background-color: $Inkblue;
  border-color: #004d63;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.15rem rgba(0, 127, 163, 0.5);
  box-shadow: 0 0 0 0.15rem rgba(0, 127, 163, 0.5);
}

.btn-secondary {
  background-color: $secondarycolor;
  border: solid 1px $greyC7;
  color: $black25;
}

.btn-secondary:hover {
  // color: #000000;
  background-color: $secondaryhover;
}

.btn-secondary:focus,
.btn-secondary.focus {
  -webkit-box-shadow: 0 0 0 0.15rem rgba(135, 135, 135, 0.5);
  box-shadow: 0 0 0 0.15rem rgba(135, 135, 135, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: $white;
  background-color: $secondarycolor;
  border-color: $secondarycolor;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: $white;
  background-color: #6e6e6e;
  border-color: #676767;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.15rem rgba(135, 135, 135, 0.5);
  box-shadow: 0 0 0 0.15rem rgba(135, 135, 135, 0.5);
}

.btn-success {
  color: $white;
  background-color: $green;
  border-color: $green;
}

.btn-success:hover {
  color: $white;
  background-color: #006028;
  border-color: #005323;
}

.btn-success:focus,
.btn-success.focus {
  -webkit-box-shadow: 0 0 0 0.15rem rgba(0, 134, 56, 0.5);
  box-shadow: 0 0 0 0.15rem rgba(0, 134, 56, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: $white;
  background-color: $green;
  border-color: $green;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: $white;
  background-color: #005323;
  border-color: #00461d;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.15rem rgba(0, 134, 56, 0.5);
  box-shadow: 0 0 0 0.15rem rgba(0, 134, 56, 0.5);
}

.btn-info {
  color: #212529;
  background-color: #6ba4ff;
  border-color: #6ba4ff;
}

.btn-info:hover {
  color: $white;
  background-color: #458cff;
  border-color: #3885ff;
}

.btn-info:focus,
.btn-info.focus {
  -webkit-box-shadow: 0 0 0 0.15rem rgba(107, 164, 255, 0.5);
  box-shadow: 0 0 0 0.15rem rgba(107, 164, 255, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #212529;
  background-color: #6ba4ff;
  border-color: #6ba4ff;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: $white;
  background-color: #3885ff;
  border-color: #2b7dff;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.15rem rgba(107, 164, 255, 0.5);
  box-shadow: 0 0 0 0.15rem rgba(107, 164, 255, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffb81c;
  border-color: #ffb81c;
}

.btn-warning:hover {
  color: #212529;
  background-color: #f5a800;
  border-color: #e89f00;
}

.btn-warning:focus,
.btn-warning.focus {
  -webkit-box-shadow: 0 0 0 0.15rem rgba(255, 184, 28, 0.5);
  box-shadow: 0 0 0 0.15rem rgba(255, 184, 28, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #ffb81c;
  border-color: #ffb81c;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #e89f00;
  border-color: #db9700;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.15rem rgba(255, 184, 28, 0.5);
  box-shadow: 0 0 0 0.15rem rgba(255, 184, 28, 0.5);
}

.btn-danger {
  color: $white;
  background-color: $red;
  // border-color: $red;
}

.btn-danger:hover {
  color: $white;
  background-color: #890014;
  // border-color: #a80019;
}

.btn-danger:focus,
.btn-danger.focus {
  -webkit-box-shadow: 0 0 0 0.15rem rgba(219, 0, 32, 0.5);
  box-shadow: 0 0 0 0.15rem rgba(219, 0, 32, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: $white;
  background-color: $red;
  border-color: $red;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: $white;
  background-color: #a80019;
  border-color: #9b0017;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.15rem rgba(219, 0, 32, 0.5);
  box-shadow: 0 0 0 0.15rem rgba(219, 0, 32, 0.5);
}

.btn-light {
  color: #212529;
  background-color: $white;
  border-color: $white;
}

.btn-light:hover {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
}

.btn-light:focus,
.btn-light.focus {
  -webkit-box-shadow: 0 0 0 0.15rem rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 0 0.15rem rgba(255, 255, 255, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: $white;
  border-color: $white;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.15rem rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 0 0.15rem rgba(255, 255, 255, 0.5);
}

.btn-dark {
  color: $white;
  background-color: #3d3c3c;
  border-color: #3d3c3c;
}

.btn-dark:hover {
  color: $white;
  background-color: #2a2929;
  border-color: #232323;
}

.btn-dark:focus,
.btn-dark.focus {
  -webkit-box-shadow: 0 0 0 0.15rem rgba(61, 60, 60, 0.5);
  box-shadow: 0 0 0 0.15rem rgba(61, 60, 60, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: $white;
  background-color: #3d3c3c;
  border-color: #3d3c3c;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: $white;
  background-color: #232323;
  border-color: #1d1c1c;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.15rem rgba(61, 60, 60, 0.5);
  box-shadow: 0 0 0 0.15rem rgba(61, 60, 60, 0.5);
}

.btn-grey {
  color: #212529;
  background-color: #cccccc;
  border-color: #cccccc;
}

.btn-grey:hover {
  color: #212529;
  background-color: #b9b9b9;
  border-color: #b3b3b3;
}

.btn-grey:focus,
.btn-grey.focus {
  -webkit-box-shadow: 0 0 0 0.15rem rgba(204, 204, 204, 0.5);
  box-shadow: 0 0 0 0.15rem rgba(204, 204, 204, 0.5);
}

.btn-grey.disabled,
.btn-grey:disabled {
  color: #212529;
  background-color: #cccccc;
  border-color: #cccccc;
}

.btn-grey:not(:disabled):not(.disabled):active,
.btn-grey:not(:disabled):not(.disabled).active,
.show > .btn-grey.dropdown-toggle {
  color: #212529;
  background-color: #b3b3b3;
  border-color: #acacac;
}

.btn-grey:not(:disabled):not(.disabled):active:focus,
.btn-grey:not(:disabled):not(.disabled).active:focus,
.show > .btn-grey.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.15rem rgba(204, 204, 204, 0.5);
  box-shadow: 0 0 0 0.15rem rgba(204, 204, 204, 0.5);
}

.btn-outline-primary {
  color: $primarycolor;
  background-color: transparent;
  background-image: none;
  border-color: $primarycolor;
}

.btn-outline-primary:hover {
  color: $white;
  background-color: $primarycolor;
  border-color: $primarycolor;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  -webkit-box-shadow: 0 0 0 0.15rem rgba(0, 127, 163, 0.5);
  box-shadow: 0 0 0 0.15rem rgba(0, 127, 163, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: $primarycolor;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: $white;
  background-color: $primarycolor;
  border-color: $primarycolor;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.15rem rgba(0, 127, 163, 0.5);
  box-shadow: 0 0 0 0.15rem rgba(0, 127, 163, 0.5);
}

.btn-outline-secondary {
  color: $secondarycolor;
  background-color: transparent;
  background-image: none;
  border-color: $secondarycolor;
}

.btn-outline-secondary:hover {
  color: $white;
  background-color: $secondarycolor;
  border-color: $secondarycolor;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  -webkit-box-shadow: 0 0 0 0.15rem rgba(135, 135, 135, 0.5);
  box-shadow: 0 0 0 0.15rem rgba(135, 135, 135, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: $secondarycolor;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: $white;
  background-color: $secondarycolor;
  border-color: $secondarycolor;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.15rem rgba(135, 135, 135, 0.5);
  box-shadow: 0 0 0 0.15rem rgba(135, 135, 135, 0.5);
}

.btn-outline-success {
  color: $green;
  background-color: transparent;
  background-image: none;
  border-color: $green;
}

.btn-outline-success:hover {
  color: $white;
  background-color: $green;
  border-color: $green;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  -webkit-box-shadow: 0 0 0 0.15rem rgba(0, 134, 56, 0.5);
  box-shadow: 0 0 0 0.15rem rgba(0, 134, 56, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: $green;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: $white;
  background-color: $green;
  border-color: $green;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.15rem rgba(0, 134, 56, 0.5);
  box-shadow: 0 0 0 0.15rem rgba(0, 134, 56, 0.5);
}

.btn-outline-info {
  color: #6ba4ff;
  background-color: transparent;
  background-image: none;
  border-color: #6ba4ff;
}

.btn-outline-info:hover {
  color: #212529;
  background-color: #6ba4ff;
  border-color: #6ba4ff;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  -webkit-box-shadow: 0 0 0 0.15rem rgba(107, 164, 255, 0.5);
  box-shadow: 0 0 0 0.15rem rgba(107, 164, 255, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #6ba4ff;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #212529;
  background-color: #6ba4ff;
  border-color: #6ba4ff;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.15rem rgba(107, 164, 255, 0.5);
  box-shadow: 0 0 0 0.15rem rgba(107, 164, 255, 0.5);
}

.btn-outline-warning {
  color: #ffb81c;
  background-color: transparent;
  background-image: none;
  border-color: #ffb81c;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffb81c;
  border-color: #ffb81c;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  -webkit-box-shadow: 0 0 0 0.15rem rgba(255, 184, 28, 0.5);
  box-shadow: 0 0 0 0.15rem rgba(255, 184, 28, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffb81c;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffb81c;
  border-color: #ffb81c;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.15rem rgba(255, 184, 28, 0.5);
  box-shadow: 0 0 0 0.15rem rgba(255, 184, 28, 0.5);
}

.btn-outline-danger {
  color: $red;
  background-color: transparent;
  background-image: none;
  border-color: $red;
}

.btn-outline-danger:hover {
  color: $white;
  background-color: $red;
  border-color: $red;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  -webkit-box-shadow: 0 0 0 0.15rem rgba(219, 0, 32, 0.5);
  box-shadow: 0 0 0 0.15rem rgba(219, 0, 32, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: $red;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: $white;
  background-color: $red;
  border-color: $red;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.15rem rgba(219, 0, 32, 0.5);
  box-shadow: 0 0 0 0.15rem rgba(219, 0, 32, 0.5);
}

.btn-outline-light {
  color: $white;
  background-color: transparent;
  background-image: none;
  border-color: $white;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: $white;
  border-color: $white;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
  -webkit-box-shadow: 0 0 0 0.15rem rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 0 0.15rem rgba(255, 255, 255, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: $white;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: $white;
  border-color: $white;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.15rem rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 0 0.15rem rgba(255, 255, 255, 0.5);
}

.btn-outline-dark {
  color: #3d3c3c;
  background-color: transparent;
  background-image: none;
  border-color: #3d3c3c;
}

.btn-outline-dark:hover {
  color: $white;
  background-color: #3d3c3c;
  border-color: #3d3c3c;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
  -webkit-box-shadow: 0 0 0 0.15rem rgba(61, 60, 60, 0.5);
  box-shadow: 0 0 0 0.15rem rgba(61, 60, 60, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #3d3c3c;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: $white;
  background-color: #3d3c3c;
  border-color: #3d3c3c;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.15rem rgba(61, 60, 60, 0.5);
  box-shadow: 0 0 0 0.15rem rgba(61, 60, 60, 0.5);
}

.btn-outline-grey {
  color: #cccccc;
  background-color: transparent;
  background-image: none;
  border-color: #cccccc;
}

.btn-outline-grey:hover {
  color: #212529;
  background-color: #cccccc;
  border-color: #cccccc;
}

.btn-outline-grey:focus,
.btn-outline-grey.focus {
  -webkit-box-shadow: 0 0 0 0.15rem rgba(204, 204, 204, 0.5);
  box-shadow: 0 0 0 0.15rem rgba(204, 204, 204, 0.5);
}

.btn-outline-grey.disabled,
.btn-outline-grey:disabled {
  color: #cccccc;
  background-color: transparent;
}

.btn-outline-grey:not(:disabled):not(.disabled):active,
.btn-outline-grey:not(:disabled):not(.disabled).active,
.show > .btn-outline-grey.dropdown-toggle {
  color: #212529;
  background-color: #cccccc;
  border-color: #cccccc;
}

.btn-outline-grey:not(:disabled):not(.disabled):active:focus,
.btn-outline-grey:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-grey.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.15rem rgba(204, 204, 204, 0.5);
  box-shadow: 0 0 0 0.15rem rgba(204, 204, 204, 0.5);
}

.btn-link {
  font-weight: 400;
  color: $primarycolor;
  background-color: transparent;
}

.btn-link:hover {
  color: #004357;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}

.btn-link:focus,
.btn-link.focus {
  text-decoration: underline;
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-link:disabled,
.btn-link.disabled {
  color: $secondarycolor;
  pointer-events: none;
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 0.5rem 12px;
  font-size: 1.15rem;
  line-height: 1.7;
  border-radius: 4px;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.25rem 8px;
  font-size: 0.85rem;
  line-height: 1.6;
  border-radius: 2px;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 6px;
  padding-left: 6px;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 9px;
  padding-left: 9px;
}

.btn-group-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  height: 2.846rem;
  padding: 0.5rem 12px;
  font-size: 1.15rem;
  line-height: 1.7;
  border-radius: 4px;
}

.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  height: 2.3125rem;
  padding: 0.25rem 8px;
  font-size: 0.85rem;
  line-height: 1.6;
  border-radius: 2px;
}
.btn .badge {
  position: relative;
  top: -1px;
}

.be-top-header .search-container .btn {
  height: 37px;
  font-size: 1rem;
  padding: 4px 12px;
  border-radius: 0;
}

@media (max-width: 1199.98px) {
  .be-aside-header-filters .btn-group + .btn-group {
    margin-left: 0;
  }
}

.be-aside-pagination-nav .btn {
  min-width: 30px;
}

.be-aside-pagination-nav .btn i {
  font-size: 1.615rem;
  vertical-align: middle;
}

@media (max-width: 979.98px) {
  .widget-head .button-toolbar .btn-group {
    margin-bottom: 5px;
  }
}

.widget-head .button-toolbar .btn-group + * {
  margin-left: 7px;
}

.pricing-table .btn {
  line-height: 36px;
  min-width: 120px;
}

.invoice-footer .btn {
  min-width: 96px;
  font-size: 1.077rem;
}

/*------------------------------------------------------------------
  [Buttons]
*/
.btn {
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  // border: 1px solid transparent;
  padding: 12px 16px;
  font-size: 1rem;
  line-height: 1.1rem;
  border-radius: 2px;
}

.btn:active,
.btn.active {
  -webkit-box-shadow: inset 0 2px 0 #003355;
  box-shadow: inset 0 2px 0 #003355;
  background-color: $Inkblue;
  border-color: $Inkblue;
  border-top-color: #003355;
}

.btn:active:focus {
  outline: none;
}

.btn-secondary:active,
.btn-secondary.active,
.btn-secondary:not([disabled]):not(.disabled):active,
.btn-secondary:not([disabled]):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle,
.btn-light:active,
.btn-light.active,
.btn-light:not([disabled]):not(.disabled):active,
.btn-light:not([disabled]):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  -webkit-box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.06);
  color: #272727;
  background-color: #e3e3e3;
  border-color: #bababa;
}

.btn-secondary:active:hover,
.btn-secondary:active:focus,
.btn-secondary:active.focus,
.btn-secondary.active:hover,
.btn-secondary.active:focus,
.btn-secondary.active.focus,
.btn-secondary:not([disabled]):not(.disabled):active:hover,
.btn-secondary:not([disabled]):not(.disabled):active:focus,
.btn-secondary:not([disabled]):not(.disabled):active.focus,
.btn-secondary:not([disabled]):not(.disabled).active:hover,
.btn-secondary:not([disabled]):not(.disabled).active:focus,
.btn-secondary:not([disabled]):not(.disabled).active.focus,
.show > .btn-secondary.dropdown-toggle:hover,
.show > .btn-secondary.dropdown-toggle:focus,
.show > .btn-secondary.dropdown-toggle.focus,
.btn-light:active:hover,
.btn-light:active:focus,
.btn-light:active.focus,
.btn-light.active:hover,
.btn-light.active:focus,
.btn-light.active.focus,
.btn-light:not([disabled]):not(.disabled):active:hover,
.btn-light:not([disabled]):not(.disabled):active:focus,
.btn-light:not([disabled]):not(.disabled):active.focus,
.btn-light:not([disabled]):not(.disabled).active:hover,
.btn-light:not([disabled]):not(.disabled).active:focus,
.btn-light:not([disabled]):not(.disabled).active.focus,
.show > .btn-light.dropdown-toggle:hover,
.show > .btn-light.dropdown-toggle:focus,
.show > .btn-light.dropdown-toggle.focus {
  color: #272727;
  background-color: #e3e3e3;
  border-color: #bababa;
  -webkit-box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.06);
}

.btn-secondary:active,
.btn-secondary.active,
.show > .btn-secondary.dropdown-toggle,
.btn-light:active,
.btn-light.active,
.show > .btn-light.dropdown-toggle {
  background-image: none;
}

.btn-secondary.disabled,
.btn-secondary[disabled],
fieldset[disabled] .btn-secondary,
.btn-light.disabled,
.btn-light[disabled],
fieldset[disabled] .btn-light {
  color: #404040;
}

.btn-secondary.disabled,
.btn-secondary.disabled:hover,
.btn-secondary.disabled:focus,
.btn-secondary.disabled.focus,
.btn-secondary.disabled:active,
.btn-secondary.disabled.active,
.btn-secondary[disabled],
.btn-secondary[disabled]:hover,
.btn-secondary[disabled]:focus,
.btn-secondary[disabled].focus,
.btn-secondary[disabled]:active,
.btn-secondary[disabled].active,
fieldset[disabled] .btn-secondary,
fieldset[disabled] .btn-secondary:hover,
fieldset[disabled] .btn-secondary:focus,
fieldset[disabled] .btn-secondary.focus,
fieldset[disabled] .btn-secondary:active,
fieldset[disabled] .btn-secondary.active,
.btn-light.disabled,
.btn-light.disabled:hover,
.btn-light.disabled:focus,
.btn-light.disabled.focus,
.btn-light.disabled:active,
.btn-light.disabled.active,
.btn-light[disabled],
.btn-light[disabled]:hover,
.btn-light[disabled]:focus,
.btn-light[disabled].focus,
.btn-light[disabled]:active,
.btn-light[disabled].active,
fieldset[disabled] .btn-light,
fieldset[disabled] .btn-light:hover,
fieldset[disabled] .btn-light:focus,
fieldset[disabled] .btn-light.focus,
fieldset[disabled] .btn-light:active,
fieldset[disabled] .btn-light.active {
  color: #404040;
  background-color: $white;
  border-color: #dbdbdb;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-secondary.disabled .icon,
.btn-secondary.disabled:hover .icon,
.btn-secondary.disabled:focus .icon,
.btn-secondary.disabled.focus .icon,
.btn-secondary.disabled:active .icon,
.btn-secondary.disabled.active .icon,
.btn-secondary[disabled] .icon,
.btn-secondary[disabled]:hover .icon,
.btn-secondary[disabled]:focus .icon,
.btn-secondary[disabled].focus .icon,
.btn-secondary[disabled]:active .icon,
.btn-secondary[disabled].active .icon,
fieldset[disabled] .btn-secondary .icon,
fieldset[disabled] .btn-secondary:hover .icon,
fieldset[disabled] .btn-secondary:focus .icon,
fieldset[disabled] .btn-secondary.focus .icon,
fieldset[disabled] .btn-secondary:active .icon,
fieldset[disabled] .btn-secondary.active .icon,
.btn-light.disabled .icon,
.btn-light.disabled:hover .icon,
.btn-light.disabled:focus .icon,
.btn-light.disabled.focus .icon,
.btn-light.disabled:active .icon,
.btn-light.disabled.active .icon,
.btn-light[disabled] .icon,
.btn-light[disabled]:hover .icon,
.btn-light[disabled]:focus .icon,
.btn-light[disabled].focus .icon,
.btn-light[disabled]:active .icon,
.btn-light[disabled].active .icon,
fieldset[disabled] .btn-light .icon,
fieldset[disabled] .btn-light:hover .icon,
fieldset[disabled] .btn-light:focus .icon,
fieldset[disabled] .btn-light.focus .icon,
fieldset[disabled] .btn-light:active .icon,
fieldset[disabled] .btn-light.active .icon {
  color: #404040;
}

.btn-secondary .icon,
.btn-light .icon {
  color: #646464;
}

.btn-secondary:active .icon,
.btn-light:active .icon {
  color: #4a4a4a;
}

.btn-secondary .badge,
.btn-light .badge {
  color: $white;
  background-color: $e9grat;
}

.btn-primary {
  color: $white;
  background-color: $primarycolor;
  border-color: $primarycolor;
}

.btn-primary:focus,
.btn-primary.focus {
  color: $white;
  background-color: $primarycolor;
  border-color: $primarycolor;
  outline: none;
}

.btn-primary:hover,
.btn-primary.hover {
  color: $white;
  -webkit-box-shadow: inset 0 -1px 0 $Inkblue;
  box-shadow: inset 0 -1px 0 $Inkblue;
  background-color: $lightblue;
  border-color: $lightblue;
  // border-bottom-color: $Inkblue;
}

.btn-primary:active,
.btn-primary.active,
.btn-primary:not([disabled]):not(.disabled):active,
.btn-primary:not([disabled]):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: $white;
  -webkit-box-shadow: inset 0 2px 0 $Inkblue;
  box-shadow: inset 0 2px 0 $Inkblue;
  background-color: #006784;
  border-color: #006784;
  border-top-color: #003b4c;
}

.btn-primary:active .icon,
.btn-primary.active .icon,
.btn-primary:not([disabled]):not(.disabled):active .icon,
.btn-primary:not([disabled]):not(.disabled).active .icon,
.show > .btn-primary.dropdown-toggle .icon {
  color: $white;
}

.btn-primary:active:hover,
.btn-primary:active:focus,
.btn-primary:active.focus,
.btn-primary.active:hover,
.btn-primary.active:focus,
.btn-primary.active.focus,
.btn-primary:not([disabled]):not(.disabled):active:hover,
.btn-primary:not([disabled]):not(.disabled):active:focus,
.btn-primary:not([disabled]):not(.disabled):active.focus,
.btn-primary:not([disabled]):not(.disabled).active:hover,
.btn-primary:not([disabled]):not(.disabled).active:focus,
.btn-primary:not([disabled]):not(.disabled).active.focus,
.show > .btn-primary.dropdown-toggle:hover,
.show > .btn-primary.dropdown-toggle:focus,
.show > .btn-primary.dropdown-toggle.focus {
  color: $white;
  background-color: #006784;
  border-color: #006784;
  border-top-color: #003b4c;
  -webkit-box-shadow: inset 0 2px 0 $Inkblue;
  box-shadow: inset 0 2px 0 $Inkblue;
}

.btn-primary:active:hover .icon,
.btn-primary:active:focus .icon,
.btn-primary:active.focus .icon,
.btn-primary.active:hover .icon,
.btn-primary.active:focus .icon,
.btn-primary.active.focus .icon,
.btn-primary:not([disabled]):not(.disabled):active:hover .icon,
.btn-primary:not([disabled]):not(.disabled):active:focus .icon,
.btn-primary:not([disabled]):not(.disabled):active.focus .icon,
.btn-primary:not([disabled]):not(.disabled).active:hover .icon,
.btn-primary:not([disabled]):not(.disabled).active:focus .icon,
.btn-primary:not([disabled]):not(.disabled).active.focus .icon,
.show > .btn-primary.dropdown-toggle:hover .icon,
.show > .btn-primary.dropdown-toggle:focus .icon,
.show > .btn-primary.dropdown-toggle.focus .icon {
  color: $white;
}

.btn-primary:active,
.btn-primary.active,
.btn-primary:not([disabled]):not(.disabled):active,
.btn-primary:not([disabled]):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background-image: none;
}

.btn-primary.disabled,
.btn-primary.disabled:hover,
.btn-primary.disabled:focus,
.btn-primary.disabled.focus,
.btn-primary.disabled:active,
.btn-primary.disabled.active,
.btn-primary[disabled],
.btn-primary[disabled]:hover,
.btn-primary[disabled]:focus,
.btn-primary[disabled].focus,
.btn-primary[disabled]:active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary.focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary.active {
  background-color: $primarycolor;
  border-color: $primarycolor;
  color: $white;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-primary .badge {
  color: $primarycolor;
  background-color: $white;
}

.btn-info {
  color: $white;
  background-color: #6ba4ff;
  border-color: #6ba4ff;
}

.btn-info:focus,
.btn-info.focus {
  color: $white;
  background-color: #6ba4ff;
  border-color: #6ba4ff;
  outline: none;
}

.btn-info:hover,
.btn-info.hover {
  color: $white;
  -webkit-box-shadow: inset 0 -1px 0 #3885ff;
  box-shadow: inset 0 -1px 0 #3885ff;
  background-color: #75aaff;
  border-color: #75aaff;
  border-bottom-color: #3885ff;
}

.btn-info:active,
.btn-info.active,
.btn-info:not([disabled]):not(.disabled):active,
.btn-info:not([disabled]):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: $white;
  -webkit-box-shadow: inset 0 2px 0 #3885ff;
  box-shadow: inset 0 2px 0 #3885ff;
  background-color: #4c91ff;
  border-color: #4c91ff;
  border-top-color: #146fff;
}

.btn-info:active .icon,
.btn-info.active .icon,
.btn-info:not([disabled]):not(.disabled):active .icon,
.btn-info:not([disabled]):not(.disabled).active .icon,
.show > .btn-info.dropdown-toggle .icon {
  color: $white;
}

.btn-info:active:hover,
.btn-info:active:focus,
.btn-info:active.focus,
.btn-info.active:hover,
.btn-info.active:focus,
.btn-info.active.focus,
.btn-info:not([disabled]):not(.disabled):active:hover,
.btn-info:not([disabled]):not(.disabled):active:focus,
.btn-info:not([disabled]):not(.disabled):active.focus,
.btn-info:not([disabled]):not(.disabled).active:hover,
.btn-info:not([disabled]):not(.disabled).active:focus,
.btn-info:not([disabled]):not(.disabled).active.focus,
.show > .btn-info.dropdown-toggle:hover,
.show > .btn-info.dropdown-toggle:focus,
.show > .btn-info.dropdown-toggle.focus {
  color: $white;
  background-color: #4c91ff;
  border-color: #4c91ff;
  border-top-color: #146fff;
  -webkit-box-shadow: inset 0 2px 0 #3885ff;
  box-shadow: inset 0 2px 0 #3885ff;
}

.btn-info:active:hover .icon,
.btn-info:active:focus .icon,
.btn-info:active.focus .icon,
.btn-info.active:hover .icon,
.btn-info.active:focus .icon,
.btn-info.active.focus .icon,
.btn-info:not([disabled]):not(.disabled):active:hover .icon,
.btn-info:not([disabled]):not(.disabled):active:focus .icon,
.btn-info:not([disabled]):not(.disabled):active.focus .icon,
.btn-info:not([disabled]):not(.disabled).active:hover .icon,
.btn-info:not([disabled]):not(.disabled).active:focus .icon,
.btn-info:not([disabled]):not(.disabled).active.focus .icon,
.show > .btn-info.dropdown-toggle:hover .icon,
.show > .btn-info.dropdown-toggle:focus .icon,
.show > .btn-info.dropdown-toggle.focus .icon {
  color: $white;
}

.btn-info:active,
.btn-info.active,
.btn-info:not([disabled]):not(.disabled):active,
.btn-info:not([disabled]):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  background-image: none;
}

.btn-info.disabled,
.btn-info.disabled:hover,
.btn-info.disabled:focus,
.btn-info.disabled.focus,
.btn-info.disabled:active,
.btn-info.disabled.active,
.btn-info[disabled],
.btn-info[disabled]:hover,
.btn-info[disabled]:focus,
.btn-info[disabled].focus,
.btn-info[disabled]:active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info,
fieldset[disabled] .btn-info:hover,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info.focus,
fieldset[disabled] .btn-info:active,
fieldset[disabled] .btn-info.active {
  background-color: #6ba4ff;
  border-color: #6ba4ff;
  color: $white;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-info .badge {
  color: #6ba4ff;
  background-color: $white;
}

.btn-danger {
  color: $white;
  background-color: $red;
  border-color: $red;
}

.btn-danger:focus,
.btn-danger.focus {
  color: $white;
  background-color: $red;
  border-color: $red;
  outline: none;
}

.btn-danger:hover,
.btn-danger.hover {
  color: $white;
  -webkit-box-shadow: inset 0 -1px 0 #a80019;
  box-shadow: inset 0 -1px 0 #a80019;
  background-color: #e50021;
  border-color: #e50021;
  border-bottom-color: #a80019;
}

.btn-danger:active,
.btn-danger.active,
.btn-danger:not([disabled]):not(.disabled):active,
.btn-danger:not([disabled]):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: $white;
  -webkit-box-shadow: inset 0 2px 0 #a80019;
  box-shadow: inset 0 2px 0 #a80019;
  background-color: #bc001c;
  border-color: #bc001c;
  border-top-color: #840013;
}

.btn-danger:active .icon,
.btn-danger.active .icon,
.btn-danger:not([disabled]):not(.disabled):active .icon,
.btn-danger:not([disabled]):not(.disabled).active .icon,
.show > .btn-danger.dropdown-toggle .icon {
  color: $white;
}

.btn-danger:active:hover,
.btn-danger:active:focus,
.btn-danger:active.focus,
.btn-danger.active:hover,
.btn-danger.active:focus,
.btn-danger.active.focus,
.btn-danger:not([disabled]):not(.disabled):active:hover,
.btn-danger:not([disabled]):not(.disabled):active:focus,
.btn-danger:not([disabled]):not(.disabled):active.focus,
.btn-danger:not([disabled]):not(.disabled).active:hover,
.btn-danger:not([disabled]):not(.disabled).active:focus,
.btn-danger:not([disabled]):not(.disabled).active.focus,
.show > .btn-danger.dropdown-toggle:hover,
.show > .btn-danger.dropdown-toggle:focus,
.show > .btn-danger.dropdown-toggle.focus {
  color: $white;
  background-color: #bc001c;
  border-color: #bc001c;
  border-top-color: #840013;
  -webkit-box-shadow: inset 0 2px 0 #a80019;
  box-shadow: inset 0 2px 0 #a80019;
}

.btn-danger:active:hover .icon,
.btn-danger:active:focus .icon,
.btn-danger:active.focus .icon,
.btn-danger.active:hover .icon,
.btn-danger.active:focus .icon,
.btn-danger.active.focus .icon,
.btn-danger:not([disabled]):not(.disabled):active:hover .icon,
.btn-danger:not([disabled]):not(.disabled):active:focus .icon,
.btn-danger:not([disabled]):not(.disabled):active.focus .icon,
.btn-danger:not([disabled]):not(.disabled).active:hover .icon,
.btn-danger:not([disabled]):not(.disabled).active:focus .icon,
.btn-danger:not([disabled]):not(.disabled).active.focus .icon,
.show > .btn-danger.dropdown-toggle:hover .icon,
.show > .btn-danger.dropdown-toggle:focus .icon,
.show > .btn-danger.dropdown-toggle.focus .icon {
  color: $white;
}

.btn-danger:active,
.btn-danger.active,
.btn-danger:not([disabled]):not(.disabled):active,
.btn-danger:not([disabled]):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  background-image: none;
}

.btn-danger.disabled,
.btn-danger.disabled:hover,
.btn-danger.disabled:focus,
.btn-danger.disabled.focus,
.btn-danger.disabled:active,
.btn-danger.disabled.active,
.btn-danger[disabled],
.btn-danger[disabled]:hover,
.btn-danger[disabled]:focus,
.btn-danger[disabled].focus,
.btn-danger[disabled]:active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger,
fieldset[disabled] .btn-danger:hover,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger.focus,
fieldset[disabled] .btn-danger:active,
fieldset[disabled] .btn-danger.active {
  background-color: $red;
  border-color: $red;
  color: $white;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-danger .badge {
  color: $red;
  background-color: $white;
}

.btn-warning:focus,
.btn-warning.focus {
  color: $white;
  background-color: #ffb81c;
  border-color: #ffb81c;
  outline: none;
}

.btn-warning:hover,
.btn-warning.hover {
  color: $white;
  -webkit-box-shadow: inset 0 -1px 0 #e89f00;
  box-shadow: inset 0 -1px 0 #e89f00;
  background-color: #ffbb26;
  border-color: #ffbb26;
  border-bottom-color: #e89f00;
}

.btn-warning:active,
.btn-warning.active,
.btn-warning:not([disabled]):not(.disabled):active,
.btn-warning:not([disabled]):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: $white;
  -webkit-box-shadow: inset 0 2px 0 #e89f00;
  box-shadow: inset 0 2px 0 #e89f00;
  background-color: #fcad00;
  border-color: #fcad00;
  border-top-color: #c48700;
}

.btn-warning:active .icon,
.btn-warning.active .icon,
.btn-warning:not([disabled]):not(.disabled):active .icon,
.btn-warning:not([disabled]):not(.disabled).active .icon,
.show > .btn-warning.dropdown-toggle .icon {
  color: $white;
}

.btn-warning:active:hover,
.btn-warning:active:focus,
.btn-warning:active.focus,
.btn-warning.active:hover,
.btn-warning.active:focus,
.btn-warning.active.focus,
.btn-warning:not([disabled]):not(.disabled):active:hover,
.btn-warning:not([disabled]):not(.disabled):active:focus,
.btn-warning:not([disabled]):not(.disabled):active.focus,
.btn-warning:not([disabled]):not(.disabled).active:hover,
.btn-warning:not([disabled]):not(.disabled).active:focus,
.btn-warning:not([disabled]):not(.disabled).active.focus,
.show > .btn-warning.dropdown-toggle:hover,
.show > .btn-warning.dropdown-toggle:focus,
.show > .btn-warning.dropdown-toggle.focus {
  color: $white;
  background-color: #fcad00;
  border-color: #fcad00;
  border-top-color: #c48700;
  -webkit-box-shadow: inset 0 2px 0 #e89f00;
  box-shadow: inset 0 2px 0 #e89f00;
}

.btn-warning:active:hover .icon,
.btn-warning:active:focus .icon,
.btn-warning:active.focus .icon,
.btn-warning.active:hover .icon,
.btn-warning.active:focus .icon,
.btn-warning.active.focus .icon,
.btn-warning:not([disabled]):not(.disabled):active:hover .icon,
.btn-warning:not([disabled]):not(.disabled):active:focus .icon,
.btn-warning:not([disabled]):not(.disabled):active.focus .icon,
.btn-warning:not([disabled]):not(.disabled).active:hover .icon,
.btn-warning:not([disabled]):not(.disabled).active:focus .icon,
.btn-warning:not([disabled]):not(.disabled).active.focus .icon,
.show > .btn-warning.dropdown-toggle:hover .icon,
.show > .btn-warning.dropdown-toggle:focus .icon,
.show > .btn-warning.dropdown-toggle.focus .icon {
  color: $white;
}

.btn-warning:active,
.btn-warning.active,
.btn-warning:not([disabled]):not(.disabled):active,
.btn-warning:not([disabled]):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  background-image: none;
}

.btn-warning.disabled,
.btn-warning.disabled:hover,
.btn-warning.disabled:focus,
.btn-warning.disabled.focus,
.btn-warning.disabled:active,
.btn-warning.disabled.active,
.btn-warning[disabled],
.btn-warning[disabled]:hover,
.btn-warning[disabled]:focus,
.btn-warning[disabled].focus,
.btn-warning[disabled]:active,
.btn-warning[disabled].active,
fieldset[disabled] .btn-warning,
fieldset[disabled] .btn-warning:hover,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning.focus,
fieldset[disabled] .btn-warning:active,
fieldset[disabled] .btn-warning.active {
  background-color: #ffb81c;
  border-color: #ffb81c;
  color: $white;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-warning .badge {
  color: #ffb81c;
  background-color: $white;
}

.btn-success {
  color: $white;
  background-color: $green;
  border-color: $green;
}

.btn-success:focus,
.btn-success.focus {
  color: $white;
  background-color: $green;
  border-color: $green;
  outline: none;
}

.btn-success:hover,
.btn-success.hover {
  color: $white;
  -webkit-box-shadow: inset 0 -1px 0 #005323;
  box-shadow: inset 0 -1px 0 #005323;
  background-color: #00903c;
  border-color: #00903c;
  border-bottom-color: #005323;
}

.btn-success:active,
.btn-success.active,
.btn-success:not([disabled]):not(.disabled):active,
.btn-success:not([disabled]):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: $white;
  -webkit-box-shadow: inset 0 2px 0 #005323;
  box-shadow: inset 0 2px 0 #005323;
  background-color: #00672b;
  border-color: #00672b;
  border-top-color: #002f14;
}

.btn-success:active .icon,
.btn-success.active .icon,
.btn-success:not([disabled]):not(.disabled):active .icon,
.btn-success:not([disabled]):not(.disabled).active .icon,
.show > .btn-success.dropdown-toggle .icon {
  color: $white;
}

.btn-success:active:hover,
.btn-success:active:focus,
.btn-success:active.focus,
.btn-success.active:hover,
.btn-success.active:focus,
.btn-success.active.focus,
.btn-success:not([disabled]):not(.disabled):active:hover,
.btn-success:not([disabled]):not(.disabled):active:focus,
.btn-success:not([disabled]):not(.disabled):active.focus,
.btn-success:not([disabled]):not(.disabled).active:hover,
.btn-success:not([disabled]):not(.disabled).active:focus,
.btn-success:not([disabled]):not(.disabled).active.focus,
.show > .btn-success.dropdown-toggle:hover,
.show > .btn-success.dropdown-toggle:focus,
.show > .btn-success.dropdown-toggle.focus {
  color: $white;
  background-color: #00672b;
  border-color: #00672b;
  border-top-color: #002f14;
  -webkit-box-shadow: inset 0 2px 0 #005323;
  box-shadow: inset 0 2px 0 #005323;
}

.btn-success:active:hover .icon,
.btn-success:active:focus .icon,
.btn-success:active.focus .icon,
.btn-success.active:hover .icon,
.btn-success.active:focus .icon,
.btn-success.active.focus .icon,
.btn-success:not([disabled]):not(.disabled):active:hover .icon,
.btn-success:not([disabled]):not(.disabled):active:focus .icon,
.btn-success:not([disabled]):not(.disabled):active.focus .icon,
.btn-success:not([disabled]):not(.disabled).active:hover .icon,
.btn-success:not([disabled]):not(.disabled).active:focus .icon,
.btn-success:not([disabled]):not(.disabled).active.focus .icon,
.show > .btn-success.dropdown-toggle:hover .icon,
.show > .btn-success.dropdown-toggle:focus .icon,
.show > .btn-success.dropdown-toggle.focus .icon {
  color: $white;
}

.btn-success:active,
.btn-success.active,
.btn-success:not([disabled]):not(.disabled):active,
.btn-success:not([disabled]):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  background-image: none;
}

.btn-success.disabled,
.btn-success.disabled:hover,
.btn-success.disabled:focus,
.btn-success.disabled.focus,
.btn-success.disabled:active,
.btn-success.disabled.active,
.btn-success[disabled],
.btn-success[disabled]:hover,
.btn-success[disabled]:focus,
.btn-success[disabled].focus,
.btn-success[disabled]:active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success,
fieldset[disabled] .btn-success:hover,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success.focus,
fieldset[disabled] .btn-success:active,
fieldset[disabled] .btn-success.active {
  background-color: $green;
  border-color: $green;
  color: $white;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-success .badge {
  color: $green;
  background-color: $white;
}

.btn-light {
  color: #404040;
  background-color: $white;
  border-color: $white;
}

.btn-light:focus,
.btn-light.focus {
  color: #404040;
  background-color: $white;
  border-color: $white;
  outline: none;
}

.btn-light:hover,
.btn-light.hover {
  color: #404040;
  -webkit-box-shadow: inset 0 -1px 0 #e6e6e6;
  box-shadow: inset 0 -1px 0 #e6e6e6;
  background-color: white;
  border-color: white;
  border-bottom-color: #e6e6e6;
}

.btn-light:active,
.btn-light.active,
.btn-light:not([disabled]):not(.disabled):active,
.btn-light:not([disabled]):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #404040;
  -webkit-box-shadow: inset 0 2px 0 #e6e6e6;
  box-shadow: inset 0 2px 0 #e6e6e6;
  background-color: #f0f0f0;
  border-color: #f0f0f0;
  border-top-color: #d4d4d4;
}

.btn-light:active .icon,
.btn-light.active .icon,
.btn-light:not([disabled]):not(.disabled):active .icon,
.btn-light:not([disabled]):not(.disabled).active .icon,
.show > .btn-light.dropdown-toggle .icon {
  color: #404040;
}

.btn-light:active:hover,
.btn-light:active:focus,
.btn-light:active.focus,
.btn-light.active:hover,
.btn-light.active:focus,
.btn-light.active.focus,
.btn-light:not([disabled]):not(.disabled):active:hover,
.btn-light:not([disabled]):not(.disabled):active:focus,
.btn-light:not([disabled]):not(.disabled):active.focus,
.btn-light:not([disabled]):not(.disabled).active:hover,
.btn-light:not([disabled]):not(.disabled).active:focus,
.btn-light:not([disabled]):not(.disabled).active.focus,
.show > .btn-light.dropdown-toggle:hover,
.show > .btn-light.dropdown-toggle:focus,
.show > .btn-light.dropdown-toggle.focus {
  color: #404040;
  background-color: #f0f0f0;
  border-color: #f0f0f0;
  border-top-color: #d4d4d4;
  -webkit-box-shadow: inset 0 2px 0 #e6e6e6;
  box-shadow: inset 0 2px 0 #e6e6e6;
}

.btn-light:active:hover .icon,
.btn-light:active:focus .icon,
.btn-light:active.focus .icon,
.btn-light.active:hover .icon,
.btn-light.active:focus .icon,
.btn-light.active.focus .icon,
.btn-light:not([disabled]):not(.disabled):active:hover .icon,
.btn-light:not([disabled]):not(.disabled):active:focus .icon,
.btn-light:not([disabled]):not(.disabled):active.focus .icon,
.btn-light:not([disabled]):not(.disabled).active:hover .icon,
.btn-light:not([disabled]):not(.disabled).active:focus .icon,
.btn-light:not([disabled]):not(.disabled).active.focus .icon,
.show > .btn-light.dropdown-toggle:hover .icon,
.show > .btn-light.dropdown-toggle:focus .icon,
.show > .btn-light.dropdown-toggle.focus .icon {
  color: #404040;
}

.btn-light:active,
.btn-light.active,
.btn-light:not([disabled]):not(.disabled):active,
.btn-light:not([disabled]):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  background-image: none;
}

.btn-light.disabled,
.btn-light.disabled:hover,
.btn-light.disabled:focus,
.btn-light.disabled.focus,
.btn-light.disabled:active,
.btn-light.disabled.active,
.btn-light[disabled],
.btn-light[disabled]:hover,
.btn-light[disabled]:focus,
.btn-light[disabled].focus,
.btn-light[disabled]:active,
.btn-light[disabled].active,
fieldset[disabled] .btn-light,
fieldset[disabled] .btn-light:hover,
fieldset[disabled] .btn-light:focus,
fieldset[disabled] .btn-light.focus,
fieldset[disabled] .btn-light:active,
fieldset[disabled] .btn-light.active {
  background-color: $white;
  border-color: $white;
  color: #404040;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-light .badge {
  color: $white;
  background-color: #404040;
}

.btn-dark {
  color: $white;
  background-color: #3d3c3c;
  border-color: #3d3c3c;
}

.btn-dark:focus,
.btn-dark.focus {
  color: $white;
  background-color: #3d3c3c;
  border-color: #3d3c3c;
  outline: none;
}

.btn-dark:hover,
.btn-dark.hover {
  color: $white;
  -webkit-box-shadow: inset 0 -1px 0 #232323;
  box-shadow: inset 0 -1px 0 #232323;
  background-color: #424141;
  border-color: #424141;
  border-bottom-color: #232323;
}

.btn-dark:active,
.btn-dark.active,
.btn-dark:not([disabled]):not(.disabled):active,
.btn-dark:not([disabled]):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: $white;
  -webkit-box-shadow: inset 0 2px 0 #232323;
  box-shadow: inset 0 2px 0 #232323;
  background-color: #2e2d2d;
  border-color: #2e2d2d;
  border-top-color: #111111;
}

.btn-dark:active .icon,
.btn-dark.active .icon,
.btn-dark:not([disabled]):not(.disabled):active .icon,
.btn-dark:not([disabled]):not(.disabled).active .icon,
.show > .btn-dark.dropdown-toggle .icon {
  color: $white;
}

.btn-dark:active:hover,
.btn-dark:active:focus,
.btn-dark:active.focus,
.btn-dark.active:hover,
.btn-dark.active:focus,
.btn-dark.active.focus,
.btn-dark:not([disabled]):not(.disabled):active:hover,
.btn-dark:not([disabled]):not(.disabled):active:focus,
.btn-dark:not([disabled]):not(.disabled):active.focus,
.btn-dark:not([disabled]):not(.disabled).active:hover,
.btn-dark:not([disabled]):not(.disabled).active:focus,
.btn-dark:not([disabled]):not(.disabled).active.focus,
.show > .btn-dark.dropdown-toggle:hover,
.show > .btn-dark.dropdown-toggle:focus,
.show > .btn-dark.dropdown-toggle.focus {
  color: $white;
  background-color: #2e2d2d;
  border-color: #2e2d2d;
  border-top-color: #111111;
  -webkit-box-shadow: inset 0 2px 0 #232323;
  box-shadow: inset 0 2px 0 #232323;
}

.btn-dark:active:hover .icon,
.btn-dark:active:focus .icon,
.btn-dark:active.focus .icon,
.btn-dark.active:hover .icon,
.btn-dark.active:focus .icon,
.btn-dark.active.focus .icon,
.btn-dark:not([disabled]):not(.disabled):active:hover .icon,
.btn-dark:not([disabled]):not(.disabled):active:focus .icon,
.btn-dark:not([disabled]):not(.disabled):active.focus .icon,
.btn-dark:not([disabled]):not(.disabled).active:hover .icon,
.btn-dark:not([disabled]):not(.disabled).active:focus .icon,
.btn-dark:not([disabled]):not(.disabled).active.focus .icon,
.show > .btn-dark.dropdown-toggle:hover .icon,
.show > .btn-dark.dropdown-toggle:focus .icon,
.show > .btn-dark.dropdown-toggle.focus .icon {
  color: $white;
}

.btn-dark:active,
.btn-dark.active,
.btn-dark:not([disabled]):not(.disabled):active,
.btn-dark:not([disabled]):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  background-image: none;
}

.btn-dark.disabled,
.btn-dark.disabled:hover,
.btn-dark.disabled:focus,
.btn-dark.disabled.focus,
.btn-dark.disabled:active,
.btn-dark.disabled.active,
.btn-dark[disabled],
.btn-dark[disabled]:hover,
.btn-dark[disabled]:focus,
.btn-dark[disabled].focus,
.btn-dark[disabled]:active,
.btn-dark[disabled].active,
fieldset[disabled] .btn-dark,
fieldset[disabled] .btn-dark:hover,
fieldset[disabled] .btn-dark:focus,
fieldset[disabled] .btn-dark.focus,
fieldset[disabled] .btn-dark:active,
fieldset[disabled] .btn-dark.active {
  background-color: #3d3c3c;
  border-color: #3d3c3c;
  color: $white;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-dark .badge {
  color: #3d3c3c;
  background-color: $white;
}

.btn-xs {
  padding: 0 6px;
  font-size: 0.8462rem;
  line-height: 18px;
  border-radius: 1px;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0 8px;
  font-size: 1rem;
  line-height: 22px;
  border-radius: 2px;
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 0 12px;
  font-size: 1.1538rem;
  line-height: 38px;
  border-radius: 3px;
  font-weight: 500;
}

.btn-xl {
  padding: 0 12px;
  font-size: 1.1538rem;
  line-height: 43px;
  border-radius: 3px;
  font-weight: 500;
}

.btn-rounded {
  border-radius: 15px;
}

.btn-rounded.btn-xs {
  border-radius: 10px;
}

.btn-rounded.btn-sm,
.btn-group-sm > .btn-rounded.btn {
  border-radius: 12px;
}

.btn-rounded.btn-lg,
.btn-group-lg > .btn-rounded.btn {
  border-radius: 20px;
}

.btn-hspace {
  margin-right: 5px;
}

.btn-vspace {
  margin-bottom: 5px;
}

.btn-space {
  margin-right: 5px;
  margin-bottom: 5px;
}

.btn-facebook .icon {
  color: #5d82d1;
}

.btn-facebook:active .icon,
.btn-facebook.active .icon,
.show > .btn-facebook.dropdown-toggle .icon {
  color: #5d82d1;
}

.btn-facebook:active:hover .icon,
.btn-facebook:active:focus .icon,
.btn-facebook:active.focus .icon,
.btn-facebook.active:hover .icon,
.btn-facebook.active:focus .icon,
.btn-facebook.active.focus .icon,
.show > .btn-facebook.dropdown-toggle:hover .icon,
.show > .btn-facebook.dropdown-toggle:focus .icon,
.show > .btn-facebook.dropdown-toggle.focus .icon {
  color: #5d82d1;
}

.btn-twitter .icon {
  color: #50bff5;
}

.btn-twitter:active .icon,
.btn-twitter.active .icon,
.show > .btn-twitter.dropdown-toggle .icon {
  color: #50bff5;
}

.btn-twitter:active:hover .icon,
.btn-twitter:active:focus .icon,
.btn-twitter:active.focus .icon,
.btn-twitter.active:hover .icon,
.btn-twitter.active:focus .icon,
.btn-twitter.active.focus .icon,
.show > .btn-twitter.dropdown-toggle:hover .icon,
.show > .btn-twitter.dropdown-toggle:focus .icon,
.show > .btn-twitter.dropdown-toggle.focus .icon {
  color: #50bff5;
}

.btn-google-plus .icon {
  color: #eb5e4c;
}

.btn-google-plus:active .icon,
.btn-google-plus.active .icon,
.show > .btn-google-plus.dropdown-toggle .icon {
  color: #eb5e4c;
}

.btn-google-plus:active:hover .icon,
.btn-google-plus:active:focus .icon,
.btn-google-plus:active.focus .icon,
.btn-google-plus.active:hover .icon,
.btn-google-plus.active:focus .icon,
.btn-google-plus.active.focus .icon,
.show > .btn-google-plus.dropdown-toggle:hover .icon,
.show > .btn-google-plus.dropdown-toggle:focus .icon,
.show > .btn-google-plus.dropdown-toggle.focus .icon {
  color: #eb5e4c;
}

.btn-dribbble .icon {
  color: #f7659c;
}

.btn-dribbble:active .icon,
.btn-dribbble.active .icon,
.show > .btn-dribbble.dropdown-toggle .icon {
  color: #f7659c;
}

.btn-dribbble:active:hover .icon,
.btn-dribbble:active:focus .icon,
.btn-dribbble:active.focus .icon,
.btn-dribbble.active:hover .icon,
.btn-dribbble.active:focus .icon,
.btn-dribbble.active.focus .icon,
.show > .btn-dribbble.dropdown-toggle:hover .icon,
.show > .btn-dribbble.dropdown-toggle:focus .icon,
.show > .btn-dribbble.dropdown-toggle.focus .icon {
  color: #f7659c;
}

.btn-flickr .icon {
  color: #0063dc;
}

.btn-flickr:active .icon,
.btn-flickr.active .icon,
.show > .btn-flickr.dropdown-toggle .icon {
  color: #0063dc;
}

.btn-flickr:active:hover .icon,
.btn-flickr:active:focus .icon,
.btn-flickr:active.focus .icon,
.btn-flickr.active:hover .icon,
.btn-flickr.active:focus .icon,
.btn-flickr.active.focus .icon,
.show > .btn-flickr.dropdown-toggle:hover .icon,
.show > .btn-flickr.dropdown-toggle:focus .icon,
.show > .btn-flickr.dropdown-toggle.focus .icon {
  color: #0063dc;
}

.btn-linkedin .icon {
  color: #238cc8;
}

.btn-linkedin:active .icon,
.btn-linkedin.active .icon,
.show > .btn-linkedin.dropdown-toggle .icon {
  color: #238cc8;
}

.btn-linkedin:active:hover .icon,
.btn-linkedin:active:focus .icon,
.btn-linkedin:active.focus .icon,
.btn-linkedin.active:hover .icon,
.btn-linkedin.active:focus .icon,
.btn-linkedin.active.focus .icon,
.show > .btn-linkedin.dropdown-toggle:hover .icon,
.show > .btn-linkedin.dropdown-toggle:focus .icon,
.show > .btn-linkedin.dropdown-toggle.focus .icon {
  color: #238cc8;
}

.btn-youtube .icon {
  color: #ef4e41;
}

.btn-youtube:active .icon,
.btn-youtube.active .icon,
.show > .btn-youtube.dropdown-toggle .icon {
  color: #ef4e41;
}

.btn-youtube:active:hover .icon,
.btn-youtube:active:focus .icon,
.btn-youtube:active.focus .icon,
.btn-youtube.active:hover .icon,
.btn-youtube.active:focus .icon,
.btn-youtube.active.focus .icon,
.show > .btn-youtube.dropdown-toggle:hover .icon,
.show > .btn-youtube.dropdown-toggle:focus .icon,
.show > .btn-youtube.dropdown-toggle.focus .icon {
  color: #ef4e41;
}

.btn-pinterest .icon {
  color: #e13138;
}

.btn-pinterest:active .icon,
.btn-pinterest.active .icon,
.show > .btn-pinterest.dropdown-toggle .icon {
  color: #e13138;
}

.btn-pinterest:active:hover .icon,
.btn-pinterest:active:focus .icon,
.btn-pinterest:active.focus .icon,
.btn-pinterest.active:hover .icon,
.btn-pinterest.active:focus .icon,
.btn-pinterest.active.focus .icon,
.show > .btn-pinterest.dropdown-toggle:hover .icon,
.show > .btn-pinterest.dropdown-toggle:focus .icon,
.show > .btn-pinterest.dropdown-toggle.focus .icon {
  color: #e13138;
}

.btn-github .icon {
  color: #333;
}

.btn-github:active .icon,
.btn-github.active .icon,
.show > .btn-github.dropdown-toggle .icon {
  color: #333;
}

.btn-github:active:hover .icon,
.btn-github:active:focus .icon,
.btn-github:active.focus .icon,
.btn-github.active:hover .icon,
.btn-github.active:focus .icon,
.btn-github.active.focus .icon,
.show > .btn-github.dropdown-toggle:hover .icon,
.show > .btn-github.dropdown-toggle:focus .icon,
.show > .btn-github.dropdown-toggle.focus .icon {
  color: #333;
}

.btn-tumblr .icon {
  color: #426d9b;
}

.btn-tumblr:active .icon,
.btn-tumblr.active .icon,
.show > .btn-tumblr.dropdown-toggle .icon {
  color: #426d9b;
}

.btn-tumblr:active:hover .icon,
.btn-tumblr:active:focus .icon,
.btn-tumblr:active.focus .icon,
.btn-tumblr.active:hover .icon,
.btn-tumblr.active:focus .icon,
.btn-tumblr.active.focus .icon,
.show > .btn-tumblr.dropdown-toggle:hover .icon,
.show > .btn-tumblr.dropdown-toggle:focus .icon,
.show > .btn-tumblr.dropdown-toggle.focus .icon {
  color: #426d9b;
}

.btn-dropbox .icon {
  color: #007ee5;
}

.btn-dropbox:active .icon,
.btn-dropbox.active .icon,
.show > .btn-dropbox.dropdown-toggle .icon {
  color: #007ee5;
}

.btn-dropbox:active:hover .icon,
.btn-dropbox:active:focus .icon,
.btn-dropbox:active.focus .icon,
.btn-dropbox.active:hover .icon,
.btn-dropbox.active:focus .icon,
.btn-dropbox.active.focus .icon,
.show > .btn-dropbox.dropdown-toggle:hover .icon,
.show > .btn-dropbox.dropdown-toggle:focus .icon,
.show > .btn-dropbox.dropdown-toggle.focus .icon {
  color: #007ee5;
}

.btn-evernote .icon {
  color: #2dbe60;
}

.btn-evernote:active .icon,
.btn-evernote.active .icon,
.show > .btn-evernote.dropdown-toggle .icon {
  color: #2dbe60;
}

.btn-evernote:active:hover .icon,
.btn-evernote:active:focus .icon,
.btn-evernote:active.focus .icon,
.btn-evernote.active:hover .icon,
.btn-evernote.active:focus .icon,
.btn-evernote.active.focus .icon,
.show > .btn-evernote.dropdown-toggle:hover .icon,
.show > .btn-evernote.dropdown-toggle:focus .icon,
.show > .btn-evernote.dropdown-toggle.focus .icon {
  color: #2dbe60;
}

.btn-social {
  min-width: 31px;
  padding: 0 8px;
}

.btn-social .icon {
  font-size: 1.308rem;
}

.btn-color.btn-facebook {
  color: $white;
  background-color: #5d82d1;
  border-color: #5d82d1;
}

.btn-color.btn-facebook .icon {
  color: $white;
}

.btn-color.btn-facebook:focus,
.btn-color.btn-facebook.focus {
  color: $white;
  background-color: #5d82d1;
  border-color: #5d82d1;
  outline: none;
}

.btn-color.btn-facebook:hover,
.btn-color.btn-facebook.hover {
  color: $white;
  -webkit-box-shadow: inset 0 -1px 0 #3864c3;
  box-shadow: inset 0 -1px 0 #3864c3;
  background-color: #6588d3;
  border-color: #6588d3;
  border-bottom-color: #3864c3;
}

.btn-color.btn-facebook:active,
.btn-color.btn-facebook.active,
.btn-color.btn-facebook:not([disabled]):not(.disabled):active,
.btn-color.btn-facebook:not([disabled]):not(.disabled).active,
.show > .btn-color.btn-facebook.dropdown-toggle {
  color: $white;
  -webkit-box-shadow: inset 0 2px 0 #3864c3;
  box-shadow: inset 0 2px 0 #3864c3;
  background-color: #4570ca;
  border-color: #4570ca;
  border-top-color: #3056a8;
}

.btn-color.btn-facebook:active .icon,
.btn-color.btn-facebook.active .icon,
.btn-color.btn-facebook:not([disabled]):not(.disabled):active .icon,
.btn-color.btn-facebook:not([disabled]):not(.disabled).active .icon,
.show > .btn-color.btn-facebook.dropdown-toggle .icon {
  color: $white;
}

.btn-color.btn-facebook:active:hover,
.btn-color.btn-facebook:active:focus,
.btn-color.btn-facebook:active.focus,
.btn-color.btn-facebook.active:hover,
.btn-color.btn-facebook.active:focus,
.btn-color.btn-facebook.active.focus,
.btn-color.btn-facebook:not([disabled]):not(.disabled):active:hover,
.btn-color.btn-facebook:not([disabled]):not(.disabled):active:focus,
.btn-color.btn-facebook:not([disabled]):not(.disabled):active.focus,
.btn-color.btn-facebook:not([disabled]):not(.disabled).active:hover,
.btn-color.btn-facebook:not([disabled]):not(.disabled).active:focus,
.btn-color.btn-facebook:not([disabled]):not(.disabled).active.focus,
.show > .btn-color.btn-facebook.dropdown-toggle:hover,
.show > .btn-color.btn-facebook.dropdown-toggle:focus,
.show > .btn-color.btn-facebook.dropdown-toggle.focus {
  color: $white;
  background-color: #4570ca;
  border-color: #4570ca;
  border-top-color: #3056a8;
  -webkit-box-shadow: inset 0 2px 0 #3864c3;
  box-shadow: inset 0 2px 0 #3864c3;
}

.btn-color.btn-facebook:active:hover .icon,
.btn-color.btn-facebook:active:focus .icon,
.btn-color.btn-facebook:active.focus .icon,
.btn-color.btn-facebook.active:hover .icon,
.btn-color.btn-facebook.active:focus .icon,
.btn-color.btn-facebook.active.focus .icon,
.btn-color.btn-facebook:not([disabled]):not(.disabled):active:hover .icon,
.btn-color.btn-facebook:not([disabled]):not(.disabled):active:focus .icon,
.btn-color.btn-facebook:not([disabled]):not(.disabled):active.focus .icon,
.btn-color.btn-facebook:not([disabled]):not(.disabled).active:hover .icon,
.btn-color.btn-facebook:not([disabled]):not(.disabled).active:focus .icon,
.btn-color.btn-facebook:not([disabled]):not(.disabled).active.focus .icon,
.show > .btn-color.btn-facebook.dropdown-toggle:hover .icon,
.show > .btn-color.btn-facebook.dropdown-toggle:focus .icon,
.show > .btn-color.btn-facebook.dropdown-toggle.focus .icon {
  color: $white;
}

.btn-color.btn-facebook:active,
.btn-color.btn-facebook.active,
.btn-color.btn-facebook:not([disabled]):not(.disabled):active,
.btn-color.btn-facebook:not([disabled]):not(.disabled).active,
.show > .btn-color.btn-facebook.dropdown-toggle {
  background-image: none;
}

.btn-color.btn-facebook.disabled,
.btn-color.btn-facebook.disabled:hover,
.btn-color.btn-facebook.disabled:focus,
.btn-color.btn-facebook.disabled.focus,
.btn-color.btn-facebook.disabled:active,
.btn-color.btn-facebook.disabled.active,
.btn-color.btn-facebook[disabled],
.btn-color.btn-facebook[disabled]:hover,
.btn-color.btn-facebook[disabled]:focus,
.btn-color.btn-facebook[disabled].focus,
.btn-color.btn-facebook[disabled]:active,
.btn-color.btn-facebook[disabled].active,
fieldset[disabled] .btn-color.btn-facebook,
fieldset[disabled] .btn-color.btn-facebook:hover,
fieldset[disabled] .btn-color.btn-facebook:focus,
fieldset[disabled] .btn-color.btn-facebook.focus,
fieldset[disabled] .btn-color.btn-facebook:active,
fieldset[disabled] .btn-color.btn-facebook.active {
  background-color: #5d82d1;
  border-color: #5d82d1;
  color: $white;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-color.btn-facebook .badge {
  color: #5d82d1;
  background-color: $white;
}

.btn-color.btn-twitter {
  color: $white;
  background-color: #50bff5;
  border-color: #50bff5;
}

.btn-color.btn-twitter .icon {
  color: $white;
}

.btn-color.btn-twitter:focus,
.btn-color.btn-twitter.focus {
  color: $white;
  background-color: #50bff5;
  border-color: #50bff5;
  outline: none;
}

.btn-color.btn-twitter:hover,
.btn-color.btn-twitter.hover {
  color: $white;
  -webkit-box-shadow: inset 0 -1px 0 #20adf2;
  box-shadow: inset 0 -1px 0 #20adf2;
  background-color: #5ac3f6;
  border-color: #5ac3f6;
  border-bottom-color: #20adf2;
}

.btn-color.btn-twitter:active,
.btn-color.btn-twitter.active,
.btn-color.btn-twitter:not([disabled]):not(.disabled):active,
.btn-color.btn-twitter:not([disabled]):not(.disabled).active,
.show > .btn-color.btn-twitter.dropdown-toggle {
  color: $white;
  -webkit-box-shadow: inset 0 2px 0 #20adf2;
  box-shadow: inset 0 2px 0 #20adf2;
  background-color: #33b4f3;
  border-color: #33b4f3;
  border-top-color: #0d9ce1;
}

.btn-color.btn-twitter:active .icon,
.btn-color.btn-twitter.active .icon,
.btn-color.btn-twitter:not([disabled]):not(.disabled):active .icon,
.btn-color.btn-twitter:not([disabled]):not(.disabled).active .icon,
.show > .btn-color.btn-twitter.dropdown-toggle .icon {
  color: $white;
}

.btn-color.btn-twitter:active:hover,
.btn-color.btn-twitter:active:focus,
.btn-color.btn-twitter:active.focus,
.btn-color.btn-twitter.active:hover,
.btn-color.btn-twitter.active:focus,
.btn-color.btn-twitter.active.focus,
.btn-color.btn-twitter:not([disabled]):not(.disabled):active:hover,
.btn-color.btn-twitter:not([disabled]):not(.disabled):active:focus,
.btn-color.btn-twitter:not([disabled]):not(.disabled):active.focus,
.btn-color.btn-twitter:not([disabled]):not(.disabled).active:hover,
.btn-color.btn-twitter:not([disabled]):not(.disabled).active:focus,
.btn-color.btn-twitter:not([disabled]):not(.disabled).active.focus,
.show > .btn-color.btn-twitter.dropdown-toggle:hover,
.show > .btn-color.btn-twitter.dropdown-toggle:focus,
.show > .btn-color.btn-twitter.dropdown-toggle.focus {
  color: $white;
  background-color: #33b4f3;
  border-color: #33b4f3;
  border-top-color: #0d9ce1;
  -webkit-box-shadow: inset 0 2px 0 #20adf2;
  box-shadow: inset 0 2px 0 #20adf2;
}

.btn-color.btn-twitter:active:hover .icon,
.btn-color.btn-twitter:active:focus .icon,
.btn-color.btn-twitter:active.focus .icon,
.btn-color.btn-twitter.active:hover .icon,
.btn-color.btn-twitter.active:focus .icon,
.btn-color.btn-twitter.active.focus .icon,
.btn-color.btn-twitter:not([disabled]):not(.disabled):active:hover .icon,
.btn-color.btn-twitter:not([disabled]):not(.disabled):active:focus .icon,
.btn-color.btn-twitter:not([disabled]):not(.disabled):active.focus .icon,
.btn-color.btn-twitter:not([disabled]):not(.disabled).active:hover .icon,
.btn-color.btn-twitter:not([disabled]):not(.disabled).active:focus .icon,
.btn-color.btn-twitter:not([disabled]):not(.disabled).active.focus .icon,
.show > .btn-color.btn-twitter.dropdown-toggle:hover .icon,
.show > .btn-color.btn-twitter.dropdown-toggle:focus .icon,
.show > .btn-color.btn-twitter.dropdown-toggle.focus .icon {
  color: $white;
}

.btn-color.btn-twitter:active,
.btn-color.btn-twitter.active,
.btn-color.btn-twitter:not([disabled]):not(.disabled):active,
.btn-color.btn-twitter:not([disabled]):not(.disabled).active,
.show > .btn-color.btn-twitter.dropdown-toggle {
  background-image: none;
}

.btn-color.btn-twitter.disabled,
.btn-color.btn-twitter.disabled:hover,
.btn-color.btn-twitter.disabled:focus,
.btn-color.btn-twitter.disabled.focus,
.btn-color.btn-twitter.disabled:active,
.btn-color.btn-twitter.disabled.active,
.btn-color.btn-twitter[disabled],
.btn-color.btn-twitter[disabled]:hover,
.btn-color.btn-twitter[disabled]:focus,
.btn-color.btn-twitter[disabled].focus,
.btn-color.btn-twitter[disabled]:active,
.btn-color.btn-twitter[disabled].active,
fieldset[disabled] .btn-color.btn-twitter,
fieldset[disabled] .btn-color.btn-twitter:hover,
fieldset[disabled] .btn-color.btn-twitter:focus,
fieldset[disabled] .btn-color.btn-twitter.focus,
fieldset[disabled] .btn-color.btn-twitter:active,
fieldset[disabled] .btn-color.btn-twitter.active {
  background-color: #50bff5;
  border-color: #50bff5;
  color: $white;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-color.btn-twitter .badge {
  color: #50bff5;
  background-color: $white;
}

.btn-color.btn-google-plus {
  color: $white;
  background-color: #eb5e4c;
  border-color: #eb5e4c;
}

.btn-color.btn-google-plus .icon {
  color: $white;
}

.btn-color.btn-google-plus:focus,
.btn-color.btn-google-plus.focus {
  color: $white;
  background-color: #eb5e4c;
  border-color: #eb5e4c;
  outline: none;
}

.btn-color.btn-google-plus:hover,
.btn-color.btn-google-plus.hover {
  color: $white;
  -webkit-box-shadow: inset 0 -1px 0 #e6351e;
  box-shadow: inset 0 -1px 0 #e6351e;
  background-color: #ec6655;
  border-color: #ec6655;
  border-bottom-color: #e6351e;
}

.btn-color.btn-google-plus:active,
.btn-color.btn-google-plus.active,
.btn-color.btn-google-plus:not([disabled]):not(.disabled):active,
.btn-color.btn-google-plus:not([disabled]):not(.disabled).active,
.show > .btn-color.btn-google-plus.dropdown-toggle {
  color: $white;
  -webkit-box-shadow: inset 0 2px 0 #e6351e;
  box-shadow: inset 0 2px 0 #e6351e;
  background-color: #e84530;
  border-color: #e84530;
  border-top-color: #ca2b17;
}

.btn-color.btn-google-plus:active .icon,
.btn-color.btn-google-plus.active .icon,
.btn-color.btn-google-plus:not([disabled]):not(.disabled):active .icon,
.btn-color.btn-google-plus:not([disabled]):not(.disabled).active .icon,
.show > .btn-color.btn-google-plus.dropdown-toggle .icon {
  color: $white;
}

.btn-color.btn-google-plus:active:hover,
.btn-color.btn-google-plus:active:focus,
.btn-color.btn-google-plus:active.focus,
.btn-color.btn-google-plus.active:hover,
.btn-color.btn-google-plus.active:focus,
.btn-color.btn-google-plus.active.focus,
.btn-color.btn-google-plus:not([disabled]):not(.disabled):active:hover,
.btn-color.btn-google-plus:not([disabled]):not(.disabled):active:focus,
.btn-color.btn-google-plus:not([disabled]):not(.disabled):active.focus,
.btn-color.btn-google-plus:not([disabled]):not(.disabled).active:hover,
.btn-color.btn-google-plus:not([disabled]):not(.disabled).active:focus,
.btn-color.btn-google-plus:not([disabled]):not(.disabled).active.focus,
.show > .btn-color.btn-google-plus.dropdown-toggle:hover,
.show > .btn-color.btn-google-plus.dropdown-toggle:focus,
.show > .btn-color.btn-google-plus.dropdown-toggle.focus {
  color: $white;
  background-color: #e84530;
  border-color: #e84530;
  border-top-color: #ca2b17;
  -webkit-box-shadow: inset 0 2px 0 #e6351e;
  box-shadow: inset 0 2px 0 #e6351e;
}

.btn-color.btn-google-plus:active:hover .icon,
.btn-color.btn-google-plus:active:focus .icon,
.btn-color.btn-google-plus:active.focus .icon,
.btn-color.btn-google-plus.active:hover .icon,
.btn-color.btn-google-plus.active:focus .icon,
.btn-color.btn-google-plus.active.focus .icon,
.btn-color.btn-google-plus:not([disabled]):not(.disabled):active:hover .icon,
.btn-color.btn-google-plus:not([disabled]):not(.disabled):active:focus .icon,
.btn-color.btn-google-plus:not([disabled]):not(.disabled):active.focus .icon,
.btn-color.btn-google-plus:not([disabled]):not(.disabled).active:hover .icon,
.btn-color.btn-google-plus:not([disabled]):not(.disabled).active:focus .icon,
.btn-color.btn-google-plus:not([disabled]):not(.disabled).active.focus .icon,
.show > .btn-color.btn-google-plus.dropdown-toggle:hover .icon,
.show > .btn-color.btn-google-plus.dropdown-toggle:focus .icon,
.show > .btn-color.btn-google-plus.dropdown-toggle.focus .icon {
  color: $white;
}

.btn-color.btn-google-plus:active,
.btn-color.btn-google-plus.active,
.btn-color.btn-google-plus:not([disabled]):not(.disabled):active,
.btn-color.btn-google-plus:not([disabled]):not(.disabled).active,
.show > .btn-color.btn-google-plus.dropdown-toggle {
  background-image: none;
}

.btn-color.btn-google-plus.disabled,
.btn-color.btn-google-plus.disabled:hover,
.btn-color.btn-google-plus.disabled:focus,
.btn-color.btn-google-plus.disabled.focus,
.btn-color.btn-google-plus.disabled:active,
.btn-color.btn-google-plus.disabled.active,
.btn-color.btn-google-plus[disabled],
.btn-color.btn-google-plus[disabled]:hover,
.btn-color.btn-google-plus[disabled]:focus,
.btn-color.btn-google-plus[disabled].focus,
.btn-color.btn-google-plus[disabled]:active,
.btn-color.btn-google-plus[disabled].active,
fieldset[disabled] .btn-color.btn-google-plus,
fieldset[disabled] .btn-color.btn-google-plus:hover,
fieldset[disabled] .btn-color.btn-google-plus:focus,
fieldset[disabled] .btn-color.btn-google-plus.focus,
fieldset[disabled] .btn-color.btn-google-plus:active,
fieldset[disabled] .btn-color.btn-google-plus.active {
  background-color: #eb5e4c;
  border-color: #eb5e4c;
  color: $white;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-color.btn-google-plus .badge {
  color: #eb5e4c;
  background-color: $white;
}

.btn-color.btn-dribbble {
  color: $white;
  background-color: #f7659c;
  border-color: #f7659c;
}

.btn-color.btn-dribbble .icon {
  color: $white;
}

.btn-color.btn-dribbble:focus,
.btn-color.btn-dribbble.focus {
  color: $white;
  background-color: #f7659c;
  border-color: #f7659c;
  outline: none;
}

.btn-color.btn-dribbble:hover,
.btn-color.btn-dribbble.hover {
  color: $white;
  -webkit-box-shadow: inset 0 -1px 0 #f4357d;
  box-shadow: inset 0 -1px 0 #f4357d;
  background-color: #f86fa2;
  border-color: #f86fa2;
  border-bottom-color: #f4357d;
}

.btn-color.btn-dribbble:active,
.btn-color.btn-dribbble.active,
.btn-color.btn-dribbble:not([disabled]):not(.disabled):active,
.btn-color.btn-dribbble:not([disabled]):not(.disabled).active,
.show > .btn-color.btn-dribbble.dropdown-toggle {
  color: $white;
  -webkit-box-shadow: inset 0 2px 0 #f4357d;
  box-shadow: inset 0 2px 0 #f4357d;
  background-color: #f54889;
  border-color: #f54889;
  border-top-color: #f31367;
}

.btn-color.btn-dribbble:active .icon,
.btn-color.btn-dribbble.active .icon,
.btn-color.btn-dribbble:not([disabled]):not(.disabled):active .icon,
.btn-color.btn-dribbble:not([disabled]):not(.disabled).active .icon,
.show > .btn-color.btn-dribbble.dropdown-toggle .icon {
  color: $white;
}

.btn-color.btn-dribbble:active:hover,
.btn-color.btn-dribbble:active:focus,
.btn-color.btn-dribbble:active.focus,
.btn-color.btn-dribbble.active:hover,
.btn-color.btn-dribbble.active:focus,
.btn-color.btn-dribbble.active.focus,
.btn-color.btn-dribbble:not([disabled]):not(.disabled):active:hover,
.btn-color.btn-dribbble:not([disabled]):not(.disabled):active:focus,
.btn-color.btn-dribbble:not([disabled]):not(.disabled):active.focus,
.btn-color.btn-dribbble:not([disabled]):not(.disabled).active:hover,
.btn-color.btn-dribbble:not([disabled]):not(.disabled).active:focus,
.btn-color.btn-dribbble:not([disabled]):not(.disabled).active.focus,
.show > .btn-color.btn-dribbble.dropdown-toggle:hover,
.show > .btn-color.btn-dribbble.dropdown-toggle:focus,
.show > .btn-color.btn-dribbble.dropdown-toggle.focus {
  color: $white;
  background-color: #f54889;
  border-color: #f54889;
  border-top-color: #f31367;
  -webkit-box-shadow: inset 0 2px 0 #f4357d;
  box-shadow: inset 0 2px 0 #f4357d;
}

.btn-color.btn-dribbble:active:hover .icon,
.btn-color.btn-dribbble:active:focus .icon,
.btn-color.btn-dribbble:active.focus .icon,
.btn-color.btn-dribbble.active:hover .icon,
.btn-color.btn-dribbble.active:focus .icon,
.btn-color.btn-dribbble.active.focus .icon,
.btn-color.btn-dribbble:not([disabled]):not(.disabled):active:hover .icon,
.btn-color.btn-dribbble:not([disabled]):not(.disabled):active:focus .icon,
.btn-color.btn-dribbble:not([disabled]):not(.disabled):active.focus .icon,
.btn-color.btn-dribbble:not([disabled]):not(.disabled).active:hover .icon,
.btn-color.btn-dribbble:not([disabled]):not(.disabled).active:focus .icon,
.btn-color.btn-dribbble:not([disabled]):not(.disabled).active.focus .icon,
.show > .btn-color.btn-dribbble.dropdown-toggle:hover .icon,
.show > .btn-color.btn-dribbble.dropdown-toggle:focus .icon,
.show > .btn-color.btn-dribbble.dropdown-toggle.focus .icon {
  color: $white;
}

.btn-color.btn-dribbble:active,
.btn-color.btn-dribbble.active,
.btn-color.btn-dribbble:not([disabled]):not(.disabled):active,
.btn-color.btn-dribbble:not([disabled]):not(.disabled).active,
.show > .btn-color.btn-dribbble.dropdown-toggle {
  background-image: none;
}

.btn-color.btn-dribbble.disabled,
.btn-color.btn-dribbble.disabled:hover,
.btn-color.btn-dribbble.disabled:focus,
.btn-color.btn-dribbble.disabled.focus,
.btn-color.btn-dribbble.disabled:active,
.btn-color.btn-dribbble.disabled.active,
.btn-color.btn-dribbble[disabled],
.btn-color.btn-dribbble[disabled]:hover,
.btn-color.btn-dribbble[disabled]:focus,
.btn-color.btn-dribbble[disabled].focus,
.btn-color.btn-dribbble[disabled]:active,
.btn-color.btn-dribbble[disabled].active,
fieldset[disabled] .btn-color.btn-dribbble,
fieldset[disabled] .btn-color.btn-dribbble:hover,
fieldset[disabled] .btn-color.btn-dribbble:focus,
fieldset[disabled] .btn-color.btn-dribbble.focus,
fieldset[disabled] .btn-color.btn-dribbble:active,
fieldset[disabled] .btn-color.btn-dribbble.active {
  background-color: #f7659c;
  border-color: #f7659c;
  color: $white;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-color.btn-dribbble .badge {
  color: #f7659c;
  background-color: $white;
}

.btn-color.btn-flickr {
  color: $white;
  background-color: #0063dc;
  border-color: #0063dc;
}

.btn-color.btn-flickr .icon {
  color: $white;
}

.btn-color.btn-flickr:focus,
.btn-color.btn-flickr.focus {
  color: $white;
  background-color: #0063dc;
  border-color: #0063dc;
  outline: none;
}

.btn-color.btn-flickr:hover,
.btn-color.btn-flickr.hover {
  color: $white;
  -webkit-box-shadow: inset 0 -1px 0 #004ca9;
  box-shadow: inset 0 -1px 0 #004ca9;
  background-color: #0068e6;
  border-color: #0068e6;
  border-bottom-color: #004ca9;
}

.btn-color.btn-flickr:active,
.btn-color.btn-flickr.active,
.btn-color.btn-flickr:not([disabled]):not(.disabled):active,
.btn-color.btn-flickr:not([disabled]):not(.disabled).active,
.show > .btn-color.btn-flickr.dropdown-toggle {
  color: $white;
  -webkit-box-shadow: inset 0 2px 0 #004ca9;
  box-shadow: inset 0 2px 0 #004ca9;
  background-color: #0055bd;
  border-color: #0055bd;
  border-top-color: #003c85;
}

.btn-color.btn-flickr:active .icon,
.btn-color.btn-flickr.active .icon,
.btn-color.btn-flickr:not([disabled]):not(.disabled):active .icon,
.btn-color.btn-flickr:not([disabled]):not(.disabled).active .icon,
.show > .btn-color.btn-flickr.dropdown-toggle .icon {
  color: $white;
}

.btn-color.btn-flickr:active:hover,
.btn-color.btn-flickr:active:focus,
.btn-color.btn-flickr:active.focus,
.btn-color.btn-flickr.active:hover,
.btn-color.btn-flickr.active:focus,
.btn-color.btn-flickr.active.focus,
.btn-color.btn-flickr:not([disabled]):not(.disabled):active:hover,
.btn-color.btn-flickr:not([disabled]):not(.disabled):active:focus,
.btn-color.btn-flickr:not([disabled]):not(.disabled):active.focus,
.btn-color.btn-flickr:not([disabled]):not(.disabled).active:hover,
.btn-color.btn-flickr:not([disabled]):not(.disabled).active:focus,
.btn-color.btn-flickr:not([disabled]):not(.disabled).active.focus,
.show > .btn-color.btn-flickr.dropdown-toggle:hover,
.show > .btn-color.btn-flickr.dropdown-toggle:focus,
.show > .btn-color.btn-flickr.dropdown-toggle.focus {
  color: $white;
  background-color: #0055bd;
  border-color: #0055bd;
  border-top-color: #003c85;
  -webkit-box-shadow: inset 0 2px 0 #004ca9;
  box-shadow: inset 0 2px 0 #004ca9;
}

.btn-color.btn-flickr:active:hover .icon,
.btn-color.btn-flickr:active:focus .icon,
.btn-color.btn-flickr:active.focus .icon,
.btn-color.btn-flickr.active:hover .icon,
.btn-color.btn-flickr.active:focus .icon,
.btn-color.btn-flickr.active.focus .icon,
.btn-color.btn-flickr:not([disabled]):not(.disabled):active:hover .icon,
.btn-color.btn-flickr:not([disabled]):not(.disabled):active:focus .icon,
.btn-color.btn-flickr:not([disabled]):not(.disabled):active.focus .icon,
.btn-color.btn-flickr:not([disabled]):not(.disabled).active:hover .icon,
.btn-color.btn-flickr:not([disabled]):not(.disabled).active:focus .icon,
.btn-color.btn-flickr:not([disabled]):not(.disabled).active.focus .icon,
.show > .btn-color.btn-flickr.dropdown-toggle:hover .icon,
.show > .btn-color.btn-flickr.dropdown-toggle:focus .icon,
.show > .btn-color.btn-flickr.dropdown-toggle.focus .icon {
  color: $white;
}

.btn-color.btn-flickr:active,
.btn-color.btn-flickr.active,
.btn-color.btn-flickr:not([disabled]):not(.disabled):active,
.btn-color.btn-flickr:not([disabled]):not(.disabled).active,
.show > .btn-color.btn-flickr.dropdown-toggle {
  background-image: none;
}

.btn-color.btn-flickr.disabled,
.btn-color.btn-flickr.disabled:hover,
.btn-color.btn-flickr.disabled:focus,
.btn-color.btn-flickr.disabled.focus,
.btn-color.btn-flickr.disabled:active,
.btn-color.btn-flickr.disabled.active,
.btn-color.btn-flickr[disabled],
.btn-color.btn-flickr[disabled]:hover,
.btn-color.btn-flickr[disabled]:focus,
.btn-color.btn-flickr[disabled].focus,
.btn-color.btn-flickr[disabled]:active,
.btn-color.btn-flickr[disabled].active,
fieldset[disabled] .btn-color.btn-flickr,
fieldset[disabled] .btn-color.btn-flickr:hover,
fieldset[disabled] .btn-color.btn-flickr:focus,
fieldset[disabled] .btn-color.btn-flickr.focus,
fieldset[disabled] .btn-color.btn-flickr:active,
fieldset[disabled] .btn-color.btn-flickr.active {
  background-color: #0063dc;
  border-color: #0063dc;
  color: $white;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-color.btn-flickr .badge {
  color: #0063dc;
  background-color: $white;
}

.btn-color.btn-linkedin {
  color: $white;
  background-color: #238cc8;
  border-color: #238cc8;
}

.btn-color.btn-linkedin .icon {
  color: $white;
}

.btn-color.btn-linkedin:focus,
.btn-color.btn-linkedin.focus {
  color: $white;
  background-color: #238cc8;
  border-color: #238cc8;
  outline: none;
}

.btn-color.btn-linkedin:hover,
.btn-color.btn-linkedin.hover {
  color: $white;
  -webkit-box-shadow: inset 0 -1px 0 #1b6e9d;
  box-shadow: inset 0 -1px 0 #1b6e9d;
  background-color: #2592d1;
  border-color: #2592d1;
  border-bottom-color: #1b6e9d;
}

.btn-color.btn-linkedin:active,
.btn-color.btn-linkedin.active,
.btn-color.btn-linkedin:not([disabled]):not(.disabled):active,
.btn-color.btn-linkedin:not([disabled]):not(.disabled).active,
.show > .btn-color.btn-linkedin.dropdown-toggle {
  color: $white;
  -webkit-box-shadow: inset 0 2px 0 #1b6e9d;
  box-shadow: inset 0 2px 0 #1b6e9d;
  background-color: #1e7aae;
  border-color: #1e7aae;
  border-top-color: #16587e;
}

.btn-color.btn-linkedin:active .icon,
.btn-color.btn-linkedin.active .icon,
.btn-color.btn-linkedin:not([disabled]):not(.disabled):active .icon,
.btn-color.btn-linkedin:not([disabled]):not(.disabled).active .icon,
.show > .btn-color.btn-linkedin.dropdown-toggle .icon {
  color: $white;
}

.btn-color.btn-linkedin:active:hover,
.btn-color.btn-linkedin:active:focus,
.btn-color.btn-linkedin:active.focus,
.btn-color.btn-linkedin.active:hover,
.btn-color.btn-linkedin.active:focus,
.btn-color.btn-linkedin.active.focus,
.btn-color.btn-linkedin:not([disabled]):not(.disabled):active:hover,
.btn-color.btn-linkedin:not([disabled]):not(.disabled):active:focus,
.btn-color.btn-linkedin:not([disabled]):not(.disabled):active.focus,
.btn-color.btn-linkedin:not([disabled]):not(.disabled).active:hover,
.btn-color.btn-linkedin:not([disabled]):not(.disabled).active:focus,
.btn-color.btn-linkedin:not([disabled]):not(.disabled).active.focus,
.show > .btn-color.btn-linkedin.dropdown-toggle:hover,
.show > .btn-color.btn-linkedin.dropdown-toggle:focus,
.show > .btn-color.btn-linkedin.dropdown-toggle.focus {
  color: $white;
  background-color: #1e7aae;
  border-color: #1e7aae;
  border-top-color: #16587e;
  -webkit-box-shadow: inset 0 2px 0 #1b6e9d;
  box-shadow: inset 0 2px 0 #1b6e9d;
}

.btn-color.btn-linkedin:active:hover .icon,
.btn-color.btn-linkedin:active:focus .icon,
.btn-color.btn-linkedin:active.focus .icon,
.btn-color.btn-linkedin.active:hover .icon,
.btn-color.btn-linkedin.active:focus .icon,
.btn-color.btn-linkedin.active.focus .icon,
.btn-color.btn-linkedin:not([disabled]):not(.disabled):active:hover .icon,
.btn-color.btn-linkedin:not([disabled]):not(.disabled):active:focus .icon,
.btn-color.btn-linkedin:not([disabled]):not(.disabled):active.focus .icon,
.btn-color.btn-linkedin:not([disabled]):not(.disabled).active:hover .icon,
.btn-color.btn-linkedin:not([disabled]):not(.disabled).active:focus .icon,
.btn-color.btn-linkedin:not([disabled]):not(.disabled).active.focus .icon,
.show > .btn-color.btn-linkedin.dropdown-toggle:hover .icon,
.show > .btn-color.btn-linkedin.dropdown-toggle:focus .icon,
.show > .btn-color.btn-linkedin.dropdown-toggle.focus .icon {
  color: $white;
}

.btn-color.btn-linkedin:active,
.btn-color.btn-linkedin.active,
.btn-color.btn-linkedin:not([disabled]):not(.disabled):active,
.btn-color.btn-linkedin:not([disabled]):not(.disabled).active,
.show > .btn-color.btn-linkedin.dropdown-toggle {
  background-image: none;
}

.btn-color.btn-linkedin.disabled,
.btn-color.btn-linkedin.disabled:hover,
.btn-color.btn-linkedin.disabled:focus,
.btn-color.btn-linkedin.disabled.focus,
.btn-color.btn-linkedin.disabled:active,
.btn-color.btn-linkedin.disabled.active,
.btn-color.btn-linkedin[disabled],
.btn-color.btn-linkedin[disabled]:hover,
.btn-color.btn-linkedin[disabled]:focus,
.btn-color.btn-linkedin[disabled].focus,
.btn-color.btn-linkedin[disabled]:active,
.btn-color.btn-linkedin[disabled].active,
fieldset[disabled] .btn-color.btn-linkedin,
fieldset[disabled] .btn-color.btn-linkedin:hover,
fieldset[disabled] .btn-color.btn-linkedin:focus,
fieldset[disabled] .btn-color.btn-linkedin.focus,
fieldset[disabled] .btn-color.btn-linkedin:active,
fieldset[disabled] .btn-color.btn-linkedin.active {
  background-color: #238cc8;
  border-color: #238cc8;
  color: $white;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-color.btn-linkedin .badge {
  color: #238cc8;
  background-color: $white;
}

.btn-color.btn-youtube {
  color: $white;
  background-color: #ef4e41;
  border-color: #ef4e41;
}

.btn-color.btn-youtube .icon {
  color: $white;
}

.btn-color.btn-youtube:focus,
.btn-color.btn-youtube.focus {
  color: $white;
  background-color: #ef4e41;
  border-color: #ef4e41;
  outline: none;
}

.btn-color.btn-youtube:hover,
.btn-color.btn-youtube.hover {
  color: $white;
  -webkit-box-shadow: inset 0 -1px 0 #e92414;
  box-shadow: inset 0 -1px 0 #e92414;
  background-color: #f0574a;
  border-color: #f0574a;
  border-bottom-color: #e92414;
}

.btn-color.btn-youtube:active,
.btn-color.btn-youtube.active,
.btn-color.btn-youtube:not([disabled]):not(.disabled):active,
.btn-color.btn-youtube:not([disabled]):not(.disabled).active,
.show > .btn-color.btn-youtube.dropdown-toggle {
  color: $white;
  -webkit-box-shadow: inset 0 2px 0 #e92414;
  box-shadow: inset 0 2px 0 #e92414;
  background-color: #ed3425;
  border-color: #ed3425;
  border-top-color: #c81f11;
}

.btn-color.btn-youtube:active .icon,
.btn-color.btn-youtube.active .icon,
.btn-color.btn-youtube:not([disabled]):not(.disabled):active .icon,
.btn-color.btn-youtube:not([disabled]):not(.disabled).active .icon,
.show > .btn-color.btn-youtube.dropdown-toggle .icon {
  color: $white;
}

.btn-color.btn-youtube:active:hover,
.btn-color.btn-youtube:active:focus,
.btn-color.btn-youtube:active.focus,
.btn-color.btn-youtube.active:hover,
.btn-color.btn-youtube.active:focus,
.btn-color.btn-youtube.active.focus,
.btn-color.btn-youtube:not([disabled]):not(.disabled):active:hover,
.btn-color.btn-youtube:not([disabled]):not(.disabled):active:focus,
.btn-color.btn-youtube:not([disabled]):not(.disabled):active.focus,
.btn-color.btn-youtube:not([disabled]):not(.disabled).active:hover,
.btn-color.btn-youtube:not([disabled]):not(.disabled).active:focus,
.btn-color.btn-youtube:not([disabled]):not(.disabled).active.focus,
.show > .btn-color.btn-youtube.dropdown-toggle:hover,
.show > .btn-color.btn-youtube.dropdown-toggle:focus,
.show > .btn-color.btn-youtube.dropdown-toggle.focus {
  color: $white;
  background-color: #ed3425;
  border-color: #ed3425;
  border-top-color: #c81f11;
  -webkit-box-shadow: inset 0 2px 0 #e92414;
  box-shadow: inset 0 2px 0 #e92414;
}

.btn-color.btn-youtube:active:hover .icon,
.btn-color.btn-youtube:active:focus .icon,
.btn-color.btn-youtube:active.focus .icon,
.btn-color.btn-youtube.active:hover .icon,
.btn-color.btn-youtube.active:focus .icon,
.btn-color.btn-youtube.active.focus .icon,
.btn-color.btn-youtube:not([disabled]):not(.disabled):active:hover .icon,
.btn-color.btn-youtube:not([disabled]):not(.disabled):active:focus .icon,
.btn-color.btn-youtube:not([disabled]):not(.disabled):active.focus .icon,
.btn-color.btn-youtube:not([disabled]):not(.disabled).active:hover .icon,
.btn-color.btn-youtube:not([disabled]):not(.disabled).active:focus .icon,
.btn-color.btn-youtube:not([disabled]):not(.disabled).active.focus .icon,
.show > .btn-color.btn-youtube.dropdown-toggle:hover .icon,
.show > .btn-color.btn-youtube.dropdown-toggle:focus .icon,
.show > .btn-color.btn-youtube.dropdown-toggle.focus .icon {
  color: $white;
}

.btn-color.btn-youtube:active,
.btn-color.btn-youtube.active,
.btn-color.btn-youtube:not([disabled]):not(.disabled):active,
.btn-color.btn-youtube:not([disabled]):not(.disabled).active,
.show > .btn-color.btn-youtube.dropdown-toggle {
  background-image: none;
}

.btn-color.btn-youtube.disabled,
.btn-color.btn-youtube.disabled:hover,
.btn-color.btn-youtube.disabled:focus,
.btn-color.btn-youtube.disabled.focus,
.btn-color.btn-youtube.disabled:active,
.btn-color.btn-youtube.disabled.active,
.btn-color.btn-youtube[disabled],
.btn-color.btn-youtube[disabled]:hover,
.btn-color.btn-youtube[disabled]:focus,
.btn-color.btn-youtube[disabled].focus,
.btn-color.btn-youtube[disabled]:active,
.btn-color.btn-youtube[disabled].active,
fieldset[disabled] .btn-color.btn-youtube,
fieldset[disabled] .btn-color.btn-youtube:hover,
fieldset[disabled] .btn-color.btn-youtube:focus,
fieldset[disabled] .btn-color.btn-youtube.focus,
fieldset[disabled] .btn-color.btn-youtube:active,
fieldset[disabled] .btn-color.btn-youtube.active {
  background-color: #ef4e41;
  border-color: #ef4e41;
  color: $white;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-color.btn-youtube .badge {
  color: #ef4e41;
  background-color: $white;
}

.btn-color.btn-pinterest {
  color: $white;
  background-color: #e13138;
  border-color: #e13138;
}

.btn-color.btn-pinterest .icon {
  color: $white;
}

.btn-color.btn-pinterest:focus,
.btn-color.btn-pinterest.focus {
  color: $white;
  background-color: #e13138;
  border-color: #e13138;
  outline: none;
}

.btn-color.btn-pinterest:hover,
.btn-color.btn-pinterest.hover {
  color: $white;
  -webkit-box-shadow: inset 0 -1px 0 #c31c23;
  box-shadow: inset 0 -1px 0 #c31c23;
  background-color: #e23a41;
  border-color: #e23a41;
  border-bottom-color: #c31c23;
}

.btn-color.btn-pinterest:active,
.btn-color.btn-pinterest.active,
.btn-color.btn-pinterest:not([disabled]):not(.disabled):active,
.btn-color.btn-pinterest:not([disabled]):not(.disabled).active,
.show > .btn-color.btn-pinterest.dropdown-toggle {
  color: $white;
  -webkit-box-shadow: inset 0 2px 0 #c31c23;
  box-shadow: inset 0 2px 0 #c31c23;
  background-color: #d41f26;
  border-color: #d41f26;
  border-top-color: #a3181d;
}

.btn-color.btn-pinterest:active .icon,
.btn-color.btn-pinterest.active .icon,
.btn-color.btn-pinterest:not([disabled]):not(.disabled):active .icon,
.btn-color.btn-pinterest:not([disabled]):not(.disabled).active .icon,
.show > .btn-color.btn-pinterest.dropdown-toggle .icon {
  color: $white;
}

.btn-color.btn-pinterest:active:hover,
.btn-color.btn-pinterest:active:focus,
.btn-color.btn-pinterest:active.focus,
.btn-color.btn-pinterest.active:hover,
.btn-color.btn-pinterest.active:focus,
.btn-color.btn-pinterest.active.focus,
.btn-color.btn-pinterest:not([disabled]):not(.disabled):active:hover,
.btn-color.btn-pinterest:not([disabled]):not(.disabled):active:focus,
.btn-color.btn-pinterest:not([disabled]):not(.disabled):active.focus,
.btn-color.btn-pinterest:not([disabled]):not(.disabled).active:hover,
.btn-color.btn-pinterest:not([disabled]):not(.disabled).active:focus,
.btn-color.btn-pinterest:not([disabled]):not(.disabled).active.focus,
.show > .btn-color.btn-pinterest.dropdown-toggle:hover,
.show > .btn-color.btn-pinterest.dropdown-toggle:focus,
.show > .btn-color.btn-pinterest.dropdown-toggle.focus {
  color: $white;
  background-color: #d41f26;
  border-color: #d41f26;
  border-top-color: #a3181d;
  -webkit-box-shadow: inset 0 2px 0 #c31c23;
  box-shadow: inset 0 2px 0 #c31c23;
}

.btn-color.btn-pinterest:active:hover .icon,
.btn-color.btn-pinterest:active:focus .icon,
.btn-color.btn-pinterest:active.focus .icon,
.btn-color.btn-pinterest.active:hover .icon,
.btn-color.btn-pinterest.active:focus .icon,
.btn-color.btn-pinterest.active.focus .icon,
.btn-color.btn-pinterest:not([disabled]):not(.disabled):active:hover .icon,
.btn-color.btn-pinterest:not([disabled]):not(.disabled):active:focus .icon,
.btn-color.btn-pinterest:not([disabled]):not(.disabled):active.focus .icon,
.btn-color.btn-pinterest:not([disabled]):not(.disabled).active:hover .icon,
.btn-color.btn-pinterest:not([disabled]):not(.disabled).active:focus .icon,
.btn-color.btn-pinterest:not([disabled]):not(.disabled).active.focus .icon,
.show > .btn-color.btn-pinterest.dropdown-toggle:hover .icon,
.show > .btn-color.btn-pinterest.dropdown-toggle:focus .icon,
.show > .btn-color.btn-pinterest.dropdown-toggle.focus .icon {
  color: $white;
}

.btn-color.btn-pinterest:active,
.btn-color.btn-pinterest.active,
.btn-color.btn-pinterest:not([disabled]):not(.disabled):active,
.btn-color.btn-pinterest:not([disabled]):not(.disabled).active,
.show > .btn-color.btn-pinterest.dropdown-toggle {
  background-image: none;
}

.btn-color.btn-pinterest.disabled,
.btn-color.btn-pinterest.disabled:hover,
.btn-color.btn-pinterest.disabled:focus,
.btn-color.btn-pinterest.disabled.focus,
.btn-color.btn-pinterest.disabled:active,
.btn-color.btn-pinterest.disabled.active,
.btn-color.btn-pinterest[disabled],
.btn-color.btn-pinterest[disabled]:hover,
.btn-color.btn-pinterest[disabled]:focus,
.btn-color.btn-pinterest[disabled].focus,
.btn-color.btn-pinterest[disabled]:active,
.btn-color.btn-pinterest[disabled].active,
fieldset[disabled] .btn-color.btn-pinterest,
fieldset[disabled] .btn-color.btn-pinterest:hover,
fieldset[disabled] .btn-color.btn-pinterest:focus,
fieldset[disabled] .btn-color.btn-pinterest.focus,
fieldset[disabled] .btn-color.btn-pinterest:active,
fieldset[disabled] .btn-color.btn-pinterest.active {
  background-color: #e13138;
  border-color: #e13138;
  color: $white;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-color.btn-pinterest .badge {
  color: #e13138;
  background-color: $white;
}

.btn-color.btn-github {
  color: $white;
  background-color: #333;
  border-color: #333;
}

.btn-color.btn-github .icon {
  color: $white;
}

.btn-color.btn-github:focus,
.btn-color.btn-github.focus {
  color: $white;
  background-color: #333;
  border-color: #333;
  outline: none;
}

.btn-color.btn-github:hover,
.btn-color.btn-github.hover {
  color: $white;
  -webkit-box-shadow: inset 0 -1px 0 #1a1a1a;
  box-shadow: inset 0 -1px 0 #1a1a1a;
  background-color: #383838;
  border-color: #383838;
  border-bottom-color: #1a1a1a;
}

.btn-color.btn-github:active,
.btn-color.btn-github.active,
.btn-color.btn-github:not([disabled]):not(.disabled):active,
.btn-color.btn-github:not([disabled]):not(.disabled).active,
.show > .btn-color.btn-github.dropdown-toggle {
  color: $white;
  -webkit-box-shadow: inset 0 2px 0 #1a1a1a;
  box-shadow: inset 0 2px 0 #1a1a1a;
  background-color: #242424;
  border-color: #242424;
  border-top-color: #080808;
}

.btn-color.btn-github:active .icon,
.btn-color.btn-github.active .icon,
.btn-color.btn-github:not([disabled]):not(.disabled):active .icon,
.btn-color.btn-github:not([disabled]):not(.disabled).active .icon,
.show > .btn-color.btn-github.dropdown-toggle .icon {
  color: $white;
}

.btn-color.btn-github:active:hover,
.btn-color.btn-github:active:focus,
.btn-color.btn-github:active.focus,
.btn-color.btn-github.active:hover,
.btn-color.btn-github.active:focus,
.btn-color.btn-github.active.focus,
.btn-color.btn-github:not([disabled]):not(.disabled):active:hover,
.btn-color.btn-github:not([disabled]):not(.disabled):active:focus,
.btn-color.btn-github:not([disabled]):not(.disabled):active.focus,
.btn-color.btn-github:not([disabled]):not(.disabled).active:hover,
.btn-color.btn-github:not([disabled]):not(.disabled).active:focus,
.btn-color.btn-github:not([disabled]):not(.disabled).active.focus,
.show > .btn-color.btn-github.dropdown-toggle:hover,
.show > .btn-color.btn-github.dropdown-toggle:focus,
.show > .btn-color.btn-github.dropdown-toggle.focus {
  color: $white;
  background-color: #242424;
  border-color: #242424;
  border-top-color: #080808;
  -webkit-box-shadow: inset 0 2px 0 #1a1a1a;
  box-shadow: inset 0 2px 0 #1a1a1a;
}

.btn-color.btn-github:active:hover .icon,
.btn-color.btn-github:active:focus .icon,
.btn-color.btn-github:active.focus .icon,
.btn-color.btn-github.active:hover .icon,
.btn-color.btn-github.active:focus .icon,
.btn-color.btn-github.active.focus .icon,
.btn-color.btn-github:not([disabled]):not(.disabled):active:hover .icon,
.btn-color.btn-github:not([disabled]):not(.disabled):active:focus .icon,
.btn-color.btn-github:not([disabled]):not(.disabled):active.focus .icon,
.btn-color.btn-github:not([disabled]):not(.disabled).active:hover .icon,
.btn-color.btn-github:not([disabled]):not(.disabled).active:focus .icon,
.btn-color.btn-github:not([disabled]):not(.disabled).active.focus .icon,
.show > .btn-color.btn-github.dropdown-toggle:hover .icon,
.show > .btn-color.btn-github.dropdown-toggle:focus .icon,
.show > .btn-color.btn-github.dropdown-toggle.focus .icon {
  color: $white;
}

.btn-color.btn-github:active,
.btn-color.btn-github.active,
.btn-color.btn-github:not([disabled]):not(.disabled):active,
.btn-color.btn-github:not([disabled]):not(.disabled).active,
.show > .btn-color.btn-github.dropdown-toggle {
  background-image: none;
}

.btn-color.btn-github.disabled,
.btn-color.btn-github.disabled:hover,
.btn-color.btn-github.disabled:focus,
.btn-color.btn-github.disabled.focus,
.btn-color.btn-github.disabled:active,
.btn-color.btn-github.disabled.active,
.btn-color.btn-github[disabled],
.btn-color.btn-github[disabled]:hover,
.btn-color.btn-github[disabled]:focus,
.btn-color.btn-github[disabled].focus,
.btn-color.btn-github[disabled]:active,
.btn-color.btn-github[disabled].active,
fieldset[disabled] .btn-color.btn-github,
fieldset[disabled] .btn-color.btn-github:hover,
fieldset[disabled] .btn-color.btn-github:focus,
fieldset[disabled] .btn-color.btn-github.focus,
fieldset[disabled] .btn-color.btn-github:active,
fieldset[disabled] .btn-color.btn-github.active {
  background-color: #333;
  border-color: #333;
  color: $white;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-color.btn-github .badge {
  color: #333;
  background-color: $white;
}

.btn-color.btn-tumblr {
  color: $white;
  background-color: #426d9b;
  border-color: #426d9b;
}

.btn-color.btn-tumblr .icon {
  color: $white;
}

.btn-color.btn-tumblr:focus,
.btn-color.btn-tumblr.focus {
  color: $white;
  background-color: #426d9b;
  border-color: #426d9b;
  outline: none;
}

.btn-color.btn-tumblr:hover,
.btn-color.btn-tumblr.hover {
  color: $white;
  -webkit-box-shadow: inset 0 -1px 0 #335477;
  box-shadow: inset 0 -1px 0 #335477;
  background-color: #4572a2;
  border-color: #4572a2;
  border-bottom-color: #335477;
}

.btn-color.btn-tumblr:active,
.btn-color.btn-tumblr.active,
.btn-color.btn-tumblr:not([disabled]):not(.disabled):active,
.btn-color.btn-tumblr:not([disabled]):not(.disabled).active,
.show > .btn-color.btn-tumblr.dropdown-toggle {
  color: $white;
  -webkit-box-shadow: inset 0 2px 0 #335477;
  box-shadow: inset 0 2px 0 #335477;
  background-color: #395e86;
  border-color: #395e86;
  border-top-color: #28425e;
}

.btn-color.btn-tumblr:active .icon,
.btn-color.btn-tumblr.active .icon,
.btn-color.btn-tumblr:not([disabled]):not(.disabled):active .icon,
.btn-color.btn-tumblr:not([disabled]):not(.disabled).active .icon,
.show > .btn-color.btn-tumblr.dropdown-toggle .icon {
  color: $white;
}

.btn-color.btn-tumblr:active:hover,
.btn-color.btn-tumblr:active:focus,
.btn-color.btn-tumblr:active.focus,
.btn-color.btn-tumblr.active:hover,
.btn-color.btn-tumblr.active:focus,
.btn-color.btn-tumblr.active.focus,
.btn-color.btn-tumblr:not([disabled]):not(.disabled):active:hover,
.btn-color.btn-tumblr:not([disabled]):not(.disabled):active:focus,
.btn-color.btn-tumblr:not([disabled]):not(.disabled):active.focus,
.btn-color.btn-tumblr:not([disabled]):not(.disabled).active:hover,
.btn-color.btn-tumblr:not([disabled]):not(.disabled).active:focus,
.btn-color.btn-tumblr:not([disabled]):not(.disabled).active.focus,
.show > .btn-color.btn-tumblr.dropdown-toggle:hover,
.show > .btn-color.btn-tumblr.dropdown-toggle:focus,
.show > .btn-color.btn-tumblr.dropdown-toggle.focus {
  color: $white;
  background-color: #395e86;
  border-color: #395e86;
  border-top-color: #28425e;
  -webkit-box-shadow: inset 0 2px 0 #335477;
  box-shadow: inset 0 2px 0 #335477;
}

.btn-color.btn-tumblr:active:hover .icon,
.btn-color.btn-tumblr:active:focus .icon,
.btn-color.btn-tumblr:active.focus .icon,
.btn-color.btn-tumblr.active:hover .icon,
.btn-color.btn-tumblr.active:focus .icon,
.btn-color.btn-tumblr.active.focus .icon,
.btn-color.btn-tumblr:not([disabled]):not(.disabled):active:hover .icon,
.btn-color.btn-tumblr:not([disabled]):not(.disabled):active:focus .icon,
.btn-color.btn-tumblr:not([disabled]):not(.disabled):active.focus .icon,
.btn-color.btn-tumblr:not([disabled]):not(.disabled).active:hover .icon,
.btn-color.btn-tumblr:not([disabled]):not(.disabled).active:focus .icon,
.btn-color.btn-tumblr:not([disabled]):not(.disabled).active.focus .icon,
.show > .btn-color.btn-tumblr.dropdown-toggle:hover .icon,
.show > .btn-color.btn-tumblr.dropdown-toggle:focus .icon,
.show > .btn-color.btn-tumblr.dropdown-toggle.focus .icon {
  color: $white;
}

.btn-color.btn-tumblr:active,
.btn-color.btn-tumblr.active,
.btn-color.btn-tumblr:not([disabled]):not(.disabled):active,
.btn-color.btn-tumblr:not([disabled]):not(.disabled).active,
.show > .btn-color.btn-tumblr.dropdown-toggle {
  background-image: none;
}

.btn-color.btn-tumblr.disabled,
.btn-color.btn-tumblr.disabled:hover,
.btn-color.btn-tumblr.disabled:focus,
.btn-color.btn-tumblr.disabled.focus,
.btn-color.btn-tumblr.disabled:active,
.btn-color.btn-tumblr.disabled.active,
.btn-color.btn-tumblr[disabled],
.btn-color.btn-tumblr[disabled]:hover,
.btn-color.btn-tumblr[disabled]:focus,
.btn-color.btn-tumblr[disabled].focus,
.btn-color.btn-tumblr[disabled]:active,
.btn-color.btn-tumblr[disabled].active,
fieldset[disabled] .btn-color.btn-tumblr,
fieldset[disabled] .btn-color.btn-tumblr:hover,
fieldset[disabled] .btn-color.btn-tumblr:focus,
fieldset[disabled] .btn-color.btn-tumblr.focus,
fieldset[disabled] .btn-color.btn-tumblr:active,
fieldset[disabled] .btn-color.btn-tumblr.active {
  background-color: #426d9b;
  border-color: #426d9b;
  color: $white;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-color.btn-tumblr .badge {
  color: #426d9b;
  background-color: $white;
}

.btn-color.btn-dropbox {
  color: $white;
  background-color: #007ee5;
  border-color: #007ee5;
}

.btn-color.btn-dropbox .icon {
  color: $white;
}

.btn-color.btn-dropbox:focus,
.btn-color.btn-dropbox.focus {
  color: $white;
  background-color: #007ee5;
  border-color: #007ee5;
  outline: none;
}

.btn-color.btn-dropbox:hover,
.btn-color.btn-dropbox.hover {
  color: $white;
  -webkit-box-shadow: inset 0 -1px 0 #0062b2;
  box-shadow: inset 0 -1px 0 #0062b2;
  background-color: #0084ef;
  border-color: #0084ef;
  border-bottom-color: #0062b2;
}

.btn-color.btn-dropbox:active,
.btn-color.btn-dropbox.active,
.btn-color.btn-dropbox:not([disabled]):not(.disabled):active,
.btn-color.btn-dropbox:not([disabled]):not(.disabled).active,
.show > .btn-color.btn-dropbox.dropdown-toggle {
  color: $white;
  -webkit-box-shadow: inset 0 2px 0 #0062b2;
  box-shadow: inset 0 2px 0 #0062b2;
  background-color: #006dc6;
  border-color: #006dc6;
  border-top-color: #004e8e;
}

.btn-color.btn-dropbox:active .icon,
.btn-color.btn-dropbox.active .icon,
.btn-color.btn-dropbox:not([disabled]):not(.disabled):active .icon,
.btn-color.btn-dropbox:not([disabled]):not(.disabled).active .icon,
.show > .btn-color.btn-dropbox.dropdown-toggle .icon {
  color: $white;
}

.btn-color.btn-dropbox:active:hover,
.btn-color.btn-dropbox:active:focus,
.btn-color.btn-dropbox:active.focus,
.btn-color.btn-dropbox.active:hover,
.btn-color.btn-dropbox.active:focus,
.btn-color.btn-dropbox.active.focus,
.btn-color.btn-dropbox:not([disabled]):not(.disabled):active:hover,
.btn-color.btn-dropbox:not([disabled]):not(.disabled):active:focus,
.btn-color.btn-dropbox:not([disabled]):not(.disabled):active.focus,
.btn-color.btn-dropbox:not([disabled]):not(.disabled).active:hover,
.btn-color.btn-dropbox:not([disabled]):not(.disabled).active:focus,
.btn-color.btn-dropbox:not([disabled]):not(.disabled).active.focus,
.show > .btn-color.btn-dropbox.dropdown-toggle:hover,
.show > .btn-color.btn-dropbox.dropdown-toggle:focus,
.show > .btn-color.btn-dropbox.dropdown-toggle.focus {
  color: $white;
  background-color: #006dc6;
  border-color: #006dc6;
  border-top-color: #004e8e;
  -webkit-box-shadow: inset 0 2px 0 #0062b2;
  box-shadow: inset 0 2px 0 #0062b2;
}

.btn-color.btn-dropbox:active:hover .icon,
.btn-color.btn-dropbox:active:focus .icon,
.btn-color.btn-dropbox:active.focus .icon,
.btn-color.btn-dropbox.active:hover .icon,
.btn-color.btn-dropbox.active:focus .icon,
.btn-color.btn-dropbox.active.focus .icon,
.btn-color.btn-dropbox:not([disabled]):not(.disabled):active:hover .icon,
.btn-color.btn-dropbox:not([disabled]):not(.disabled):active:focus .icon,
.btn-color.btn-dropbox:not([disabled]):not(.disabled):active.focus .icon,
.btn-color.btn-dropbox:not([disabled]):not(.disabled).active:hover .icon,
.btn-color.btn-dropbox:not([disabled]):not(.disabled).active:focus .icon,
.btn-color.btn-dropbox:not([disabled]):not(.disabled).active.focus .icon,
.show > .btn-color.btn-dropbox.dropdown-toggle:hover .icon,
.show > .btn-color.btn-dropbox.dropdown-toggle:focus .icon,
.show > .btn-color.btn-dropbox.dropdown-toggle.focus .icon {
  color: $white;
}

.btn-color.btn-dropbox:active,
.btn-color.btn-dropbox.active,
.btn-color.btn-dropbox:not([disabled]):not(.disabled):active,
.btn-color.btn-dropbox:not([disabled]):not(.disabled).active,
.show > .btn-color.btn-dropbox.dropdown-toggle {
  background-image: none;
}

.btn-color.btn-dropbox.disabled,
.btn-color.btn-dropbox.disabled:hover,
.btn-color.btn-dropbox.disabled:focus,
.btn-color.btn-dropbox.disabled.focus,
.btn-color.btn-dropbox.disabled:active,
.btn-color.btn-dropbox.disabled.active,
.btn-color.btn-dropbox[disabled],
.btn-color.btn-dropbox[disabled]:hover,
.btn-color.btn-dropbox[disabled]:focus,
.btn-color.btn-dropbox[disabled].focus,
.btn-color.btn-dropbox[disabled]:active,
.btn-color.btn-dropbox[disabled].active,
fieldset[disabled] .btn-color.btn-dropbox,
fieldset[disabled] .btn-color.btn-dropbox:hover,
fieldset[disabled] .btn-color.btn-dropbox:focus,
fieldset[disabled] .btn-color.btn-dropbox.focus,
fieldset[disabled] .btn-color.btn-dropbox:active,
fieldset[disabled] .btn-color.btn-dropbox.active {
  background-color: #007ee5;
  border-color: #007ee5;
  color: $white;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-color.btn-dropbox .badge {
  color: #007ee5;
  background-color: $white;
}

.btn-color.btn-evernote {
  color: $white;
  background-color: #2dbe60;
  border-color: #2dbe60;
}

.btn-color.btn-evernote .icon {
  color: $white;
}

.btn-color.btn-evernote:focus,
.btn-color.btn-evernote.focus {
  color: $white;
  background-color: #2dbe60;
  border-color: #2dbe60;
  outline: none;
}

.btn-color.btn-evernote:hover,
.btn-color.btn-evernote.hover {
  color: $white;
  -webkit-box-shadow: inset 0 -1px 0 #23954b;
  box-shadow: inset 0 -1px 0 #23954b;
  background-color: #2fc664;
  border-color: #2fc664;
  border-bottom-color: #23954b;
}

.btn-color.btn-evernote:active,
.btn-color.btn-evernote.active,
.btn-color.btn-evernote:not([disabled]):not(.disabled):active,
.btn-color.btn-evernote:not([disabled]):not(.disabled).active,
.show > .btn-color.btn-evernote.dropdown-toggle {
  color: $white;
  -webkit-box-shadow: inset 0 2px 0 #23954b;
  box-shadow: inset 0 2px 0 #23954b;
  background-color: #27a553;
  border-color: #27a553;
  border-top-color: #1c783d;
}

.btn-color.btn-evernote:active .icon,
.btn-color.btn-evernote.active .icon,
.btn-color.btn-evernote:not([disabled]):not(.disabled):active .icon,
.btn-color.btn-evernote:not([disabled]):not(.disabled).active .icon,
.show > .btn-color.btn-evernote.dropdown-toggle .icon {
  color: $white;
}

.btn-color.btn-evernote:active:hover,
.btn-color.btn-evernote:active:focus,
.btn-color.btn-evernote:active.focus,
.btn-color.btn-evernote.active:hover,
.btn-color.btn-evernote.active:focus,
.btn-color.btn-evernote.active.focus,
.btn-color.btn-evernote:not([disabled]):not(.disabled):active:hover,
.btn-color.btn-evernote:not([disabled]):not(.disabled):active:focus,
.btn-color.btn-evernote:not([disabled]):not(.disabled):active.focus,
.btn-color.btn-evernote:not([disabled]):not(.disabled).active:hover,
.btn-color.btn-evernote:not([disabled]):not(.disabled).active:focus,
.btn-color.btn-evernote:not([disabled]):not(.disabled).active.focus,
.show > .btn-color.btn-evernote.dropdown-toggle:hover,
.show > .btn-color.btn-evernote.dropdown-toggle:focus,
.show > .btn-color.btn-evernote.dropdown-toggle.focus {
  color: $white;
  background-color: #27a553;
  border-color: #27a553;
  border-top-color: #1c783d;
  -webkit-box-shadow: inset 0 2px 0 #23954b;
  box-shadow: inset 0 2px 0 #23954b;
}

.btn-color.btn-evernote:active:hover .icon,
.btn-color.btn-evernote:active:focus .icon,
.btn-color.btn-evernote:active.focus .icon,
.btn-color.btn-evernote.active:hover .icon,
.btn-color.btn-evernote.active:focus .icon,
.btn-color.btn-evernote.active.focus .icon,
.btn-color.btn-evernote:not([disabled]):not(.disabled):active:hover .icon,
.btn-color.btn-evernote:not([disabled]):not(.disabled):active:focus .icon,
.btn-color.btn-evernote:not([disabled]):not(.disabled):active.focus .icon,
.btn-color.btn-evernote:not([disabled]):not(.disabled).active:hover .icon,
.btn-color.btn-evernote:not([disabled]):not(.disabled).active:focus .icon,
.btn-color.btn-evernote:not([disabled]):not(.disabled).active.focus .icon,
.show > .btn-color.btn-evernote.dropdown-toggle:hover .icon,
.show > .btn-color.btn-evernote.dropdown-toggle:focus .icon,
.show > .btn-color.btn-evernote.dropdown-toggle.focus .icon {
  color: $white;
}

.btn-color.btn-evernote:active,
.btn-color.btn-evernote.active,
.btn-color.btn-evernote:not([disabled]):not(.disabled):active,
.btn-color.btn-evernote:not([disabled]):not(.disabled).active,
.show > .btn-color.btn-evernote.dropdown-toggle {
  background-image: none;
}

.btn-color.btn-evernote.disabled,
.btn-color.btn-evernote.disabled:hover,
.btn-color.btn-evernote.disabled:focus,
.btn-color.btn-evernote.disabled.focus,
.btn-color.btn-evernote.disabled:active,
.btn-color.btn-evernote.disabled.active,
.btn-color.btn-evernote[disabled],
.btn-color.btn-evernote[disabled]:hover,
.btn-color.btn-evernote[disabled]:focus,
.btn-color.btn-evernote[disabled].focus,
.btn-color.btn-evernote[disabled]:active,
.btn-color.btn-evernote[disabled].active,
fieldset[disabled] .btn-color.btn-evernote,
fieldset[disabled] .btn-color.btn-evernote:hover,
fieldset[disabled] .btn-color.btn-evernote:focus,
fieldset[disabled] .btn-color.btn-evernote.focus,
fieldset[disabled] .btn-color.btn-evernote:active,
fieldset[disabled] .btn-color.btn-evernote.active {
  background-color: #2dbe60;
  border-color: #2dbe60;
  color: $white;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-color.btn-evernote .badge {
  color: #2dbe60;
  background-color: $white;
}

.btn .icon {
  font-size: 1.231rem;
  line-height: 15px;
  vertical-align: middle;
}

.btn .icon.icon-left {
  margin-right: 4px;
}

.btn .icon.icon-right {
  margin-left: 1px;
}

.btn .icon.fa {
  font-size: 1.154rem;
}

.btn.btn-lg .icon,
.btn-group-lg > .btn .icon {
  font-size: 1.615rem;
  line-height: 20px;
}

.btn.btn-sm .icon,
.btn-group-sm > .btn .icon {
  font-size: 1.154rem;
  line-height: 15px;
}

.btn.btn-xs .icon {
  font-size: 1.077rem;
  line-height: 14px;
}

.btn-group .btn.dropdown-toggle.btn-primary,
.btn-group .btn.dropdown-toggle.btn-success,
.btn-group .btn.dropdown-toggle.btn-warning,
.btn-group .btn.dropdown-toggle.btn-danger {
  border-right-color: transparent;
}

.btn-group .btn.dropdown-toggle.btn-primary:hover,
.btn-group .btn.dropdown-toggle.btn-success:hover,
.btn-group .btn.dropdown-toggle.btn-warning:hover,
.btn-group .btn.dropdown-toggle.btn-danger:hover {
  border-right-color: transparent;
}

.btn.dropdown-toggle .mdi-chevron-down,
.btn.dropdown-toggle .mdi-chevron-up {
  font-size: 1.385rem;
  vertical-align: middle;
  line-height: 17px;
}

.btn.dropdown-toggle .icon-dropdown {
  font-size: 1.385rem;
  line-height: 17px;
  margin-left: 6px;
}

.btn.dropdown-toggle .icon-dropdown-left {
  margin-left: 0;
  margin-right: 6px;
}

.btn-big {
  padding: 10px 13px;
  text-align: center;
  line-height: 13px;
  min-width: 62px;
}

.btn-big > .icon {
  display: block;
  font-size: 2rem;
  line-height: 20px;
  margin-bottom: 7px;
}

.btn-outline.btn-primary {
  background-color: transparent;
  color: $white;
  border-color: rgba(255, 255, 255, 0.5);
  border-width: 2px;
}

.btn-outline.btn-primary:hover {
  border-color: $white;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: rgba(255, 255, 255, 0.1);
}

.btn-outline.btn-primary:hover + .btn {
  border-left-color: transparent;
}

.btn-outline.btn-primary:active,
.btn-outline.btn-primary.active {
  background-color: $white;
  color: #5f99f5;
  border-color: transparent;
  border-width: 2px;
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
}

.btn-outline.btn-primary:focus {
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
}

.btn-outline.btn-secondary {
  background-color: transparent;
  color: $white;
  border-color: rgba(255, 255, 255, 0.5);
  border-width: 2px;
}

.btn-outline.btn-secondary:hover {
  border-color: $white;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: rgba(255, 255, 255, 0.1);
}

.btn-outline.btn-secondary:hover + .btn {
  border-left-color: transparent;
}

.btn-outline.btn-secondary:active,
.btn-outline.btn-secondary.active {
  background-color: $white;
  color: #d9d9d9;
  border-color: transparent;
  border-width: 2px;
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
}

.btn-outline.btn-secondary:focus {
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
}

.btn-outline.btn-success {
  background-color: transparent;
  color: $white;
  border-color: rgba(255, 255, 255, 0.5);
  border-width: 2px;
}

.btn-outline.btn-success:hover {
  border-color: $white;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: rgba(255, 255, 255, 0.1);
}

.btn-outline.btn-success:hover + .btn {
  border-left-color: transparent;
}

.btn-outline.btn-success:active,
.btn-outline.btn-success.active {
  background-color: $white;
  color: #37b358;
  border-color: transparent;
  border-width: 2px;
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
}

.btn-outline.btn-success:focus {
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
}

.btn-outline.btn-warning {
  background-color: transparent;
  color: $white;
  border-color: rgba(255, 255, 255, 0.5);
  border-width: 2px;
}

.btn-outline.btn-warning:hover {
  border-color: $white;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: rgba(255, 255, 255, 0.1);
}

.btn-outline.btn-warning:hover + .btn {
  border-left-color: transparent;
}

.btn-outline.btn-warning:active,
.btn-outline.btn-warning.active {
  background-color: $white;
  color: #f6c163;
  border-color: transparent;
  border-width: 2px;
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
}

.btn-outline.btn-warning:focus {
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
}

.btn-outline.btn-danger {
  background-color: transparent;
  color: $white;
  border-color: rgba(255, 255, 255, 0.5);
  border-width: 2px;
}

.btn-outline.btn-danger:hover {
  border-color: $white;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: rgba(255, 255, 255, 0.1);
}

.btn-outline.btn-danger:hover + .btn {
  border-left-color: transparent;
}

.btn-outline.btn-danger:active,
.btn-outline.btn-danger.active {
  background-color: $white;
  color: #eb6357;
  border-color: transparent;
  border-width: 2px;
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
}

.btn-outline.btn-danger:focus {
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
}

.btn-group > .btn.btn-secondary:hover + .btn,
.btn-group > .btn.btn-secondary.active + .btn,
.input-group-btn > .btn.btn-secondary:hover + .btn,
.input-group-btn > .btn.btn-secondary.active + .btn,
.input-group-prepend > .btn.btn-secondary:hover + .btn,
.input-group-prepend > .btn.btn-secondary.active + .btn,
.input-group-append > .btn.btn-secondary:hover + .btn,
.input-group-append > .btn.btn-secondary.active + .btn {
  border-left-color: #bababa;
}

.btn-group > .btn.btn-primary,
.input-group-btn > .btn.btn-primary,
.input-group-prepend > .btn.btn-primary,
.input-group-append > .btn.btn-primary {
  border-left-color: #00637f;
  border-right-color: #00637f;
}

.btn-group > .btn.btn-primary:hover,
.btn-group > .btn.btn-primary:active,
.btn-group > .btn.btn-primary.active,
.input-group-btn > .btn.btn-primary:hover,
.input-group-btn > .btn.btn-primary:active,
.input-group-btn > .btn.btn-primary.active,
.input-group-prepend > .btn.btn-primary:hover,
.input-group-prepend > .btn.btn-primary:active,
.input-group-prepend > .btn.btn-primary.active,
.input-group-append > .btn.btn-primary:hover,
.input-group-append > .btn.btn-primary:active,
.input-group-append > .btn.btn-primary.active {
  border-left-color: #004b61;
  border-right-color: #004b61;
  border-bottom-color: transparent;
}

.btn-group > .btn.btn-primary.active,
.btn-group > .btn.btn-primary:active,
.input-group-btn > .btn.btn-primary.active,
.input-group-btn > .btn.btn-primary:active,
.input-group-prepend > .btn.btn-primary.active,
.input-group-prepend > .btn.btn-primary:active,
.input-group-append > .btn.btn-primary.active,
.input-group-append > .btn.btn-primary:active {
  -webkit-box-shadow: inset 0 2px 0 $Inkblue;
  box-shadow: inset 0 2px 0 $Inkblue;
}

.btn-group > .btn.btn-primary:hover + .btn,
.btn-group > .btn.btn-primary:active + .btn,
.btn-group > .btn.btn-primary.active + .btn,
.input-group-btn > .btn.btn-primary:hover + .btn,
.input-group-btn > .btn.btn-primary:active + .btn,
.input-group-btn > .btn.btn-primary.active + .btn,
.input-group-prepend > .btn.btn-primary:hover + .btn,
.input-group-prepend > .btn.btn-primary:active + .btn,
.input-group-prepend > .btn.btn-primary.active + .btn,
.input-group-append > .btn.btn-primary:hover + .btn,
.input-group-append > .btn.btn-primary:active + .btn,
.input-group-append > .btn.btn-primary.active + .btn {
  border-left-color: #004b61;
}

.btn-group > .btn.btn-primary:first-child,
.input-group-btn > .btn.btn-primary:first-child,
.input-group-prepend > .btn.btn-primary:first-child,
.input-group-append > .btn.btn-primary:first-child {
  border-left-color: transparent;
}

.btn-group > .btn.btn-primary:last-child,
.input-group-btn > .btn.btn-primary:last-child,
.input-group-prepend > .btn.btn-primary:last-child,
.input-group-append > .btn.btn-primary:last-child {
  border-right-color: transparent;
}

.btn-group > .btn.btn-success,
.input-group-btn > .btn.btn-success,
.input-group-prepend > .btn.btn-success,
.input-group-append > .btn.btn-success {
  border-left-color: #006229;
  border-right-color: #006229;
}

.btn-group > .btn.btn-success:hover,
.btn-group > .btn.btn-success:active,
.btn-group > .btn.btn-success.active,
.input-group-btn > .btn.btn-success:hover,
.input-group-btn > .btn.btn-success:active,
.input-group-btn > .btn.btn-success.active,
.input-group-prepend > .btn.btn-success:hover,
.input-group-prepend > .btn.btn-success:active,
.input-group-prepend > .btn.btn-success.active,
.input-group-append > .btn.btn-success:hover,
.input-group-append > .btn.btn-success:active,
.input-group-append > .btn.btn-success.active {
  border-left-color: #00441c;
  border-right-color: #00441c;
  border-bottom-color: transparent;
}

.btn-group > .btn.btn-success.active,
.btn-group > .btn.btn-success:active,
.input-group-btn > .btn.btn-success.active,
.input-group-btn > .btn.btn-success:active,
.input-group-prepend > .btn.btn-success.active,
.input-group-prepend > .btn.btn-success:active,
.input-group-append > .btn.btn-success.active,
.input-group-append > .btn.btn-success:active {
  -webkit-box-shadow: inset 0 2px 0 #005323;
  box-shadow: inset 0 2px 0 #005323;
}

.btn-group > .btn.btn-success:hover + .btn,
.btn-group > .btn.btn-success:active + .btn,
.btn-group > .btn.btn-success.active + .btn,
.input-group-btn > .btn.btn-success:hover + .btn,
.input-group-btn > .btn.btn-success:active + .btn,
.input-group-btn > .btn.btn-success.active + .btn,
.input-group-prepend > .btn.btn-success:hover + .btn,
.input-group-prepend > .btn.btn-success:active + .btn,
.input-group-prepend > .btn.btn-success.active + .btn,
.input-group-append > .btn.btn-success:hover + .btn,
.input-group-append > .btn.btn-success:active + .btn,
.input-group-append > .btn.btn-success.active + .btn {
  border-left-color: #00441c;
}

.btn-group > .btn.btn-success:first-child,
.input-group-btn > .btn.btn-success:first-child,
.input-group-prepend > .btn.btn-success:first-child,
.input-group-append > .btn.btn-success:first-child {
  border-left-color: transparent;
}

.btn-group > .btn.btn-success:last-child,
.input-group-btn > .btn.btn-success:last-child,
.input-group-prepend > .btn.btn-success:last-child,
.input-group-append > .btn.btn-success:last-child {
  border-right-color: transparent;
}

.btn-group > .btn.btn-warning,
.input-group-btn > .btn.btn-warning,
.input-group-prepend > .btn.btn-warning,
.input-group-append > .btn.btn-warning {
  border-left-color: #f7aa00;
  border-right-color: #f7aa00;
}

.btn-group > .btn.btn-warning:hover,
.btn-group > .btn.btn-warning:active,
.btn-group > .btn.btn-warning.active,
.input-group-btn > .btn.btn-warning:hover,
.input-group-btn > .btn.btn-warning:active,
.input-group-btn > .btn.btn-warning.active,
.input-group-prepend > .btn.btn-warning:hover,
.input-group-prepend > .btn.btn-warning:active,
.input-group-prepend > .btn.btn-warning.active,
.input-group-append > .btn.btn-warning:hover,
.input-group-append > .btn.btn-warning:active,
.input-group-append > .btn.btn-warning.active {
  border-left-color: #d99500;
  border-right-color: #d99500;
  border-bottom-color: transparent;
}

.btn-group > .btn.btn-warning.active,
.btn-group > .btn.btn-warning:active,
.input-group-btn > .btn.btn-warning.active,
.input-group-btn > .btn.btn-warning:active,
.input-group-prepend > .btn.btn-warning.active,
.input-group-prepend > .btn.btn-warning:active,
.input-group-append > .btn.btn-warning.active,
.input-group-append > .btn.btn-warning:active {
  -webkit-box-shadow: inset 0 2px 0 #e89f00;
  box-shadow: inset 0 2px 0 #e89f00;
}

.btn-group > .btn.btn-warning:hover + .btn,
.btn-group > .btn.btn-warning:active + .btn,
.btn-group > .btn.btn-warning.active + .btn,
.input-group-btn > .btn.btn-warning:hover + .btn,
.input-group-btn > .btn.btn-warning:active + .btn,
.input-group-btn > .btn.btn-warning.active + .btn,
.input-group-prepend > .btn.btn-warning:hover + .btn,
.input-group-prepend > .btn.btn-warning:active + .btn,
.input-group-prepend > .btn.btn-warning.active + .btn,
.input-group-append > .btn.btn-warning:hover + .btn,
.input-group-append > .btn.btn-warning:active + .btn,
.input-group-append > .btn.btn-warning.active + .btn {
  border-left-color: #d99500;
}

.btn-group > .btn.btn-warning:first-child,
.input-group-btn > .btn.btn-warning:first-child,
.input-group-prepend > .btn.btn-warning:first-child,
.input-group-append > .btn.btn-warning:first-child {
  border-left-color: transparent;
}

.btn-group > .btn.btn-warning:last-child,
.input-group-btn > .btn.btn-warning:last-child,
.input-group-prepend > .btn.btn-warning:last-child,
.input-group-append > .btn.btn-warning:last-child {
  border-right-color: transparent;
}

.btn-group > .btn.btn-danger,
.input-group-btn > .btn.btn-danger,
.input-group-prepend > .btn.btn-danger,
.input-group-append > .btn.btn-danger {
  border-left-color: #b7001b;
  border-right-color: #b7001b;
}

.btn-group > .btn.btn-danger:hover,
.btn-group > .btn.btn-danger:active,
.btn-group > .btn.btn-danger.active,
.input-group-btn > .btn.btn-danger:hover,
.input-group-btn > .btn.btn-danger:active,
.input-group-btn > .btn.btn-danger.active,
.input-group-prepend > .btn.btn-danger:hover,
.input-group-prepend > .btn.btn-danger:active,
.input-group-prepend > .btn.btn-danger.active,
.input-group-append > .btn.btn-danger:hover,
.input-group-append > .btn.btn-danger:active,
.input-group-append > .btn.btn-danger.active {
  border-left-color: #990016;
  border-right-color: #990016;
  border-bottom-color: transparent;
}

.btn-group > .btn.btn-danger.active,
.btn-group > .btn.btn-danger:active,
.input-group-btn > .btn.btn-danger.active,
.input-group-btn > .btn.btn-danger:active,
.input-group-prepend > .btn.btn-danger.active,
.input-group-prepend > .btn.btn-danger:active,
.input-group-append > .btn.btn-danger.active,
.input-group-append > .btn.btn-danger:active {
  -webkit-box-shadow: inset 0 2px 0 #a80019;
  box-shadow: inset 0 2px 0 #a80019;
}

.btn-group > .btn.btn-danger:hover + .btn,
.btn-group > .btn.btn-danger:active + .btn,
.btn-group > .btn.btn-danger.active + .btn,
.input-group-btn > .btn.btn-danger:hover + .btn,
.input-group-btn > .btn.btn-danger:active + .btn,
.input-group-btn > .btn.btn-danger.active + .btn,
.input-group-prepend > .btn.btn-danger:hover + .btn,
.input-group-prepend > .btn.btn-danger:active + .btn,
.input-group-prepend > .btn.btn-danger.active + .btn,
.input-group-append > .btn.btn-danger:hover + .btn,
.input-group-append > .btn.btn-danger:active + .btn,
.input-group-append > .btn.btn-danger.active + .btn {
  border-left-color: #990016;
}

.btn-group > .btn.btn-danger:first-child,
.input-group-btn > .btn.btn-danger:first-child,
.input-group-prepend > .btn.btn-danger:first-child,
.input-group-append > .btn.btn-danger:first-child {
  border-left-color: transparent;
}

.btn-group > .btn.btn-danger:last-child,
.input-group-btn > .btn.btn-danger:last-child,
.input-group-prepend > .btn.btn-danger:last-child,
.input-group-append > .btn.btn-danger:last-child {
  border-right-color: transparent;
}

.btn-group > .btn:last-of-type:not(.btn-secondary),
.input-group-btn > .btn:last-of-type:not(.btn-secondary),
.input-group-prepend > .btn:last-of-type:not(.btn-secondary),
.input-group-append > .btn:last-of-type:not(.btn-secondary) {
  border-right-color: transparent;
}

.btn-group > .btn:last-of-type:not(.btn-secondary):hover,
.input-group-btn > .btn:last-of-type:not(.btn-secondary):hover,
.input-group-prepend > .btn:last-of-type:not(.btn-secondary):hover,
.input-group-append > .btn:last-of-type:not(.btn-secondary):hover {
  border-right-color: transparent;
}

.btn-group > .btn + .dropdown-toggle,
.input-group-btn > .btn + .dropdown-toggle,
.input-group-prepend > .btn + .dropdown-toggle,
.input-group-append > .btn + .dropdown-toggle {
  padding-left: 7px;
  padding-right: 7px;
}

.btn-group:first-child,
.input-group-btn:first-child,
.input-group-prepend:first-child,
.input-group-append:first-child {
  border-right-color: transparent;
}

.btn-group:last-child,
.input-group-btn:last-child,
.input-group-prepend:last-child,
.input-group-append:last-child {
  border-right-color: transparent;
}

.btn-group.open > .btn.btn-primary:hover,
.btn-group.open > .btn.btn-success:hover,
.btn-group.open > .btn.btn-warning:hover,
.btn-group.open > .btn.btn-danger:hover,
.input-group-btn.open > .btn.btn-primary:hover,
.input-group-btn.open > .btn.btn-success:hover,
.input-group-btn.open > .btn.btn-warning:hover,
.input-group-btn.open > .btn.btn-danger:hover,
.input-group-prepend.open > .btn.btn-primary:hover,
.input-group-prepend.open > .btn.btn-success:hover,
.input-group-prepend.open > .btn.btn-warning:hover,
.input-group-prepend.open > .btn.btn-danger:hover,
.input-group-append.open > .btn.btn-primary:hover,
.input-group-append.open > .btn.btn-success:hover,
.input-group-append.open > .btn.btn-warning:hover,
.input-group-append.open > .btn.btn-danger:hover {
  -webkit-box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.1);
}

.btn-group.open > .btn + .btn-primary,
.btn-group.open > .btn + .btn-success,
.btn-group.open > .btn + .btn-warning,
.btn-group.open > .btn + .btn-danger,
.input-group-btn.open > .btn + .btn-primary,
.input-group-btn.open > .btn + .btn-success,
.input-group-btn.open > .btn + .btn-warning,
.input-group-btn.open > .btn + .btn-danger,
.input-group-prepend.open > .btn + .btn-primary,
.input-group-prepend.open > .btn + .btn-success,
.input-group-prepend.open > .btn + .btn-warning,
.input-group-prepend.open > .btn + .btn-danger,
.input-group-append.open > .btn + .btn-primary,
.input-group-append.open > .btn + .btn-success,
.input-group-append.open > .btn + .btn-warning,
.input-group-append.open > .btn + .btn-danger {
  border-left-color: rgba(0, 0, 0, 0.15);
}

.btn-group-justified {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
}

.btn-group-justified .btn,
.btn-group-justified .btn-group {
  float: none;
  display: table-cell;
  width: 1%;
}

.btn-group-justified .btn .btn,
.btn-group-justified .btn-group .btn {
  width: 100%;
}

.btn-group-justified .btn .dropdown-menu,
.btn-group-justified .btn-group .dropdown-menu {
  left: auto;
}

.btn-group-justified > .btn {
  border-right-width: 0;
}

.btn-group-justified > .btn:last-child {
  border-right-width: 1px;
}

.btn-group-vertical > .btn.btn-secondary:hover,
.btn-group-vertical > .btn.btn-secondary:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-group-vertical > .btn.btn-secondary:active,
.btn-group-vertical > .btn.btn-secondary.active {
  -webkit-box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.06);
}

.btn-group-vertical > .btn.btn-primary {
  border-top-color: #00637f;
  border-bottom-color: #00637f;
}

.btn-group-vertical > .btn.btn-primary:hover,
.btn-group-vertical > .btn.btn-primary:active,
.btn-group-vertical > .btn.btn-primary.active {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-top-color: #004b61;
  border-bottom-color: #004b61;
}

.btn-group-vertical > .btn.btn-primary:first-child {
  border-top-color: transparent;
}

.btn-group-vertical > .btn.btn-primary:last-child {
  border-bottom-color: transparent;
}

.btn-group-vertical > .btn.btn-primary:active,
.btn-group-vertical > .btn.btn-primary.active {
  -webkit-box-shadow: inset 0 1px 0 #004b61;
  box-shadow: inset 0 1px 0 #004b61;
  border-top-color: #004b61;
  border-bottom-color: #004b61;
}

.btn-group-vertical > .btn.btn-success {
  border-top-color: #006229;
  border-bottom-color: #006229;
}

.btn-group-vertical > .btn.btn-success:hover,
.btn-group-vertical > .btn.btn-success:active,
.btn-group-vertical > .btn.btn-success.active {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-top-color: #00441c;
  border-bottom-color: #00441c;
}

.btn-group-vertical > .btn.btn-success:first-child {
  border-top-color: transparent;
}

.btn-group-vertical > .btn.btn-success:last-child {
  border-bottom-color: transparent;
}

.btn-group-vertical > .btn.btn-success:active,
.btn-group-vertical > .btn.btn-success.active {
  -webkit-box-shadow: inset 0 1px 0 #00441c;
  box-shadow: inset 0 1px 0 #00441c;
  border-top-color: #00441c;
  border-bottom-color: #00441c;
}

.btn-group-vertical > .btn.btn-warning {
  border-top-color: #f7aa00;
  border-bottom-color: #f7aa00;
}

.btn-group-vertical > .btn.btn-warning:hover,
.btn-group-vertical > .btn.btn-warning:active,
.btn-group-vertical > .btn.btn-warning.active {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-top-color: #d99500;
  border-bottom-color: #d99500;
}

.btn-group-vertical > .btn.btn-warning:first-child {
  border-top-color: transparent;
}

.btn-group-vertical > .btn.btn-warning:last-child {
  border-bottom-color: transparent;
}

.btn-group-vertical > .btn.btn-warning:active,
.btn-group-vertical > .btn.btn-warning.active {
  -webkit-box-shadow: inset 0 1px 0 #d99500;
  box-shadow: inset 0 1px 0 #d99500;
  border-top-color: #d99500;
  border-bottom-color: #d99500;
}

.btn-group-vertical > .btn.btn-danger {
  border-top-color: #b7001b;
  border-bottom-color: #b7001b;
}

.btn-group-vertical > .btn.btn-danger:hover,
.btn-group-vertical > .btn.btn-danger:active,
.btn-group-vertical > .btn.btn-danger.active {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-top-color: #990016;
  border-bottom-color: #990016;
}

.btn-group-vertical > .btn.btn-danger:first-child {
  border-top-color: transparent;
}

.btn-group-vertical > .btn.btn-danger:last-child {
  border-bottom-color: transparent;
}

.btn-group-vertical > .btn.btn-danger:active,
.btn-group-vertical > .btn.btn-danger.active {
  -webkit-box-shadow: inset 0 1px 0 #990016;
  box-shadow: inset 0 1px 0 #990016;
  border-top-color: #990016;
  border-bottom-color: #990016;
}

.input-group-btn > .btn + .dropdown-toggle,
.input-group-prepend > .btn + .dropdown-toggle,
.input-group-append > .btn + .dropdown-toggle {
  padding-left: 9px;
  padding-right: 9px;
}
