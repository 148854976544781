.assign_form_details {
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  height: 100%;
  background-color: var(--white);
  padding: 30px;
  background-color: #ffffff;
  @media (max-width: 425px) {
    padding: 16px;
  }
  .assig_assignee {
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 12px;
    color: #000000;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    overflow-wrap: break-word;
  }
  .two_sides {
    padding-top: 15px;
    display: flex;
    justify-content: space-between;

    .group_to_message {
      .assign_seperate {
        padding: 0px 0px 17px 0px;
        .assign_expiry_msg {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #db0020;
          .info_icon {
            margin-right: 5px;
            img {
              vertical-align: sub;
              border-style: none;
            }
          }
        }
        .assign_grey_title {
          font-size: 13px;
          color: #505759;
        }
        .assign_black_data {
          font-size: 15px;
          color: #000000;
          max-width: 555px;
          .assign_content {
            overflow-wrap: break-word;
          }
        }
      }
    }
    .group_date_views {
      display: flex;
      @media (max-width: 375px) {
        display: none;
      }
      .seperate_line {
        border-left: 1px solid #e8e8e8;
        margin-right: 20px;
        &.no_border {
          display: none;
        }
      }
      .grouped {
        margin-right: 20px;
        .assign_all_views {
          padding-top: 10px;
        }
        .due_date {
          .assign_grey_title {
            font-size: 13px;
            color: #505759;
          }
          .assign_black_data {
            font-size: 15px;
            color: #000000;
            .assign_content {
              max-width: 300px;
            }
          }
        }
        .assign_redo_assessment {
          padding-top: 10px;
          font-size: 13px;
          color: #505759;
          .redo_status {
            font-size: 15px;
            color: #000000;
          }
        }
        .assign_all_views {
          padding-bottom: 15px;
          font-size: 13px;
          color: #505759;
          .views_number {
            &:hover {
              background-color: #005a70;
              cursor: pointer;
            }
            cursor: pointer;
            font-size: 15px;
            display: flex;
            padding: 0px 10px;
            height: 28px;
            border-radius: 17px;
            transition: 0.2s background-color;
            background-color: #007fa3;
            .people_icn {
              margin: auto;
              margin-right: 4px;
              img {
                height: 22px;
                width: 22px;
              }
            }
            .views_number_sub {
              margin: auto;
              font-size: 15px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              white-space: nowrap;
              letter-spacing: normal;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
  .moblie {
    display: none;
    @media (max-width: 375px) {
      display: block;
      margin-right: 50px;
      &.assign_redo_assessment {
        padding-bottom: 10px;
        font-size: 13px;
        color: #505759;
        .redo_status {
          font-size: 15px;
          color: #000000;
        }
      }
      .assign_expiry_msg {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #db0020;
      }
      .assign_grey_title {
        font-size: 13px;
        color: #505759;
      }
      .assign_black_data {
        font-size: 15px;
        color: #000000;
        padding-bottom: 20px;
      }
      &.assign_all_views {
        font-size: 13px;
        color: #505759;
        .views_number {
          &:hover {
            background-color: #005a70;
            cursor: pointer;
          }
          font-size: 13px;
          margin: auto 0;
          line-height: 13px;
          width: 100px;
          padding: 0 10px;
          display: flex;
          cursor: pointer;
          font-size: 15px;
          height: 28px;
          border-radius: 17px;
          transition: 0.2s background-color;
          background-color: #007fa3;
          .people_icn {
            margin-right: 4px;
            margin: auto;
            img {
              height: 22px;
              width: 22px;
            }
          }

          .views_number_sub {
            margin: auto;
            margin-left: 4px;
            font-size: 15px;
            font-weight: 600;
            white-space: nowrap;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #ffffff;
          }
        }

        padding-bottom: 10px;
      }
    }
  }
  .resource_view_between {
    display: flex;
    justify-content: space-between;
    margin: 0px 10px 7px 0px;
    font-size: 13px;
    color: #505759;
  }
  .assign_resource_list {
    &.expiry_resource {
      pointer-events: none;
      opacity: 0.4;
    }

    &:hover {
      background-color: #f3f3f3;
      cursor: pointer;
    }
    border-top: 1px solid #e8e8e8;
    display: flex;
    padding: 8px 6px 8px 0px;
    justify-content: space-between;
    .assign_resource_group {
      width: 80%;
      display: flex;
      position: relative;
      .red_dot_students {
        padding-left: 8px;
        line-height: normal;
      }
      .assign_resource_list_image {
        width: 25px;
        height: 25px;
        margin: auto 5px;
        background-position: center center;
        background-size: contain;
      }
      .assign_resource_list_name {
        @media (max-width: 475px) {
          max-width: 180px;
        }
        width: 80%;
        padding-left: 8px;
        font-size: 14px;
        line-height: 17px;
        margin: auto 0;
        display: inline-block;
        i {
          padding-left: 5px;
          font-size: 16px;
          // margin: auto 0;
        }
      }
    }
    .assign_number_of_res {
      &:hover {
        background-color: #005a70;
        cursor: pointer;
      }
      @media (max-width: 375px) {
        width: 100px;
      }
      font-size: 13px;
      margin: auto 0;
      line-height: 13px;
      padding: 0 10px;
      display: flex;
      cursor: pointer;
      font-size: 15px;
      height: 28px;
      border-radius: 17px;
      transition: 0.2s background-color;
      background-color: #007fa3;
      .people_icn {
        margin-right: 4px;
        margin: auto;

        img {
          height: 22px;
          width: 22px;
        }
      }
      .views_number_sub {
        margin: auto;
        margin-left: 4px;
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        white-space: nowrap;
        color: #ffffff;
      }
    }
  }

  .line-separater {
    border-top: 1px solid #e8e8e8;
  }
  .assign_from_title {
    padding-top: 15px;
    padding-bottom: 3px;
    font-size: 13px;
    color: #505759;
  }
  .assign_create_user {
    font-size: 15px;
    color: #000000;
  }
  .assign_action {
    padding: 16px 0;
    display: flex;
    /* margin-left: auto; */
    justify-content: flex-end;
    border-top: 1px solid #e8e8e8;
    margin-top: 16px;
    button {
      font-weight: 600;
      padding: 8px;
      &:last-child {
        margin-left: 16px;
      }
      &.assigment_delete {
        cursor: pointer;
        border: none;
        color: #db0020;
        background: transparent;
        text-decoration: underline;
        i {
          font-size: 16px;
        }
      }
      span {
        padding-left: 4px;
      }
    }
  }
}
