.year-drop-down-sdl-result,
.main-level-drop-down-result {
  background-image: url("../../../../../../assets/img/pec/all-images/Filter-Books/pull-down-arrow-down.png") !important;
  background-repeat: no-repeat !important;
  background-position: right !important;
  display: inline-block;
  width: 157px;
  height: 31px;
  background: #ffffff;
  border-radius: 5px;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  color: #377d9f;
  cursor: pointer;
  padding: 5px;
  background-position-x: 95% !important;
  margin-top: 9px;
  display: flex !important;
  align-items: center !important;
}
.year-drop-down-sdl-result {
  overflow: hidden;
  text-overflow: ellipsis;
  //   display: -webkit-box !important;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  height: 43px;
  flex-grow: 1;
  line-height: 19px;
  padding-right: 25px;
  padding-left: 10px;
  border-radius: 5px;
}
.main-level-drop-down-result {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  flex-grow: 1;
  width: 340px;
  height: 43px;
  background: #e8eff3;
  border-radius: 10px;
  color: #198cc5;
  line-height: 19px;
  padding-right: 25px;
  padding-left: 10px;
}
.main-level-drop-down {
  display: grid;
  justify-content: end;
  margin-bottom: 20px;
}
.report-box-main-section {
  background: #f5f6f7;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  padding: 10px;
  border-top: 14px solid #0088cf;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.main-component2 {
  transform: translate(1px, 50px);
}
.main-component3 {
  background: #e8eff3;
  border-radius: 10px;
  width: 250px;
  height: 220px;
  overflow-y: auto;
  transform: translate(59px, 125px);
}
.overall-component-section3 {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .year-drop-down-sdl-result {
    width: 100%;
  }
}

.chart-row {
  background: #fff;
}

.distribution-section {
  @media only screen and (max-width: 640px){
    margin-top: 30px;
  }
  &-score {
    color: #000000;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
  }
  &-bottom {
    display: flex;
    gap: 10px;
    align-items: center;
    &-submitted {
      color: #828282;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
    }
    &-total {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      color: #828282;
    }
  }
}
