@media only screen and (max-width: 700px) {
  .pear-eng-con-text {
    display: none;
  }
}

@media only screen and (max-width: 320px) {
  .link-button.nav-link {
    font-size: 15px !important;
  }
}
.before-login-banner {
  box-shadow: none !important;
  border: none !important;
}
//
//#dashboard {
//  padding-top:env(safe-area-inset-top, 7px) !important;
//  padding-top:constant(safe-area-inset-top);
//}

.main_nav_container {
  margin-top:7px !important;
}

.link-button.nav-link {
  color:black;
}