section.assignment-handbook {
  .notification-item {
    width: 100%;
    min-height: 87px;
    background-color: #fafafa;
    padding: 11px 16px;
    border-width: 1px;
    border-bottom-width: 1px;
    border-bottom-color: #e7e6e5;
    border-bottom-style: solid;
    position: relative;
    .new-assignment {
      width: 16px;
      height: 100%;
      position: absolute;
      display: flex;
      right: 15px;
      top: 0;
      img {
        width: 16px;
        height: 16px;
        object-fit: contain;
        margin: auto;
      }
    }
    .notification-item-header {
      width: 90%;
      min-height: 22px;
      font-size: 16px;
      color: #005a70;
      // padding: 11px 16px;
      // font-family: sans-serif;
      font-weight: 600;
      display: block;
      // padding: 11px 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .notification-item-body {
      width: 90%;
      min-height: 17px;
      // font-family: sans-serif;
      font-size: 12px;
      display: block;
      // padding: 12px 16px;
      // font-weight: normal;
      // font-stretch: normal;
      // font-style: normal;
      // line-height: normal;
      // letter-spacing: normal;
      color: #505759;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .notification-item-views {
      width: 100%;
      min-height: 17px;
      font-size: 12px;
    }
    .notification-item-footer {
      width: 100%;
      min-height: 17px;
      // font-family: sans-serif;
      font-size: 12px;
      display: block;
      // padding-top: 9px;
      // padding-bottom: 6px;
      // padding-left: 16px;
      // padding-right: 16px;
      // font-weight: normal;
      // font-stretch: normal;
      // font-style: normal;
      // line-height: normal;
      // letter-spacing: normal;
      color: #505759;
    }
  }
}
