.header-exercise {
  text-align: left !important;
  width: 24%;
}
.h-scroll-shadow{
  position: absolute;
  height: -moz-calc(100% - 63px);
  height: -webkit-calc(100% - 63px);
  height: calc(100% - 63px);
  width: 20px;
  top: 63px;
  z-index:1;
  display:none;
}
.arrow-left{
  background-repeat: no-repeat;
  background-image: url("../../../../../../../assets/img/scroll-arrow-left.png") !important;
  background-size: cover;
  height: 30px;
  width: 30px;
  display: block;
  /* text-align: center; */
  position: absolute;
  left: 25%;
  top: 33%;
}
.arrow-right{
  background-repeat: no-repeat;
  background-image: url("../../../../../../../assets/img/scroll-arrow-right.png") !important;
  background-size: cover;
  height: 30px;
  width: 30px;
  display: block;
  /* text-align: center; */
  position: absolute;
  left: 25%;
  top: 33%;
}
.h-scroll-button{
    
 text-align: center;
 line-height: 7;
  position: absolute;
  top: 145px;
  width: 50px;
  height: 150px;
  background: #198CC5;
  opacity: 0.8;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
  cursor:pointer;
 
  
}

.h-scroll-text-right{
  font-size: 21px;
  color: #fff;
  font-weight: bold;
  background-repeat: no-repeat;
  background-image: url("../../../../../../../assets/img/scroll-arrow-left.png") !important;
}
.h-scroll-text-left{
  font-size: 21px;
  color: #fff;
  font-weight: bold;
  background-repeat: no-repeat;
  background-image: url("../../../../../../../assets/img/scroll-arrow-left.png") !important;
}
.h-scroll-shadow-left{
  left:0;
  border-radius: 10px 0px 0px 10px;
  

  background: linear-gradient(-90deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.20) 100%);
}
.h-scroll-shadow-right{
  right:0;
  border-radius: 0px 10px 10px 0px;
   

  background: linear-gradient(90deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.20) 100%);
}
.h-scroll-shadow-left .h-scroll-button{
    left: 0px;
  border-radius: 0px 8px 8px 0px;
}
.h-scroll-shadow-right .h-scroll-button{
    right: 0px;
  border-radius: 8px 0px 0px 8px;
}
 

.header-exercise-center {
  text-align: center !important;
}

.table-heading {
  background-color: #b7e6e6 !important;
}

.table-heading-row {
  border: 1px solid #ccc;
}

.submission-view-button {
  background: #198cc5 !important;
  border-radius: 10px;
  width: 75px;
  height: 35px;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
}

.main__section-student-unit-grid.result-page {
  scroll-behavior: smooth;
  grid-template-columns: 1fr 120px 1fr;
  overflow-x: scroll;
  width: 100%;
}
.main__section-student-unit-grid.result-page::-webkit-scrollbar {
  height: 1px;
}
.main__section-student-unit-grid-grade.overall-table {
  td:first-child,
  th:first-child {
    border-radius: 10px 10px 10px 10px;
  }
}

.main__section-student-unit-grid-details-bottomheading-primary.result-page-th-heading {
  white-space: break-spaces;
}

.school-activity-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  height: 43px;
  flex-grow: 1;
  line-height: 20px;
  padding-right: 20px;
  padding-left: 10px;
}

.table-sorting-arrow.school-activities {
  margin-left: 30px;
}
.activities-table-data td {
  padding: 0 20px !important;
}
// .result-table-heading {
//   display: flex;
//   align-items: center;
// }
.result-page-table-head-height {
  height: 38px;
}

.student-result-container {
  display: flex;
  align-items: center;
  width: 110px;
  // gap: 2px;
  gap: 22px;
  &-left { 
    // flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &-right {
    display: flex;
    align-items: center;
  }

  &-img {
    display: block;
    margin: auto;
    // width: 24px;
    // height: 24px;
  }
}
.student-result-container-gap{
  gap: 5px;
  justify-content: center;
}

.progress-bar-center-position {
  text-align: -webkit-center;
}
.single-column-border-radius {
  border-radius: 10px !important;
}
// .single-progress-bar-center-position {
//   border-radius: 8px;
//   display: flex;
//   justify-content: flex-start;
//   min-width: 100%;
//   padding-left: 0px;
//   padding-right: 0px;
// }
// .progressbar-contents {
//   flex: 0 0 auto;
//   padding: 0px;
//   border: 0px;
//   width: 110px;
//   text-align: center;
//   padding-top: 8px;
//   padding-bottom: 8px;
// }

#scoretable{
  overflow-x: scroll;
}

.school_activities_table {
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  min-width: 100%;
}

.school_activities_table table th {
  // width: 110px;
  // text-align: center;
  // padding: 8px 0;
  width: 110px;
  text-align: left;
  padding: 0 5px;
  // vertical-align: top;
}

.school_activities_table table td {
  width: 110px;
  // text-align: center;
  padding: 8px 0;
}

//mobile phone compatibility
@media only screen and (max-width: 640px) {
  .main__handbooks-table-data {
    white-space: nowrap;
  }
}
