.login-now-section {
  position: absolute;
  top: 90%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  font-size: 16px;
  padding: 12px 24px;
  border: none;
}
.tablet_login_banner {
  .content_box {
    position: relative;
    padding-top: 20px;
    display: flex;
    .login_banner_text_box {
      width: 100%;
      position: relative;
      left: 0;
      display: flex;
      .text_body {
        margin: 0px;
        img {
          width: 100%;
        }
        h1 {
          font-size: 32px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #007fa3;
        }
        p {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          //text-align: center;
          color: #000000;
        }
      }
      button {
        //width: 100%;
        //margin: 25px 0;
        height: 45px;
        padding: 5px 20px;
        font-size: 18px;
        font-weight: 600;
        line-height: 1.22;
      }
    }
  }
  .content_box.web {
    display: none;
    .login_banner_text_box {
      .text_body {
      }
      img {
        max-width: 685px;
      }
    }
  }
  .content_box.mobile {
    display: none;
  }
  .content_box.tablet {
    height: 540px;
    background-size: 600px;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-position-y: 55px;
    .login_banner_text_box {
      position: absolute;
      left: 50px;
      width: 50%;
      height: 100%;
      .text_body {
        margin: 50px 0px;
        h1 {
          font-size: 32px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #007fa3;
        }
        p {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #000000;
        }
        button {
          cursor: pointer;
          padding: 5px 20px;
          font-size: 18px;
          font-weight: 600;
          line-height: 1.22;
        }
      }
    }
    img {
      //position: absolute;
      max-width: 650px;
    }
  }

  @media (max-width: 1160px) {
    .card {
      margin: 25px 12px;
      .content_box.tablet {
        //display: none;
        .login_banner_text_box {
          //left: 15px;
          width: 40%;
          .text_body {
            :nth-child(4) {
              position: absolute;
              bottom: 45px;
              //padding-top: 120px;
              font-size: 12px;
            }
            button {
            }
          }
        }
      }
    }
  }
  @media (max-width: 950px) {
    .card {
      .content_box.tablet {
        //display: none;
        .login_banner_text_box {
          //left: 15px;
          width: 35%;
          .text_body {
            button {
            }
          }
        }
      }
    }
  }
  @media (max-width: 850px) {
    .card {
      .content_box.tablet {
        display: none;
      }
      .content_box.mobile {
        display: block;
        height: auto;
        .login_banner_text_box {
          display: block;
          width: 100%;

          position: relative;
          left: 0;
          text-align: center;
          .text_body {
            margin: 0px;
            img {
              width: 100%;
              @media (min-width: 500px) {
                width: 70%;
              }
            }
            p {
              padding: 0 25px;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: center;
              color: #000000;
            }
          }
          :nth-child(3) {
            font-size: 12px;
          }
          button {
            width: 100%;
            margin: 25px 0;
            height: 45px;
            padding: 5px 20px;
            font-size: 18px;
            font-weight: 600;
            line-height: 1.22;
          }
        }
      }
    }
  }

  @media (min-width: 1100px) {
    .card {
      max-width: 1356px;
      width: 98%;
      margin: 25px auto;
      //margin: 25px;

      .content_box.tablet,
      .content_box.mobile {
        display: none;
      }
      .content_box.web {
        display: block;
        padding: 64px;
        .text_body {
          h1 {
            font-size: 48px;
          }
          p {
            font-size: 18px;
          }
          :nth-child(4) {
            padding-top: 25%;
            font-size: 18px;
          }
          button {
            width: 100px;
            height: 45px;
          }
        }
      }
    }
  }
}