body .fullscreen {
  .react-images__blanket {
    z-index: 99999 !important;
  }
  .react-images__positioner {
    z-index: 100000 !important;
  }
  .react-images__view-image{
      max-height: 80vh !important;
  }
}
