.assign_form {
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

  padding: 30px;
  background-color: #ffffff;
  @media (max-width: 425px) {
    padding: 16px;
  }
  .assign_title {
    padding-top: 15px;
    .form-control {
      @media (max-width: 425px) {
        font-size: 15px;
      }
    }
    .input_valid {
      color: red;
    }
    .vaild_border {
      border: red solid 1px;
    }
  }
  .assign_bookseries.form-group {
    a {
      color: #000000;
      font-size: 15px;
    }
  }
  .assign_classes {
    .basic-multi-select {
      .select__control {
        .select__value-container {
          .select__multi-value {
            padding: 5px;
            border-radius: 15px;
            background-color: #e8e8e8;
          }
        }
      }
    }
    .input_valid {
      color: red;
    }
    .basic-multi-select .css-2b097c-container {
      .vaild_border {
        border: red solid 1px;
      }
    }
  }
  .assign_due_date.form-group {
    @media (max-width: 320px) {
      padding-bottom: 34px;
    }
    display: flex;
    flex-wrap: nowrap;
    // border-bottom: 1px solid #dcdee3;
    label {
      &.custom-control {
        padding-top: 5px;
      }
      margin-top: 6px;
      .due_date_text {
        color: #000000;
        font-size: 17px;

        margin-top: -4px;
      }

      .custom-control {
        .custom-control-input {
          border-radius: 2px;
          border: solid 1px #bbbbbb;
          background-color: #ecf9f7;
        }
      }
    }

    .date_picker {
      @media (max-width: 320px) {
        width: 50%;
      }
      label {
        padding-left: 16px;
        @media (max-width: 320px) {
          padding-left: 0px;
        }
        position: relative;
        .react-datepicker-wrapper {
          .react-datepicker__input-container {
            input {
              transition: none;
              box-shadow: none;
              font-size: 15px;
              border-style: solid;
              background-color: var(--white);
              border: solid 1px #bdc0c7;
              border-radius: 4px;
              padding: 4px;
              font-family: none;
            }
          }
        }

        i {
          position: absolute;
          top: 7px;
          right: 5px;
          font-size: 17px;
          cursor: pointer;
        }
      }
      .date_picker_message {
        padding-left: 16px;
        color: red;
        i {
          padding-right: 5px;
        }
      }
    }
  }
  .assign_redo.form-group {
    border-top: 1px solid #dcdee3;
    border-bottom: 1px solid #dcdee3;
    padding: 15px 0;
    label.switch {
      margin-left: 15px;
      margin-bottom: 0;
    }
  }
  .yellow_footer {
    background-color: #ffb81c;
    padding: 10px 20px 10px 20px;
    width: 100%;
    cursor: pointer;
    div {
      text-align: center;
      color: #000000;
      font-size: 18px;
    }
  }
  .assignment-container {
    width: 100%;
  }
  .image_dnd {
    width: 25px;
    height: 25px;
  }
  .assignment-item {
    border: 1px solid #dcdee3;
    padding: 1em;
    margin: 0 0 0.5rem 0;
    display: flex;
    align-items: center;
    &.disable {
      border: none;
      border-top: 1px solid #dcdee3;
      &:last-child {
        border-bottom: 1px solid #dcdee3;
      }
    }
    span {
      margin-left: 9px;
      font-size: 15px;
    }
    .mdi-menu,
    .mdi-delete {
      font-size: 1.5em;
      cursor: pointer;
    }

    .mdi-menu {
      margin-right: 0.5em;
      display: flex;
      align-items: center;
    }

    .mdi-delete {
      margin-left: auto;

      &:hover {
        color: #d92818;
      }
    }
  }
}
