.filter-row.question-view {
  //padding-left: 20px;
  //padding-right: 20px;
  display: flex;
  justify-content: space-between;
  .left {
    display: flex;
    button {
      margin-right: 6px;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
      text-align: center;
      color: #252525;
      i {
        font-size: 24px;
        padding: 0 5px;
      }
    }
  }
  .right {
    display: flex;
    select {
      margin: 0 3px;
      height: 36px;
      &:nth-child(1) {
        width: 73px;
      }
      &:nth-child(2) {
        width: 152px;
        @media (max-width: 500px) {
          display: none;
        }
      }
      &:last-child {
        margin-right: 0px;
      }
    }
  }
}
.question-box {
  padding: 10px 0px;
  #learnosity_assess {
    min-height: auto;
  }
  .item-next {
    display: none !important;
  }
  .item-prev {
    display: none !important;
  }
}
.user-list {
  padding: 0 0px;
  table {
    width: 100%;
    thead {
      tr {
        height: 50px;
        background-color: #f9f9f9;
        border: 1px solid #e3e3e3;
        .correct {
          text-align: center;
        }
        th {
          border: 1px solid #e3e3e3;
          padding: 5px 10px;
          &:nth-child(1) {
            font-size: 14px;
            font-weight: 600;
            width: 17%;
          }
        }
      }
    }
    tbody {
      tr {
        min-height: 38px;
        height: 100%;
        border-top: none;
        border: 1px solid #e3e3e3;
        td {
          border: 1px solid #e3e3e3;
          padding: 9px 10px;
          font-size: 14px;
          &.title {
            cursor: pointer;
            color: #007fa3;
          }
          .score-mark {
            &.green {
              background-color: #61ae41;
            }
            &.red {
              background-color: #da1819;
            }
            &.yellow {
              background-color: #f39c12;
            }
            &.grey {
              background-color: #a0a3a2;
            }
            text-align: center;
            margin: auto;
            border-radius: 50%;
            width: 16px;
            height: 16px;
          }
          .responese {
            &.red {
              color: #da1819;
            }
            &.grey {
              color: #a0a3a2;
              font-style: italic;
            }
          }
          .clickDetail {
            cursor: pointer;
            display: inline-block;
            width: auto;
            text-decoration: underline;
            color: #007fa3;
            i {
              padding-left: 5px;
            }
          }
        }
      }
    }
  }
}
.questions_icon_center{
  text-align: -webkit-center;
}