/*------------------------------------------------------------------
	[Main wrapper]
*/
.be-wrapper {
  min-height: 100%;
  // padding-top: 50px;
  display: flex;
  flex: 1;
  &.for-inapp {
    padding-top: 45px;
  }
}

@media (max-width: 599.98px) {
  .be-wrapper {
    // overflow-x: hidden;
  }
  .be-sub-navigation {
    top: 0px;
    border-bottom: 0px solid #eee;
  }
  .select-action-bar {
    transform: translate(0, 150px);
  }
}

@media print {
  .be-wrapper {
    padding-top: 0;
  }
}

/**/

.pageTitle {
  display: block;
  font-size: 27px;
  line-height: 27px;
  font-weight: 300;
  flex: 1;
  cursor: default;
  padding: 5px 0px;
}

.be-login {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* padding-top: 61px; */
  min-width: 401px;
}

.be-tp {
  min-height: 100%;
  /* padding-top: 61px; */
}

.select-form-control {
  border-width: 1px;
  border-top-color: #bdc0c7;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 10px 12px;
  font-size: 1.1538rem;
  -webkit-transition: none;
  transition: none;
  height: 3.692rem;
}

.select-form-control-small {
  border-width: 1px;
  border-top-color: #bdc0c7;
  box-shadow: none;
  font-size: 1.1538rem;
  transition: none;
  height: 1.692rem;
  line-height: 1.428571;
  color: #404040;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #d5d8de;
  border-radius: 2px;
}

.select-form-control {
  display: block;
  width: 100%;
  height: 3.692rem;
  padding: 0.7692rem 0.75rem;
  font-size: 1rem;
  line-height: 1.428571;
  color: #404040;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #d5d8de;
  border-radius: 2px;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

#menu.menu-overlay {
  position: fixed;
  margin-top: 4px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  // pointer-events: none;
  backdrop-filter: blur(5px);
}

#iconbutton.menu-overlay {
  display: none;
  position: fixed;
  margin-top: 5px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  // pointer-events: none;
  backdrop-filter: blur(5px);
  &.show {
    @media (max-width: 979px) {
      display: block;
    }
  }
  &.profile {
    //margin-top: 8px;
  }
}

.site-wrapper {
  display: flex;
  flex-direction: column;
}

.dashboard-body {
  flex-grow: 1;
  height: 100%;
}

#studygroup.card{
  height: 100%;
}

.body{
  height:100%;
}

.class-card-hover {
  > div {
    transition: transform 0.1s;
    transition-timing-function: ease-in;
  }
  &:active > div,
  &:focus > div {
    transform: scale(0.95, 0.95);
  }
  &:hover > div {
    transform: scale(1.04, 1.04);
  }
}

.container-NavSub {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.tab-scroll-title {
  color: #007fa3;
  font-size: 1rem;
  cursor: pointer;
  &:hover {
    color: #004357;
  }
}

.modal-open {
  padding-right: 0px !important;
  #dashboard.class-nav-bar {
    padding: 0.5rem 1rem !important;
  }
}

#createLessonPlan.fancybox-content,
#changeClassName.fancybox-content,
#changeTeacher.fancybox-content,
.description_box.fancybox-content {
  padding: 0px;
  max-width: 600px;
  width: 100%;
}

.plan-button .btn {
  margin: 0px 5px 5px 0px;
}

.tp-subject .row {
  .form_group label {
    min-width: 150px;
  }
  margin-left: 0px;
  margin-right: 0px;
}

@media (max-width: 979.98px) {
  .tp-subject .row .form_group label {
    min-width: 100px;
    display: block;
    font-size: 0.8rem;
    margin-top: 12px;
    margin-bottom: 0px;
  }
}

.card-body.ani-card-body.mt3
  .row
  .card-header.card-header-divider.aside-header {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.lessonplan-container {
  text-align: center;
  margin: 0px 20px;
  > {
    img {
      padding-top: 22px;
      padding-bottom: 27px;
    }
    a {
      margin-top: 20px;
      margin-bottom: 27px;
    }
  }
}

/*-!!!!!!!!!!-- NOTICE-!!!!!!!--*/

.lessonplan-title {
  background-color: #f0f0f0;
  color: #505759;
  font-weight: bold;
  position: -webkit-sticky;
  position: sticky;
  top: 160px;
  z-index: 1;
  > .lptitle {
    padding: 10px 0px 10px 10px;
    cursor: default;
    > {
      .text {
        display: inline-block;
      }
      .edit-title > .action-btn {
        padding: 0px 3px 0px 3px;
        height: 16px;
      }
    }
  }
  &:hover {
    > .lptitle > .edit-title {
      display: inline-block;
    }
    > .move-handle {
      background-color: #fff;
      &:hover {
        background-color: #fff;
      }
    }
  }
  > .move-handle {
    padding: 10px 10px 10px 7px;
    cursor: pointer;
  }
  > .more-btn {
    padding-top: 5px;
  }
}

table#title-hover .odd > .dataTables_empty {
  display: none;
}

.fancybox-close-small svg {
  color: #000;
  opacity: 0.5;
  margin-top: 8px;
}

.scrollToTop {
  background-color: #007fa3;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3);
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 4px;
  bottom: 150px;
  cursor: pointer;
  font-size: 20px;
  padding: 10px;
  position: fixed;
  right: 20px;
  bottom: -100px;
  transition: bottom 0.5s;
  z-index: 9998;
  > div > i {
    font-size: 2rem;
    color: white;
  }
  &:hover {
    background-color: #005a70;
  }
}

.scrollToTopTransition {
  // bottom: calc(61px + env(safe-area-inset-bottom));
  bottom: calc(61px + constant(safe-area-inset-bottom)); /* 兼容 iOS < 11.2 */
  bottom: calc(61px + env(safe-area-inset-bottom)); /* 兼容 iOS >= 11.2 */
}

.lessobPlanBox {
  .row.be-datatable-footer {
    padding: 0;
  }
  &:last-child {
    padding: 0px 0px 35px;
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 0.33s ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.react-modal-close {
  /*
	top: 0;
	right: 0;
	background: transparent;
	border: 0;
	border-radius: 0;
	color: #ccc;
	cursor: pointer;
	opacity: 0.8;
	padding: 8px;
	position: absolute;
	z-index: 401;
	display: inline-block;
	height: 44px;
	margin: 8px 0px;
	transition: color 0.2s;
	vertical-align: top;
	visibility: inherit;
	width: 44px;
	-webkit-transition: color 0.2s;*/
  &:hover {
    opacity: 1;
  }
  .svg {
    display: block;
    height: 100%;
    overflow: visible;
    position: relative;
    width: 100%;
    color: #000;
    opacity: 0.5;
    margin-top: 8px;
  }
}

.table.dataTable td.dataTables_empty,
table.dataTable th.dataTables_empty {
  padding: 20px;
}

.resource-title-box .title .title-btn-box button {
  padding: 0;
  .icon {
    padding-top: 5px;
  }
}

.parent {
  &.open ul.sub-menu {
    display: block;
  }
  ul.sub-menu {
    display: none;
  }
}

/*OnBoarding*/

.onBoardingCard {
  margin-top: 25px;
}

.onBoardingBanner {
  padding: 0px 20px 0px 20px;
}

.onBoardingTitle {
  padding: 5px;
  h4 p {
    font-size: 1.8rem;
    color: #007fa3;
  }
}

.fuelux .wizard > .steps-container > .steps li.tab-allow {
  cursor: pointer;
}

.setUp-box {
  padding: 5px 0px 5px 5px;
  .setUp-later {
    text-decoration: underline;
    cursor: pointer;
  }
}

.notify-box {
  display: flex;
  .notify-title {
    display: flex;

    font-size: 18px;

    padding: 21px 48px 4px 17px;
    .notify-icon {
      padding-right: 10px;
    }
    img {
      padding-right: 5px;
      width: 40px;
      height: 40px;
      margin: auto 0;
    }
    padding-right: 10px;
    margin: auto 0;
    color: #252525;
    .notify-text {
      margin: auto 0;
      h4 {
        @media (width: 768px) {
          font-size: 18px;
        }
        @media (width: 320px) {
          font-size: 18px;
        }
      }
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
    }
  }
  .notify-des {
    color: #252525;
  }
}

div#headingOne {
  font-size: 1.2rem;
}

.bookWithUnit-box > .book-box > .unit-box {
  .unit-btn {
    cursor: pointer;
    display: block;
    padding: 5px;
    &:hover {
      background: #f0f0f0;
    }
  }
  a .icon {
    margin-right: 5px;
  }
}

div.lesson-box {
  margin-left: 15px;
}

.row-box {
  position: relative;
  margin-left: 30px;
  margin-right: 15px;
  &.unit .row-child {
    height: 240%;
    top: -65%;
  }
}

.row-child {
  height: 140%;
  top: -20%;
  position: absolute;
  width: 10px;
  border-left: 1px solid #999;
  &.last {
    border: 0px;
  }
  .inside-line {
    height: 50%;
    width: 10px;
    border-bottom: 1px solid #999;
  }
  &.last .inside-line {
    height: 50%;
    width: 10px;
    border-bottom: 1px solid #999;
    border-left: 1px solid #999;
  }
}

.table td.no-border-top {
  border-top: 0px;
}

.form-control.error {
  border-color: red;
}

.error-text {
  color: red;
  .mdi {
    margin-right: 3px;
  }
}

.assigneelist {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 20px;
}

@media (min-width: 1199px) {
  .lessonplan-title > .lptitle > .edit-title {
    display: none;
  }
}

@media (min-width: 620px) {
  .tp-subject .row .form_group div.plan-button {
    &:first-child {
      float: left;
    }
    float: right;
  }
}

.studygrouplist-td {
  display: flex;
}

@media (max-width: 979.98px) {
  .container-NavSub {
    padding-right: 0;
    padding-left: 0;
  }
  .mobile-hidden,
  .studygrouplist-td {
    display: none;
  }
  .resource-title-box .title {
    .title-btn-box button .icon {
      padding-top: 0px;
    }
    padding-top: 15px;
  }
  .custom-control-sm .custom-control-input ~ .custom-control-label::before {
    margin-top: 0px;
  }
  .row-box.unit .row-child {
    height: 260%;
    top: -80%;
  }
}

.be-container {
  padding-top: env(safe-area-inset-top);
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-grow: 1;
  .tp-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    flex-grow: 1;
  }
}

.sorting_disable {
  cursor: default;
}

.handbook-status-new {
  object-fit: contain;
  width: 25px;
  height: 14px;
  margin-bottom: 3px;
}

.handbook-status-newtext {
  color: #eb7600;
  font-size: 1.2em;
  padding-left: 0.3em;
}

.mdi.reset-password {
  font-size: 7rem;
  padding-top: 15px;
}

.button-margin {
  padding: 10px 0px 10px 0;
  width: 100%;
}

.studygrouplist {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
}

@media (max-width: 1199px) {
  .lessonplan-title > .lptitle > .edit-title {
    display: inline-block;
  }
}

.compensate-for-scrollbar {
  margin-right: 0;
}

@media (min-width: 600px) {
  .card-subtitle-select {
    float: right !important;
  }
}

@media (max-width: 599.98px) {
  .card-subtitle-select {
    //padding-top: 60px;
  }
  /*-!!!!!!!!!!-- NOTICE-!!!!!!!--*/
  .show-menu .be-wrapper {
    overflow-x: hidden;
  }
}

@media (max-width: 979.98px) {
  .be-icons-nav .dropdown.show #iconbutton.menu-overlay {
    // opacity: 1 !important;
    // pointer-events: auto !important;
  }
}

.site-wrapper-right {
  //margin-left: 230px;
  right: 0;
  bottom: 0;
}

.full-rourte {
  margin-left: 0px;
}

.school_year_row {
  background-color: #eeeeee;
  height: 20px;
  display: flex;
  flex-direction: row-reverse;
  padding: 0 15px;
  span {
    margin: auto 0;
    font-weight: bold;
    font-size: 11px;
  }
}
