$Roboto-Thin-eot: './lib/roboto';

@font-face {
	font-family: 'Roboto';
	src: url('#{$Roboto-Thin-eot}/Roboto-Thin.eot');
	src: local("Roboto Thin"), local("Roboto-Thin"),
		url('#{$Roboto-Thin-eot}/Roboto-Thin.eot?#iefix') format("embedded-opentype"),
		url('#{$Roboto-Thin-eot}/Roboto-Thin.woff2') format("woff2"),
		url('#{$Roboto-Thin-eot}/Roboto-Thin.ttf') format("truetype"),
		url('#{$Roboto-Thin-eot}/Roboto-Thin.svg#Roboto') format("svg");
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: "Roboto-Thin";
	src: url('#{$Roboto-Thin-eot}/Roboto-Thin.eot');
	src: local("Roboto Thin"), local("Roboto-Thin"),
		url('#{$Roboto-Thin-eot}/Roboto-Thin.eot?#iefix') format("embedded-opentype"),
		url('#{$Roboto-Thin-eot}/Roboto-Thin.woff2') format("woff2"),
		url('#{$Roboto-Thin-eot}/Roboto-Thin.woff') format("woff"),
		url('#{$Roboto-Thin-eot}/Roboto-Thin.ttf') format("truetype"),
		url('#{$Roboto-Thin-eot}/Roboto-Thin.svg#Roboto') format("svg");
}

@font-face {
	font-family: 'Roboto';
	src: url('#{$Roboto-Thin-eot}/Roboto-ThinItalic.eot');
	src: local("Roboto ThinItalic"), local("Roboto-ThinItalic"),
		url('#{$Roboto-Thin-eot}/Roboto-ThinItalic.eot?#iefix') format("embedded-opentype"),
		url('#{$Roboto-Thin-eot}/Roboto-ThinItalic.woff2') format("woff2"),
		url('#{$Roboto-Thin-eot}/Roboto-ThinItalic.woff') format("woff"),
		url('#{$Roboto-Thin-eot}/Roboto-ThinItalic.ttf') format("truetype"),
		url('#{$Roboto-Thin-eot}/Roboto-ThinItalic.svg#Roboto') format("svg");
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: "Roboto-ThinItalic";
	src: url('#{$Roboto-Thin-eot}/Roboto-ThinItalic.eot');
	src: local("Roboto ThinItalic"), local("Roboto-ThinItalic"),
		url('#{$Roboto-Thin-eot}/Roboto-ThinItalic.eot?#iefix') format("embedded-opentype"),
		url('#{$Roboto-Thin-eot}/Roboto-ThinItalic.woff2') format("woff2"),
		url('#{$Roboto-Thin-eot}/Roboto-ThinItalic.woff') format("woff"),
		url('#{$Roboto-Thin-eot}/Roboto-ThinItalic.ttf') format("truetype"),
		url('#{$Roboto-Thin-eot}/Roboto-ThinItalic.svg#Roboto') format("svg");
}

@font-face {
	font-family: 'Roboto';
	src: url('#{$Roboto-Thin-eot}/Roboto-Light.eot');
	src: local("Roboto Light"), local("Roboto-Light"),
		url('#{$Roboto-Thin-eot}/Roboto-Light.eot?#iefix') format("embedded-opentype"),
		url('#{$Roboto-Thin-eot}/Roboto-Light.woff2') format("woff2"),
		url('#{$Roboto-Thin-eot}/Roboto-Light.woff') format("woff"),
		url('#{$Roboto-Thin-eot}/Roboto-Light.ttf') format("truetype"),
		url('#{$Roboto-Thin-eot}/Roboto-Light.svg#Roboto') format("svg");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "Roboto-Light";
	src: url('#{$Roboto-Thin-eot}/Roboto-Light.eot');
	src: local("Roboto Light"), local("Roboto-Light"),
		url('#{$Roboto-Thin-eot}/Roboto-Light.eot?#iefix') format("embedded-opentype"),
		url('#{$Roboto-Thin-eot}/Roboto-Light.woff2') format("woff2"),
		url('#{$Roboto-Thin-eot}/Roboto-Light.woff') format("woff"),
		url('#{$Roboto-Thin-eot}/Roboto-Light.ttf') format("truetype"),
		url('#{$Roboto-Thin-eot}/Roboto-Light.svg#Roboto') format("svg");
}

@font-face {
	font-family: 'Roboto';
	src: url('#{$Roboto-Thin-eot}/Roboto-LightItalic.eot');
	src: local("Roboto LightItalic"), local("Roboto-LightItalic"),
		url('#{$Roboto-Thin-eot}/Roboto-LightItalic.eot?#iefix') format("embedded-opentype"),
		url('#{$Roboto-Thin-eot}/Roboto-LightItalic.woff2') format("woff2"),
		url('#{$Roboto-Thin-eot}/Roboto-LightItalic.woff') format("woff"),
		url('#{$Roboto-Thin-eot}/Roboto-LightItalic.ttf') format("truetype"),
		url('#{$Roboto-Thin-eot}/Roboto-LightItalic.svg#Roboto') format("svg");
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: "Roboto-LightItalic";
	src: url('#{$Roboto-Thin-eot}/Roboto-LightItalic.eot');
	src: local("Roboto LightItalic"), local("Roboto-LightItalic"),
		url('#{$Roboto-Thin-eot}/Roboto-LightItalic.eot?#iefix') format("embedded-opentype"),
		url('#{$Roboto-Thin-eot}/Roboto-LightItalic.woff2') format("woff2"),
		url('#{$Roboto-Thin-eot}/Roboto-LightItalic.woff') format("woff"),
		url('#{$Roboto-Thin-eot}/Roboto-LightItalic.ttf') format("truetype"),
		url('#{$Roboto-Thin-eot}/Roboto-LightItalic.svg#Roboto') format("svg");
}

@font-face {
	font-family: 'Roboto';
	src: url('#{$Roboto-Thin-eot}/Roboto-Regular.eot');
	src: local("Roboto Regular"), local("Roboto-Regular"),
		url('#{$Roboto-Thin-eot}/Roboto-Regular.eot?#iefix') format("embedded-opentype"),
		url('#{$Roboto-Thin-eot}/Roboto-Regular.woff2') format("woff2"),
		url('#{$Roboto-Thin-eot}/Roboto-Regular.woff') format("woff"),
		url('#{$Roboto-Thin-eot}/Roboto-Regular.ttf') format("truetype"),
		url('#{$Roboto-Thin-eot}/Roboto-Regular.svg#Roboto') format("svg");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "Roboto-Regular";
	src: url('#{$Roboto-Thin-eot}/Roboto-Regular.eot');
	src: local("Roboto Regular"), local("Roboto-Regular"),
		url('#{$Roboto-Thin-eot}/Roboto-Regular.eot?#iefix') format("embedded-opentype"),
		url('#{$Roboto-Thin-eot}/Roboto-Regular.woff2') format("woff2"),
		url('#{$Roboto-Thin-eot}/Roboto-Regular.woff') format("woff"),
		url('#{$Roboto-Thin-eot}/Roboto-Regular.ttf') format("truetype"),
		url('#{$Roboto-Thin-eot}/Roboto-Regular.svg#Roboto') format("svg");
}

@font-face {
	font-family: 'Roboto';
	src: url('#{$Roboto-Thin-eot}/Roboto-RegularItalic.eot');
	src: local("Roboto RegularItalic"), local("Roboto-RegularItalic"),
		url('#{$Roboto-Thin-eot}/Roboto-RegularItalic.eot?#iefix') format("embedded-opentype"),
		url('#{$Roboto-Thin-eot}/Roboto-RegularItalic.woff2') format("woff2"),
		url('#{$Roboto-Thin-eot}/Roboto-RegularItalic.woff') format("woff"),
		url('#{$Roboto-Thin-eot}/Roboto-RegularItalic.ttf') format("truetype"),
		url('#{$Roboto-Thin-eot}/Roboto-RegularItalic.svg#Roboto') format("svg");
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: "Roboto-RegularItalic";
	src: url('#{$Roboto-Thin-eot}/Roboto-RegularItalic.eot');
	src: local("Roboto RegularItalic"), local("Roboto-RegularItalic"),
		url('#{$Roboto-Thin-eot}/Roboto-RegularItalic.eot?#iefix') format("embedded-opentype"),
		url('#{$Roboto-Thin-eot}/Roboto-RegularItalic.woff2') format("woff2"),
		url('#{$Roboto-Thin-eot}/Roboto-RegularItalic.woff') format("woff"),
		url('#{$Roboto-Thin-eot}/Roboto-RegularItalic.ttf') format("truetype"),
		url('#{$Roboto-Thin-eot}/Roboto-RegularItalic.svg#Roboto') format("svg");
}

@font-face {
	font-family: 'Roboto';
	src: url('#{$Roboto-Thin-eot}/Roboto-Medium.eot');
	src: local("Roboto Medium"), local("Roboto-Medium"),
		url('#{$Roboto-Thin-eot}/Roboto-Medium.eot?#iefix') format("embedded-opentype"),
		url('#{$Roboto-Thin-eot}/Roboto-Medium.woff2') format("woff2"),
		url('#{$Roboto-Thin-eot}/Roboto-Medium.woff') format("woff"),
		url('#{$Roboto-Thin-eot}/Roboto-Medium.ttf') format("truetype"),
		url('#{$Roboto-Thin-eot}/Roboto-Medium.svg#Roboto') format("svg");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "Roboto-Medium";
	src: url('#{$Roboto-Thin-eot}/Roboto-Medium.eot');
	src: local("Roboto Medium"), local("Roboto-Medium"),
		url('#{$Roboto-Thin-eot}/Roboto-Medium.eot?#iefix') format("embedded-opentype"),
		url('#{$Roboto-Thin-eot}/Roboto-Medium.woff2') format("woff2"),
		url('#{$Roboto-Thin-eot}/Roboto-Medium.woff') format("woff"),
		url('#{$Roboto-Thin-eot}/Roboto-Medium.ttf') format("truetype"),
		url('#{$Roboto-Thin-eot}/Roboto-Medium.svg#Roboto') format("svg");
}

@font-face {
	font-family: 'Roboto';
	src: url('#{$Roboto-Thin-eot}/Roboto-MediumItalic.eot');
	src: local("Roboto MediumItalic"), local("Roboto-MediumItalic"),
		url('#{$Roboto-Thin-eot}/Roboto-MediumItalic.eot?#iefix') format("embedded-opentype"),
		url('#{$Roboto-Thin-eot}/Roboto-MediumItalic.woff2') format("woff2"),
		url('#{$Roboto-Thin-eot}/Roboto-MediumItalic.woff') format("woff"),
		url('#{$Roboto-Thin-eot}/Roboto-MediumItalic.ttf') format("truetype"),
		url('#{$Roboto-Thin-eot}/Roboto-MediumItalic.svg#Roboto') format("svg");
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: "Roboto-MediumItalic";
	src: url('#{$Roboto-Thin-eot}/Roboto-MediumItalic.eot');
	src: local("Roboto MediumItalic"), local("Roboto-MediumItalic"),
		url('#{$Roboto-Thin-eot}/Roboto-MediumItalic.eot?#iefix') format("embedded-opentype"),
		url('#{$Roboto-Thin-eot}/Roboto-MediumItalic.woff2') format("woff2"),
		url('#{$Roboto-Thin-eot}/Roboto-MediumItalic.woff') format("woff"),
		url('#{$Roboto-Thin-eot}/Roboto-MediumItalic.ttf') format("truetype"),
		url('#{$Roboto-Thin-eot}/Roboto-MediumItalic.svg#Roboto') format("svg");
}

@font-face {
	font-family: 'Roboto';
	src: url('#{$Roboto-Thin-eot}/Roboto-Bold.eot');
	src: local("Roboto Bold"), local("Roboto-Bold"),
		url('#{$Roboto-Thin-eot}/Roboto-Bold.eot?#iefix') format("embedded-opentype"),
		url('#{$Roboto-Thin-eot}/Roboto-Bold.woff2') format("woff2"),
		url('#{$Roboto-Thin-eot}/Roboto-Bold.woff') format("woff"),
		url('#{$Roboto-Thin-eot}/Roboto-Bold.ttf') format("truetype"),
		url('#{$Roboto-Thin-eot}/Roboto-Bold.svg#Roboto') format("svg");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "Roboto-Bold";
	src: url('#{$Roboto-Thin-eot}/Roboto-Bold.eot');
	src: local("Roboto Bold"), local("Roboto-Bold"),
		url('#{$Roboto-Thin-eot}/Roboto-Bold.eot?#iefix') format("embedded-opentype"),
		url('#{$Roboto-Thin-eot}/Roboto-Bold.woff2') format("woff2"),
		url('#{$Roboto-Thin-eot}/Roboto-Bold.woff') format("woff"),
		url('#{$Roboto-Thin-eot}/Roboto-Bold.ttf') format("truetype"),
		url('#{$Roboto-Thin-eot}/Roboto-Bold.svg#Roboto') format("svg");
}

@font-face {
	font-family: 'Roboto';
	src: url('#{$Roboto-Thin-eot}/Roboto-BoldItalic.eot');
	src: local("Roboto BoldItalic"), local("Roboto-BoldItalic"),
		url('#{$Roboto-Thin-eot}/Roboto-BoldItalic.eot?#iefix') format("embedded-opentype"),
		url('#{$Roboto-Thin-eot}/Roboto-BoldItalic.woff2') format("woff2"),
		url('#{$Roboto-Thin-eot}/Roboto-BoldItalic.woff') format("woff"),
		url('#{$Roboto-Thin-eot}/Roboto-BoldItalic.ttf') format("truetype"),
		url('#{$Roboto-Thin-eot}/Roboto-BoldItalic.svg#Roboto') format("svg");
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: "Roboto-BoldItalic";
	src: url('#{$Roboto-Thin-eot}/Roboto-BoldItalic.eot');
	src: local("Roboto BoldItalic"), local("Roboto-BoldItalic"),
		url('#{$Roboto-Thin-eot}/Roboto-BoldItalic.eot?#iefix') format("embedded-opentype"),
		url('#{$Roboto-Thin-eot}/Roboto-BoldItalic.woff2') format("woff2"),
		url('#{$Roboto-Thin-eot}/Roboto-BoldItalic.woff') format("woff"),
		url('#{$Roboto-Thin-eot}/Roboto-BoldItalic.ttf') format("truetype"),
		url('#{$Roboto-Thin-eot}/Roboto-BoldItalic.svg#Roboto') format("svg");
}

@font-face {
	font-family: 'Roboto';
	src: url('#{$Roboto-Thin-eot}/Roboto-Black.eot');
	src: local("Roboto Black"), local("Roboto-Black"),
		url('#{$Roboto-Thin-eot}/Roboto-Black.eot?#iefix') format("embedded-opentype"),
		url('#{$Roboto-Thin-eot}/Roboto-Black.woff2') format("woff2"),
		url('#{$Roboto-Thin-eot}/Roboto-Black.woff') format("woff"),
		url('#{$Roboto-Thin-eot}/Roboto-Black.ttf') format("truetype"),
		url('#{$Roboto-Thin-eot}/Roboto-Black.svg#Roboto') format("svg");
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: "Roboto-Black";
	src: url('#{$Roboto-Thin-eot}/Roboto-Black.eot');
	src: local("Roboto Black"), local("Roboto-Black"),
		url('#{$Roboto-Thin-eot}/Roboto-Black.eot?#iefix') format("embedded-opentype"),
		url('#{$Roboto-Thin-eot}/Roboto-Black.woff2') format("woff2"),
		url('#{$Roboto-Thin-eot}/Roboto-Black.woff') format("woff"),
		url('#{$Roboto-Thin-eot}/Roboto-Black.ttf') format("truetype"),
		url('#{$Roboto-Thin-eot}/Roboto-Black.svg#Roboto') format("svg");
}

@font-face {
	font-family: 'Roboto';
	src: url('#{$Roboto-Thin-eot}/Roboto-BlackItalic.eot');
	src: local("Roboto BlackItalic"), local("Roboto-BlackItalic"),
		url('#{$Roboto-Thin-eot}/Roboto-BlackItalic.eot?#iefix') format("embedded-opentype"),
		url('#{$Roboto-Thin-eot}/Roboto-BlackItalic.woff2') format("woff2"),
		url('#{$Roboto-Thin-eot}/Roboto-BlackItalic.woff') format("woff"),
		url('#{$Roboto-Thin-eot}/Roboto-BlackItalic.ttf') format("truetype"),
		url('#{$Roboto-Thin-eot}/Roboto-BlackItalic.svg#Roboto') format("svg");
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: "Roboto-BlackItalic";
	src: url('#{$Roboto-Thin-eot}/Roboto-BlackItalic.eot');
	src: local("Roboto BlackItalic"), local("Roboto-BlackItalic"),
		url('#{$Roboto-Thin-eot}/Roboto-BlackItalic.eot?#iefix') format("embedded-opentype"),
		url('#{$Roboto-Thin-eot}/Roboto-BlackItalic.woff2') format("woff2"),
		url('#{$Roboto-Thin-eot}/Roboto-BlackItalic.woff') format("woff"),
		url('#{$Roboto-Thin-eot}/Roboto-BlackItalic.ttf') format("truetype"),
		url('#{$Roboto-Thin-eot}/Roboto-BlackItalic.svg#Roboto') format("svg");
}
