.dropup,
.dropright,
.dropdown,
.dropleft {
	position: relative;
}

.dropdown-toggle {
	&::after {
		display: inline-block;
		width: 0;
		height: 0;
		margin-left: 0.255em;
		vertical-align: 0.255em;
		content: "";
		border-top: 0.3em solid;
		border-right: 0.3em solid transparent;
		border-bottom: 0;
		border-left: 0.3em solid transparent;
	}
	&:empty::after {
		margin-left: 0;
	}
}

.dropdown-menu {
	position: absolute;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 10rem;
	padding: 0.5rem 0;
	margin: 0.125rem 0 0;
	font-size: 1rem;
	color: #404040;
	text-align: left;
	list-style: none;
	background-color: #fff;
	//background-clip: padding-box;
	border: 1px solid #e3e3e3;

	//padding: 7px 0px;
	min-width: 185px;
	line-height: 18px;
}

.dropdown-menu-right {
	right: 0;
	left: auto;
}

.dropup {
	.dropdown-menu {
		top: auto;
		bottom: 100%;
		margin-top: 0;
		margin-bottom: 0.125rem;
	}
	.dropdown-toggle {
		&::after {
			display: inline-block;
			width: 0;
			height: 0;
			margin-left: 0.255em;
			vertical-align: 0.255em;
			content: "";
			border-top: 0;
			border-right: 0.3em solid transparent;
			border-bottom: 0.3em solid;
			border-left: 0.3em solid transparent;
		}
		&:empty::after {
			margin-left: 0;
		}
	}
}

.dropright {
	.dropdown-menu {
		top: 0;
		right: auto;
		left: 100%;
		margin-top: 0;
		margin-left: 0.125rem;
	}
	.dropdown-toggle {
		&::after {
			display: inline-block;
			width: 0;
			height: 0;
			margin-left: 0.255em;
			vertical-align: 0.255em;
			content: "";
			border-top: 0.3em solid transparent;
			border-right: 0;
			border-bottom: 0.3em solid transparent;
			border-left: 0.3em solid;
		}
		&:empty::after {
			margin-left: 0;
		}
		&::after {
			vertical-align: 0;
		}
	}
}

.dropleft {
	.dropdown-menu {
		top: 0;
		right: 100%;
		left: auto;
		margin-top: 0;
		margin-right: 0.125rem;
	}
	.dropdown-toggle {
		&::after {
			display: inline-block;
			width: 0;
			height: 0;
			margin-left: 0.255em;
			vertical-align: 0.255em;
			content: "";
			display: none;
		}
		&::before {
			display: inline-block;
			width: 0;
			height: 0;
			margin-right: 0.255em;
			vertical-align: 0.255em;
			content: "";
			border-top: 0.3em solid transparent;
			border-right: 0.3em solid;
			border-bottom: 0.3em solid transparent;
		}
		&:empty::after {
			margin-left: 0;
		}
		&::before {
			vertical-align: 0;
		}
	}
}

.dropdown-menu {
	&[x-placement^="top"],
	&[x-placement^="right"],
	&[x-placement^="bottom"],
	&[x-placement^="left"] {
		right: auto;
		bottom: auto;
	}
}

.dropdown-divider {
	height: 0;
	margin: 0.5rem 0;
	overflow: hidden;
	border-top: 1px solid #e5e5e5;
}

.dropdown-item {
	display: block;
	width: 100%;
	padding: 0.25rem 1.5rem;
	clear: both;
	font-weight: 400;
	color: #212529;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
	&:hover,
	&:focus {
		color: #16181b;
		text-decoration: none;
		background-color: #f5f5f5;
	}
	&.active,
	&:active {
		color: $white;
		text-decoration: none;
		background-color: $primarycolor;
	}
	&.disabled,
	&:disabled {
		color: $grey87;
		background-color: transparent;
	}
}

.dropdown-menu.show {
	display: block;
}

.dropdown-header {
	display: block;
	padding: 0.5rem 1.5rem;
	margin-bottom: 0;
	font-size: 0.85rem;
	color: $grey87;
	white-space: nowrap;
}

.dropdown-item-text {
	display: block;
	padding: 0.25rem 1.5rem;
	color: #212529;
}

.dropdown-toggle-split {
	padding-right: 0.5625rem;
	padding-left: 0.5625rem;
	&::after {
		margin-left: 0;
	}
}

.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
	margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
	margin-right: 0;
}
