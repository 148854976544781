// base colors info

$blue: #007fa3; // Pearson Blue
$lightblue: #0087ad;
$Inkblue: #005a70;
$marineturquoise: #12b2a6;
$iceblue: #d4eae4;
$grey: #505759;
$dcGrey: #dcdcdc;
$grey87: #878787;
$grey999: #999;
$greyc3: #c3c3c3;
$red: #db0020;
$midnightblue: #003057;
$limegreen: #d2db0e;
$white: #ffffff;
$lightgrey: #dddddd;
$green: #008638;
$black: #000;
$e9grat: #e9e9e9;
$black25: #252525;
$greyC7: #c7c7c7;
$greyD9: #d9d9d9;
// setting color info

$primarycolor: $blue;
$secondarycolor: $e9grat;
$background: $iceblue;
$cardbackground: $white;
$primarytextcolor: $grey;

// button color

$primarybutton: $primarycolor;
$secondarybutton: $e9grat;
$secondaryhover: $greyD9;
