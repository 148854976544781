#___reactour {
  div.reactour__helper--is-open {
    padding: 15px 15px;
    div[data-tour-elem="controls"] {
      margin-top: 12px;
      nav {
        margin: auto;
      }
    }
    div.reactour-title {
      color: black;
      font-weight: bold;
    }
    div.reactour-message {
      color: black;
      padding-top: 10px;
    }
    div.reactour-footer {
      padding-top: 10px;
      display: flex;
      justify-content: space-between;
      label {
        margin: auto 0;
        font-size: 12px;
        color: black;
      }
      button {
        padding: 8px 16px;
      }
    }
  }
}
