.footer1 {
  display: grid;
  background-color: #198cc5 !important;
}

.footer1 {
  //   margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.footers-copyright1 {
  // font-family: Avenir;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  color: #ffffff;
  text-transform: capitalize;
}

.footer1 .footer-copyright1 a {
  // font-family: Avenir;
  font-size: 15px;
  line-height: 23px;
  color: #fff !important;
  font-weight: 400;
  text-decoration: underline;
  text-transform: capitalize;
}

.footer1 .footer-copyright1 span {
  color: #fff !important;
}

.footer-bottom {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
}

.bottom-footer {
  margin-top: -60px !important;
}
.footer-copyright1 {
  display: flex;
  flex-direction: column;
  margin: 0 auto !important;
}

.footer-part1 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.footer-part2 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
// new footer css starts here
.new-footer,
.copyright {
  text-align: center;
  max-width: 1342px;
  margin: 8px auto;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  text-transform: capitalize;
  color: #ffffff;
}
.new-footer a {
  display: inline-block;
  padding: 4px 10px;
  // text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  text-decoration-line: underline;
  text-transform: capitalize;
  color: #ffffff;
}
.new-footer a:hover {
  text-decoration: underline;
}
.updated-footers {
  background: #198cc5;
  padding: 16px 0px;
}
// new footer css ends here
