.pop_up_view {
  @media (max-width: 1360px) {
    background-color: #fff;
  }
  .p-score {
    i {
      margin-left: 4px;
      font-size: 24px;
      vertical-align: sub;
      display: inline;
      color: rgba(0, 0, 0, 0.54);
    }
  }
  .be-content {
    padding: 0px;
    #a {
      & > .row {
        margin: 0px;
      }
      .card {
        width: 100%;
        max-width: 1360px;
        // margin: 0 auto 15px auto; //old code to show footer overlap
        // margin: 0 auto 60px auto;
        margin: 0 auto 0 auto;

        border-top-left-radius: 0;
        border-top-right-radius: 0;
        padding-bottom: 0px;
        @media (max-width: 1360px) {
          border: 0px;
          box-shadow: none;
          // height: 100%;
        }
        &.b2c {
          max-width: none;
        }
        &#studygroup {
          section#menu {
            table {
              width: 100%;
              padding: 0 20px;
              thead {
                tr {
                  cursor: default;
                  &.tab {
                    cursor: pointer;
                  }

                  &.tab:hover {
                    background-image: linear-gradient(
                      to bottom,
                      #f0f0f0,
                      #d3d3d3
                    );

                    th {
                      background-image: linear-gradient(
                        to bottom,
                        #f0f0f0,
                        #d3d3d3
                      );
                    }
                  }
                  &.tab:active {
                    background-image: linear-gradient(to bottom, #f0f0f0, #ccc);
                    th {
                      background-image: linear-gradient(
                        to bottom,
                        #f0f0f0,
                        #ccc
                      );
                    }
                  }
                  th {
                    background-image: linear-gradient(
                      to bottom,
                      #f0f0f0,
                      #dddddd
                    );
                    height: 50px;
                    position: -webkit-sticky;
                    position: sticky;
                    // top: 95px;
                    z-index: 1;
                    -webkit-transition: 2s; /* Safari prior 6.1 */
                    transition: 2s;
                    font-size: 18px;
                    padding: 0 15px;
                    //font-family: OpenSans;
                    .tab {
                      display: flex;
                      white-space: nowrap;
                      .mdi {
                        font-size: 25px;
                        // padding-right: 5px;
                        &.mdi-chevron-right {
                          padding-right: 0px;
                          padding-left: 3px;
                        }
                      }
                    }
                    &.answers {
                      @media (max-width: 500px) {
                        display: none;
                      }
                    }
                    &.none {
                      width: 100%;
                      // @media (max-width: 500px) {
                      //   display: none;
                      // }
                    }
                    &.empty-first {
                      width: 35px;
                      min-width: 35px;
                      padding-right: 0px;
                      @media (max-width: 500px) {
                        width: 5px;
                        min-width: 5px;
                        padding-right: 0px;
                      }
                    }
                    // &:nth-child(1) {
                    //   width: 35px;
                    //   min-width: 35px;
                    //   padding-right: 0px;
                    //   @media (max-width: 500px) {
                    //     padding-left: 8px;
                    //   }
                    // }
                    &.level-name,
                      &.book-name,
                      // &.exercise {
                      //   white-space: nowrap;
                      //   //width: 100%;
                      //   padding-left: 0px;
                      //   max-width: 799px;
                      //   font-weight: normal;
                      //   padding-left: 0px;
                      //   @media (max-width: 500px) {
                      //     width: 100%;
                      //   }
                      //   /*&.exercise {
                      //       font-size: 14px;
                      //       font-weight: 600;
                      //     }*/
                      // }
                      // &.exercise {
                      //   width: 100%;
                      //   @media (max-width: 500px) {
                      //     padding-left: 8px;
                      //   }
                      // }
                      &.exercise {
                      // white-space: nowrap;
                      //width: 100%;
                      color: #000000;
                      font-size: 14px;
                      font-weight: bold;
                      padding-left: 0px;
                      // max-width: 799px;
                      // font-weight: normal;
                      // padding-left: 0px;
                      @media (max-width: 500px) {
                        width: 100%;
                      }
                      /*&.exercise {
                          font-size: 14px;
                          font-weight: 600;
                        }*/
                    }
                    &.exercise {
                      width: 40%;
                      @media (max-width: 500px) {
                        padding-left: 8px;
                      }
                    }
                    /*
                        &:nth-child(3) {
                          width: 100%;
                          @media (max-width: 500px) {
                            display: none;
                          }
                        }
                        */
                    /*
                        &:nth-child(4) {
                          width: 15%;
                          font-size: 14px;
                          font-weight: 600;
                          max-width: 180px;
                          @media (max-width: 500px) {
                            display: none;
                            width: auto;
                            min-width: auto;
                          }
                        }
                        */
                    &.submitted {
                      color: #000000;
                      font-size: 14px;
                      font-weight: 600;
                      white-space: nowrap;
                      @media (max-width: 500px) {
                        display: none;
                      }
                    }
                    &.viewable {
                      color: #000000;
                      font-size: 14px;
                      font-weight: 600;

                      white-space: nowrap;
                      @media (max-width: 500px) {
                        display: none;
                      }
                    }
                    &.redoable {
                      color: #000000;
                      font-size: 14px;
                      font-weight: 600;

                      white-space: nowrap;
                      @media (max-width: 500px) {
                        display: none;
                      }
                    }
                    &.p-score {
                      color: #000000;
                      cursor: pointer;
                      width: 12%;
                      font-size: 14px;
                      font-weight: 600;
                      min-width: 180px;
                      @media (max-width: 500px) {
                        min-width: 0px;
                        display: none;
                      }
                      &.mobile {
                        display: none;
                        @media (max-width: 500px) {
                          display: table-cell;
                          padding-right: 1px;
                        }
                      }
                    }
                    &.answers {
                      white-space: nowrap;
                      padding-left: 0;
                      font-size: 14px;
                      font-weight: 600;
                    }
                    &:nth-child(6) {
                      width: 10%;

                      // padding-left: 0;
                      font-size: 14px;
                      font-weight: 600;
                    }
                    // &:last-child {
                    //   width: 35px;
                    //   min-width: 35px;
                    // }
                    .title {
                      div {
                        max-width: 400px;
                        width: 100%;
                      }
                    }
                  }
                }
              }
              tbody {
                tr {
                  cursor: pointer;
                  -webkit-transition: 0.15s; /* Safari prior 6.1 */
                  transition: 0.15s;

                  &:hover {
                    background-color: #ebf9ff;
                  }
                  &.assessment_row_dim {
                    opacity: 0.3;
                    cursor: default;
                    &:hover {
                      background-color: #ffffff;
                    }
                  }

                  &:active {
                    background-color: #d4f2ff;
                  }
                  td {
                    .assessment-document-icon {
                      position: absolute;
                    }
                    white-space: nowrap;
                    height: 44px;
                    border-bottom: 1px solid #e8e8e8;
                    padding: 5px 19px;
                    @media (max-width: 500px) {
                      padding: 5px 13px;
                    }
                    font-size: 16px;
                    &.none {
                      // @media (max-width: 500px) {
                      //   display: none;
                      // }
                      @media (max-width: 500px) {
                        padding: 5px 5px;
                      }
                    }
                    &.sub-title {
                      padding-left: 0px;
                      padding-right: 0px;
                      width: 100%;
                      position: relative;
                      .unit-name {
                        color: #007fa3;
                        font-size: 15px;
                        max-width: 500px;
                        white-space: normal;
                        //display: table;
                        @media (max-width: 500px) {
                          width: 100%;
                        }
                      }
                    }
                    &.other {
                      padding-left: 0px;
                      width: 35%;
                      .unit-name {
                        color: #007fa3;
                        font-size: 15px;
                        //display: table;
                      }
                      @media (max-width: 500px) {
                        display: none;
                      }
                    }
                    &.empty:first-child {
                      width: 35px;
                      min-width: 35px;
                      //min-width: 50px;
                    }
                    &.empty:last-child {
                      width: 35px;
                      text-align: center;
                      font-size: 20px;
                    }
                    &.submitted {
                      font-size: 14px;
                      i {
                        font-size: 24px;
                        color: #84bd00;
                      }
                      @media (max-width: 500px) {
                        display: none;
                      }
                    }
                    &.title {
                      //width: 5%;
                      font-weight: 600;
                      color: #007fa3;
                      padding-left: 0px;
                      @media (max-width: 500px) {
                        padding-left: 2px;
                      }
                      div {
                        white-space: normal;
                        //max-width: 400px;
                        @media (max-width: 500px) {
                          //max-width: 200px;
                        }
                        // &.study-group,
                        // &.assessment {
                        //   display: flex;
                        // }
                        &.study-group {
                          display: flex;
                        }
                        &.assessment {
                          display: flex;
                          flex-direction: column;
                        }
                        min-height: 25px;
                        &.study-group {
                          i {
                            font-size: 20px;
                            line-height: initial;
                          }
                          div.name {
                            padding-left: 5px;
                          }
                        }

                        .order-number {
                          // color: #000000;
                          // font-size: 16px;
                          // min-height: 25px;
                          color: #000000;
                          font-size: 16px;
                          min-height: 25px;
                          min-width: 30px;
                          white-space: nowrap;
                          margin-right: 5px;
                        }
                        .assessment-name {
                          padding-left: 30px;
                          // padding-left: 15px;
                          // font-size: 15px;
                          // min-height: 22px;
                          // margin: auto 0;
                          // padding-left: 25px;
                          font-size: 15px;
                          min-height: 22px;
                          margin: auto 0;
                          @media (max-width: 500px) {
                            padding-left: 20px;
                          }
                        }
                        &.unit-box {
                          display: flex;

                          > .unit-name {
                            padding-left: 15px;
                          }
                          .label {
                            color: #000000;
                            font-weight: 600;
                            //display: table;
                            white-space: nowrap;
                            min-height: 25px;
                          }
                        }
                      }
                    }
                    &.center {
                      width: 15%;
                    }
                    &.info {
                      //width: 30%;

                      .progress {
                        display: flex;

                        .text {
                          font-size: 14px;
                          color: #008638;
                          margin: auto 0;
                          min-width: 37px;
                        }
                        .follow-up-text {
                          font-weight: 600;
                          font-size: 14px;
                          line-height: 18px;
                          text-align: center;
                          color: #007fa3;
                          align-self: center;
                          place-self: center;
                          margin-left: 25px;
                        }
                        button {
                          margin-left: 25px;
                          @media (max-width: 500px) {
                            display: none;
                          }
                        }
                        .progress-bar {
                          margin: auto;
                          margin-left: 5px;
                          width: 100%;
                          @media (max-width: 500px) {
                            display: none;
                          }
                        }
                      }
                    }
                    &.answer {
                      font-size: 14px;
                      padding-left: 0;
                      @media (max-width: 500px) {
                        display: none;
                      }
                    }
                  }
                }
              }
            }
          }
          #assessment.card.learnosity {
            box-shadow: none;
            padding-bottom: 0px;
            // .body {
            // @media (max-width: 599.9px) {
            //   padding-top: 35px;
            // }
            // @media (max-width: 768px) {
            //   padding: 0 8px;
            // }
            // @media (max-width: 400px) {
            //   padding: 0 4px;
            // }
            // }
          }
        }
      }
    }
  }
}
.result-page-footer-section {
  margin-top: -97px;
  z-index: 9;
}
