.ReactModal__Content.ReactModal__Content--after-open {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  right: auto !important;
  bottom: auto !important;
  background: rgb(255, 255, 255);
  // overflow: visible !important;
  outline: none !important;
  width: 100% !important;
  &.img-title {
    width: 95% !important;
  }
  // @media (max-width: 645px) {
  //   width: 95% !important;
  // }
  border-radius: 4px !important;
  margin-right: -50% !important;
  transform: translate(-50%, -50%) !important;
  max-height: 100% !important;
  padding: 0px !important;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.5);
  .ios-speech-body {
    margin: 30px 20px;
    text-align: center;
    .mic-icon {
      width: 150px;
      margin: 0 auto;
      margin-bottom: 20px;
      border-radius: 100%;
      //box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
      height: 150px;
      //box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, .3);
      i.mdi-mic {
        font-size: 100px;
        margin: 25px 0px;
        width: 60px;
        height: 100px;
        color: #11b2a6;
      }
      animation-name: pulse;
      animation-duration: 1.8s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-out;
      @keyframes pulse {
        0% {
          box-shadow: 0px 0px 5px 2px rgba(173, 0, 0, 0.3);
          //opacity: 1;
          //transform: scale(1);
        }
        65% {
          box-shadow: 0px 0px 10px 13px rgba(173, 0, 0, 0.1);
          //opacity: 0.6;
          //transform: scale(1.06);
        }
        100% {
          box-shadow: 0px 0px 5px 2px rgba(173, 0, 0, 0.3);
          //opacity: 0;
          //transform: scale(1);
        }
      }
    }
    .text {
      font-size: 25px;
      margin-bottom: 50px;
    }
    .footer-btn {
      margin: 0 95px;
    }
  }
  .modal-header {
    border: 0;
    // padding: 30px 40px 1rem 40px;
    padding: 40px 40px 24px 40px;
    display: inline-block;
    // display: -webkit-box;
    // display: -ms-flexbox;
    // display: flex;
    // -webkit-box-align: start;
    // -ms-flex-align: start;
    // align-items: flex-start;
    // -webkit-box-pack: justify;
    // -ms-flex-pack: justify;
    // justify-content: space-between;
    // border-bottom: 1px solid #dddddd;
    background-color: #ffffff;
    font-size: 24px;
    @media (max-width: 599px) {
      padding: 32px 16px 20px 16px;
      font-size: 21px;
    }
    &.messagebox {
      border-bottom: none;
    }
    &.messageboxgc {
      border-bottom: none;
      width:100% !important;
    }

    &.assignment-student-head {
      border-bottom: none !important;
      .asignment-title {
        // margin: 0 10px 16px 10px;
        // font-size: 24px;

        // font-weight: normal;
        // font-stretch: normal;
        // font-style: normal;
        // line-height: normal;
        margin: 0;
        letter-spacing: normal;
        color: #505759;
        font-size: 24px;
        @media (max-width: 599px) {
          font-size: 21px;
        }
        p {
          font-size: 24px;
          @media (max-width: 599px) {
            font-size: 21px;
          }
          margin: 0;
          line-height: normal;
        }
      }
    }

    .react-modal-header-overflow-image {
      position: absolute;
      top: -15px;
      width: 100%;
      left: 0;
      text-align: center;
      opacity: 1;
      img {
        width: 88px;
        /* opacity: 1; */
        background: #fff;
        border-radius: 100%;
        /* box-shadow: 4px 4px 4px 4px rgba(255, 255, 255, 0.5); */
        border: 3px solid #fff;
      }
    }
    &.icon-header {
      display: block;
      // border-bottom: 1px solid #c7c7c7;
    }

    .modal-title {
      margin: 0;
      line-height: 1.428571;
      // font-size: 24px;
      color: #505759;

      font-weight: 300;
      font-size: 24px;
      @media (max-width: 599px) {
        font-size: 21px;
      }
      &.countdown {
        padding: 0 15px;
      }
      &.with-overflow-image {
        width: 100%;
        // text-align: center;
        padding-top: 10px;
        font-weight: normal;
        font-size: 24px;

        &.score_title {
          text-align: center;
          color: #505759;
          padding-top: 45px;
        }
      }
    }
    .modal-sub-title {
      // font-weight: normal;
      font-size: 24px;
      @media (max-width: 599px) {
        font-size: 21px;
      }
      // text-align: center;
      padding-top: 5px;
      margin-bottom: 0;
    }
  }
  #advance_viewRedo {
    .modal-body {
      padding: 0px;
    }
  }
  #viewRedo {
    .modal-body {
      padding: 0px;
    }
    .modal-sub-title {
      font-size: 24px;
      @media (max-width: 599px) {
        font-size: 21px;
      }
      // text-align: center;
      padding-top: 5px;
    }
  }
  #image.light-box,
  #video.light-box {
    position: absolute;
    /* margin: auto 0; */
    /* height: 80%; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* text-align: center; */
    top: 0;
    bottom: 0px;
    left: 0;
    right: 0;
    .light-box-top-tool {
      position: absolute;
      top: 0;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      z-index: 1;
      button {
        width: 54px;
        height: 54px;
        margin: 5px;
        border: none;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.5);
        transition: 0.2s background-color;

        cursor: pointer;
        i {
          font-size: 24px;
          color: #fff;
          @media (max-height: 400px) {
            font-size: 18px;
          }
        }
        @media (max-height: 400px) {
          width: 34px;
          height: 34px;
        }
        &:hover {
          background-color: rgba(0, 0, 0, 0.7);
          // i {
          //   color: #fff;
          // }
        }
      }
    }
    .light-box-body {
      width: 100%;
      max-width: 1032px;

      &#vimeo {
        height: 80%;

        .video-element {
          display: flex;
          justify-content: center;
        }
      }
      &.image {
        height: 80%;
        width: 80%;
        text-align: center;
        display: flex;
        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
          margin: auto;
        }
      }
      .element {
        width: 100%;
        height: 80%;
      }
      &#youtube {
        height: 80%;
        .youtube {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .modal-body {
    div {
      .check_is_Assesssment {
        .check_is_Assesssment_warning {
          color: #db0020;
          font-size: 16px;
          img {
            width: 22px;
            height: 19px;
          }
        }
        font-size: 16px;
      }
    }
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    // max-height: 400px;
    // padding: 1rem 40px 1rem 40px;
    padding: 0 40px 0px 40px;
    min-height: 60px;
    overflow-y: auto;
    @media (max-width: 599px) {
      padding: 0 16px 0px 16px;
    }
    &.border-top {
      border-top: 1px solid #dddddd;
    }
    &.border-bottom {
      border-bottom: 1px solid #dddddd;
    }
    &.assignment-student-list {
      // max-height: calc(90vh - 220px);

      padding: 0px !important;
      // margin-bottom: 20px;
      .student-list {
        // max-height: 450px;
        // overflow-y: auto;
      }
    }
    &.with-image-pop-up {
      // padding-bottom: 1rem;
      padding-top: 0px;
      // padding-left: 0;
      // padding-right: 0;
      .score_desc {
        margin-top: 10px;
        font-size: 16px;
        color: #000000;
      }
      .container {
        padding: 0;
      }
      .class_title {
        display: flex;
        justify-content: space-between;
        padding: 12px 40px;
        @media (max-width: 599px) {
          padding: 12px 16px;
        }
        background-image: linear-gradient(to bottom, #f0f0f0, #ddd);
        color: #000000;
      }
      .class-list {
        padding: 0;
        overflow: auto;
        max-height: 250px;
        .item {
          display: flex;
          justify-content: space-between;
          height: 44px;
          padding: 12px 40px;
          @media (max-width: 599px) {
            padding: 12px 16px;
          }
          border-bottom: 1px solid #dddddd;
          .title {
            display: flex;
            color: #000000;
            i {
              font-size: 19px;
            }
            h5 {
              font-size: 16px;
              font-weight: 600;
              margin: auto;
            }
          }
        }
        &.QA {
          padding: 22px;
          .h5 {
            font-size: 16px;
          }
          ul {
            list-style: none;
            padding-top: 22px;

            font-size: 16px;
            li:before {
              content: "\F2FB";
              position: absolute;
              left: 45px;
              font: normal normal normal 14px/1 "Material Icons";
              transform: translateY(1px);
              font-size: inherit;
            }
            h5 {
              font-weight: 600;
            }
          }
        }
      }
    }
    &.smartclassTPC-popup {
      max-height: 600px;
      .container {
        .subtitle {
          font-size: 16px;
          color: #505759;
          max-width: 518px;
          width: 100%;
          text-align: left;
          padding-bottom: 18px;
        }
        img {
          max-width: 518px;
          max-height: 294px;
          width: 100%;
          height: 100%;
          padding-bottom: 16px;
        }
        .count-down {
          font-size: 16px;
          color: #505759;
          padding-bottom: 8px;
          padding-top: 6px;
        }
        .dont-show {
          font-size: 12px;
          color: #505759;
          padding-top: 14px;
          padding-bottom: 18px;
          label {
            display: flex;
            justify-content: center;
            line-height: 1;
            div {
              padding-left: 5px;
            }
          }
        }
      }
    }

    ul {
      li {
        font-size: 14px;

        color: #505759;
      }
    }
    .qrCode {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      & > div {
        max-width: 140px;
        width: 100%;
        text-align: center;
        img {
          width: 140px;
        }
      }

      a {
        line-height: 25px;
        -webkit-transition: 0.15s ease-in;
        -moz-transition: 0.15s ease-in;
        -o-transition: 0.15s ease-in;
        transition: 0.15s ease-in;

        text-decoration: none;
        display: inline-block;
        margin: 0 2px 0 0;
        background-color: transparent;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        padding: 7px 5px;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.6) !important;
        margin: 0 auto;
        height: 25px;
        line-height: 25px;
        width: 100px;
        &.android {
          width: 90px;
        }
        &.ios {
          width: 60px;
        }
        &.web {
          width: 80px;
        }
        img {
          width: 18px;
          padding-right: 5px;
          padding-left: 5px;
          display: block;
          float: left;
          padding-top: 2px;
        }
        &:hover {
          background-color: #fafafa;
          border-color: #aaa;
        }
      }
    }
  }
  .modal-footer > .modal-btn-right {
    float: right;
    // padding: 0px 18px;
    button {
      margin-left: 5px;
      width: auto;
    }
  }
  .modal-footer {
    width: 100%;

    padding: 20px 40px 40px 40px;
    border: none;
    display: inline-block;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    // border-top: 1px solid #dddddd;
    @media (max-width: 599px) {
      // padding-bottom: 24px;
      padding: 20px 16px 24px 16px;
    }
    &.messagebox {
      border-top: none;
    }
    &.scorebox {
      // padding: 0px;
      // height: 68px;
    }
  }
  .score_btn {
    // margin-top: 16px;
  }
  .react-modal-close {
    top: -20px;
    right: -8px;
    &.normal {
      top: -10px;
      right: 0px;
    }

    background: transparent;
    border: 0;
    border-radius: 0;
    color: #ccc;
    cursor: pointer;
    opacity: 0.8;
    padding: 8px;
    position: absolute;
    z-index: 401;
    display: inline-block;
    height: 44px;
    margin: 8px 0px;
    transition: color 0.2s;
    vertical-align: top;
    visibility: inherit;
    width: 44px;
    -webkit-transition: color 0.2s;
    &.circle {
      padding: 3px;
      border-radius: 100%;
      width: 42px;
      height: 42px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      background: white;
      opacity: 1;
      top: -4px;
      right: 4px;
      div {
        height: 100%;
        border-radius: 100%;
        background-color: #888888;
        i {
          font-size: 24px;
          margin: 6px;
          color: #fff;
        }
      }
    }
    // &.design_assign {

    // }
  }
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  position: fixed !important;
  top: 0px !important;
  left: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  // z-index: 99992 !important;
  z-index: 99995 !important;
  background-color: rgba(0, 0, 0, 0.75) !important;
}
